<template>
  <div class="content">
    <div class="estados">
      <v-data-table
        class="table"
        :headers="headers"
        :items="
          accionesSituacionOperativa.length ? accionesSituacionOperativa : []
        "
        :height="mobile ? 400 : 160"
        :loading="isLoading"
        hide-default-footer
        disable-pagination
        fixed-header
        dense
      >
        <template #no-data>
          <p>Datos no disponibles.</p>
        </template>

        <!-- ACCIONES CABECERA -->
        <template #[`header.ACCIONES`]>
          <v-tooltip bottom v-if="comprobarPermisos(['MOD_EMERGENCIAS'])">
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="showAddTipoAccionDialog"
                :disabled="situacionOperativaCompletaYnoEsLaActual()"
                class="add-accion-situacion-operativa"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <span>Añadir acción</span>
          </v-tooltip>
        </template>

        <!-- NOMBRE ACCION + CHECKBOX -->
        <template #[`item.Accion.nombre`]="{ item }">
          <div class="acciones-container">
            <v-checkbox
              v-model="accionChecked"
              :label="item.Accion.nombre"
              :value="item"
              @click="estadoAccionInstanciada(item)"
              :disabled="situacionOperativaCompletaYnoEsLaActual()"
            />
          </div>
        </template>

        <!-- FECHASTAMP -->
        <template #[`item.fechastamp`]="{ item }">
          <span>{{ item.fechastamp | formatDate }}</span>
        </template>

        <!-- ACCIONES -->
        <template #[`item.ACCIONES`]="{ item }">
          <div class="accionesButtons">
            <!-- INFORMACION ACCION -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
              </template>
              <span> {{ item.Accion.descripcion }} </span>
            </v-tooltip>

            <!-- EDITAR ACCION -->
            <v-tooltip bottom v-if="comprobarPermisos(['MOD_EMERGENCIAS'])">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="showEditAccionInstancia(item)"
                  :disabled="situacionOperativaCompletaYnoEsLaActual()"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar acción</span>
            </v-tooltip>

            <!-- BORRAR ACCION -->
            <v-tooltip bottom v-if="comprobarPermisos(['MOD_EMERGENCIAS'])">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="acceptDeleteAccionInstanciada(item)"
                  :disabled="situacionOperativaCompletaYnoEsLaActual()"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span>Borrar acción</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </div>
    <!-- EDIT GRUPO ACCION -->
    <vx-dialog-add-edit-grupo-accion
      :show="showDialog"
      :editedAccionInstancia="editAccionInstancia"
      @cancelar="showDialog = false"
      @editAccion="acceptEditAccionInstancia"
    />

    <!-- ADD TIPO ACCION -->
    <vx-dialog-add-edit-tipo_accion
      :show="showDialogAccion"
      :isEdit="isEdit"
      :editedTipoAccion="editTipoAccion"
      @cancelar="showDialogAccion = false"
      @addTipoAccion="acceptAddTipoAccion"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import DialogAddEditGrupoAccion from './DialogAddEditGrupoAccion.vue'
  import DialogAddEditTipoAccion from '../../acciones/DialogAddEditTipoAccion.vue'

  import api from '../../../api'

  export default {
    components: {
      'vx-dialog-add-edit-grupo-accion': DialogAddEditGrupoAccion,
      'vx-dialog-add-edit-tipo_accion': DialogAddEditTipoAccion,
    },

    props: {
      protocoloIntervencion: Array,
      idEmergenciaSituacion: String,
      isSituacionOperativaActiva: Boolean,
      // accionesSinCompletar: Boolean
    },

    data: () => ({
      showDialog: false,
      editAccionInstancia: {},

      showDialogAccion: false,
      isEdit: false,
      editTipoAccion: {},

      isLoading: false,

      accionChecked: [],

      headers: [
        { text: 'ACCIÓN', value: 'Accion.nombre', sortable: false },
        { text: 'ACTUALIZACIÓN', value: 'fechastamp', sortable: false },
        { text: 'GRUPO ACCIÓN', value: 'Grupo_accion.nombre', sortable: false },
        // { text: 'OBSERVACIONES', value: 'observaciones', sortable: false },
        {
          text: 'ACCIONES',
          value: 'ACCIONES',
          align: 'end',
          width: '25%',
          sortable: false,
        },
      ],

      accionesSituacionOperativa: [],
    }),

    watch: {
      async protocoloIntervencion(newValue) {
        if (newValue) {
          await this.cargarAccionesSitOpe(newValue)
        }
      },
    },
    async mounted() {
      if (this.protocoloIntervencion) {
        await this.cargarAccionesSitOpe(this.protocoloIntervencion)
      }
    },
    async activated() {
      if (this.protocoloIntervencion) {
        await this.cargarAccionesSitOpe(this.protocoloIntervencion)
      }
    },

    computed: {
      mobile() {
        return this.$vuetify.breakpoint.mobile
      },

      ...mapGetters('usuario', ['currentUsuario']),
    },

    methods: {
      ...mapActions('grupoAccion', ['fetchGruposAccion']),
      ...mapActions('accionSituacionEmergencia', [
        'addAccionSituacionEmergencia',
        'updateAccionSituacionEmergencia',
        'deleteAccionSituacionEmergencia',
      ]),

      ...mapActions('tipoAccion', ['fetchTiposAccion', 'addTipoAccion']),

      comprobarPermisos(listaRolesPermisos) {
        return this.$store.getters['usuario/rolesUsuario'](listaRolesPermisos)
      },

      async cargarAccionesSitOpe(data) {
        this.isLoading = true
        try {
          this.accionesSituacionOperativa = data
          await this.fetchGruposAccion()
        } catch (err) {
          // Do nothing
        }
        await this.cargarAccionesCompletadas()
        this.isLoading = false
      },

      cargarAccionesCompletadas() {
        this.accionChecked = []
        for (const instancia of this.accionesSituacionOperativa) {
          if (instancia.completada) {
            this.accionChecked.push(instancia)
          }
        }
      },

      showEditAccionInstancia(accionInstanciada) {
        this.showDialog = true
        this.editAccionInstancia = Object.assign({}, accionInstanciada)
      },

      async acceptEditAccionInstancia(accionInstanciada) {
        this.showDialog = false
        try {
          const response = await this.updateAccionSituacionEmergencia(
            accionInstanciada,
          )
          if (response) {
            await this.actualizarAccionesInstanciadasEmergenciaSituacion(
              accionInstanciada.id_emergencia_situacion_operativa,
            )
            await this.cargarAccionesCompletadas()
            this.notificarActualizacionAccionInstanciaSituacionEmergencia(
              accionInstanciada,
            )
          }
        } catch (err) {
          // Do nothing, capturamos la excepcion en updateAccionByAccionSituacionEmergenciaId de accionSituacionEmergencia.js
        }
      },

      async actualizarAccionesInstanciadasEmergenciaSituacion(
        idEmergenciaSituacionOperativa,
      ) {
        this.accionesSituacionOperativa = await this.$store.getters[
          'accionSituacionEmergencia/getAccionesSituacionEmergencia'
        ]({ situacionEmergenciaId: idEmergenciaSituacionOperativa })
      },

      notificarActualizacionAccionInstanciaSituacionEmergencia(
        accionInstanciada,
      ) {
        this.$notify({
          title: 'Protocolo de intervención actualizado',
          text:
            'Grupo de intervención actualizado para la acción ' +
            accionInstanciada.Accion.nombre,
        })
      },

      acceptDeleteAccionInstanciada(accionInstanciada) {
        const msg = 'Eliminando la acción: ' + accionInstanciada.Accion.nombre
        this.$root.$confirmDialog
          .open('¿Está seguro?', msg)
          .then((confirmacionUsuario) => {
            if (confirmacionUsuario) {
              this.deleteAccionSituacionEmergencia(accionInstanciada.id).then(
                (deleteAccion) => {
                  if (deleteAccion) {
                    this.reiniciarAccionesChecked(accionInstanciada)
                  }
                },
              )
            }
          })
      },

      async reiniciarAccionesChecked(accionInstanciada) {
        this.isLoading = true
        await this.actualizarAccionesInstanciadasEmergenciaSituacion(
          accionInstanciada.id_emergencia_situacion_operativa,
        )
        await this.notificarBorradoAccionInstanciaSituacionEmergencia(
          accionInstanciada,
        )
        await this.cargarAccionesCompletadas()
        this.isLoading = false
      },

      notificarBorradoAccionInstanciaSituacionEmergencia(accionInstanciada) {
        this.$notify({
          title: 'Protocolo de intervención actualizado',
          text: 'Se ha borrado la acción ' + accionInstanciada.Accion.nombre,
        })
      },

      async estadoAccionInstanciada(accionInstanciada) {
        let encontrado = null
        encontrado = this.accionChecked.find(
          (x) => x.id === accionInstanciada.id,
        )
        if (encontrado) {
          this.isLoading = true
          try {
            await this.accionCompletada(accionInstanciada)
          } catch (err) {
            // Do nothing, se gestiona en updateAccionSituacionEmergencia de accionSituacionEmergencia.js
          }
          this.isLoading = false
        } else {
          this.isLoading = true
          try {
            await this.accionNoCompletada(accionInstanciada)
          } catch (err) {
            // Do nothing, se gestiona en updateAccionSituacionEmergencia de accionSituacionEmergencia.js
          }
          this.isLoading = false
        }
      },

      async accionCompletada(accionInstanciada) {
        accionInstanciada.completada = true
        const response = await this.updateAccionSituacionEmergencia(
          accionInstanciada,
        )
        if (response) {
          await this.actualizarAccionesInstanciadasEmergenciaSituacion(
            accionInstanciada.id_emergencia_situacion_operativa,
          )
          await this.cargarAccionesCompletadas()
          this.notificarCompletadaAccionInstanciaSituacionEmergencia()
        }
      },

      async accionNoCompletada(accionInstanciada) {
        accionInstanciada.completada = false
        const response = await this.updateAccionSituacionEmergencia(
          accionInstanciada,
        )
        if (response) {
          await this.actualizarAccionesInstanciadasEmergenciaSituacion(
            accionInstanciada.id_emergencia_situacion_operativa,
          )
          await this.cargarAccionesCompletadas()
          this.notificarNoCompletadaAccionInstanciaSituacionEmergencia()
        }
      },

      notificarCompletadaAccionInstanciaSituacionEmergencia() {
        this.$notify({
          title: 'Acción completada',
          text: 'La acción se ha marcado como completada',
        })
      },

      notificarNoCompletadaAccionInstanciaSituacionEmergencia() {
        this.$notify({
          title: 'Acción incompleta',
          text: 'La acción se ha marcado como no completada',
        })
      },

      showAddTipoAccionDialog() {
        this.showDialogAccion = true
        this.isEdit = false
        this.editTipoAccion = null
      },

      async acceptAddTipoAccion(tipoAccion) {
        this.showDialogAccion = false
        this.isLoading = true
        try {
          // Añadir nueva accion
          const response = await api.tipoAccion.addTipoAccion(tipoAccion)
          const nuevaAccion = JSON.parse(JSON.stringify(response.data.body))
          // Actualizar lista de acciones
          this.fetchTiposAccion()
          // Asociar la nueva accion a la instancia de una emergencia situacion operativa
          await this.asociarAccionAemergenciaSituacionOperativa(nuevaAccion)
        } catch (err) {
          // Do nothing, se gestioa la excepción en accionSituacionEmergencia.js
        }

        this.isLoading = false
      },

      async asociarAccionAemergenciaSituacionOperativa(nuevaAccion) {
        const idEmergenciaSituacionYorden =
          await this.getIdEmergenciaSituacionYorden()

        const nuevaInstanciaAccion =
          await this.crearNuevaAccionSituacionEmergencia({
            id_tipo_accion: nuevaAccion.id,
            idEmergenciaSituacionYorden: idEmergenciaSituacionYorden,
          })

        const nuevaAccionInstanciada = await this.addAccionSituacionEmergencia(
          nuevaInstanciaAccion,
        )

        if (nuevaAccionInstanciada) {
          // Añadir al array de accionesSituacionOperativa
          this.accionesSituacionOperativa.push(nuevaAccionInstanciada)
          // Notificar creacion de nueva accion instanciada
          this.notificarCreacionDeNuevaAccionInstanciada()
        }
      },

      getIdEmergenciaSituacionYorden() {
        if (this.accionesSituacionOperativa.length) {
          const ultimaPos = this.accionesSituacionOperativa.length - 1
          return {
            id_emergencia_situacion_operativa:
              this.accionesSituacionOperativa[ultimaPos]
                .id_emergencia_situacion_operativa,
            orden: this.accionesSituacionOperativa[ultimaPos].orden,
          }
        } else {
          return {
            id_emergencia_situacion_operativa: this.idEmergenciaSituacion,
            orden: 0,
          }
        }
      },

      crearNuevaAccionSituacionEmergencia(data) {
        return {
          id_emergencia_situacion_operativa:
            data.idEmergenciaSituacionYorden.id_emergencia_situacion_operativa,
          id_tipo_accion: data.id_tipo_accion,
          orden: data.idEmergenciaSituacionYorden.orden + 1,
          usuariostamp: this.currentUsuario.ID,
        }
      },

      notificarCreacionDeNuevaAccionInstanciada() {
        this.$notify({
          title: 'Nueva acción creada',
          text: 'La nueva acción se ha añadido al protocolo de intervención actual',
        })
      },

      situacionOperativaCompletaYnoEsLaActual() {
        // Comprobar si es la situacionOperativa actual.
        if (!this.isSituacionOperativaActiva) {
          return true
        } else if (this.isSituacionOperativaActiva) {
          const permisos = this.comprobarPermisos(['MOD_EMERGENCIAS'])
          if (permisos) {
            return false
          } else {
            return true
          }
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .v-data-table::v-deep {
    th {
      font-size: 12px !important;
    }
    td {
      font-size: 14px !important;
    }
  }

  .content {
    padding: 12px;
    .datos {
      width: 100%;
      display: flex;
      gap: 6px;
      flex-direction: column;
      margin-bottom: 9px;
      span {
        line-height: 19px;
        .label-title {
          font-weight: bold;
        }
      }
    }
  }

  .estados::v-deep {
    overflow: hidden;
    .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
      min-height: 20px;
    }
    .table {
      tbody {
        overflow: auto;
      }
    }
    ::v-deep .v-data-table-header {
      font-size: 12px;
    }
    .add-accion-situacion-operativa {
      z-index: 3;
      margin-right: 12px;
    }
  }

  .fase-container {
    display: flex;
    align-items: center;
  }

  /* Alto de las filas de la tabla */
  ::v-deep .v-input--selection-controls.v-input {
    flex: 0 1 auto;
    margin-top: 1px;
    margin-bottom: -12px;
  }

  /* Separacion checkbox y texto */
  // ::v-deep .v-application--is-ltr .v-input--selection-controls__input {
  //     margin-right: 4px;
  // }

  /* Tamaño texto checkbox */
  ::v-deep .v-input--selection-controls .v-input__slot > .v-label,
  .v-input--selection-controls .v-radio > .v-label {
    align-items: center;
    display: inline-flex;
    flex: 1 1 auto;
    height: auto;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
  }
</style>
