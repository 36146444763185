<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 18L4.854 19.146C4.78413 19.216 4.69505 19.2638 4.59804 19.2832C4.50102 19.3026 4.40043 19.2928 4.309 19.255C4.21757 19.2172 4.1394 19.1531 4.0844 19.0709C4.02939 18.9886 4.00002 18.8919 4 18.793V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6H18H8H6V8V15.1716V18Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 15L1.854 16.146C1.78413 16.216 1.69505 16.2638 1.59804 16.2832C1.50102 16.3026 1.40043 16.2928 1.309 16.255C1.21757 16.2172 1.1394 16.1531 1.0844 16.0709C1.02939 15.9886 1.00002 15.8919 1 15.793V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3H15H5H3V5V12.1716V15Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 11C10 10.4477 10.4477 10 11 10H19C19.5523 10 20 10.4477 20 11C20 11.5523 19.5523 12 19 12H11C10.4477 12 10 11.5523 10 11Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 15C10 14.4477 10.4477 14 11 14H15C15.5523 14 16 14.4477 16 15C16 15.5523 15.5523 16 15 16H11C10.4477 16 10 15.5523 10 15Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.854 22.146L10.707 19.293C10.8945 19.1055 11.1488 19.0001 11.414 19H21C21.5304 19 22.0391 18.7893 22.4142 18.4142C22.7893 18.0391 23 17.5304 23 17V9C23 8.46957 22.7893 7.96086 22.4142 7.58579C22.0391 7.21071 21.5304 7 21 7H9C8.46957 7 7.96086 7.21071 7.58579 7.58579C7.21071 7.96086 7 8.46957 7 9V21.793C7.00002 21.8919 7.02939 21.9886 7.0844 22.0709C7.1394 22.1531 7.21757 22.2172 7.309 22.255C7.40043 22.2928 7.50102 22.3026 7.59804 22.2832C7.69505 22.2638 7.78413 22.216 7.854 22.146ZM9 18.1716L9.29257 17.879C9.29264 17.8789 9.2925 17.8791 9.29257 17.879C9.85503 17.3165 10.6181 17.0002 11.4136 17H21V9H9V18.1716Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      active: null,
    },

    computed: {
      fillColor() {
        const result = this.active ? '#1AF4C0' : 'white'
        return result
      },
    },
  }
</script>
