<template>
  <v-app-bar :color="headerColor" dark app height="64px">
    <!--  clipped-left fixed  -->
    <div class="d-flex align-center ml-1">
      <img
        :height="height"
        src="../../assets/firma_madrid_blanco.svg"
        alt="Logo"
      />
    </div>

    <div class="d-flex align-center ml-5">
      <img :height="height" src="../../assets/PEMAM_logo.svg" />
    </div>

    <v-spacer />

    <!-- Toolbar escritorio -->
    <div class="d-flex" v-if="!$vuetify.breakpoint.mobile">
      <v-tooltip v-if="userCanSeeEmergencias" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            :input-value="isShowEmergencias"
            @click="enabledShowEmergencias"
          >
            <img :src="emergenciasIcon" />
          </v-btn>
        </template>
        <span>Emergencias</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            :input-value="isMap"
            @click="showMap"
          >
            <img
              :src="require(`@/assets/mapa_madrid.svg`)"
              height="24px"
              width="24px"
            />
          </v-btn>
        </template>
        <span>Visor</span>
      </v-tooltip>

      <!-- Botones rutas -->
      <v-tooltip bottom v-for="(item, i) in pathButtons" :key="i + item.name">
        <template #activator="{ on, attrs }">
          <!-- Boton mapa con svg -->
          <v-btn
            v-if="item.svg && item.toolbar === 'desktop'"
            icon
            v-bind="attrs"
            v-on="on"
            :to="item.path"
          >
            <img
              :src="require(`@/assets/${item.svg}`)"
              height="24px"
              width="24px"
            />
          </v-btn>

          <v-btn
            v-else-if="item.icon"
            icon
            v-bind="attrs"
            v-on="on"
            :to="item.path"
            v-show="permisos(item.roles)"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-btn>
        </template>
        <span v-if="item.svg && item.toolbar === 'desktop'">{{
          item.name
        }}</span>
        <span v-else-if="item.icon">{{ item.name }}</span>
      </v-tooltip>

      <!-- Botón simulador - pemam -->
      <v-divider class="mx-3" vertical />

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="redirectTo(redirectUrl)">
            <v-icon>{{ redirectIcon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ redirectionText }}</span>
      </v-tooltip>

      <v-divider class="mx-3" vertical />
      <!-- Nombre del usuario y rol -->
      <div class="mt-4">
        <h6 v-if="currentUsuario">
          {{ currentUsuario.NOMBRE + ' ' + currentUsuario.APELLIDOS }}
        </h6>
      </div>
      <!-- Botón para acceder al perfil del usuario -->
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="mostrarDialogPerfil()">
            <v-icon>mdi-account-cog-outline</v-icon>
          </v-btn>
        </template>
        <span>Perfil de usuario</span>
      </v-tooltip>

      <v-divider class="mx-3" v-show="!$vuetify.breakpoint.mobile" vertical />

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="initLogout">
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </div>

    <!-- Toolbar movil -->
    <div class="d-flex" v-if="$vuetify.breakpoint.mobile">
      <v-dialog
        v-model="showMobileMenu"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template #activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on" />
        </template>

        <v-card tile>
          <v-toolbar flat color="primaryLight" dark>
            <v-toolbar-title>Menú</v-toolbar-title>

            <v-spacer />
            <v-btn icon @click="showMobileMenu = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-list>
            <v-list-item
              v-if="userCanSeeEmergencias"
              @click="enabledShowEmergencias"
              :input-value="isShowEmergencias"
            >
              <v-list-item-action>
                <v-icon>mdi-alert-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Emergencias</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item key="Visor" :input-value="isMap" @click="showMap">
              <v-list-item-action>
                <img
                  :src="require(`@/assets/mapa_madrid_movil.svg`)"
                  height="24px"
                  width="24px"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Visor</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-for="(item, i) in pathButtons"
              :key="i + item.name"
              :to="item.path"
              @click="showMobileMenu = false"
              v-show="item.svg && item.toolbar === 'mobile'"
            >
              <!-- Item mapa con svg -->
              <v-list-item-action v-if="item.svg">
                <img
                  :src="require(`@/assets/${item.svg}`)"
                  height="24px"
                  width="24px"
                />
              </v-list-item-action>

              <!-- Item content visor -->
              <v-list-item-content v-if="item.svg">
                <v-list-item-title :title="item.name">
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Item con icon y control de permisos -->
            <v-list-item
              v-for="(item, j) in pathButtons"
              :key="j + 'icon'"
              :to="item.path"
              @click="showMobileMenu = false"
              v-show="item.icon && permisos(item.roles)"
            >
              <!-- Icono del item -->
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>

              <!-- Nombre del item -->
              <v-list-item-content v-if="item.icon">
                <v-list-item-title :title="item.name">
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <!-- Botón simulador - pemam -->
            <v-list-item key="redirection" @click="redirectTo(redirectUrl)">
              <v-list-item-action>
                <v-icon>{{ redirectIcon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ redirectionText }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item @click="mostrarDialogPerfil()">
              <v-list-item-action>
                <v-icon>mdi-account-cog</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title v-if="currentUsuario">
                  Perfil de usuario:
                  {{ currentUsuario.NOMBRE + ' ' + currentUsuario.APELLIDOS }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item @click="initLogout">
              <v-list-item-action>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Cerrar sesión</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </div>

    <!-- Perfil de usuario -->
    <vx-perfil-dialog
      :show="showPerfilDialog"
      @cerrar="showPerfilDialog = false"
    />
  </v-app-bar>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import PerfilDialog from '../usuarios/PerfilDialog'

  export default {
    components: {
      'vx-perfil-dialog': PerfilDialog,
    },

    data: () => ({
      pathButtons: [
        {
          path: 'acciones',
          icon: 'mdi-clipboard-flow',
          name: 'Administrar flujos de trabajo',
          roles: new Array('VER_ACCIONES', 'MOD_ACCIONES'),
        },
        {
          path: '/documentacion',
          icon: 'mdi-file-document-outline',
          name: 'Repositorio documental',
          roles: new Array('VER_DOCUMENTACION', 'MOD_DOCUMENTACION'),
        },
        {
          path: '/contactos',
          icon: 'mdi-card-account-phone',
          name: 'Gestión de contactos',
          roles: new Array('VER_CONTACTOS', 'MOD_CONTACTOS'),
        },
        {
          path: '/medios_recursos',
          icon: 'mdi-transit-transfer',
          name: 'Gestión de recursos',
          roles: new Array('VER_MEDIOS', 'MOD_MEDIOS'),
        },
        {
          path: '/entidades',
          icon: 'mdi-home-group',
          name: 'Gestión de entidades',
          roles: new Array('VER_ENTIDADES', 'MOD_ENTIDADES'),
        },
        {
          path: '/usuarios',
          icon: 'mdi-account-multiple',
          name: 'Gestión de usuarios',
          roles: new Array('VER_USUARIOS', 'MOD_USUARIOS'),
        },
      ],
      showMobileMenu: false,
      showListadoIncendiosDialog: false,
      showSettingsDialog: false,
      showPerfilDialog: false,
      headerColor: process.env.VUE_APP_HEADER_COLOR || 'primary',
      redirectUrl: process.env.VUE_APP_REDIRECTION_URL,
      redirectIcon: process.env.VUE_APP_REDIRECTION_ICON,
      redirectionText: process.env.VUE_APP_REDIRECTION_TOOLTIP,
    }),

    computed: {
      height() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return 25
          case 'sm':
            return 20
          case 'md':
            return 30
          case 'lg':
            return 30
          case 'xl':
            return 30
        }
        return this.height
      },

      ...mapGetters('map', ['paramsComunidad']),

      ...mapGetters('usuario', ['currentUsuario', 'rolesUsuario']),

      userCanSeeEmergencias() {
        return this.$store.getters['usuario/rolesUsuario']([
          'VER_EMERGENCIAS',
          'MOD_EMERGENCIAS',
        ])
      },

      isMap() {
        return this.$route.path === '/' && !this.isShowEmergencias
      },

      disabledByRol() {
        // Deshabilita funciones dependiendo del rol
        return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
      },

      emergencias() {
        return this.$store.state.emergencia.emergencias
      },

      emergenciasIcon() {
        return this.$store.state.emergencia.emergenciasIcon
      },

      isShowEmergencias: {
        get() {
          return this.$store.state.emergencia.isShowEmergencias
        },
        set(newValue) {
          this.$store.dispatch('emergencia/changeShowEmergencias', newValue)
        },
      },
    },

    watch: {
      isShowEmergencias(newValue) {
        if (newValue) {
          // Si nos desplazamos al modulo de emergencias, emit para cerrar el contextMenu en caso de que este abierto
          this.$eventHub.$emit('showPopup', false)
          this.$eventHub.$emit('showContextMenu', false)
        } else {
          // Si nos desplazamos al visor, emit para cerrar el contextMenu en caso de que este abierto
          this.$eventHub.$emit('showPopup', false)
          this.$eventHub.$emit('showContextMenu', false)
        }
      },
    },

    methods: {
      ...mapActions('auth', ['logout']),

      showMap() {
        this.navigateToMap()
        this.isShowEmergencias = false
        this.showMobileMenu = false
      },

      navigateToMap() {
        if (this.$route.path !== '/') {
          this.$router.push('/')
        }
      },

      enabledShowEmergencias() {
        if (this.userCanSeeEmergencias) {
          this.navigateToMap()
          this.isShowEmergencias = true
          this.showMobileMenu = false
        }
      },

      initLogout() {
        this.$root.$confirmDialog
          .open('Cerrar sesión', '¿Está seguro de que quiere salir?')
          .then((result) => {
            if (result) {
              this.logout()
            }
          })
      },

      mostrarDialogPerfil() {
        this.showPerfilDialog = true
      },

      permisos(listaRolesPermisos) {
        return this.$store.getters['usuario/rolesUsuario'](listaRolesPermisos)
      },
      redirectTo(url) {
        window.location.replace(url)
      },
    },

    mounted() {},
  }
</script>

<style lang="scss" scoped>
  .v-btn--active.no-active::before {
    opacity: 0 !important;
  }

  .v-list-item__title,
  .v-list-item__subtitle {
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
</style>
