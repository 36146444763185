<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title>Gestión de tipos de emergencia</v-toolbar-title>

      <!-- AÑADIR TIPO EMERGENCIA -->
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            bottom
            right
            absolute
            color="primary"
            v-show="comprobarPermisos(['MOD_ENTIDADES'])"
            @click="showAddTipoEmergenciaDialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Añadir tipo emergencia</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col xs="3" sm="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Búsqueda"
              :dense="$vuetify.breakpoint.mobile"
              outlined
              clearable
              hide-details
            />
          </v-col>
        </v-row>

        <v-row>
          <v-data-table
            :class="{
              table: !$vuetify.breakpoint.mobile,
              tableMobile: $vuetify.breakpoint.mobile,
            }"
            :headers="headers"
            :items="tiposEmergencia"
            height="100%"
            :search="search"
            :loading="isLoading"
            loading-text="Cargando datos... por favor, espere"
            fixed-header
            disable-pagination
            hide-default-footer
          >
            <!-- NO DATA -->
            <template #no-data>
              <p>Datos no disponibles.</p>
            </template>

            <!-- FECHA -->
            <template #[`item.FECHA`]="{ item }">
              <span>{{ item.fechastamp | formatDate }}</span>
            </template>

            <!-- USUARIO -->
            <template #[`item.Usuario`]="{ item }">
              <span>{{
                item.Usuario.nombre + ' ' + item.Usuario.apellidos
              }}</span>
            </template>

            <!-- ACCIONES ENTIDAD -->
            <template #[`item.ACCIONES`]="{ item }">
              <!-- EDITAR ENTIDAD -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-show="comprobarPermisos(['MOD_ENTIDADES'])"
                    @click="showEditTipoEmergenciaDialog(item)"
                  >
                    <v-icon color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <!-- BORRAR TIPO EMERGENCIA -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-show="comprobarPermisos(['MOD_TIPO_EMERGENCIA'])"
                    @click="acceptDeleteTipoEmergencia(item)"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Borrar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- DIALOG ADD/EDIT TIPO EMERGENCIA -->
    <vx-dialog-add-edit-tipo-emergencia
      :show="showDialog"
      :isEdit="isEdit"
      :editedTipoEmergencia="editTipoEmergencia"
      @cancelar="showDialog = false"
      @addTipoEmergencia="acceptAddTipoEmergencia"
      @editTipoEmergencia="acceptEditTipoEmergencia"
    />
  </v-card>
</template>

<script>
  import { TipoEmergenciaRequest } from '@/api/tipoEmergencia'

  import DialogAddEditTipoEmergencia from './DialogAddEditTipoEmergencia.vue'

  export default {
    components: {
      'vx-dialog-add-edit-tipo-emergencia': DialogAddEditTipoEmergencia,
    },

    data: () => ({
      search: '',

      isLoading: false,

      showDialog: false,
      isEdit: false,
      editTipoEmergencia: {},

      tiposEmergencia: [],

      headers: [
        {
          text: 'Tipo emergencia',
          value: 'nombre',
          align: 'left',
          width: '140px',
          sortable: false,
        },
        {
          text: 'Últ. actualización',
          value: 'FECHA',
          align: 'left',
          width: '140px',
          sortable: false,
        },
        {
          text: 'Usuario',
          value: 'Usuario',
          align: 'left',
          width: '140px',
          sortable: false,
        },
      ],
    }),

    methods: {
      async loadData() {
        this.isLoading = true
        try {
          this.tiposEmergencia = await TipoEmergenciaRequest.getAll()
        } catch (err) {
          if (err.response.status !== 200 && err.response.status !== 500) {
            this.$notify({
              title: err.response.statusText,
              text: 'El usuario no dispone de permisos para acceder al módulo de Administración de flujos de trabajo',
              type: 'error',
            })
          }
          this.$router.push('/')
        }
        this.isLoading = false
      },

      comprobarPermisos(listaRolesPermisos) {
        return this.$store.getters['usuario/rolesUsuario'](listaRolesPermisos)
      },

      showAddTipoEmergenciaDialog() {
        this.editTipoEmergencia = null
        this.showDialog = true
        this.isEdit = false
      },

      showEditTipoEmergenciaDialog(tipoEmergencia) {
        this.editTipoEmergencia = Object.assign({}, tipoEmergencia)
        this.showDialog = true
        this.isEdit = true
      },

      async acceptAddTipoEmergencia(tipoEmergencia) {
        this.showDialog = false
        try {
          await TipoEmergenciaRequest.addTipoEmergencia(tipoEmergencia).then(
            (tipoEmergenciaCreado) => {
              this.notificarTipoEmergenciaCreado(tipoEmergenciaCreado)
            },
          )
          await this.loadData()
        } catch (err) {
          // Do nothing, gestionamos la excepcion en tipoEmergencia.js
        }
      },

      async acceptEditTipoEmergencia(tipoEmergencia) {
        this.showDialog = false
        try {
          await TipoEmergenciaRequest.update(tipoEmergencia).then(
            (tipoEmergenciaActualizado) => {
              this.notificarTipoEmergenciaActualizado(tipoEmergenciaActualizado)
            },
          )
          await this.loadData()
        } catch (err) {
          // Do nothing, gestionamos la excepcion en tipoEmergencia.js
        }
      },

      async acceptDeleteTipoEmergencia(tipoEmergencia) {
        const msg = 'Eliminando el tipo emergencia: ' + tipoEmergencia.nombre
        this.$root.$confirmDialog
          .open('¿Está seguro?', msg)
          .then((confirmacionUsuario) => {
            if (confirmacionUsuario) {
              TipoEmergenciaRequest.deleteTipoEmergencia(
                tipoEmergencia.id,
              ).then((tipoEmergenciaBorrada) => {
                try {
                  tipoEmergenciaBorrada[0] === 1 &&
                    this.notificarTipoEmergenciaBorrado(tipoEmergencia)
                  this.loadData()
                } catch (err) {
                  // Do nothing, gestionamos la excepcion en tipoEmergencia.js
                }
              })
            }
          })
      },

      notificarTipoEmergenciaCreado(tipoEmergencia) {
        this.$notify({
          title: 'Tipo emergencia creado',
          text: `Se ha creado el tipo emergencia ${tipoEmergencia.nombre}`,
        })
      },

      notificarTipoEmergenciaActualizado(tipoEmergencia) {
        this.$notify({
          title: 'Tipo emergencia actualizado',
          text: `Se ha actualizado el tipo emergencia ${tipoEmergencia.nombre}`,
        })
      },

      notificarTipoEmergenciaBorrado(tipoEmergencia) {
        this.$notify({
          title: 'Tipo emergencia borrado',
          text: `Se ha eliminado el tipo emergencia ${tipoEmergencia.nombre}`,
        })
      },
    },

    async created() {
      // Mostrar acciones si currentUsuario tienen rol admin o rol mod_tipo_emergencia
      if (this.comprobarPermisos(['MOD_TIPO_EMERGENCIA'])) {
        // Acciones tipo emergencia
        this.headers.push({
          text: 'Acciones',
          value: 'ACCIONES',
          align: 'center',
          width: '140px',
          sortable: false,
        })
      }
    },

    async activated() {
      await this.loadData()
    },
  }
</script>

<style lang="scss" scoped>
  /* Estilos para que la tabla tenga un max-height y pueda crecer */
  .table {
    width: 100vw;
    max-height: calc(100vh - 215px);
  }
  .tableMobile {
    width: 100vw;
    max-height: calc(100vh - 209px);
  }

  .v-data-table {
    overflow: auto;
  }

  .v-data-table >>> .v-data-table__wrapper {
    overflow: unset;
  }

  /* Hover transparente para todas las filas */
  ::v-deep tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
  /* Hover en color gris para las filas impares */
  ::v-deep tbody {
    tr:hover:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
  /* Color gris fila data table impar */
  ::v-deep tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
</style>
