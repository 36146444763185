<template>
  <div class="usuarios">
    <v-list>
      <v-list-item-group
        v-if="chatUsers"
        v-model="selectedItemNumber"
        active-class="border"
        color="indigo"
      >
        <v-list-item v-for="usuario in usersFiltered" :key="usuario.id_usuario">
          <template #default="{}">
            <v-list-item-content>
              <v-list-item-title>{{ usuario.username }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ usuario.chatName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
  import { UserHelper } from '@/helpers/UserHelper'
  import { mapActions, mapState } from 'vuex'

  export default {
    expose: ['selectedUser'],

    components: {},

    props: {
      modelValue: null,
      filter: {
        type: String,
        optional: true,
        default: '',
      },
    },

    data: () => ({
      selectedItemNumber: null,
    }),

    async mounted() {
      await this.fetchChatUsers()
    },

    computed: {
      ...mapState('usuario', ['chatUsers']),
      usersFiltered() {
        const chatUsers = this.chatUsers.map((usuario) => ({
          ...usuario,
          chatName: this.getUserChatName(usuario),
        }))
        if (!this.filter) return chatUsers
        const result = chatUsers.filter(
          (usuario) =>
            usuario.username
              .toLowerCase()
              .includes(this.filter.toLowerCase()) ||
            usuario.chatName.toLowerCase().includes(this.filter.toLowerCase()),
        )
        return result
      },
      selectedUser: {
        get() {
          return this.usersFiltered[this.selectedItemNumber]
        },
        set(value) {
          const index = this.usersFiltered.findIndex(
            (usuario) => usuario.id_usuario === value.id_usuario,
          )
          this.selectedItemNumber = index
        },
      },
    },

    emits: ['userSelected'],

    watch: {
      selectedUser(value) {
        console.log('selectedUser', value)
        this.$emit('userSelected', value)
      },
    },

    methods: {
      ...mapActions('usuario', ['fetchChatUsers']),

      /**
       * @param {import("@/typedefs").User} user
       * @returns {string}
       */
      getUserChatName(user) {
        return UserHelper.getCargoPrincipalOnombreUsuario(user)
      },
    },
  }
</script>

<style scoped lang="scss">
  .usuarios {
    flex-grow: 1;
    height: 100%;
    overflow: auto;

    ::v-deep .v-list-item__title {
      text-overflow: inherit;
      white-space: normal;
    }
  }

  .buttons {
    padding: 8px;
    display: flex;
    gap: 6px;
    justify-content: space-between;
    width: 100%;
  }
</style>
