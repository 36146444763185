<template>
  <v-slide-x-reverse-transition>
    <v-card
      id="layerList"
      height="calc(100% - 43.5px)"
      width="350px"
      tile
      v-show="show"
    >
      <v-toolbar color="primaryLight" dark dense fixed id="layerListHeader">
        <v-toolbar-title> Capas </v-toolbar-title>
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              x-large
              class="mx-0"
              v-bind="attrs"
              v-on="on"
              v-show="capasActivas"
              @click="desactivarCapas()"
            >
              <v-icon size="28">mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Desactivar todas las capas</span>
        </v-tooltip>
        <!-- <v-btn icon x-large class="mx-0" v-if="capasActivas" @click="desactivarCapas()">
          <v-icon size="28">mdi-eye-off</v-icon>
        </v-btn> -->
        <v-btn icon x-large class="mx-0" @click="close()">
          <v-icon size="30">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text grid-list-sm>
        <!-- NIVEL 0 -->
        <div>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(layer1, i) in layersOrder.filter(
                (x) =>
                  x.title !== null &&
                  x.type !== 'vector-tileLayer' &&
                  x.subLayers,
              )"
              :key="i"
            >
              <!-- NIVEL 1 -->
              <v-expansion-panel-header
                v-show="
                  layer1.id != 'emergencyLayers' ||
                    (!isShowEmergencias && userCanSeeEmergencias)
                "
                hide-actions
              >
                <template #default="{ open }">
                  <div id="layerItem">
                    <v-icon>
                      <template v-if="open">mdi-chevron-down</template>
                      <template v-else>mdi-chevron-right</template>
                    </v-icon>
                    <span
                      :class="{
                        'label-strong': layer1.visible,
                      }"
                    >{{ layer1.title }}</span>
                  </div>
                </template>
              </v-expansion-panel-header>

              <!-- NIVEL 2 -->
              <v-expansion-panel-content>
                <div v-for="(layer2, j) in layer1.subLayers" :key="j">
                  <!-- NIVEL DOS CON HIJOS -->
                  <div
                    v-if="layer2.type === 'groupLayer' && layer2.layers"
                    class="abuelo"
                  >
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header hide-actions>
                          <template #default="{ open }">
                            <div id="layerItem">
                              <v-icon>
                                <template v-if="open">
                                  mdi-chevron-down
                                </template>
                                <template v-else>mdi-chevron-right</template>
                              </v-icon>
                              <span
                                :class="{
                                  'label-strong': layer2.visible,
                                }"
                              >{{ layer2.title }}</span>
                            </div>
                          </template>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content v-if="layer2.layers">
                          <!-- Capa padre sin hijos, no poner expansion panels -->

                          <div v-for="(layer3, k) in layer2.layers" :key="k">
                            <div
                              v-if="layer3.type === 'groupLayer'"
                              class="abuelo"
                            >
                              <v-expansion-panels>
                                <v-expansion-panel>
                                  <v-expansion-panel-header hide-actions>
                                    <template #default="{ open }">
                                      <div
                                        v-if="layer3.layers.length > 0"
                                        id="layerItem"
                                      >
                                        <v-icon>
                                          <template v-if="open">
                                            mdi-chevron-down
                                          </template>
                                          <template v-else>
                                            mdi-chevron-right
                                          </template>
                                        </v-icon>
                                        <span
                                          :class="{
                                            'label-strong': layer3.visible,
                                          }"
                                        >{{ layer3.title }}</span>
                                      </div>
                                    </template>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content
                                    v-if="layer3.layers.length > 0"
                                  >
                                    <v-list :key="updateList" dense>
                                      <!-- Capa padre con hijos -->
                                      <vx-layer-item
                                        :showTitle="true"
                                        :onlyPadre="false"
                                        :item="item"
                                        v-for="(item, n) in layer3.layers"
                                        :key="n"
                                        id="layerItem"
                                      >
                                        {{ item }}
                                      </vx-layer-item>
                                    </v-list>
                                    <!--<v-list v-else dense>
                                      kk <vx-layer-item :showTitle="true" :onlyPadre="false" :item="layer3" :key="updateList" />
                                    </v-list>-->
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </div>
                            <div v-else>
                              <div
                                v-if="layer3.layers.length > 0"
                                id="layerItem"
                                class="abuelo"
                              >
                                <v-list dense>
                                  <vx-layer-item
                                    :showTitle="true"
                                    :key="updateList"
                                    :onlyPadre="false"
                                    :item="layer3"
                                  />
                                </v-list>
                              </div>
                            </div>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>

                  <!-- NIEVEL 2 SIN HIJOS -->
                  <div v-else>
                    <!-- Capa padre sin hijos, no poner expansion panels -->
                    <div
                      v-if="layer2.id !== 'layer-drawSketcher'"
                      id="layerItem"
                      class="abuelo"
                    >
                      <v-list dense>
                        <vx-layer-item
                          :showTitle="true"
                          :onlyPadre="false"
                          :key="updateList"
                          :item="layer2"
                        />
                      </v-list>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card-text>
    </v-card>
  </v-slide-x-reverse-transition>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import LayerItem from './LayerItem'

  import * as ArcGIS from '@/helpers/ArcGIS'
  import constants from '@/helpers/constants'

  export default {
    components: {
      'vx-layer-item': LayerItem,
    },

    props: {
      show: Boolean,
    },

    data: () => ({
      layersOrder: [],
      // exageracion: 1,
      capasActivas: false,
      updateList: 0,
    }),

    watch: {
      show() {
        if (this.show) {
          this.layersOrder = this.sortLayers()
        }
      },

      layers() {
        // Watch que cuando la visibilidad / opacidad cambia EN LA STORE, lo actualiza en el mapa
        for (let i = 0; i < this.layers.length; i++) {
          const layer = this.layers[i]

          ArcGIS.setVisibilityLayer(layer.id, layer.visible)
          ArcGIS.setOpacityLayer(layer.id, layer.opacity)
        }
      },
      // exageracion () {
      //   ArcGIS.setExageration3D(this.exageracion)
      // }
    },

    computed: {
      ...mapGetters('map', ['layersAdd']),
      ...mapGetters('map', ['layersPrueba']),
      ...mapGetters('map', ['layers']),
      is3D() {
        return this.$store.getters['map/isMap3D']
      },
      isShowEmergencias() {
        return this.$store.state.emergencia.isShowEmergencias
      },

      userCanSeeEmergencias() {
        return this.$store.getters['usuario/rolesUsuario']([
          'VER_EMERGENCIAS',
          'MOD_EMERGENCIAS',
        ])
      },
    },
    mounted() {
      this.$eventHub.$on('updateLayerList', this.prueba)
      // Mostrar icono para desactivar todas las capas
    },

    methods: {
      ...mapActions('map', ['updateLayersPrueba']),

      prueba() {
        this.layersOrder = this.sortLayers()
      },
      close() {
        this.$emit('hide', this.sortLayers())
        this.$eventHub.$emit('moveButtonsMap', true)
      },

      changeOpacity(layerId, opacity) {
        ArcGIS.changeOpacityWebMap(layerId, opacity / 100)
      },

      sortLayers() {
        const orderLayers = JSON.parse(JSON.stringify(this.layersPrueba))

        orderLayers.sort((a, b) => {
          const aOrden = a.position
          const bOrden = b.position
          return aOrden > bOrden ? -1 : 1
        })

        this.compruebaOjoActivo()
        return orderLayers.filter((x) => x.addListLayer)
      },

      compruebaOjoActivo() {
        const orderLayers = JSON.parse(JSON.stringify(this.layersPrueba))
        this.capasActivas = false

        // Recorro las capas del nivel 0
        for (const layer1 of orderLayers.filter(
          (x) =>
            x.title !== null && x.type !== 'vector-tileLayer' && x.subLayers,
        )) {
          if (layer1.type === 'groupLayer') {
            // Recorro las capas hijas de layer1
            for (const layer2 of layer1.subLayers) {
              if (layer2.type === 'groupLayer') {
                // Recorro las capas hijas de layer2
                for (const layer3 of layer2.layers) {
                  if (layer3.type === 'groupLayer') {
                    // Recorro las hijas de layer 3
                    for (const layer4 of layer3.layers) {
                      if (layer4.visible) {
                        this.capasActivas = true
                      }
                    }
                  } else {
                    if (layer3.visible) {
                      this.capasActivas = true
                    }
                  }
                }
              } else {
                if (layer2.visible && layer2.id !== 'ContornoAnillos') {
                  this.capasActivas = true
                }
              }
            }
          } else {
            if (layer1.visible) {
              this.capasActivas = true
            }
          }
        }
      },

      // refreshExaggeration () {
      //   this.exageracion = 1
      // },

      desactivarCapas() {
        // Desactivar todas, excepto la capa de Emergencias
        // Obtener lista de capas de la store
        const orderLayers = JSON.parse(
          JSON.stringify(this.$store.getters['map/layersPrueba']),
        )
        for (const layer1 of orderLayers.filter(
          (x) =>
            x.title !== null && x.type !== 'vector-tileLayer' && x.subLayers,
        )) {
          if (layer1.type === 'groupLayer') {
            // Recorro las capas hijas de layer1
            for (const layer2 of layer1.subLayers) {
              if (layer2.type === 'groupLayer') {
                // Recorro las capas hijas de layer2
                for (const layer3 of layer2.layers) {
                  if (layer3.type === 'groupLayer') {
                    // Recorro las hijas de layer 3
                    for (const layer4 of layer3.layers) {
                      if (layer4.visible) {
                        const capa = JSON.parse(JSON.stringify(layer4))
                        capa.visible = false
                        this.updateLayersPrueba(capa)
                        ArcGIS.changeVisibilityWebMap(layer4.id, false)
                      }
                    }
                  } else {
                    if (layer3.visible) {
                      const capa = JSON.parse(JSON.stringify(layer3))
                      capa.visible = false
                      this.updateLayersPrueba(capa)
                      ArcGIS.changeVisibilityWebMap(layer3.id, false)
                    }
                  }

                  const capa = JSON.parse(JSON.stringify(layer3))
                  capa.visible = false
                  this.updateLayersPrueba(capa)
                }
              } else {
                // No desactivar subLayer de Pre - Emergencias o Emergencias
                if (layer2.title !== constants.EMERGENCIA_LAYER_TITLE) {
                  // if (layer2.visible && layer2.id !== 'ContornoAnillos') {
                  const capa = JSON.parse(JSON.stringify(layer2))
                  capa.visible = false
                  this.updateLayersPrueba(capa)
                  ArcGIS.changeVisibilityWebMap(layer2.id, false)
                  // }
                }
              }
              // No desactivar icono del ojo subLayer de Pre - Emergencias o Emergencias
              if (layer2.title !== constants.EMERGENCIA_LAYER_TITLE) {
                // if (layer2.id !== 'ContornoAnillos') {
                const capa = JSON.parse(JSON.stringify(layer2))
                capa.visible = false
                this.updateLayersPrueba(capa)
                // }
              }
            }
          } else {
            if (layer1.visible) {
              const capa = JSON.parse(JSON.stringify(layer1))
              capa.visible = false
              this.updateLayersPrueba(capa)
              ArcGIS.changeVisibilityWebMap(layer1.id, false)
            }
          }
          // No desactivar Layer Emergencias
          if (layer1.id !== 'emergencyLayers') {
            // console.log('Capita pather: ', layer1)
            const capa = JSON.parse(JSON.stringify(layer1))
            capa.visible = false
            this.updateLayersPrueba(capa)
          }
        }

        // Llamar a sortLayers para actualizar la vista de la lista de capas
        this.prueba()
        this.updateList++

        // Ocultar boton de la cabecera de la lista de capas
        this.capasActivas = false
        this.$eventHub.$emit('tileLayerActivas', true)
      },
    },
  }
</script>

<style scoped lang="scss">
  #layerList {
    z-index: 3;
    position: absolute;
    top: 0px;
    right: 0px;
    direction: rtl;
    :last-child {
      .layer-last-item {
        margin-bottom: 0.3rem;
      }
    }
    .v-expansion-panel-header {
      min-height: 0px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .v-list {
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  #layerListHeader {
    direction: ltr;
  }

  #layerItem {
    /* background: #ffffff; */
    padding: 0.2rem 0;
    direction: ltr;
    display: flex;
    align-items: center;
    /* border-radius: 10px; */
    .label-strong {
      font-weight: bold;
    }
    .col {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }

  .visibilidadCapaPadre {
    text-align: right;
  }

  .abuelo {
    padding-top: 0.5px;
    padding-bottom: 0.5px;
    direction: ltr;
  }

  /* ::v-deep .v-toolbar {
    direction: ltr;
  } */

  /* ::v-deep .v-card {
    border-width: thin;
    display: block;
    max-width: 100%;
    outline: none;
    text-decoration: none;
    transition-property: box-shadow, opacity;
    overflow-wrap: break-word;
    position: relative;
    white-space: normal;
    direction: ltr;
} */

  ::v-deep .v-card__title {
    word-break: normal;
  }

  ::v-deep .v-card__subtitle,
  .v-card__text,
  .v-card__title {
    padding: 0px;
    height: calc(100% - 48px);
    overflow-y: scroll;
    /* direction: rtl; */
  }

  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0px 10px 0px;
    /* direction: ltr; */
  }

  ::v-deep .v-expansion-panel-header {
    padding-top: 16px;
    padding-right: 8px;
    padding-bottom: 16px;
    padding-left: 24px;
  }

  ::v-deep .v-expansion-panel::before {
    border-radius: 0px;
    margin-top: 0px !important;
  }

  ::v-deep .v-application .mt-4 {
    margin: 0px !important;
  }
</style>
