<template>
  <v-dialog v-model="show" persistent max-width="350px">
    <v-card>
      <v-card-title class="pt-0 px-0">
        <v-toolbar color="primaryLight" dark dense>
          <v-toolbar-title>Editar grupo de acción</v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-row class="mt-1">
            <v-col>
              <v-select
                v-model="grupoAccionSelected"
                label="Grupo acción"
                :items="gruposAccion"
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="nombre"
                item-value="nombre"
                return-object
                outlined
                dense
                :rules="[globalRules.required]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    props: {
      show: Boolean,
      editedAccionInstancia: Object,
    },

    data: () => ({
      isValid: false,

      grupoAccionSelected: null,
    }),

    watch: {
      show() {
        if (this.show) {
          if (this.editedAccionInstancia) {
            this.grupoAccionSelected = this.editedAccionInstancia.Grupo_accion
              .id_grupo_accion
              ? this.editedAccionInstancia.Grupo_accion
              : null
          }
        }
      },
    },

    computed: {
      ...mapGetters('grupoAccion', ['gruposAccion']),
    },

    methods: {
      cancelar() {
        this.$refs.form.reset()
        this.$emit('cancelar')
      },

      aceptar() {
        // TODO:
        this.editedAccionInstancia.id_grupo_accion =
          this.grupoAccionSelected.id_grupo_accion
        this.editedAccionInstancia.Grupo_accion = this.grupoAccionSelected
        this.$refs.form.reset()
        this.$emit('editAccion', this.editedAccionInstancia)
      },
    },
  }
</script>

<style></style>
