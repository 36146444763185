<template>
  <v-text-field
    ref="input"
    :value="time"
    @input="onInput"
    :label="label"
    :prepend-icon="icon"
    :disabled="disabled"
    :rules="allRules"
    v-mask="mask"
    :outlined="outlined"
    :dense="dense"
  />
</template>

<script>
  export default {
    name: 'TimeTextEdit',

    model: {
      prop: 'time',
      event: 'input',
    },

    props: {
      time: [String, Object],

      label: String,
      icon: String,
      outlined: Boolean,
      dense: Boolean,

      disabled: {
        type: Boolean,
        default: false,
      },

      required: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      timeFormat: 'HH:mm',
      mask: '##:##',
    }),

    computed: {
      allRules() {
        const rules = []

        const length = 5

        rules.push(
          (v) =>
            !v || v.length === length || v.length === 0 || 'Hora inválida.',
        )
        rules.push(this.isTimeValid)

        if (this.required) {
          rules.push((v) => !!v || 'Campo obligatorio.')
        }

        return rules
      },
    },

    methods: {
      isTimeValid(v) {
        return (
          !v ||
          v === '' ||
          this.$date.parseTime(v).isValid() ||
          'Hora inválida.'
        )
      },

      isValid() {
        const v = this.date
        const rules = this.allRules

        for (let i = 0; i < rules.length; i++) {
          const isValid = rules[i](v)

          if (isValid !== true) {
            // (Si no cumple la regla devuelve string, no boolean)
            return false
          }
        }

        return true
      },

      onInput(event) {
        this.$emit('input', event)
      },
    },
  }
</script>
