<template>
  <v-dialog v-model="show" scrollable persistent max-width="600px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row class="mt-1">
              <v-col sm="8">
                <!-- NOMBRE -->
                <v-text-field
                  label="Documento*"
                  v-model="nombre"
                  dense
                  maxlenght="240"
                  :rules="[globalRules.required]"
                />
              </v-col>

              <!-- ESTADO (VALIDO/NO VALIDO) -->
              <v-col sm="4">
                <v-select
                  label="Estado*"
                  v-model="estadoSelected"
                  :items="documentoValido"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="estado"
                  item-value="estado"
                  return-object
                  outlined
                  dense
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- CATEGORIA -->
                <v-select
                  v-model="categoriaSelected"
                  :items="
                    isMensajeria
                      ? categoriasDocumento
                      : categoriasDocumento
                        ? categoriasDocumento.filter(
                          (x) => x.nombre !== 'Mensajería',
                        )
                        : []
                  "
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  label="Categoría*"
                  return-object
                  outlined
                  dense
                  :rules="[globalRules.required]"
                />
              </v-col>
              <v-col>
                <!-- EMERGENCIA -->
                <v-select
                  v-model="emergenciaSelected"
                  :items="
                    orderEmergenciasByNombreAsc
                      ? orderEmergenciasByNombreAsc
                      : []
                  "
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  label="Emergencia"
                  return-object
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-file-input
                  v-model="fileInput"
                  small-chips
                  ref="inputFile"
                  label="Seleccione un archivo"
                  :clearable="true"
                  dense
                  :rules="fileMaxSize"
                />
              </v-col>
              <v-col v-if="fileInput" xs="4" sm="4" class="mt-6">
                <v-row>
                  <span>Tamaño máximo: {{ maxTam() }}</span>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn
          text
          color="green"
          :disabled="!isValid"
          @click="comprobarEmergenciaActivaYEnviarDocumento"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import constants from '@/helpers/constants'
  import { FileHelper } from '@/helpers/FileHelper'
  import { mapGetters } from 'vuex'
  // import validationRules from '../../plugins/validationRules'

  export default {
    props: {
      show: Boolean,
      isEdit: Boolean,
      editedDocumento: Object,
    },

    data: () => ({
      isValid: false,

      nombre: null,
      estadoSelected: null,
      categoriaSelected: null,
      emergenciaSelected: null,
      fileSelected: null,
      initialFileType: null,
      fileInput: null,
      rulesFile: [],
      fileBase64: null,

      documento: null,
      isMensajeria: false,
    }),

    watch: {
      async show() {
        if (this.show) {
          if (this.isEdit) {
            await this.llenarCamposConDocumento(this.editedDocumento)
          }
        }
      },

      fileInput(newValue) {
        if (newValue) {
          this.getTipoDocumentoAdjuntado(newValue)
        }
      },
    },

    computed: {
      ...mapGetters('categoriaDocumento', ['categoriasDocumento']),
      ...mapGetters('usuario', ['currentUsuario']),
      ...mapGetters('emergencia', ['orderEmergenciasByNombreAsc']),

      accionDialog() {
        return this.editedDocumento
          ? 'Editar documentación'
          : 'Nueva documentación'
      },

      documentoValido() {
        return [
          {
            estado: 'Válido',
            value: true,
          },
          {
            estado: 'No válido',
            value: false,
          },
        ]
      },
    },

    methods: {
      async llenarCamposConDocumento(editedDocumento) {
        this.nombre = editedDocumento.nombre
        this.estadoSelected = await this.getEstadoDocumento(editedDocumento)
        this.categoriaSelected = await this.getCategoriaDocumento(
          editedDocumento,
        )
        this.isMensajeria =
          this.categoriaSelected.nombre === 'Mensajería' ? true : false
        this.emergenciaSelected = await this.getEmergencia(editedDocumento)
        this.fileInput = editedDocumento.ruta
          ? await this.getFile(editedDocumento)
          : null
        if (this.fileInput) {
          const extensionDocumento = FileHelper.getExtensionNombreArchivo(
            editedDocumento.ruta,
          )
          this.initialFileType = FileHelper.getFileType(extensionDocumento)
        } else {
          this.initialFileType = null
        }
      },

      getEstadoDocumento(editedDocumento) {
        const estado = editedDocumento.valido
        const encontrado = this.documentoValido.find((x) => x.value === estado)

        return encontrado
      },

      getCategoriaDocumento(editedDocumento) {
        const categoriaDocumento = editedDocumento.Categoria_documento
        const encontrado = this.categoriasDocumento.find(
          (x) =>
            x.id_categoria_documento ===
            categoriaDocumento.id_categoria_documento,
        )

        return encontrado
      },

      getEmergencia(editedDocumento) {
        if (editedDocumento.Emergencium) {
          const emergencia = editedDocumento.Emergencium
          const encontrado = this.orderEmergenciasByNombreAsc.find(
            (x) => x.id === emergencia.id,
          )

          return encontrado
        } else {
          return null
        }
      },

      async getFile(editedDocumento) {
        // Obtener extension del archivo
        const splitExtension = await FileHelper.getExtensionNombreArchivo(
          editedDocumento.ruta,
        )
        // Tipo de archivo para los radioButton
        this.fileSelected = await FileHelper.getFileType(splitExtension)
        // MimeType del archivo
        const mimeType =
          this.fileSelected && FileHelper.getMimeTypeByExtension(splitExtension)

        try {
          // Traer archivo de la bbdd
          const fileBase64FromBBDD = await this.$store.getters[
            'documento/getFileByDocumentoId'
          ]({
            id_documento: editedDocumento.id_documento,
            type: this.fileSelected,
          })

          // Archivo base64 decodificado
          const fileDecodeBase64 = await this.decodeFileBase64(
            fileBase64FromBBDD.nombre,
            fileBase64FromBBDD.ruta,
            mimeType,
          )

          return fileDecodeBase64 ? fileDecodeBase64 : null
        } catch (err) {
          if (err.response && err.response.status !== 200) {
            this.$notify({
              title: 'Se ha producido un error',
              text: err.response.data.message,
              type: 'error',
            })
          }
        }
      },

      cancelar() {
        this.$refs.form.reset()
        this.$emit('cancelar')
      },

      async comprobarEmergenciaActivaYEnviarDocumento() {
        if (this.emergenciaSelected && !this.emergenciaSelected.activa) {
          const msg =
            'La emergencia no está activa, el archivo adjunto se creara como archivado'
          this.$root.$confirmDialog
            .open('¿Está seguro?', msg)
            .then((result) => {
              if (result) {
                this.aceptar()
              }
            })
        } else {
          console.log('No emergency selected', this.emergenciaSelected)
          this.aceptar()
        }
      },

      async aceptar() {
        const documento =
          (this.editedDocumento &&
            JSON.parse(JSON.stringify(this.editedDocumento))) ||
          {}
        documento.nombre = this.nombre
        documento.valido = this.estadoSelected.value
        documento.id_categoria_documento =
          this.categoriaSelected.id_categoria_documento
        documento.id_emergencia = this.emergenciaSelected
          ? this.emergenciaSelected.id
          : null
        documento.type = this.fileInput ? this.fileSelected : null
        documento.ruta = this.fileInput
          ? await this.getArchivoAdjunto(this.fileInput)
          : null
        documento.usuariostamp = this.currentUsuario.ID

        if (this.isEdit) {
          this.$refs.form.reset()
          documento.id_documento = this.editedDocumento.id_documento
          if (!this.initialFileType) {
            // Si el adjunto inicialmente era null
            documento.oldType = null
          } else {
            /**
             * Si el archivo ha cambiado y el type es diferente. O si no ha cambiado
             * y es del mismo tipo
             */
            documento.oldType = !this.initialFileType
              ? null
              : documento.type !== this.initialFileType
              ? this.initialFileType
              : documento.type
          }
          this.$emit('editDocumento', documento)
        } else {
          this.$refs.form.reset()
          this.$emit('addDocumento', documento)
        }
      },

      comprobarArchivosPermitidos(file) {
        return FileHelper.filesAccept(file)
      },

      async getArchivoAdjunto(file) {
        // Nuevo nombre para para el archivo del nuevo documento
        const actualDateTime = new Date().getTime()
        const splitExtension = FileHelper.getExtensionNombreArchivo(file.name)
        this.fileBase64 = await FileHelper.fileToBase64(file)
        if (!this.isEdit) {
          const nombreArchivo =
            file.name.split(splitExtension)[0] +
            '_' +
            actualDateTime +
            splitExtension
          if (this.fileBase64) {
            return {
              nombre: nombreArchivo,
              archivo: this.fileBase64,
            }
          }
        } else {
          // Editar
          if (this.editedDocumento.ruta === file.name) {
            // El archivo no se ha actualizado, convertir a base64 con el mismo nombre
            const nombreArchivo = file.name
            if (this.fileBase64) {
              return {
                nombre: nombreArchivo,
                ruta: this.fileBase64,
              }
            }
          } else {
            // El archivo se ha modificado respecto al original
            const nombreArchivo =
              file.name.split(splitExtension)[0] +
              '_' +
              actualDateTime +
              splitExtension
            if (this.fileBase64) {
              return {
                nombre: nombreArchivo,
                ruta: this.fileBase64,
              }
            }
          }
        }
      },

      decodeFileBase64(nameFile, fileBase64Str, mimeType) {
        var buffer = new ArrayBuffer(fileBase64Str.length)
        var ba = new Uint8Array(buffer)
        for (var i = 0; i < fileBase64Str.length; i++) {
          ba[i] = fileBase64Str.charCodeAt(i)
        }
        var fileDecodeBase64 = new File([ba], nameFile, { type: mimeType })
        if (fileDecodeBase64) {
          return fileDecodeBase64
        }
      },

      maxTam() {
        if (this.fileSelected) {
          const file = constants.maxTamArchivo.find(
            (x) => x.type === this.fileSelected,
          )
          return file.size
        }
      },

      infoFormatos() {
        if (this.fileSelected) {
          const file = constants.infoFormatosAdmitidos.find(
            (x) => x.type === this.fileSelected,
          )
          return file.formats
        }
      },

      getTipoDocumentoAdjuntado(documento) {
        const extensionArchivo = FileHelper.getExtensionNombreArchivo(
          documento.name,
        )
        this.fileSelected = FileHelper.getFileType(extensionArchivo)
      },
    },
  }
</script>

<style></style>
