<template>
  <v-slide-y-reverse-transition>
    <v-card id="mapSlider" elevation="6">
      <v-card-text>
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col>
              <span id="timer" class="font-weight-light black--text">
                <span class="mx-1">{{ timeString }}</span>
                <span id="fecha" class="mx-0">{{ dateString }}</span>
                <span id="superficie" class="mx-2">{{ superficie }} ha</span>
              </span>
            </v-col>

            <v-col align="right">
              <v-btn
                color="blue darken-3"
                small
                dark
                depressed
                fab
                @click="centerSimulacion"
              >
                <v-icon small>mdi-image-filter-center-focus</v-icon>
              </v-btn>

              <v-btn
                class="mx-2"
                color="red darken-3"
                small
                dark
                depressed
                fab
                @click="stopSimulacion"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>

              <v-btn
                color="blue darken-3"
                small
                dark
                depressed
                fab
                @click="autoPlay"
              >
                <v-icon medium>
                  {{ isPlaying ? 'mdi-pause' : 'mdi-play' }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-slider
                v-model="sliderTime"
                color="blue darken-3"
                always-dirty
                :step="step"
                tick-size="2"
                :min="min"
                :max="max"
                hide-details
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-slide-y-reverse-transition>
</template>

<script>
  import * as ArcGIS from '../../../helpers/ArcGIS'

  export default {
    props: {
      simulacion: Object,
    },

    data: () => ({
      step: 15, // 15 min
      sliderTime: 0,

      initDate: null,
      date: null,

      superficie: null,

      min: null,
      max: null,

      interval: 0.75,
      isPlaying: false,
      playerTimer: null,
    }),

    computed: {
      dateString() {
        return this.date ? this.date.format('DD/MM/YYYY') : ''
      },
      timeString() {
        return this.date ? this.date.format('HH:mm') : ''
      },
    },

    watch: {
      simulacion() {
        if (this.simulacion) {
          this.initDate = this.$date.parseDate(this.simulacion.FECHA_INICIO)
          this.date = this.$date.parseDate(this.initDate) // .add(this.step, 'minutes')

          this.min = this.step
          this.max = this.simulacion.NUM_HORAS * 60
          this.sliderTime = this.step
        } /* else {
        this.sliderTime = null
      } */
      },

      sliderTime(val) {
        if (val !== undefined && val !== null && this.simulacion) {
          // Si val es 0 da false
          if (val > this.max) {
            this.sliderTime = this.min
            this.date = this.$date.parseDate(this.initDate)
            return
          }

          this.date = this.$date.parseDate(this.initDate).add(val, 'minutes')

          this.$emit('updateSimulacionTime', this.sliderTime)
        }
      },
    },

    methods: {
      autoPlay() {
        this.isPlaying = !this.isPlaying

        if (this.isPlaying) {
          this.playerTimer = setInterval(() => {
            this.sliderTime += this.step
          }, this.interval * 1000)
        } else clearInterval(this.playerTimer)
      },

      stopSimulacion() {
        clearInterval(this.playerTimer)
        this.$emit('stopSimulacion')
      },

      centerSimulacion() {
        ArcGIS.centerSimulacion()
      },

      updateSuperficieSimulacion(superficie) {
        this.superficie = superficie
      },
    },
    mounted() {
      this.$eventHub.$on(
        'superficieSimulacion',
        this.updateSuperficieSimulacion,
      )
    },
  }
</script>

<style scoped>
  #mapSlider {
    opacity: 0.8;
    transition: 0.5s;
    border-radius: 10px;
    z-index: 1;
    position: absolute;
    bottom: 5%;
    width: 600px;
    left: 50%;
    margin-left: -300px;
  }

  #mapSlider:hover {
    transition: 0.5s;
    opacity: 1;
  }

  #timer {
    font-size: 30px;
  }

  #fecha {
    font-size: 15px;
  }

  #superficie {
    font-size: 15px;
  }

  #slider {
    width: 80%;
  }

  /*###Desktops, big landscape tablets and laptops(Large, Extra large)####*/
  @media screen and (min-width: 1024px) {
    #mapSlider {
      bottom: 5%;
      width: 600px;
      left: 50%;
      margin-left: -300px;
    }
  }

  /*###Tablet(medium)###*/
  @media screen and (min-width: 431px) and (max-width: 1023px) {
    #mapSlider {
      bottom: 5%;
      width: 70%;
      left: 50%;
      margin-left: -35%;
    }
  }

  /*### Smartphones (portrait and landscape)(small)### */
  @media screen and (min-width: 0px) and (max-width: 430px) {
    #mapSlider {
      bottom: 5%;
      left: 50%;
      width: 80%;
      margin-left: -40%;
    }
  }
</style>
