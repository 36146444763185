<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title>Administrar flujos de trabajo</v-toolbar-title>
      <div
        id="create"
        style="z-index: 2"
        v-show="comprobarPermisos(['MOD_ACCIONES'])"
      >
        <v-speed-dial
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
          title="Menú de acciones"
        >
          <template #activator>
            <v-btn v-model="fab" color="primary" dark fab>
              <v-icon v-if="fab"> mdi-close </v-icon>
              <img v-else :src="require('@/assets/icons/menu_emergente.svg')">
            </v-btn>
          </template>
          <!-- AÑADIR TIPO ACCION -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                height="50"
                width="50"
                color="primary"
                v-show="comprobarPermisos(['MOD_ACCIONES'])"
                @click="showAddTipoAccionDialog"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Añadir acción</span>
          </v-tooltip>
          <!-- ASIGNAR ACCION A TIPO EMERGENCIA & SITUACION OPERATIVA -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                icon
                color="primary"
                v-show="comprobarPermisos(['MOD_ACCIONES'])"
                to="asignar_accion"
                class="iconsSpeedDial"
              >
                <img :src="require('@/assets/icons/accion_enlazada.svg')">
              </v-btn>
            </template>
            <span>Asignar acción a situación operativa</span>
          </v-tooltip>
          <!-- GESTION TIPO EMERGENCIA -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                icon
                color="primary"
                v-show="comprobarPermisos(['MOD_ACCIONES'])"
                to="tipo_emergencia"
                class="iconsSpeedDial"
              >
                <img :src="require('@/assets/icons/tipo_emergencia.svg')">
              </v-btn>
            </template>
            <span>Gestión tipo emergencia</span>
          </v-tooltip>
        </v-speed-dial>
      </div>
    </v-toolbar>

    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col xs="3" sm="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Búsqueda"
              outlined
              :dense="$vuetify.breakpoint.mobile"
              clearable
              hide-details
              @input="searchAcciones"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-data-table
            :class="{
              table: !$vuetify.breakpoint.mobile,
              tableMobile: $vuetify.breakpoint.mobile,
            }"
            height="100%"
            :headers="headers"
            :items="
              tiposAccionFilter
                ? tiposAccionFilter
                : tiposAccion
                  ? tiposAccion
                  : []
            "
            :loading="isLoading"
            loading-text="Cargando datos... por favor, espere"
            fixed-header
            hide-default-footer
            :page.sync="currentPage"
            :items-per-page="itemsPerPage"
          >
            <!-- NO DATA -->
            <template #no-data>
              <p>Datos no disponibles.</p>
            </template>

            <!-- ICONO Y NOMBRE ACCION -->
            <template #[`item.nombre`]="{ item }">
              <v-tooltip bottom v-if="item.SituacionOperativa.length">
                <template #activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-link-variant</v-icon>
                  </v-btn>
                </template>
                <span>Acción asociada a una situación operativa</span>
              </v-tooltip>
              <span>{{ item.nombre }}</span>
            </template>

            <!-- FECHA ULTIMA ACTUALIZACION -->
            <template #[`item.fechastamp`]="{ item }">
              <span>{{ item.fechastamp | formatDate }}</span>
            </template>

            <!-- USUARIO -->
            <template #[`item.Usuario`]="{ item }">
              <span>{{
                item.Usuario.nombre + ' ' + item.Usuario.apellidos
              }}</span>
            </template>

            <!-- ACCIONES -->
            <template #[`item.ACCIONES`]="{ item }">
              <!-- EDITAR DOCUMENTO -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-show="comprobarPermisos(['MOD_ACCIONES'])"
                    @click="showEditTipoAccionDialog(item)"
                  >
                    <v-icon color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <!-- BORRAR DOCUMENTO -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-show="comprobarPermisos(['MOD_ACCIONES'])"
                    @click="acceptDeleteTipoAccion(item)"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Borrar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-row>

        <!-- SECCIÓN PAGINACIÓN -->
        <v-row>
          <v-col xs="2" sm="3" md="2" v-if="!$vuetify.breakpoint.mobile">
            <v-row>
              <div class="text-center align-center mt-6 pl-6 ml-6">
                <span>Total {{ totalRecords }} registros</span>
              </div>
            </v-row>
          </v-col>
          <v-col xs="1" sm="4" md="3">
            <v-row>
              <v-col sm="4" md="4" class="textMostrar">
                <div>
                  <span>Mostrar</span>
                </div>
              </v-col>
              <v-col xs="4" sm="5" md="4">
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="itemsPerPage"
                  :items="perPageOptions"
                  :class="{
                    select: !$vuetify.breakpoint.mobile,
                    selectMobile: $vuetify.breakpoint.mobile,
                  }"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="6">
            <div>
              <v-pagination v-model="currentPage" :length="pageCount" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- DIALOG ADD/EDIT TIPO ACCION -->
    <vx-dialog-add-edit-tipo-accion
      :show="showDialog"
      :isEdit="isEdit"
      :editedTipoAccion="editTipoAccion"
      @cancelar="showDialog = false"
      @addTipoAccion="acceptAddTipoAccion"
      @editTipoAccion="acceptEditTipoAccion"
    />
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { StringHelper } from '@/helpers/StringHelper'

  import DialogAddEditTipoAccion from './DialogAddEditTipoAccion.vue'

  export default {
    components: {
      'vx-dialog-add-edit-tipo-accion': DialogAddEditTipoAccion,
    },

    data: () => ({
      search: '',
      tiposAccionFilter: null,
      isLoading: false,

      showDialog: false,
      editTipoAccion: {},
      isEdit: false,

      headers: [
        {
          text: 'Acción de trabajo',
          value: 'nombre',
          align: 'left',
          width: '160px',
          sortable: true,
        },
        {
          text: 'Descripción',
          value: 'descripcion',
          align: 'left',
          width: '180px',
          sortable: true,
        },
        {
          text: 'Últ. actualización',
          value: 'fechastamp',
          align: 'left',
          width: '140px',
          sortable: true,
        },
        {
          text: 'Usuario',
          value: 'Usuario',
          align: 'left',
          width: '120px',
          sortable: true,
        },
      ],

      // Speed dial buttons
      direction: 'left',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',

      currentPage: 1,
      itemsPerPage: 15,
      perPageOptions: [15, 50, 75, 100],
    }),

    watch: {
      top(val) {
        this.bottom = !val
      },
      right(val) {
        this.left = !val
      },
      bottom(val) {
        this.top = !val
      },
      left(val) {
        this.right = !val
      },
    },

    computed: {
      ...mapGetters('tipoAccion', ['tiposAccion']),

      mobile() {
        return this.$vuetify.breakpoint.mobile
      },

      totalRecords() {
        return this.tiposAccionFilter
          ? this.tiposAccionFilter.length
          : this.tiposAccion
          ? this.tiposAccion.length
          : 0
      },

      pageCount() {
        return parseInt(this.totalRecords / this.itemsPerPage + 1)
      },
    },

    methods: {
      ...mapActions('tipoAccion', [
        'fetchTiposAccion',
        'addTipoAccion',
        'updateTipoAccion',
      ]),

      comprobarPermisos(listaRolesPermisos) {
        return this.$store.getters['usuario/rolesUsuario'](listaRolesPermisos)
      },

      showAddTipoAccionDialog() {
        this.editTipoAccion = null
        this.showDialog = true
        this.isEdit = false
      },

      showEditTipoAccionDialog(tipoAccion) {
        this.editTipoAccion = Object.assign({}, tipoAccion)
        this.showDialog = true
        this.isEdit = true
      },

      async acceptAddTipoAccion(tipoAccion) {
        this.showDialog = false
        try {
          await this.addTipoAccion(tipoAccion)
          await this.fetchTiposAccion()
        } catch (err) {
          // Do nothing
        }
      },

      async acceptEditTipoAccion(tipoAccion) {
        this.showDialog = false
        try {
          await this.updateTipoAccion(tipoAccion)
          await this.fetchTiposAccion()
        } catch (err) {
          // Do nothing
        }
      },

      async acceptDeleteTipoAccion(tipoAccion) {
        if (this.accionEstaAsignadaAsituacionOperativa(tipoAccion)) {
          this.notificarTipoAccionEstaAsociadaAsituacionOperativa()
        } else {
          const msg = 'Eliminando la acción ' + tipoAccion.nombre
          this.$root.$confirmDialog
            .open('¿Está seguro?', msg)
            .then((result) => {
              if (result) {
                this.$store
                  .dispatch('tipoAccion/deleteTipoAccionbyId', {
                    id: tipoAccion.id,
                  })
                  .then((resultDelete) => {
                    if (resultDelete) {
                      this.notificarTipoAccionDelete(tipoAccion)
                      this.fetchTiposAccion()
                    }
                  })
              }
            })
        }
      },

      accionEstaAsignadaAsituacionOperativa(tipoAccion) {
        // Comprobar que la accion a borrar, no esta asignada a ninguna situacion operativa
        return tipoAccion.SituacionOperativa.length ? true : false
      },

      notificarTipoAccionEstaAsociadaAsituacionOperativa() {
        this.$notify({
          title: 'Acción asignada a situación operativa',
          text: 'Antes de borrar la acción, es necesario desasignar la acción de la situación operativa a la que está asignada.',
          type: 'error',
        })
      },

      notificarTipoAccionDelete(tipoAccion) {
        this.$notify({
          title: 'Acción eliminada',
          text: `Se ha eliminado la acción ${tipoAccion.nombre}`,
        })
      },

      async loadData() {
        this.isLoading = true
        try {
          await this.fetchTiposAccion()
        } catch (err) {
          if (err.response.status !== 200 && err.response.status !== 500) {
            this.$notify({
              title: err.response.statusText,
              text: 'El usuario no dispone de permisos para acceder al módulo de Administración de flujos de trabajo',
              type: 'error',
            })
          }
          this.$router.push('/')
        }
        this.isLoading = false
      },

      searchAcciones() {
        // Atributos no validos para filtrar
        function atributoValido(atributo) {
          const atributosNoValidos = [
            'codigo',
            'borrado',
            'fechastamp',
            'Accion',
            'Accion_situacion_tipo_emergencia',
            'SituacionOperativa',
          ]

          const encontrado = atributosNoValidos.find((x) => x === atributo)
          return encontrado ? false : true
        }

        // Filtro de busqueda para todas las acciones
        const searchText = this.search ? this.search : ''

        this.tiposAccionFilter = this.tiposAccion.filter(function (acciones) {
          for (var attrAccion in acciones) {
            if (atributoValido(attrAccion)) {
              // Usuario
              if (attrAccion === 'Usuario') {
                for (var attrUsuario in acciones[attrAccion]) {
                  if (atributoValido(attrUsuario)) {
                    const atributo =
                      acciones[attrAccion]['nombre'] +
                      ' ' +
                      acciones[attrAccion]['apellidos']

                    if (StringHelper.compareString(searchText, atributo))
                      return acciones[attrAccion]
                  }
                }
              } else {
                if (acciones[attrAccion]) {
                  const atributo = acciones[attrAccion]

                  if (StringHelper.compareString(searchText, atributo))
                    return acciones
                }
              }
            }
          }
        })
      },
    },

    async created() {
      // Mostrar acciones si currentUsuario rol admin o mod_acciones
      if (this.$store.getters['usuario/rolesUsuario'](['MOD_ACCIONES'])) {
        // Acciones tipoAccion
        this.headers.push({
          text: 'Acciones',
          value: 'ACCIONES',
          align: 'center',
          width: '80px',
          sortable: false,
        })
      }

      // await this.loadData()
    },

    async activated() {
      await this.loadData()
    },
  }
</script>

<style lang="scss" scoped>
  /* Estilos para que la tabla tenga un max-height y pueda crecer */
  .table {
    width: 100vw;
    max-height: calc(100vh - 270px);
  }
  .tableMobile {
    width: 100vw;
    max-height: calc(100vh - 330px);
  }

  .v-data-table {
    overflow: auto;
  }

  .v-data-table >>> .v-data-table__wrapper {
    overflow: unset;
  }

  /* Hover transparente para todas las filas */
  ::v-deep tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
  /* Hover en color gris para las filas impares */
  ::v-deep tbody {
    tr:hover:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
  /* Color gris fila data table impar */
  ::v-deep tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  /* Sticky column data-table - Nombre */
  ::v-deep thead tr th:nth-of-type(2) {
    position: sticky;
    left: 0;
  }

  ::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(2) {
    background: whitesmoke;
    position: sticky;
    left: 0;
  }

  ::v-deep tbody tr td:nth-of-type(2) {
    background: white;
    position: sticky;
    left: 0;
  }

  /* Sticky column data-table - Acciones */
  ::v-deep thead tr th:nth-of-type(6) {
    position: sticky;
    right: 0;
  }

  ::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(6) {
    background: whitesmoke;
    position: sticky;
    right: 0;
  }

  ::v-deep tbody tr td:nth-of-type(6) {
    background: white;
    position: sticky;
    right: 0;
  }

  /* Speed dial list */
  #create .v-speed-dial {
    position: absolute;
    top: 18px;
  }

  #create .v-btn--floating {
    position: relative;
  }

  ::v-deep .v-speed-dial--right {
    right: 16px;
    // top: 18px;
  }

  ::v-deep .v-speed-dial--direction-left .v-speed-dial__list,
  .v-speed-dial--direction-right .v-speed-dial__list {
    height: 100%;
    top: 0;
    padding: 0 16px;
    margin-top: 22px;
  }

  /* Alinear iconos speed dial list */
  .iconsSpeedDial {
    margin-top: 15px;
  }

  /* Tamaño select mostrar por página */
  .select {
    width: 85px;
  }
  .selectMobile {
    width: 85px;
  }

  /* Mostrar */
  .textMostrar {
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    justify-content: flex-end;
  }

  /* Resultados */
  .textResultados {
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -15px;
    margin-right: 0px;
    display: flex;
    justify-content: flex-start;
  }

  /* Paginacion */
  .paginacion {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    justify-content: flex-end;
  }
</style>
