import Vue from 'vue'
import request from './request'
import constants from '@/helpers/constants'

export class TipoEmergenciaRequest {
  static async getAll() {
    try {
      return (await request.get('tipoEmergencia')).data.body
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.TipoEmergencia
            .getTiposEmergencia,
          type: 'error',
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error',
        })
      }
    }
  }

  /**
   * @param  dataTipoEmergencia
   * @returns {Promise<any>}
   */
  static async addTipoEmergencia(tipoEmergencia) {
    try {
      return (await request.post('tipoEmergencia', tipoEmergencia)).data.body
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.TipoEmergencia
            .nuevoTipoEmergencia,
          type: 'error',
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error',
        })
      }
    }
  }

  /**
   * @param tipoEmergencia
   * @returns {Promise<any>}
   */
  static async update(tipoEmergencia) {
    try {
      return (
        await request.put(`tipoEmergencia/${tipoEmergencia.id}`, tipoEmergencia)
      ).data.body
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.TipoEmergencia
            .editarEmergencia,
          type: 'error',
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error',
        })
      }
    }
  }

  static async deleteTipoEmergencia(tipoEmergenciaId) {
    try {
      return (await request.delete(`tipoEmergencia/${tipoEmergenciaId}`)).data
        .body
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.TipoEmergencia
            .borrarTipoEmergencia,
          type: 'error',
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error',
        })
      }
    }
  }
}
