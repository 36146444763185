<template>
  <v-dialog v-model="value" persistent width="450px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Editar grupo</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="dialog-content">
        <v-form ref="form">
          <v-container>
            <v-row class="mt-0">
              <v-col xs="12" sm="12" md="12">
                <v-text-field
                  type="text"
                  label="Nombre*"
                  placeholder="Nombre del grupo"
                  v-model="nombre"
                  counter
                  maxlength="250"
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    components: {},

    props: {
      value: Boolean,
      chatId: null,
    },

    data: () => ({
      nombre: null,
    }),

    computed: {
      entidades() {
        return this.$store.state.entidad.entidades
      },
      emergenciaSelected() {
        return this.$store.state.emergencia.emergenciaSelected
      },
      chats() {
        return this.$store.state.chat.chats
      },
    },

    methods: {
      chatWithSameNameExist(nombre) {
        return this.chats.some((chat) => chat.nombre === nombre)
      },

      cancelar() {
        this.$refs.form.reset()
        this.$emit('input', false)
      },

      async aceptar() {
        if (this.nombre) {
          if (!this.chatWithSameNameExist(this.nombre)) {
            try {
              await this.$store.dispatch('chat/SET_NAME', {
                chatId: this.chatId,
                newName: this.nombre,
              })
              this.$notify({
                title: 'Nombre del grupo cambiado',
                text: 'Se ha cambiado el nombre del grupo',
              })
            } catch (error) {
              console.error(error)
              this.$notify({
                title: 'Error al cambiar nombre al grupo',
                text: 'Ha ocurrido un error desconocido al editar el grupo',
                type: 'error',
              })
            }
            this.$refs.form.reset()
            this.$store.dispatch('chat/FETCH_CHATS', this.emergenciaSelected.id)
            this.$emit('input', false)
          } else {
            this.$notify({
              title: 'Nombre del grupo ya usado',
              text: `Ya existe un grupo con el nombre ${this.nombre}`,
              type: 'warning',
            })
          }
        } else {
          this.$notify({
            title: 'Se necesitan mas datos',
            text: 'Se requiere un nombre para poder editar',
            type: 'warning',
          })
        }
      },
    },

    watch: {},
  }
</script>

<style scoped lang="scss">
  .inputText {
    margin-top: -15px;
  }
  .entidades {
    height: 20rem;
  }
</style>
