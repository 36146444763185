import api from '@/api'
import Vue from 'vue'
import constants from '@/helpers/constants'

const Q = require('q')

const state = {
  accionSituacionTipoEmergencia: [],
}

const getters = {
  accionSituacionTipoEmergencia: (state) => {
    return state.accionSituacionTipoEmergencia
  },

  accionesByTipoEmerSitOperativaId: () => async (data) => {
    try {
      const response =
        await api.accionSituacionTipoEmergencia.getAccionesByTipoEmergenciaIdSituacionOperativaId(
          data,
        )
      if (response.status === 200) {
        return response.data.body
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        Vue.notify({
          title: err.response.statusText,
          text: constants.notificacionesPermisos.AccionSituacionTipoEmergencia
            .getAccionesByTipoEmergenciaIdSituacionOperativaId,
          type: 'error',
        })
      } else if (err.request) {
        Vue.notify({
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
          type: 'error',
        })
      }
    }
  },
}

const mutations = {
  SET_ACCIONES_SITUACIONES_TIPOS_EMERGENCIA(state, data) {
    state.accionSituacionTipoEmergencia = data
  },

  ASSIGN_UNASSIGN_ACCIONES_SITUACION_TIPO_EMERGENCIA(state, data) {
    if (data) {
      this.notify(
        'Acciones actualizadas',
        'Se han actualizado las acciones para la situación operativa y el tipo de emergencia seleccionadas',
      )
    }
  },

  ERROR_AUTORIZACION(state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER(state, data) {
    this.notify(data.title, data.text, 'error')
  },
}

const actions = {
  async fetchAccionSituacionTipoEmergencia({ commit }) {
    const deferred = Q.defer()

    try {
      const response =
        await api.accionSituacionTipoEmergencia.getAllAccionSituacionTipoEmergencia()
      if (response.status === 200) {
        commit('SET_ACCIONES_SITUACIONES_TIPOS_EMERGENCIA', response.data.body)
      }

      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.AccionSituacionTipoEmergencia
            .getAllAccionSituacionTipoEmergencia,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async assignUnassignAccionSituacionTipoEmergencia({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response =
        await api.accionSituacionTipoEmergencia.assignUnassignAccionSituacionTipoEmergencia(
          data,
        )
      if (response.status === 200) {
        commit(
          'ASSIGN_UNASSIGN_ACCIONES_SITUACION_TIPO_EMERGENCIA',
          response.data.body,
        )
      }
      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.AccionSituacionTipoEmergencia
            .asignarDesasignarAccionesSituacionTipoEmergencia,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default module
