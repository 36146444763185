<template>
  <v-dialog v-model="show" scrollable persistent max-width="600px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title> Acción del dialog</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row class="mt-1">
              <!-- NOMBRE TIPO EMERGENCIA -->
              <v-text-field
                label="Tipo emergencia*"
                v-model="nombre"
                dense
                maxlenght="255"
                :rules="[globalRules.required]"
              />
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    props: {
      show: Boolean,
      isEdit: Boolean,
      editedTipoEmergencia: Object,
    },

    data: () => ({
      isValid: false,
      search: '',

      nombre: null,
    }),

    watch: {
      async show() {
        if (this.show) {
          if (this.isEdit) {
            // Llenar campos con tipoEmergencia
            this.nombre = this.editedTipoEmergencia.nombre
          }
        }
      },
    },

    computed: {
      ...mapGetters('usuario', ['currentUsuario']),

      accionDialog() {
        return this.editedTipoEmergencia
          ? 'Editar tipo emergencia'
          : 'Nueva emergencia'
      },
    },

    methods: {
      cancelar() {
        this.$refs.form.reset()
        this.$emit('cancelar')
      },

      async aceptar() {
        const tipoEmergencia =
          (this.editedTipoEmergencia &&
            JSON.parse(JSON.stringify(this.editedTipoEmergencia))) ||
          {}

        tipoEmergencia.nombre = this.nombre
        tipoEmergencia.usuariostamp = this.currentUsuario.ID

        if (this.isEdit) {
          this.$refs.form.reset()
          tipoEmergencia.id = this.editedTipoEmergencia.id
          this.$emit('editTipoEmergencia', tipoEmergencia)
        } else {
          this.$refs.form.reset()
          this.$emit('addTipoEmergencia', tipoEmergencia)
        }
      },
    },
  }
</script>

<style></style>
