import request from '../request'

export default {
  getAllIncendios: function () {
    return request.get('lista_incendios')
  },

  getIncendios: function (daysBack, codCCAA, codProv) {
    return request.get(`incendio/${daysBack}/${codCCAA}/${codProv}`)
  },

  getTiposIncendioEstado: function () {
    return request.get('tipo_incendio_estado')
  },

  getTiposDeteccion: function () {
    return request.get('tipo_deteccion')
  },

  addIncendio: function (data) {
    return request.post('incendio', data)
  },
  editIncendio: function (data) {
    return request.put(`incendio_id/${data.ID_INCENDIO}`, data)
  },
  ocultarIncendio: function (data) {
    return request.put(`ocultar_incendio/${data.ID_INCENDIO}/${data.OCULTO}`)
  },

  addEstadoIncendio: function (data) {
    return request.post('incendio_estado', data)
  },
  editEstadoIncendio: function (data) {
    return request.put('incendio_estado', data)
  },
  deleteEstadoIncendio: function (data) {
    return request.delete('incendio_estado', { data: data })
  },

  changeSectoresIncendio: function (data) {
    return request.put(`sector/${data.ID_INCENDIO}`, data)
  },

  addMediosIncendio: function (data) {
    return request.post('medio_sector', data)
  },
  editMedioIncendio: function (data) {
    return request.put(`medio_sector/${data.ID_MEDIO_SECTOR}`, data)
  },
  deleteMedioIncendio: function (data) {
    return request.delete(`medio_sector/${data.ID_MEDIO_SECTOR}`, {
      data: data,
    })
  },

  editPlanificacionMedio: function (data) {
    return request.put(
      `planificacion_medio/${data.PLANIFICACION.ID_PLANIFICACION_MEDIO}`,
      data.PLANIFICACION,
    )
  },

  addPlanOperaciones: function (data) {
    return request.post('plan_operaciones', data)
  },
  editPlanOperaciones: function (data) {
    return request.put(`plan_operaciones/${data.ID_PLAN_OPERACIONES}`, data)
  },
  deletePlanOperaciones: function (data) {
    return request.delete(`plan_operaciones/${data.ID_PLAN_OPERACIONES}`)
  },
}
