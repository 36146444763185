import axios from 'axios'
import { AxiosInstance } from 'axios'
import store from '../store'
import constants from '../helpers/constants'
// @todo move baseURL to config file

const baseURL = constants.baseURL

/**
 * @type {AxiosInstance}
 */
const request = axios.create({
  baseURL,
})

request.pendingRequests = 0 // Nº de llamadas pendientes

// Interceptor para añadir el token a las peticiones
request.interceptors.request.use(
  (config) => {
    request.pendingRequests++

    const token = store.getters['auth/accessToken']
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  function (error) {
    decreasePendingRequests()
    return Promise.reject(error)
  },
)

// Interceptor en caso de error token https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
request.interceptors.response.use(
  (response) => {
    decreasePendingRequests()
    return response
  },
  async function (error) {
    decreasePendingRequests()
    const originalRequest = error.config
    if (
      error.response &&
      error.response.status === 403 &&
      originalRequest.path !== '/login'
    ) {
      store.dispatch('auth/logout', true)
      store.dispatch('app/setLoading', false)
    }
    return Promise.reject(error)
  },
)

function decreasePendingRequests() {
  request.pendingRequests--

  if (request.pendingRequests === 0) {
    // hide loading
  }
}

export default request
