import request from '../request'

export default {
  getEntidades: function () {
    return request.get('entidad')
  },

  getListaEntidades: function () {
    return request.get('entidad/listaEntidades')
  },

  addEntidad: function (data) {
    return request.post('entidad', data)
  },

  getDocumentoEntidad: function (id_entidad) {
    return request.post(`entidad/documento/${id_entidad}`)
  },

  addDelegacion: function (data) {
    return request.post('entidad/delegacion', data)
  },

  addDepartamento: function (data) {
    return request.post('/entidad/departamento', data)
  },

  updateEntidad: function (data) {
    return request.put(`entidad/${data.id_entidad}`, data)
  },

  updateDelegacion: function (data) {
    return request.put(`entidad/delegacion/${data.id_delegacion}`, data)
  },

  updateDepartamento: function (data) {
    return request.put(`entidad/departamento/${data.id_departamento}`, data)
  },

  deleteEntidad: function (data) {
    return request.delete(`entidad/${data.id_entidad}`)
  },

  deleteDelegacion: function (data) {
    return request.delete(`entidad/delegacion/${data.id_delegacion}`)
  },

  deleteDepartamento: function (data) {
    return request.delete(`entidad/departamento/${data.id_departamento}`)
  },
}
