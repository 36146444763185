<template>
  <v-dialog v-model="show" persistent width="400px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Descargar informe</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="dialog-content">
        <v-form ref="form">
          <v-container>
            <v-row class="contenido-title">
              <v-col cols="12">
                <span>Contenido del informe:</span>
              </v-col>
            </v-row>

            <v-row class="data-emergencia">
              <v-col cols="12">
                <span> {{ emergencia.nombre }} </span>
              </v-col>
            </v-row>

            <v-row class="data-emergencia">
              <v-col cols="12">
                <span> Datos de la emergencia </span>
              </v-col>
            </v-row>

            <v-row class="data-emergencia">
              <v-col cols="12">
                <span> Información adicional </span>
              </v-col>
            </v-row>

            <v-row class="data-emergencia">
              <v-col cols="12">
                <span> Protocolo de intervención </span>
              </v-col>
            </v-row>

            <v-row class="data-emergencia-mensajeria">
              <v-col cols="12">
                <span> Mensajería </span>
              </v-col>
            </v-row>

            <v-row class="data-emergencia-radioButton">
              <v-col cols="12">
                <!-- Radio button -->
                <v-radio-group column v-model="mensajeriaSelected">
                  <v-radio
                    label="Incluir todos los mensajes del chat genérico"
                    value="mensajesAll"
                  />
                  <v-radio
                    label="Seleccionar rango de fechas de los mensajes del chat genérico"
                    value="mensajesRangoFecha"
                  />
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row
              v-if="mensajeriaSelected == 'mensajesRangoFecha'"
              class="fecha-title"
            >
              <v-col xs="6" sm="6" md="6">
                <span>Fecha de inicio</span>
              </v-col>

              <v-col
                v-if="mensajeriaSelected == 'mensajesRangoFecha'"
                xs="6"
                sm="6"
                md="6"
              >
                <span>Fecha de fin</span>
              </v-col>
            </v-row>

            <v-row
              v-if="mensajeriaSelected == 'mensajesRangoFecha'"
              class="fecha-date-picker"
            >
              <v-col>
                <!-- fecha de inicio -->
                <v-menu
                  ref="fechaInicio"
                  v-model="showFechaInicio"
                  :close-on-content-click="false"
                  :open-on-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      ref="fechaInicioOutput"
                      :value="fechaInicioFormatted"
                      @click:clear="fechaInicioSelected = null"
                      placeholder="dd/mm/yyyy"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      :rules="[globalRules.required]"
                    />
                  </template>
                  <v-date-picker
                    v-model="fechaInicioSelected"
                    first-day-of-week="1"
                    @input="$refs.fechaInicio.save(cerrarCalendario)"
                    locale="es-ES"
                  />
                </v-menu>
              </v-col>
              <v-col>
                <!-- fecha de fin -->
                <v-menu
                  ref="fechaFin"
                  v-model="showFechaFin"
                  :close-on-content-click="false"
                  :open-on-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      ref="fechaFinOutput"
                      :value="fechaFinFormatted"
                      @click:clear="fechaFinSelected = null"
                      placeholder="dd/mm/yyyy"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      :rules="[globalRules.required]"
                    />
                  </template>
                  <v-date-picker
                    v-model="fechaFinSelected"
                    first-day-of-week="1"
                    @input="$refs.fechaFin.save(cerrarCalendario)"
                    locale="es-ES"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar()">Cancelar</v-btn>
        <v-btn
          text
          color="green darken-1"
          :loading="isLoading"
          :disabled="unselectedDates"
          @click="aceptar()"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { SituacionOperativaRequest } from '@/api/situacionOperativa'
  import constants from '../../../helpers/constants'

  export default {
    props: {
      show: Boolean,
      emergencia: Object,
    },

    data: () => ({
      isLoading: false,
      mensajeriaSelected: null,

      escogerRangoFechas: false,
      showFechaInicio: false,
      fechaInicioSelected: null,
      showFechaFin: false,
      fechaFinSelected: null,
      unselectedDates: false,

      situacionesOperativasEmergencia: [],
    }),

    watch: {
      mensajeriaSelected(newValue) {
        newValue === 'mensajesAll'
          ? (this.unselectedDates = false)
          : (this.unselectedDates = true)
      },

      fechaInicioSelected(newValue) {
        this.unselectedDates = newValue && this.fechaFinSelected ? false : true
      },

      fechaFinSelected(newValue) {
        this.unselectedDates =
          newValue && this.fechaInicioSelected ? false : true
      },
      show(newValue) {
        if (newValue) {
          this.unselectedDates = false
        }
      },
    },

    computed: {
      fechaInicioFormatted() {
        const date = this.$date.parseDate(
          this.fechaInicioSelected,
          'YYYY-MM-DD',
        )
        return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
      },

      fechaFinFormatted() {
        const date = this.$date.parseDate(this.fechaFinSelected, 'YYYY-MM-DD')
        return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
      },

      optionMensajeriaSelected() {
        if (this.mensajeriaSelected === 'mensajesAll') {
          this.$refs.fechaInicioOutput.reset()
          this.$refs.fechaFinOutput.reset()
          return true
        } else if (this.mensajeriaSelected === 'mensajesRangoFecha') {
          // Deshabilitar boton aceptar hasta que escojan fecha inicio y fin
          return false
        } else {
          return true
        }
      },

      messages() {
        if (this.$store.state.chat.messages) {
          return this.$store.state.chat.messages
        }
        return []
      },
    },

    methods: {
      cancelar() {
        this.$emit('cancelar')
        this.fechaInicioSelected = null
        this.fechaFinSelected = null
        this.$refs.form.reset()
      },

      async aceptar() {
        if (this.mensajeriaSelected === 'mensajesAll') {
          // Generar pdf con todos los mensajes sin filtro de fecha
          this.isLoading = true
          await this.generatePDFEmergencia(
            this.emergencia,
            null,
            null,
            this.messages,
          )
          this.isLoading = false
          this.fechaInicioSelected = null
          this.fechaFinSelected = null
          this.$emit('cancelar')
          this.$refs.form.reset()
        } else if (this.mensajeriaSelected === 'mensajesRangoFecha') {
          // Generar pdf con los mensajes por rango de fechas
          this.isLoading = true
          const filterMensajes = await this.filtrarMensajesPorFecha(
            this.messages,
            this.fechaInicioSelected,
            this.fechaFinSelected,
          )
          await this.generatePDFEmergencia(
            this.emergencia,
            this.fechaInicioSelected,
            this.fechaFinSelected,
            filterMensajes,
          )
          this.isLoading = false
          this.fechaInicioSelected = null
          this.fechaFinSelected = null
          this.$emit('cancelar')
          this.$refs.form.reset()
        } else {
          // Generar pdf sin la seccion de mensajeria
          this.isLoading = true
          await this.generatePDFEmergencia(this.emergencia, null, null, null)
          this.isLoading = false
          this.fechaInicioSelected = null
          this.fechaFinSelected = null
          this.$emit('cancelar')
          this.$refs.form.reset()
        }
      },

      cerrarCalendario() {
        this.showFechaInicio = false
        this.showFechaFin = false
      },

      // TODO: dividir en diferentes metodos!
      async generatePDFEmergencia(emergencia, fechaInicio, fechaFin, mensajes) {
        const titlePdf =
          'InformeEmergencia_' + this.$date.now().format('DDMMYYYY')
        const textoMensajeriaGeneracionInforme =
          /*emergencia.nombre + '\n' +*/ 'Fecha generación informe: ' /*+ this.$date.currentDate()*/
        const tituloEmergencia = emergencia.nombre
        const coordenadas = emergencia.latitude + ', ' + emergencia.longitude
        const descripcion = emergencia.descripcion
        const riesgos =
          emergencia.TipoRiesgos &&
          this.showRiesgosEmergencia(emergencia.TipoRiesgos)
        const listaSituacionesOperativas = await this.getSituacionesOperativas(
          emergencia,
        )
        // console.log('LISTA SITUACIONES: ', listaSituacionesOperativas)
        const victimas = emergencia.victimas
        const vehiculosAfectados = emergencia.vehiculosAfectados
        const operativosIntervencion = emergencia.operativosIntervencion
        const personasEvacuadas = emergencia.personasEvacuadas
        const infraestructurasAfectadas = emergencia.infraestructurasAfectadas
        const serviciosInvolucrados = emergencia.serviciosInvolucrados

        const docDefinition = {
          // pageOrientation: 'landscape',
          info: {
            title: 'InformeEmergencia_' + this.$date.now().format('DDMMYYYY'),
          },

          header: function () {
            // computations...
            // if (currentPage === 1) {
            return {
              columns: [
                {
                  svg: constants.headerPDFPemam.svgLeft,
                  width: 180,
                  alignment: 'left',
                  margin: [40, 32, 0, 0],
                },
                {
                  svg: constants.headerPDFPemam.svgRight,
                  width: 250,
                  //text: textoPEMAM + '\n PEMAM',
                  alignment: 'right',
                  // fontSize: 12,
                  margin: [-120, 30, 0, 0],
                },
              ],
            }
            // }
          },

          footer: function (currentPage, pageCount) {
            // if (currentPage === 1) {
            //   return {
            //     columns: [
            //       // { text: textoGeneracionInforme },
            //       { text: textoMensajeriaGeneracionInforme, alignment: 'left', margin: [30, 5, 40, 0] },
            //       { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [60, 10, 40, 20]}
            //     ]
            //   }
            // } else {
            return {
              text: currentPage.toString() + '/' + pageCount,
              alignment: 'right',
              margin: [60, 10, 40, 20],
            }
            //}
          },
          images: {
            checked:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA5CAYAAABnLziGAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHgSURBVGhD7do/bsIwGIdhtxOwBTZm7hG2ZISDcBvuAozcgzUjZEy20l/kSKkaO7YTx5+NX8nKn+l71FqpKn/9/MY+oG9+Db4IDa0IDa2PgSp/Xl6vF3s8Hs2VUuv1mu12u+YqSwl6vV6bRbnj8cjSNOVP/xuEXi4Xdrvd+BPtDocD2+/3/Olv0j2KX1NfkAiziraWFIo96VNVVQlnlkKfzye/8yfRzPE7GloRSrHtdtssk7yBAng6nZplgvUC2iKXy2WzTLDkoV1kW4tNkoS/GY40tA/ZbbVa8bvhyEJlSPwFdD6fWVEU/M1wJKFTIxE5qA0kIgW1hURkoDaRaBQUw2GNzTYSGUPb4bDGYOdAIiNodzgsU+xcSKQN7RvOBDsnEmlDy7Ls/b+MDnZuJNKGygZRwbpAIqM9aop1hURGUKSLdYlExlCkinWNRKOgSAXrGolGQ9EQ1jUSTQJFOoPPjUSTQZEKwAUSTQpFMogrJJocivpALpHIChR1Ya6RyBoUtUDXSGQVioB1jUTWoVSK0NCKULTZbPidP4lmlkJxIsunFouFcGYpFMfOsizjT/TL81x4VE7piBxOj93vd1bXNX9DK/wkgRSdGkPahx6pnT3Cnpzs0GMIxc9LaH0IlLE3YLWWXxVrBkcAAAAASUVORK5CYII=',
            unchecked:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA1CAYAAAAK0RhzAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFBSURBVGhD7ZoxboAwDEXdTsxwBDgDK2zABisH5ArAyMoZ4AiwwtbWIVXVJlRRl+YjPwlFjoSUp9jKYL+8fUAP5FWvj0PE0Pi1xvZ9p2VZ1OoTYRhSkiRqveNWbBgG9flM0zSUZZmOvmMV6/uexnHUkd/UdU15nuvoC6PGOO1QpBg+q61UDDGuKSSO47Ce2UhFWxrGcayK1QdYYl1XHV0URUFVVenowknM9uN/4Xo+eaDREDE0RAwNEUNDxNAQMTREDA0RQ0PE0BAxNEQMDRFDQ8TQEDE0RAwNEUPjsWJOHU1u0/rUqv3Zc3Zq1c7zTF3X6QiDtm0pTVMdXRip6MvNuBIEgfXMhhiP8fDVolCWpXX0yDqZw3CtTdNE53nqHb/gm2Ip21QOcyvGfA6Jbdumd/wgiiKVfn8aEkNHHmgsiN4BnMmSNGTFgFkAAAAASUVORK5CYII=',
          },
          content: [],
          defaultStyle: {
            fontSize: 11,
          },
          pageMargins: [60, 60, 40, 60], // [left, top, right, bottom]
          // Control de pagina
          // pageBreakBefore: function (currentNode, nodeContainer) {
          //   return currentNode.headlineLevel === 1 && nodeContainer.getFollowingNodesOnPage.length === 0
          // }
        }

        // FECHA DE GENERACION DEL INFORME
        docDefinition.content.push({
          text: [
            {
              text: textoMensajeriaGeneracionInforme,
              fontSize: 12,
              alignment: 'left',
              bold: true,
            },
            { text: this.$date.currentDate(), fontSize: 12, alignment: 'left' },
          ],
          margin: [0, 40, 0, 0],
        })

        // TITULO
        docDefinition.content.push({
          text: [
            {
              text: tituloEmergencia,
              bold: true,
              fontSize: 20,
              alignment: 'left',
            },
          ],
          margin: [0, 40, 40, 12],
        })

        // COORDENADAS
        docDefinition.content.push({
          columns: [
            { text: 'Coordenadas: ', bold: true, width: 100 },
            { text: coordenadas, bold: false },
          ],
          fontSize: 12,
          margin: [0, 12, 0, 0],
        })

        // DESCRIPCION
        docDefinition.content.push({
          columns: [
            { text: 'Descripción: ', bold: true, width: 100 },
            { text: descripcion, bold: false },
          ],
          fontSize: 12,
          margin: [0, 12, 0, 0],
        })

        // RIESGO
        docDefinition.content.push({
          columns: [
            { text: 'Riesgos: ', bold: true, width: 100 },
            { text: riesgos, bold: false },
          ],
          fontSize: 12,
          margin: [0, 12, 0, 0],
        })

        // INFORMACION ADICIONAL
        docDefinition.content.push({
          text: [
            {
              text: 'Información adicional',
              bold: true,
              fontSize: 20,
              alignment: 'left',
            },
          ],
          margin: [0, 60, 40, 12],
        })

        // NUMERO VICTIMAS
        docDefinition.content.push({
          columns: [
            { text: 'Número de víctimas: ', bold: true },
            { text: victimas, bold: false },
          ],
          fontSize: 12,
          margin: [0, 12, 0, 0],
        })

        // VEHICULOS AFECTADOS
        docDefinition.content.push({
          columns: [
            { text: 'Vehículos afectados: ', bold: true },
            { text: vehiculosAfectados, bold: false },
          ],
          fontSize: 12,
          margin: [0, 12, 0, 0],
        })

        // NUMERO OPERATIVOS INTERVINIENDO
        docDefinition.content.push({
          columns: [
            { text: 'Número de operativos interviniendo: ', bold: true },
            { text: operativosIntervencion, bold: false },
          ],
          fontSize: 12,
          margin: [0, 12, 0, 0],
        })

        // PERSONAS EVACUADAS
        docDefinition.content.push({
          columns: [
            { text: 'Personas evacuadas: ', bold: true },
            { text: personasEvacuadas, bold: false },
          ],
          fontSize: 12,
          margin: [0, 12, 0, 0],
        })

        // EDIFICIOS E INFRAESTRUCTURAS AFECTADAS
        docDefinition.content.push({
          columns: [
            { text: 'Edificios e infraestructuras afectadas: ', bold: true },
            { text: infraestructurasAfectadas, bold: false },
          ],
          fontSize: 12,
          margin: [0, 12, 0, 0],
          // pageBreak: 'after'
        })

        // SERVICIOS INVOLUCRADOS
        docDefinition.content.push({
          columns: [
            { text: 'Servicios involucrados: ', bold: true },
            { text: serviciosInvolucrados, bold: false },
          ],
          fontSize: 12,
          margin: [0, 12, 0, 0],
        })

        for (let i = 0; i < listaSituacionesOperativas.length; i++) {
          // let situacionTable = []
          const situacion = listaSituacionesOperativas[i]

          // FASE DE LA EMERGENCIA
          docDefinition.content.push({
            text: [
              {
                text: situacion.SITUACION_OPERATIVA,
                bold: true,
                fontSize: 20,
                alignment: 'left',
              },
            ],
            margin: [0, 30, 40, 12],
          })

          docDefinition.content.push({
            text: [
              {
                text: 'Declaración: ',
                bold: true,
                fontSize: 12,
                alignment: 'left',
              },
              { text: situacion.DECLARACION, fontSize: 12, alignment: 'left' },
            ],
            margin: [0, 12, 0, 12],
          })

          docDefinition.content.push({
            text: [
              {
                text: 'Fase: ',
                bold: true,
                fontSize: 12,
                alignment: 'left',
              },
              { text: situacion.FASE, fontSize: 12, alignment: 'left' },
            ],
            margin: [0, 0, 0, 12],
          })

          // SITUACIÓN OPERATIVA
          // let tableSituacionOperativa = []
          // tableSituacionOperativa.push([
          //   'DECLARACIÓN',
          //   'FASE',
          //   'SITUACIÓN OPERATIVA'
          // ])

          // situacionTable.push(
          //   situacion.DECLARACION
          // )
          // situacionTable.push(situacion.FASE)
          // situacionTable.push(situacion.SITUACION_OPERATIVA)

          // tableSituacionOperativa.push(situacionTable)

          // docDefinition.content.push({
          //   layout: 'lightHorizontalLines', // optional
          //   table: {
          //     headerRows: 1,
          //     body: tableSituacionOperativa,
          //     widths: ['25%', '33%', '42%'],
          //   },
          //   width: 'auto',
          //   alignment: 'left',
          //   margin: [0, 24, 0, 20],
          // })

          // SITUACIÓN OPERATIVA
          const tableProtocoloIntervencion = []
          tableProtocoloIntervencion.push([
            '',
            'ACCIÓN',
            'ACTUALIZACIÓN',
            'GRUPO DE ACCIÓN',
          ])

          for (let j = 0; j < situacion.ACCIONES.length; j++) {
            const protocoloTable = []
            const protocolo = situacion.ACCIONES[j]

            const imagen = protocolo.COMPLETADA ? 'checked' : 'unchecked'
            const imagenContent = { image: imagen, width: 12, height: 12 }

            protocoloTable.push(imagenContent)
            protocoloTable.push(protocolo.ACCION)
            protocoloTable.push(protocolo.ACTUALIZACION)
            protocoloTable.push(protocolo.GRUPO_ACCION)

            tableProtocoloIntervencion.push(protocoloTable)
          }
          docDefinition.content.push({
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              body: tableProtocoloIntervencion,
              widths: ['3%', '32%', '24%', '41%'],
            },
            width: 'auto',
            alignment: 'left',
            margin: [0, 5, 0, 20],
            // pageBreak: 'after'
          })
        }

        /**
         * MENSAJERIA
         */
        if (mensajes) {
          // docDefinition.header(null, null, null, true)
          docDefinition.content.push({
            text: [
              {
                text: 'Mensajería',
                bold: true,
                fontSize: 20,
                alignment: 'left',
              },
            ],
            margin: [0, 60, 40, 12],
          })

          // RANGO DE FECHAS
          if (fechaInicio && fechaFin) {
            docDefinition.content.push({
              columns: [
                {
                  text: 'Rango de fechas seleccionadas: del',
                  bold: true,
                  width: 200,
                },
                {
                  text: this.$date.formatDate(fechaInicio, 'DD/MM/YYYY'),
                  bold: false,
                  width: 70,
                },
                { text: 'al', bold: true, width: 20 },
                {
                  text: this.$date.formatDate(fechaFin, 'DD/MM/YYYY'),
                  bold: false,
                  width: 70,
                },
              ],
              fontSize: 12,
              margin: [0, 12, 0, 0],
            })
          }

          // MENSAJE
          if (mensajes.length) {
            for (const mensaje of mensajes) {
              // FECHA ENVIO, ENTIDAD, USUARIO y PRIORIDAD
              docDefinition.content.push({
                columns: [
                  {
                    text: this.$date.formatDate(
                      mensaje.fechaCreacion,
                      'DD/MM/YYYY HH:mm',
                    ),
                    bold: false,
                    width: 120,
                  },
                  {
                    text:
                      mensaje.Usuario.Entidad.entidad_titular +
                      ' - ' +
                      mensaje.Usuario.nombre +
                      ' ' +
                      mensaje.Usuario.apellidos +
                      ' - ' +
                      mensaje.Relevancium.nombre,
                    bold: true,
                  },
                ],
                fontSize: 12,
                margin: [0, 16, 0, 0],
              })

              // CONTENIDO MENSAJE
              docDefinition.content.push({
                columns: [
                  {
                    text: mensaje.texto
                      ? mensaje.texto
                      : 'Fichero adjunto: ' + mensaje.Documento.nombre,
                  },
                ],
                fontSize: 12,
                margin: [0, 4, 0, 0],
              })
            }
          }
        }

        // Descarga automatica del PDF
        // pdfMake
        //   .createPdf(docDefinition)
        //   .download(
        //     'InformeEmergencia_' +
        //       this.$date.now().format('DDMMYYYY')
        //       // this.$date.now().format('YYYY_MM_DD_hh_mm')
        //   )
        // Abrir PDF en nueva pestaña
        if (this.$vuetify.breakpoint.mobile) {
          /**
           * Test 1 y 2
           */
          // const pdfDocGenerator = pdfMake.createPdf(docDefinition)
          // pdfDocGenerator.getDataUrl((dataUrl) => {
          //console.log('DataUrl: ', dataUrl)
          /**
           * Test 1 - string base64 a googleDocs viewer
           */
          //const encode = window.btoa(dataUrl)
          //console.log('ENCODE: ', encode)
          // const googleDocUrl = 'https://docs.google.com/viewerng/viewer/embedded=true&url='

          // window.open(googleDocUrl+encode, '_blank')
          /**
           * Test 2 - pdf en un iframe en una nueva pestaña
           */
          // var wdw = window.open('', '_blank')
          // wdw.document.write('<iframe src='+dataUrl+' width=100%" height="100%"></iframe>')
          // })

          /**
           * Test 3 - descargar pdf automaticamente con su nombre
           * de informe+fecha
           */
          pdfMake.createPdf(docDefinition).download(titlePdf)
        } else {
          pdfMake.createPdf(docDefinition).open()
        }
      },

      showRiesgosEmergencia(TipoRiesgos) {
        let stringRiesgos = ''
        if (TipoRiesgos.length) {
          for (const tipoRiesgo of TipoRiesgos) {
            stringRiesgos =
              stringRiesgos === ''
                ? stringRiesgos + tipoRiesgo.nombre
                : stringRiesgos + '; ' + tipoRiesgo.nombre
          }
        }
        return stringRiesgos
      },

      async getSituacionesOperativas(emergencia) {
        this.situacionesOperativasEmergencia = (
          await SituacionOperativaRequest.getSituacionesOperativasFromEmergencia(
            emergencia.id,
          )
        ).sort((a, b) => (a.fechastamp < b.fechastamp ? 1 : -1))

        const listaSituacionesOperativas =
          await this.getListaSituacionesOperativas(
            this.situacionesOperativasEmergencia,
          )
        return listaSituacionesOperativas
      },

      async getListaSituacionesOperativas(situacionesOperativas) {
        const listaSituacionesOperativas = []
        for (const situacionOperativa of situacionesOperativas) {
          listaSituacionesOperativas.push({
            DECLARACION: this.$date.formatDate(
              situacionOperativa.fecha_declaracion,
              'DD/MM/YYYY HH:mm',
            ),
            FASE: situacionOperativa.SituacionOperativa.FaseEmergencium.nombre,
            SITUACION_OPERATIVA: situacionOperativa.SituacionOperativa.nombre,
            ACCIONES: await this.getAccionesSituacionOperativaEmergencia(
              situacionOperativa,
            ),
          })
        }

        return listaSituacionesOperativas
      },

      async getAccionesSituacionOperativaEmergencia(situacionOperativa) {
        const accionesSituacionOperativa =
          await this.getAccionesSituacionOperativaActualFromEmergencia(
            situacionOperativa.id,
          )

        return accionesSituacionOperativa.length
          ? accionesSituacionOperativa
          : []
      },

      getUltimaSituacionOperativaEmergencia() {
        if (this.situacionesOperativasEmergencia.length > 1) {
          const sitOpeEmer = JSON.parse(
            JSON.stringify(this.situacionesOperativasEmergencia),
          )
          sitOpeEmer.sort((a, b) => {
            const aOrden = a.fechastamp
            const bOrden = b.fechastamp
            return aOrden > bOrden ? -1 : 1
          })
          return sitOpeEmer[0]
        } else if (this.situacionesOperativasEmergencia.length === 1) {
          return this.situacionesOperativasEmergencia[0]
        } else {
          return null
        }
      },

      async getAccionesSituacionOperativaActualFromEmergencia(
        idEmergenciaSituacionOperativa,
      ) {
        const listaAcciones = []
        const accionesSituacionOperativa = await this.$store.getters[
          'accionSituacionEmergencia/getAccionesSituacionEmergencia'
        ]({ situacionEmergenciaId: idEmergenciaSituacionOperativa })

        if (accionesSituacionOperativa) {
          for (const data of accionesSituacionOperativa) {
            listaAcciones.push({
              ACTUALIZACION: this.$date.formatDate(
                data.fechastamp,
                'DD/MM/YYYY HH:mm',
              ),
              ACCION: data.Accion.nombre,
              GRUPO_ACCION: data.Grupo_accion.nombre
                ? data.Grupo_accion.nombre
                : '',
              COMPLETADA: data.completada,
            })
          }
        }

        return listaAcciones
      },

      filtrarMensajesPorFecha(mensajes, fechaInicio, fechaFin) {
        const filtroMensajes = []
        const newFechaInicio = new Date(fechaInicio + ' 00:00:00')
        const newFechaFin = new Date(fechaFin + ' 23:59:59')

        for (const mensaje of mensajes) {
          const newDateMessage = new Date(mensaje.fechaCreacion)
          if (
            newDateMessage >= newFechaInicio &&
            newDateMessage <= newFechaFin
          ) {
            filtroMensajes.push(mensaje)
          }
        }
        return filtroMensajes
      },
    },
  }
</script>

<style scoped>
  .dialog-content {
    max-height: 59vh;
    overflow: auto;
  }

  .contenido-title {
    align-content: flex-start;
    font-weight: bold;
    font-size: 18px;
    margin-top: 0px;
  }

  .data-emergencia {
    align-content: flex-start;
    font-size: 18px;
    margin-top: -12px;
  }

  .data-emergencia-mensajeria {
    align-content: flex-start;
    font-size: 18px;
    margin-top: 12px;
  }

  .data-emergencia-radioButton {
    align-content: flex-start;
    font-size: 16px;
    margin-top: -30px;
  }

  .fecha-title {
    align-content: flex-start;
    font-size: 14px;
    margin-top: -35px;
  }

  .fecha-date-picker {
    align-content: flex-start;
    margin-top: -20px;
  }
</style>
