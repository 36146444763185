var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"600px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.accionDialog))])],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-container',[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"sm":"8"}},[_c('v-text-field',{attrs:{"label":"Documento*","dense":"","maxlenght":"240","rules":[_vm.globalRules.required]},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}})],1),_c('v-col',{attrs:{"sm":"4"}},[_c('v-select',{attrs:{"label":"Estado*","items":_vm.documentoValido,"menu-props":{ bottom: true, offsetY: true },"item-text":"estado","item-value":"estado","return-object":"","outlined":"","dense":"","rules":[_vm.globalRules.required]},model:{value:(_vm.estadoSelected),callback:function ($$v) {_vm.estadoSelected=$$v},expression:"estadoSelected"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.isMensajeria
                    ? _vm.categoriasDocumento
                    : _vm.categoriasDocumento
                      ? _vm.categoriasDocumento.filter(
                        (x) => x.nombre !== 'Mensajería',
                      )
                      : [],"menu-props":{ bottom: true, offsetY: true },"item-text":"nombre","item-value":"nombre","label":"Categoría*","return-object":"","outlined":"","dense":"","rules":[_vm.globalRules.required]},model:{value:(_vm.categoriaSelected),callback:function ($$v) {_vm.categoriaSelected=$$v},expression:"categoriaSelected"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.orderEmergenciasByNombreAsc
                    ? _vm.orderEmergenciasByNombreAsc
                    : [],"menu-props":{ bottom: true, offsetY: true },"item-text":"nombre","item-value":"nombre","label":"Emergencia","return-object":"","outlined":"","dense":""},model:{value:(_vm.emergenciaSelected),callback:function ($$v) {_vm.emergenciaSelected=$$v},expression:"emergenciaSelected"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-file-input',{ref:"inputFile",attrs:{"small-chips":"","label":"Seleccione un archivo","clearable":true,"dense":"","rules":_vm.fileMaxSize},model:{value:(_vm.fileInput),callback:function ($$v) {_vm.fileInput=$$v},expression:"fileInput"}})],1),(_vm.fileInput)?_c('v-col',{staticClass:"mt-6",attrs:{"xs":"4","sm":"4"}},[_c('v-row',[_c('span',[_vm._v("Tamaño máximo: "+_vm._s(_vm.maxTam()))])])],1):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.cancelar}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"green","disabled":!_vm.isValid},on:{"click":_vm.comprobarEmergenciaActivaYEnviarDocumento}},[_vm._v(" Aceptar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }