<template>
  <v-dialog v-model="show" max-width="500px" persistent>
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>
          Declaración cambio de situación operativa
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="isValid">
        <v-container>
          <vx-select-situacion-operativa
            :situacionOperativa="emergencia.SituacionOperativa"
            @situacionOperativaSelected="changedSituacionOperativa"
            @showFechaHoraDeclaracion="showFechaHoraDeclaracion"
          />

          <v-row v-if="mostrarFechaHora" class="mt-3">
            <v-col>
              <!-- Fecha de declaracion de la situacion operativa -->
              <v-menu
                ref="fechaDeclaracionSitOpe"
                v-model="showFechaDeclaracion"
                :close-on-content-click="false"
                :open-on-hover="true"
                :open-on-click="true"
                :nudge-right="40"
                transition="scale-transition"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="fechaDeclaracionFormatted"
                    @click:clear="fechaDeclaracionSitOpe = null"
                    label="Fecha de declaracion"
                    prepend-icon="esri-icon-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    clearable
                    hide-details
                    :rules="[globalRules.required]"
                  />
                </template>
                <v-date-picker
                  v-model="fechaDeclaracionSitOpe"
                  first-day-of-week="1"
                  @input="$refs.fechaDeclaracionSitOpe.save(cerrarCalendario)"
                  locale="es-ES"
                />
              </v-menu>
            </v-col>

            <v-col>
              <!-- Hora de declaracion de la situacion operativa -->
              <v-text-field
                v-model="horaDeclaracion"
                type="time"
                @blur="horaDeclaracion = $event.value"
                validate-on-blur
                label="Hora de declaración"
                prepend-icon="esri-icon-time-clock"
                dense
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar()">Cancelar</v-btn>
        <v-btn
          text
          color="green darken-1"
          @click="updateSituacionOperativa()"
          :disabled="!isValid || !mostrarFechaHora"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import SelectSituacionOperativaVue from './SelectSituacionOperativa.vue'

  export default {
    components: {
      'vx-select-situacion-operativa': SelectSituacionOperativaVue,
    },

    props: {
      show: Boolean,
      emergencia: Object,
    },

    data: () => ({
      situacionOperativaSelected: null,
      faseSelected: null,

      showFechaDeclaracion: false,
      fechaDeclaracionSitOpe: null,
      horaDeclaracion: null,
      mostrarFechaHora: false,

      isValid: false,
    }),

    computed: {
      fechaDeclaracionFormatted() {
        const date = this.$date.parseDate(
          this.fechaDeclaracionSitOpe,
          'YYYY-MM-DD',
        )
        return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
      },
    },

    watch: {
      emergencia() {
        this.situacionOperativaSelected = this.emergencia.situacionOperativa
        this.faseSelected = this.emergencia.fase
      },
    },

    methods: {
      updateSituacionOperativa() {
        const fechaDeclaracionSitOpe = this.horaDeclaracion
          ? this.fechaDeclaracionConHora()
          : new Date(this.fechaDeclaracionSitOpe)

        const dataNewSituacion = {
          id: this.situacionOperativaSelected,
          fecha_declaracion: fechaDeclaracionSitOpe,
          tiene_hora: this.horaDeclaracion ? true : false,
        }

        this.$emit('selectedSituacionOperativa', dataNewSituacion)

        // Limpiar form hijo select
        // this.$refs.form.reset()
        this.$eventHub.$emit('resetFormtSituacionOperativa')
        this.mostrarFechaHora = false
      },

      async changedSituacionOperativa({ fase, situacionOperativa }) {
        this.situacionOperativaSelected = situacionOperativa
        this.faseSelected = fase
        if (fase && situacionOperativa) {
          this.mostrarFechaHora = true
          !this.fechaDeclaracionSitOpe && this.mostrarCalendario()
        }
      },

      cerrarCalendario() {
        this.showFechaDeclaracion = false
      },

      fechaDeclaracionConHora() {
        // TODO: Revisar para telefonos iOS, hace mal la conversion
        return new Date(
          this.fechaDeclaracionSitOpe + ' ' + this.horaDeclaracion,
        )
      },

      showFechaHoraDeclaracion() {
        this.mostrarFechaHora = false
      },

      mostrarCalendario() {
        setTimeout(() => {
          this.showFechaDeclaracion = true
        }, 500)
      },

      cancelar() {
        this.mostrarFechaHora = false
        this.showFechaDeclaracion = false
        this.fechaDeclaracionSitOpe = null
        this.$refs.form.reset()
        this.$eventHub.$emit('resetFormtSituacionOperativa')
        this.$emit('close')
      },
    },
  }
</script>

<style></style>
