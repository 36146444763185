<template>
  <v-dialog v-model="show" scrollable persistent max-width="600px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title v-if="isEdit">Editar unidad medio</v-toolbar-title>
        <v-toolbar-title v-else>Nueva unidad medio</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <!-- TODO: contenido filas&columnas -->
            <v-row class="mt-1">
              <v-col cols="12" sm="6" md="6">
                <!-- TIPO MEDIO -->
                <v-select
                  v-model="tipoMedioSelected"
                  label="Tipo de medio*"
                  :items="tiposMedios"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  return-object
                  outlined
                  dense
                  :rules="[globalRules.required]"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <!-- SECTOR MEDIO -->
                <v-select
                  v-model="sectorMedioSelected"
                  label="Sector"
                  :items="sectoresMediosList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  return-object
                  outlined
                  dense
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- ACTIVIDAD MEDIO -->
                <v-select
                  v-model="actividadSelected"
                  label="Actividad del medio*"
                  :items="actividadesMediosList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  return-object
                  outlined
                  dense
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="6" md="6">
                <!-- MEDIOS  -->
                <v-select
                  v-model="medioSelected"
                  label="Medio*"
                  :items="medios"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  return-object
                  outlined
                  dense
                  :rules="[globalRules.required]"
                />
              </v-col>

              <v-col sm="6" md="6">
                <!-- UNIDADES -->
                <v-text-field
                  label="Unidades"
                  v-model="unidades"
                  v-mask="'##########'"
                  dense
                  maxlenght="10"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  v-model="entidadSelected"
                  label="Entidad *"
                  :items="listaEntidades"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="entidad_titular"
                  item-value="entidad_titular"
                  return-object
                  outlined
                  dense
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col v-if="filterDelegaciones.length" sm="6" md="6">
                <!-- DELEGACIONES -->
                <v-select
                  v-model="delegacionSelected"
                  label="Delegación*"
                  :items="filterDelegaciones"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  return-object
                  outlined
                  dense
                  :rules="[globalRules.required]"
                />
              </v-col>

              <v-col v-if="departamentos.length" sm="6" md="6">
                <!-- DEPARTAMENTOS -->
                <v-select
                  v-model="departamentoSelected"
                  label="Departamento*"
                  :items="departamentos"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  return-object
                  outlined
                  dense
                  :rules="[globalRules.required]"
                />
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="12" sm="12" md="6">
                <!-- PROCEDIMIENTO DE ACTIVACION MOVILIZACION -->
                <v-checkbox v-model="activacionMovilizacion">
                  <template #label>
                    <div>Procedimientos de activación y movilización</div>
                  </template>
                </v-checkbox>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="6"
                :class="$vuetify.breakpoint.mobile ? 'inputFileMobile' : 'mt-4'"
              >
                <!-- DOCUMENTACION ACTIVACION & MOVILIZACION -->
                <v-file-input
                  v-if="showDocumentInput"
                  v-model="documentInput"
                  small-chips
                  ref="inputFile"
                  label="Seleccione documento pdf"
                  :clearable="true"
                  accept=".pdf"
                  dense
                  :show-size="true"
                  :rules="fileMaxSize"
                />
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <!-- <v-col> -->
              <!-- RUTA PROTOCOLO -->
              <!-- </v-col> -->

              <v-col>
                <!-- INFO PROTOCOLO -->
                <v-text-field
                  label="Información protocolo"
                  v-model="infoProtocolo"
                  dense
                  counter
                  maxlength="240"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- OBSERVACIONES -->
                <v-text-field
                  label="Observaciones"
                  v-model="observaciones"
                  dense
                  counter
                  maxlength="240"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      show: Boolean,
      isEdit: Boolean,
      editedUnidadMedio: Object,
    },

    data: () => ({
      isValid: false,

      tipoMedioSelected: null,
      sectorMedioSelected: null,
      sectoresMediosList: null,
      actividadSelected: null,
      actividadesMediosList: null,
      medioSelected: null,
      medios: null,
      createMedioDefault: false,
      entidadSelected: null,
      filterDelegaciones: [],
      delegacionSelected: null,
      departamentoSelected: null,
      departamentos: [],
      unidades: null,
      activacionMovilizacion: false,
      showDocumentInput: false,
      documentInput: null,
      rutaProtocolo: null,
      infoProtocolo: null,
      observaciones: null,
    }),

    watch: {
      async show() {
        if (this.show) {
          if (this.editedUnidadMedio) {
            await this.llenarCamposConUnidadMedio(this.editedUnidadMedio)
          }
        }
      },

      tipoMedioSelected() {
        if (this.tipoMedioSelected) {
          this.sectoresMediosList = []
          this.sectorMedioSelected = null
          this.actividadesMediosList = []
          this.actividadSelected = null
          this.medios = []
          this.medioSelected = null
          // Obtener sector-medio a partir de tipo-medio
          for (const sectorMedio of this.tipoMedioSelected.Sector_medios) {
            this.sectoresMediosList.push(sectorMedio)
          }
        }
      },

      sectorMedioSelected() {
        if (this.sectorMedioSelected) {
          this.actividadesMediosList = []
          this.actividadSelected = null
          this.medios = []
          this.medioSelected = null
          // Obtener actividad-medio a partir de sector-medio
          for (const actividadMedio of this.sectorMedioSelected
            .Actividad_medios) {
            this.actividadesMediosList.push(actividadMedio)
          }
        }
      },

      actividadSelected() {
        if (this.actividadSelected) {
          this.medios = []
          // Insertar un campo vacio (no tiene medio, se coge la actividad-medio seleccionada)
          // this.medios.push('Sin definir') // TODO: borrar definitivamente??
          // Obtener medios a partir de actividad-medio
          for (const medio of this.actividadSelected.Medios) {
            this.medios.push(medio)
          }
        }
      },

      entidadSelected() {
        if (this.entidadSelected) {
          // Obtener delegaciones de la entidad (!is_default)
          this.filterDelegaciones = this.entidadSelected.Delegacions.filter(
            (x) => x.is_default === false,
          )
          if (!this.filterDelegaciones.length) {
            // Guardar delegacion por defecto
            this.delegacionSelected = this.entidadSelected.Delegacions.find(
              (x) => x.is_default === true,
            )
          }
        }
      },

      async delegacionSelected() {
        if (this.delegacionSelected) {
          // Obtener departamentos de la delegacion (!is_default)
          this.departamentos = this.delegacionSelected.Departamentos.filter(
            (departamento) => !departamento.is_default,
          )
          if (!this.departamentos.length) {
            this.departamentoSelected = await this.getDepartamento()
          }
        }
      },

      activacionMovilizacion(newValue) {
        this.showDocumentInput = newValue
      },

      documentInput(newValue) {
        if (newValue && !this.tipoDeDocumentoAdmitido(newValue)) {
          this.documentInput = null
          this.$refs.inputFile.reset()
          this.notificarDocumentoNoAdmitido()
        }
      },
    },

    computed: {
      tiposMedios() {
        return this.$store.getters['tipoMedio/tiposMedios']
      },

      delegaciones() {
        return this.$store.getters['delegacion/delegaciones']
      },

      ...mapGetters('usuario', ['currentUsuario']),

      tiposEntidad() {
        return this.$store.getters['tipoEntidad/tiposEntidad']
      },

      listaEntidades() {
        return this.$store.getters['entidad/listaEntidades']
      },

      actividadesMedios() {
        return this.$store.getters['actividadMedio/actividadesMedios']
      },

      sectoresMedios() {
        return this.$store.getters['sectorMedio/sectoresMedios']
      },
    },

    methods: {
      async llenarCamposConUnidadMedio(unidadMedio) {
        this.tipoMedioSelected = await this.getTipoMedio(unidadMedio)
        this.sectorMedioSelected = await this.getSectorMedio(unidadMedio)
        this.actividadSelected = await this.getActividadMedio(unidadMedio)
        this.medioSelected = await this.getMedio(unidadMedio)
        this.unidades = unidadMedio.numero_unidades
        this.entidadSelected = await this.getEntidad(unidadMedio)
        this.delegacionSelected = await this.getDelegacion(unidadMedio)
        this.departamentoSelected = await this.getDepartamento()
        this.activacionMovilizacion = unidadMedio.activacion_movilizacion
        // Cargar documentacion (en caso de tenerla)
        if (unidadMedio.activacion_movilizacion && unidadMedio.ruta_protocolo) {
          this.cargarDocumentacion(unidadMedio)
        }
        this.infoProtocolo = unidadMedio.info_protocolo
        this.observaciones = unidadMedio.observaciones
      },

      cancelar() {
        this.$refs.form.reset()
        this.$emit('cancelar')
      },

      async aceptar() {
        const unidadMedio =
          (this.editedUnidadMedio &&
            JSON.parse(JSON.stringify(this.editedUnidadMedio))) ||
          {}

        // Guardar datos comunes
        unidadMedio.nombre =
          typeof this.medioSelected === 'object'
            ? this.medioSelected.nombre
            : this.actividadSelected.nombre
        unidadMedio.id_medio = this.medioSelected.id_medio
        unidadMedio.id_departamento = this.departamentoSelected.id_departamento
        unidadMedio.numero_unidades = this.unidades
        unidadMedio.activacion_movilizacion = this.activacionMovilizacion
          ? true
          : false
        if (this.activacionMovilizacion && this.documentInput) {
          unidadMedio.ruta_protocolo = await this.getDocumentacion()
        } else if (this.activacionMovilizacion && !this.documentInput) {
          unidadMedio.ruta_protocolo = null
        } else if (!this.activacionMovilizacion) {
          this.documentInput = null
          unidadMedio.ruta_protocolo = null
        }
        unidadMedio.info_protocolo = this.infoProtocolo
        unidadMedio.observaciones = this.observaciones
        unidadMedio.usuariostamp = this.currentUsuario.ID
        unidadMedio.borrado = false
        unidadMedio.Medio =
          typeof this.medioSelected === 'object'
            ? this.medioSelected
            : this.crearMedioDefault() // TODO: Controlar el numero de medios creados por defecto??
        unidadMedio.Medio.Actividad_medio = this.quitarMediosActividadSelected(
          Object.assign({}, this.actividadSelected),
        )
        unidadMedio.Medio.Actividad_medio.Sector_medio =
          this.quitarActividadSectorMedioSelected(
            Object.assign({}, this.sectorMedioSelected),
          )
        unidadMedio.Medio.Actividad_medio.Sector_medio.Tipo_medio =
          this.quitarSectorTipoMedioSelected(
            Object.assign({}, this.tipoMedioSelected),
          )
        unidadMedio.Departamento = this.aniadirDepartamentoYdelegacion()
        unidadMedio.Departamento.Delegacion.Entidad =
          this.aniadirEntidadYtipoEntidad()
        // Medio creado por defecto
        unidadMedio.createMedioDefault =
          typeof this.medioSelected === 'object' ? false : true

        if (this.editedUnidadMedio) {
          // Datos propios de editar unidad-medio
          this.$refs.form.reset()
          this.$emit('editUnidadMedio', unidadMedio)
        } else {
          // Datos propios de nueva unidad-medio
          unidadMedio.id_unidad_medio = this.$uuid.createUUID()

          this.$refs.form.reset()
          this.$emit('addUnidadMedio', unidadMedio)
        }
      },

      quitarMediosActividadSelected(actividadSelected) {
        delete actividadSelected.Medios
        return actividadSelected
      },

      quitarActividadSectorMedioSelected(sectorMedioSelected) {
        delete sectorMedioSelected.Actividad_medios
        return sectorMedioSelected
      },

      quitarSectorTipoMedioSelected(tipoMedioSelected) {
        delete tipoMedioSelected.Sector_medios
        return tipoMedioSelected
      },

      aniadirDepartamentoYdelegacion() {
        this.departamentoSelected.Delegacion = this.aniadirDelegacion()
        return this.departamentoSelected
      },

      aniadirDelegacion() {
        const delegacion = Object.assign(
          {},
          this.delegaciones.find(
            (delegacion) =>
              delegacion.id_delegacion ===
              this.delegacionSelected.id_delegacion,
          ),
        )
        if (delegacion) {
          // Eliminar atributos de delegacion no necesarios
          delete delegacion.Departamentos
          return delegacion
        } else {
          return null
        }
      },

      aniadirEntidadYtipoEntidad() {
        const entidad = Object.assign(
          {},
          this.listaEntidades.find(
            (entidad) =>
              entidad.id_entidad === this.delegacionSelected.id_entidad,
          ),
        )
        if (entidad) {
          // Eliminar atributos no necesarios de la entidad
          delete entidad.Delegacions
          delete entidad.Distrito
          delete entidad.Grupo_accions
          delete entidad.Periodicidad
          delete entidad.Tipo_actualizacion

          return entidad
        } else {
          return null
        }
      },

      /**
       * Métodos para completar el formulario el editar un objeto unidad-medio
       */
      getTipoMedio(unidadMedio) {
        const tipoMedio =
          unidadMedio.Medio.Actividad_medio.Sector_medio.Tipo_medio
        const encontrado = this.tiposMedios.find(
          (x) => x.id_tipo_medio === tipoMedio.id_tipo_medio,
        )

        return encontrado
      },

      getSectorMedio(unidadMedio) {
        const sectorMedio = unidadMedio.Medio.Actividad_medio.Sector_medio
        const sectoresMedios = this.tipoMedioSelected.Sector_medios
        const encontrado = sectoresMedios.find(
          (x) => x.id_sector_medio === sectorMedio.id_sector_medio,
        )

        return encontrado
      },

      getActividadMedio(unidadMedio) {
        const actividadMedio = unidadMedio.Medio.Actividad_medio
        const actividadesMedios = this.sectorMedioSelected.Actividad_medios
        const encontrado = actividadesMedios.find(
          (x) => x.id_actividad_medio === actividadMedio.id_actividad_medio,
        )

        return encontrado
      },

      getMedio(unidadMedio) {
        const medio = unidadMedio.Medio
        const medios = this.actividadSelected.Medios
        const encontrado = medios.find((x) => x.id_medio === medio.id_medio)

        return encontrado
      },

      getEntidad(unidadMedio) {
        const entidad = unidadMedio.Departamento.Delegacion.Entidad
        const encontrado = this.listaEntidades.find(
          (x) => x.id_entidad === entidad.id_entidad,
        )

        return encontrado
      },

      getDelegacion(unidadMedio) {
        const delegacion = unidadMedio.Departamento.Delegacion
        const encontrado = this.delegaciones.find(
          (x) => x.id_delegacion === delegacion.id_delegacion,
        )

        return encontrado
      },

      getDepartamento() {
        // Obtener departamentos de la delegacion (!is_default)
        this.departamentos = this.delegacionSelected.Departamentos.filter(
          (departamento) => !departamento.is_default,
        )
        if (!this.departamentos.length) {
          const encontrado = this.delegacionSelected.Departamentos.find(
            (departamento) => departamento.is_default === true,
          )

          return encontrado
        } else {
          const encontrado = this.delegacionSelected.Departamentos.find(
            (departamento) => departamento.is_default === false,
          )

          return encontrado
        }
      },

      crearMedioDefault() {
        return {
          Actividad_medio: this.actividadSelected,
          id_medio: this.$uuid.createUUID(),
          id_actividad_medio: this.actividadSelected.id_actividad_medio,
          nombre: this.actividadSelected.nombre,
          codigo: this.actividadSelected.codigo,
          usuariostamp: this.currentUsuario.ID,
          borrado: false,
        }
      },

      tipoDeDocumentoAdmitido(documento) {
        const documentos = ['application/pdf']
        const permitido = documentos.find((type) => type === documento.type)

        return permitido
      },

      notificarDocumentoNoAdmitido() {
        this.$notify({
          title: 'Formato no permitido',
          text: 'Solo se admiten documentos pdf.',
          type: 'warning',
        })
      },

      async getDocumentacion() {
        if (!this.isEdit) {
          // Nueva documentacion para la nueva unidadMedio
          const actualDateTime = new Date().getTime()
          const nombreDocumento =
            this.documentInput.name.split('.pdf')[0] +
            '_' +
            actualDateTime +
            '.pdf'
          this.documentBase64 = await this.pdfToBase64(this.documentInput)
          if (this.documentBase64) {
            return {
              nombre: nombreDocumento,
              documento: this.documentBase64,
            }
          }
        } else {
          if (
            this.editedUnidadMedio.ruta_protocolo === this.documentInput.name
          ) {
            // El documento no se ha actualizado, convertir a base64 con el mismo nombre
            const nombreDocumento = this.documentInput.name
            this.documentBase64 = await this.pdfToBase64(this.documentInput)
            if (this.documentBase64) {
              return {
                nombre: nombreDocumento,
                documento: this.documentBase64,
              }
            }
          } else {
            // El documento se ha modificado respecto al original
            const actualDateTime = new Date().getTime()
            const nombreDocumento =
              this.documentInput.name.split('.pdf')[0] +
              '_' +
              actualDateTime +
              '.pdf'
            this.documentBase64 = await this.pdfToBase64(this.documentInput)
            if (this.documentBase64) {
              return {
                nombre: nombreDocumento,
                documento: this.documentBase64,
              }
            }
          }
        }
      },

      async pdfToBase64(document) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(document)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        })
      },

      async cargarDocumentacion(unidadMedio) {
        const pdfBase64 = await this.$store.getters[
          'unidadMedio/getDocumentoByUnidadMedio'
        ](unidadMedio.id_unidad_medio)
        if (pdfBase64 && pdfBase64.documento.length) {
          var buffer = new ArrayBuffer(pdfBase64.documento.length)
          var ba = new Uint8Array(buffer)
          for (var i = 0; i < pdfBase64.documento.length; i++) {
            ba[i] = pdfBase64.documento.charCodeAt(i)
          }
          var nombreDoc = unidadMedio.ruta_protocolo
          var base64ToPdf = new File([ba], nombreDoc, {
            type: 'application/pdf',
          })
          if (base64ToPdf) {
            this.documentInput = base64ToPdf
          }
        }
      },
    },
  }
</script>

<style scoped>
  .inputFileMobile {
    margin-top: -15px;
  }
</style>
