import request from '../request'

export default {
  getCargos: function () {
    return request.get('cargo')
  },

  addCargo: function (data) {
    return request.post('cargo', data)
  },

  editCargo: function (data) {
    return request.put('cargo', data)
  },

  deleteCargo: function (data) {
    return request.delete(`cargo/${data.id}`)
  },

  existeCargo: function (data) {
    return request.get(`cargo/${data}`)
  },

  cargoTieneUsuarios: function (data) {
    return request.get(`cargo/tieneUsuarios/${data}`)
  },
}
