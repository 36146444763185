import request from './request'

export class ArcgisRequest {
  static async deletePoints(token, petitionUrl, whereClause) {
    try {
      return (
        await request.post('arcgis/deletePoints', {
          token,
          petitionUrl,
          whereClause,
        })
      ).data.body
    } catch (error) {
      console.error('ERROR AL ELIMINAR PUNTOS EN MAPA', error)
      throw error
    }
  }

  static async sendData(token, petitionUrl, atributes) {
    try {
      return (
        await request.post('arcgis/sendData', {
          token,
          petitionUrl,
          atributes,
        })
      ).data.body
    } catch (error) {
      console.error('ERROR AL ENVIAR DATOS AL MAPA', error)
      throw error
    }
  }
}
