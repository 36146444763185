import request from '../request'

export default {
  getAccionesBySituacionEmergenciaId: function (data) {
    return request.get('accionSituacionEmergencia', {
      params: { situacionEmergenciaId: data.situacionEmergenciaId },
    })
  },

  addAccionByAccionSituacionEmergenciaId: function (data) {
    return request.post('accionSituacionEmergencia', data)
  },

  updateAccionByAccionSituacionEmergenciaId: function (data) {
    return request.put('accionSituacionEmergencia', data)
  },

  deleteAccionByAccionSituacionEmergenciaId: function (data) {
    return request.delete('accionSituacionEmergencia', {
      params: { accionSituacionEmergenciaId: data },
    })
  },
}
