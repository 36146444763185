export class UserHelper {
  /**
   * @param {import("@/typedefs").User} user
   * @returns {string}
   */
  static getCargoPrincipalOnombreUsuario(user) {
    if (user.Cargos && user.Cargos.length) {
      const cargoPrincipal = user.Cargos.find(
        (cargo) => cargo.Usuario_cargo.is_principal,
      )
      if (cargoPrincipal) {
        return cargoPrincipal.nombre
      }
    }
    return `${user.Entidad.entidad_titular} - ${user.nombre} - ${user.apellidos}`
  }
}
