<template>
  <v-app app>
    <v-main>
      <router-view />
      <vx-confirm-dialog ref="confirmDialog" />

      <notifications
        position="bottom right"
        :duration="7500"
        classes="notification"
        style="bottom: 40px"
      />

      <v-overlay :value="loading || isMapLoading">
        <v-progress-circular
          color="primary"
          indeterminate
          size="100"
          width="10"
        />
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import jwtDecode from 'jwt-decode'

  import ConfirmDialog from './components/shared/ConfirmDialog'

  export default {
    name: 'App',

    components: {
      'vx-confirm-dialog': ConfirmDialog,
    },

    data: () => ({}),

    computed: {
      ...mapGetters('app', ['loading']),
      ...mapGetters('map', ['isMapLoading']),

      ...mapGetters('auth', ['accessToken']),
    },

    methods: {
      ...mapActions('auth', ['logout']),
      ...mapActions('app', ['init']),

      async initApplication() {
        if (this.accessToken) {
          // TODO: comprobar que el token es valido
          var decode = jwtDecode(this.accessToken)
          if (this.accessToken && Date.now() >= decode.exp * 1000) {
            this.$store.dispatch('auth/logout', true)
          }
          // console.log('---TOKEN VALIDO---')
          await this.init() // Fetch all data
        }
      },
    },

    mounted() {
      const vh = window.innerHeight * 0.01 - 0.63 // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      this.$root.$confirmDialog = this.$refs.confirmDialog // Asociar referencia global al ConfirmDialog

      window.addEventListener('resize', () => {
        const vh = window.innerHeight * 0.01 - 0.63
        // We execute the same script as beforelet vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      })
    },

    async created() {
      await this.initApplication()
    },
  }
</script>

<style lang="scss">
  :root {
    --important-message: #ff9940;
    --very-important-message: #f44336;
    --low-message: #00bcd4;
    --mesage-selected: #e8e9ec;
  }

  html {
    overflow: hidden;
  }

  #app {
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .underline {
    text-decoration: underline;
  }
  .noUnderline {
    text-decoration: none;
    color: inherit;
  }

  /* Custom scrollbar */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f18f;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(94, 94, 94);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Remove map focus rectangle */
  .esri-view .esri-view-surface--inset-outline:focus::after {
    outline: none !important;
  }

  /* Blue attribution */
  .esri-attribution {
    background-color: rgb(0, 61, 246, 0.3) !important;
    color: white !important;
  }

  /* Estilo SweetAlert */
  .swal2-popup {
    font-family: system-ui !important;
  }

  /* Toast Progress */
  .toast-progress {
    background-color: #fff !important;
    opacity: 0.8 !important;
  }

  /* Notification style */
  .notification {
    margin: 0 5px 5px;
    padding: 10px;
    font-size: 15px;
    color: #ffffff;

    background: var(--v-primary-base) !important;
    border-left: 5px solid var(--v-primaryLight-base) !important;

    &.success {
      background: #68cd86;
      border-left-color: #42a85f;
    }

    &.warn {
      background: #ffb648;
      border-left-color: #f48a06;
    }

    &.error {
      background: #e54d42;
      border-left-color: #b82e24;
    }
  }

  /* DialogAddMedios row colors */

  .medioAviso,
  .medioAviso:hover {
    cursor: not-allowed;
    background-color: #8eb4e3 !important;
  }
  .medioLlegadaInc,
  .medioLlegadaInc:hover {
    cursor: not-allowed;
    background-color: #f14d5a !important;
  }
  .medioSalida,
  .medioSalida:hover {
    cursor: not-allowed;
    background-color: #ffc000 !important;
  }
  .medioDescanso,
  .medioDescanso:hover {
    cursor: not-allowed;
    background-color: #aaaaaa !important;
  }

  .greyTile {
    background: #e7e7e7;
  }
</style>
