<template>
  <v-dialog v-model="show" scrollable persistent max-width="500px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row v-if="!isEditPass" class="mt-1">
              <v-col>
                <v-text-field
                  label="Nombre*"
                  v-model="nombre"
                  outlined
                  dense
                  :rules="[globalRules.required]"
                  maxlenght="20"
                />
              </v-col>
            </v-row>

            <v-row v-if="!isEditPass">
              <v-col>
                <v-text-field
                  label="Apellidos*"
                  v-model="apellidos"
                  outlined
                  dense
                  :rules="[globalRules.required]"
                  maxlenght="50"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  label="Email*"
                  v-model="email"
                  outlined
                  dense
                  :rules="emailRules"
                  maxlenght="50"
                  :disabled="isEditPass"
                />
              </v-col>
            </v-row>

            <v-row v-if="!isEdit || isEditPass">
              <v-col>
                <v-text-field
                  v-if="isEditPass"
                  label="Contraseña*"
                  v-model="password"
                  outlined
                  dense
                  :rules="[securePasswordRules.complete]"
                  :append-icon="resetPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="resetPassword ? 'text' : 'password'"
                  @click:append="resetPassword = !resetPassword"
                />
                <v-text-field
                  v-if="!isEdit && !isEditPass"
                  label="Contraseña*"
                  v-model="password"
                  outlined
                  dense
                  :rules="[securePasswordRules.complete]"
                  :append-icon="newUser ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="newUser ? 'text' : 'password'"
                  @click:append="newUser = !newUser"
                />
              </v-col>
            </v-row>

            <v-row v-if="!isEditPass">
              <v-col>
                <v-select
                  v-model="entidadSelected"
                  label="Entidad*"
                  :items="entidades"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="entidad_titular"
                  item-value="entidad_titular"
                  return-object
                  :rules="[globalRules.required]"
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <v-row v-if="!isEditPass">
              <v-col>
                <v-select
                  v-model="rolSelected"
                  label="Rol*"
                  :items="tiposRol"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="nombre"
                  return-object
                  :rules="[globalRules.required]"
                  multiple
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <!-- CARGO PRINCIPAL -->
            <v-row v-if="!isEditPass">
              <v-col>
                <v-select
                  v-model="cargoPrincipalSelected"
                  label="Cargo principal*"
                  :items="cargos"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="id"
                  return-object
                  :rules="[globalRules.required]"
                  outlined
                  dense
                  :disabled="cargoPrincipalIsSelected"
                />
              </v-col>
            </v-row>

            <!-- CARGO SECUNDARIO -->
            <v-row v-if="!isEditPass">
              <v-col>
                <v-select
                  v-model="cargoSecundarioSelected"
                  label="Cargo secundario"
                  ref="selectCargoSecundario"
                  :items="cargos"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nombre"
                  item-value="id"
                  return-object
                  multiple
                  outlined
                  dense
                  :disabled="!cargoPrincipalIsSelected"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn
          text
          color="green"
          :disabled="!isValid || (!isEditPass && rolSelected.length === 0)"
          @click="aceptar"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions } from 'vuex'
  import { mapGetters } from 'vuex'
  // import constants from '../../helpers/constants'

  export default {
    props: {
      show: Boolean,
      isEdit: Boolean,
      isEditPass: Boolean,
      editedUsuario: Object,
    },

    data: () => ({
      isValid: false,

      usuario: null,
      nombre: null,
      apellidos: null,
      email: null,
      password: null,
      value: true,

      resetPassword: false,
      newUser: false,

      entidadSelected: null,
      rolSelected: [],
      cargoPrincipalSelected: null,
      cargoPrincipalIsSelected: false,
      cargoSecundarioSelected: [],
    }),

    computed: {
      ...mapGetters('cargo', ['cargos']),
      ...mapGetters('rol', ['tiposRol']),
      ...mapGetters('usuario', ['currentUsuario']),
      ...mapGetters('entidad', ['entidades']),

      accionDialog() {
        if (this.isEditPass) {
          return 'Restablecer contraseña'
        } else {
          return this.isEdit ? 'Editar usuario' : 'Nuevo usuario'
        }
      },

      cargos() {
        return this.$store.getters['cargo/cargos']
      },
    },

    watch: {
      async show() {
        if (this.show) {
          if (this.isEditPass) {
            this.email = this.editedUsuario.username
            // Solicitar contraseña y mostrar en el campo password (encriptada)
            const response = await this.$store.dispatch(
              'usuario/getPassUpdateUsuario',
              this.editedUsuario.id_usuario,
            )
            if (response.password && response.password) {
              this.password = response.password
            } else {
              this.password = null
            }
          }

          if (this.isEdit) {
            this.usuario = this.editedUsuario
            this.nombre = this.editedUsuario.nombre
            this.apellidos = this.editedUsuario.apellidos
            this.email = this.editedUsuario.username
            /* if (response.password) {
            this.password = response.password
          } */
            this.entidadSelected = this.entidades.find(
              (x) => x.id_entidad === this.editedUsuario.Entidad.id_entidad,
            )

            // Select rol // TODO: Arreglar lista de roles
            this.editedUsuario.Rols.forEach((rol) => {
              const encontrado = this.tiposRol.find(
                (tipoRol) => tipoRol.id_rol === rol.id_rol,
              )
              if (encontrado) {
                this.rolSelected.push(encontrado)
              }
            })

            // Selected cargos
            this.editedUsuario.Cargos.forEach((cargo) => {
              if (cargo.Usuario_cargo.is_principal) {
                this.cargoPrincipalSelected = cargo
                this.cargoSecundarioSelected.push(cargo)
                this.cargoPrincipalIsSelected = true
              } else {
                this.cargoSecundarioSelected.push(cargo)
              }
            })
          }
        }
      },

      cargoPrincipalSelected(newValue) {
        // Marcar el cargo principal en el selected del cargo secundario
        const encontrado =
          newValue && this.cargos.find((cargo) => cargo.id === newValue.id)
        if (encontrado) {
          // Revisar que no hay duplicados
          if (!this.esCargoDuplicado(newValue)) {
            this.cargoSecundarioSelected.push(encontrado)
            this.siCargoPrincipalSelectedDisabled(true)
          }
        }
      },

      async cargoSecundarioSelected(oldValues, newValue) {
        // Si el usuario elimina el cargo principal del select de secundarios,
        // limpiar primer select, deshabilitar el segundo, limpiarlo y que el usuario vuelva
        // a iniciar la selección
        if (oldValues.length && this.compruebaSiSeHaEliminadoCargoPrincipal()) {
          this.limpiarCargosYvolverAseleccionar()
        }

        if (!oldValues.length && newValue.length) {
          this.limpiarCargosYvolverAseleccionar()
        }
      },
    },

    methods: {
      ...mapActions('usuario', ['existUsuario']),

      cancelar() {
        this.$emit('cancelar')
        this.$emit('cancelarPass')
        this.password = null
        this.resetPassword = false
        this.newUser = false
        this.$refs.form.reset()
      },

      crearRol(usuario, rol) {
        return {
          id_usuario_rol: this.$uuid.createUUID(),
          id_usuario: usuario.id_usuario,
          id_rol: rol.id_rol,
          usuariostamp: this.currentUsuario.ID,
          borrado: false,
        }
      },

      crearCargo(usuario, cargo, isPrincipal) {
        return {
          id_usuario: usuario.id_usuario,
          id_cargo: cargo.id,
          is_principal: isPrincipal,
          usuariostamp: this.currentUsuario.ID,
        }
      },

      notificarUsuarioYaExiste() {
        this.$notify({
          title: 'Username duplicado',
          text: `${this.email}`,
        })
      },

      async aceptar() {
        if (this.isEditPass) {
          const usuario = {}
          usuario.id_usuario = this.editedUsuario.id_usuario
          usuario.username = this.editedUsuario.username
          usuario.password = this.password
          usuario.usuariostamp = this.currentUsuario.ID

          // Restablecer password
          this.resetPassword = false
          this.newUser = false
          this.$emit('resetPassword', usuario)
          this.$refs.form.reset()
        } else {
          const usuario = this.isEdit
            ? JSON.parse(JSON.stringify(this.editedUsuario))
            : {}
          usuario.nombre = this.nombre
          usuario.apellidos = this.apellidos
          usuario.username = this.email
          usuario.password = this.password
          usuario.Entidad = this.entidadSelected
          usuario.id_entidad = this.entidadSelected.id_entidad
          usuario.Rols = this.rolSelected
          usuario.Cargos = this.cargoSecundarioSelected
          usuario.id_rol = this.rolSelected.id_rol
          usuario.usuariostamp = this.currentUsuario.ID

          usuario.borrado = 0

          if (this.isEdit) {
            // Editar usuario

            // Añadir Usuario_rol
            usuario.usuarioRol = await this.crearUsuarioRol(usuario)

            // Añadir Usuario_cargo
            usuario.usuarioCargo = await this.crearUsuarioCargo(usuario)

            // Si no es el mismo email, realizar comprobacion
            if (this.email !== this.editedUsuario.username) {
              const existe = await this.$store.dispatch(
                'usuario/existUsuario',
                this.email,
              )
              if (existe) {
                this.notificarUsuarioYaExiste()
                this.email = this.editedUsuario.username
              } else {
                this.resetPassword = false
                this.newUser = false
                this.$emit('editUsuario', usuario)
                this.$refs.form.reset()
              }
            } else {
              // Si el email es el mismo, no se realiza comprobacion
              this.resetPassword = false
              this.newUser = false
              this.$emit('editUsuario', usuario)
              this.$refs.form.reset()
            }
          } else {
            // Añadir usuario
            usuario.id_usuario = this.$uuid.createUUID()
            usuario.estado = false // Activo por defecto
            usuario.usuariostamp = this.currentUsuario.ID

            // Añadir Usuario_rol
            usuario.usuarioRol = await this.crearUsuarioRol(usuario)

            // Añadir Usuario_cargo
            usuario.usuarioCargo = await this.crearUsuarioCargo(usuario)

            // Comprobar si existe el username del nuevo usuario
            const existe = await this.$store.dispatch(
              'usuario/existUsuario',
              this.email,
            )
            if (existe) {
              this.notificarUsuarioYaExiste()
              this.email = null
            } else {
              this.resetPassword = false
              this.newUser = false
              this.$emit('addUsuario', usuario)
              this.$refs.form.reset()
            }
          }
        }
      },

      siCargoPrincipalSelectedDisabled() {
        this.cargoPrincipalIsSelected = this.cargoPrincipalSelected
          ? true
          : false
      },

      esCargoDuplicado(cargoBusqueda) {
        return this.cargoSecundarioSelected.find(
          (cargo) => cargo.id === cargoBusqueda.id,
        )
          ? true
          : false
      },

      compruebaSiSeHaEliminadoCargoPrincipal() {
        return this.cargoSecundarioSelected.find(
          (cargo) => cargo.id === this.cargoPrincipalSelected.id,
        )
          ? false
          : true
      },

      quitarFocoYcerrarMenuSelectCargoSecundario() {
        this.$refs.selectCargoSecundario.isMenuActive = false
        this.$refs.selectCargoSecundario.isFocused = false
      },

      compruebaSiEsCargoPrincipal(cargoSecundario) {
        return this.cargoPrincipalSelected.id === cargoSecundario.id
          ? true
          : false
      },

      limpiarCargosYvolverAseleccionar() {
        this.quitarFocoYcerrarMenuSelectCargoSecundario()
        this.cargoPrincipalSelected = null
        this.siCargoPrincipalSelectedDisabled()
        this.cargoSecundarioSelected = []
      },

      async crearUsuarioRol(usuario) {
        // UUID para usuario rol
        const usuarioRol = []
        this.rolSelected.forEach((rol) => {
          usuarioRol.push(this.crearRol(usuario, rol))
        })
        return usuarioRol
      },

      async crearUsuarioCargo(usuario) {
        // Usuario cargo
        const usuarioCargo = []
        this.cargoSecundarioSelected.forEach((cargo) => {
          const isPrincipal = this.compruebaSiEsCargoPrincipal(cargo)
          usuarioCargo.push(this.crearCargo(usuario, cargo, isPrincipal))
        })
        return usuarioCargo
      },
    },

    async mounted() {
      await this.$store.dispatch('rol/fetchTiposRol')
      await this.$store.dispatch('cargo/fetchCargos')
    },
  }
</script>

<style scoped></style>
