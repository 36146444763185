import request from '../request'

export default {
  addBitacora: function (data) {
    return request.post('bitacora', data)
  },

  getBitacorasByEmergencia: function (id) {
    return request.get(`bitacora/${id}`)
  },
}
