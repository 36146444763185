import { render, staticRenderFns } from "./DialogEditChatName.vue?vue&type=template&id=4e047d70&scoped=true"
import script from "./DialogEditChatName.vue?vue&type=script&lang=js"
export * from "./DialogEditChatName.vue?vue&type=script&lang=js"
import style0 from "./DialogEditChatName.vue?vue&type=style&index=0&id=4e047d70&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e047d70",
  null
  
)

export default component.exports