import request from '../request'

export default {
  getUnidadesMedios: function () {
    return request.get('unidadMedio')
  },

  addUnidadMedio: function (data) {
    return request.post('unidadMedio', data)
  },

  updateUnidadMedio: function (data) {
    return request.put(`unidadMedio/${data.id_unidad_medio}`, data)
  },

  deleteUnidadMedio: function (data) {
    return request.delete(`unidadMedio/${data.id_unidad_medio}`)
  },

  getDocumentoUnidadMedio: function (id_unidad_medio) {
    return request.post(`unidadMedio/documento/${id_unidad_medio}`)
  },
}
