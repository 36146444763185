import api from '@/api'
import constants from '@/helpers/constants'

const Q = require('q')

const state = {
  cargos: [],
}

const getters = {
  cargos: (state) => state.cargos,

  compruebaSiExisteCargo: () => async (data) => {
    try {
      const response = await api.cargo.existeCargo(data)
      if (response.status === 200) {
        return response.data.body
      }
    } catch (err) {
      console.error(err)
    }
  },

  compruebaCargoTieneUsuarios: () => async (data) => {
    try {
      const response = await api.cargo.cargoTieneUsuarios(data)
      if (response.status === 200) {
        return response.data.body
      }
    } catch (err) {
      console.error(err)
    }
  },
}

const mutations = {
  SET_CARGOS(state, data) {
    if (data) {
      state.cargos = data
    }
  },

  ADD_CARGO(state, data) {
    state.cargos.push(data)
    this.notify('Nuevo cargo', `Se ha añadido el cargo "${data.nombre}"`)
  },

  EDIT_CARGO(state, data) {
    this.notify('Cargo editado', `Se ha editado el cargo "${data.nombre}"`)
  },

  DELETE_CARGO(state, data) {
    this.notify('Cargo borrado', `Se ha borrado el cargo "${data.nombre}"`)
  },

  ERROR_AUTORIZACION(state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER(state, data) {
    this.notify(data.title, data.text, 'error')
  },
}

const actions = {
  async fetchCargos({ commit }) {
    const deferred = Q.defer()

    try {
      const response = await api.cargo.getCargos()
      if (response.status === 200) {
        commit('SET_CARGOS', response.data.body)

        deferred.resolve()
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios.getCargos,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },

  async addCargo({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.cargo.addCargo(data)
      if (response.status === 200) {
        commit('ADD_CARGO', response.data.body)

        deferred.resolve()
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios.addCargo,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },

  async updateCargo({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.cargo.editCargo(data)
      if (response.status === 200) {
        commit('EDIT_CARGO', data)

        deferred.resolve()
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios.editCargo,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },

  async deleteCargo({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.cargo.deleteCargo(data)

      if (response.status === 200) {
        commit('DELETE_CARGO', data)

        deferred.resolve()
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios.deleteCargo,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default module
