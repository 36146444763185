import api from '@/api'
import constants from '@/helpers/constants'

const Q = require('q')

const state = {
  periodicidades: null,
}

const getters = {
  periodicidades: (state) => state.periodicidades,
}

const mutations = {
  SET_PERIODICIDADES(state, listaPeriodicidades) {
    if (listaPeriodicidades) {
      state.periodicidades = listaPeriodicidades
    }
  },

  ERROR_AUTORIZACION(state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER(state, data) {
    this.notify(data.title, data.text, 'error')
  },
}

const actions = {
  async fetchPeriodicidades({ commit }) {
    const deferred = Q.defer()

    try {
      const response = await api.periodicidad.getPeriodicidades()
      if (response.status === 200) {
        commit('SET_PERIODICIDADES', response.data.body)

        deferred.resolve()
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Periodicidad.getPeriodicidades,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default module
