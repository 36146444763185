import api from '@/api'

const Q = require('q')

const state = {
  parametros: [],
}

const getters = {
  parametros: (state) => state.parametros,

  getParametro: (state) => (key) => {
    let parametro = null

    if (key) {
      parametro = state.parametros.find((x) => x.CLAVE.equalsIgnoreCase(key))
    }
    return parametro ? parametro.VALOR : null
  },
}

const mutations = {
  SET_PARAMETROS(state, parametros) {
    state.parametros = parametros
  },
}

const actions = {
  async fetchParametros({ commit }) {
    const deferred = Q.defer()

    try {
      const response = await api.parametro.getParametros()
      commit('SET_PARAMETROS', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default module
