<template>
  <div>
    <vx-share-map-select-user-dialog
      :show="showDialogShareMapSelectUser"
      @cerrar="showDialogShareMapSelectUser = false"
    />
    <vx-share-map-received-request-dialog />
    <vx-share-map-users-connected-list />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import * as ArcGIS from '../../../helpers/ArcGIS'

  import SelectUserDialog from './SelectUserDialog'
  import ReceivedRequestDialog from './ReceivedRequestDialog'
  import UsersConnectedList from './UsersConnectedList'

  export default {
    components: {
      'vx-share-map-select-user-dialog': SelectUserDialog,
      'vx-share-map-received-request-dialog': ReceivedRequestDialog,
      'vx-share-map-users-connected-list': UsersConnectedList,
    },

    data: () => ({
      showDialogShareMapSelectUser: false,
    }),

    computed: {
      ...mapGetters('shareMap', ['isSharingMap', 'graphics', 'mapExtent']),
    },

    watch: {
      isSharingMap() {
        // console.log('IS SHARING MAP:', this.isSharingMap)
        if (this.isSharingMap) {
          ArcGIS.initShareMapSketchViewModel()
        } else {
          ArcGIS.stopShareMapSketchViewModel()
        }
      },

      graphics() {
        // Re-dibujar los dibujos si cambia la lista
        ArcGIS.drawShareMapGraphics()
      },

      mapExtent() {
        // Mover mapa al recibir compartir pantalla
        ArcGIS.setExtent(this.mapExtent)
      },
    },

    methods: {
      toggleShareMap(isShareMap) {
        this.$store.dispatch('shareMap/setShareMap', isShareMap) // TODO: simplificar el proceso
        this.showDialogShareMapSelectUser = isShareMap
      },
    },

    mounted() {
      this.$eventHub.$on('shareMap', this.toggleShareMap)
    },
  }
</script>

<style scoped></style>
