import api from '@/api'
import Vue from 'vue'
import constants from '@/helpers/constants'

const Q = require('q')

const state = {
  entidades: null,

  listaEntidades: null,
}

const getters = {
  entidades: (state) => state.entidades,

  listaEntidades: (state) => state.listaEntidades,

  getEntidadByIdContactos: (state) => (id_entidad) => {
    let entidad

    if (id_entidad) {
      entidad = state.listaEntidades.find((x) => x.id_entidad === id_entidad)
    }

    return entidad
  },

  getEntidadById: (state) => (id_entidad) => {
    let entidad = null

    if (id_entidad) {
      entidad = state.entidades.find((x) => x.id_entidad === id_entidad)
    }

    return entidad
  },

  getDocumentoByEntidad: () => async (id_entidad) => {
    let docSave
    const response = await api.entidad.getDocumentoEntidad(id_entidad)
    if (response.status === 200) {
      docSave = {
        id_entidad: id_entidad,
        nombre: response.data.body.nombre,
        documento: response.data.body.documento,
      }
    }
    return docSave
    // return state.documentoEntidad
  },

  getContactosByEntidad: () => async (data) => {
    // DATA: {id_entidad, type (tipo de contacto)}
    const response = await api.entidad.getEntidadContactos(data)
    if (response.status === 200) {
      return response.data.body
    }
  },
}

const mutations = {
  SET_ENTIDADES(state, listaEntidades) {
    if (listaEntidades) {
      state.entidades = listaEntidades
    }
  },

  SET_LISTA_ENTIDADES(state, listaEntidadesSencilla) {
    if (listaEntidadesSencilla) {
      state.listaEntidades = listaEntidadesSencilla
    }
  },

  DELETE_ENTIDAD_LISTA_STORE(state, entidad) {
    const positionEntidad = state.listaEntidades.findIndex(
      (x) => x.id_entidad === entidad.id_entidad,
    )

    if (positionEntidad !== -1) {
      state.listaEntidades.splice(positionEntidad, 1)
    }
  },

  ADD_ENTIDAD(state, entidad) {
    if (entidad) {
      state.entidades.push(entidad)
      this.notify(
        'Nueva entidad',
        `Se ha añadido la entidad ${entidad.entidad_titular}`,
      )
    }
  },

  UPDATE_ENTIDAD(state, entidad) {
    const positionEntidad = state.entidades.findIndex(
      (x) => x.id_entidad === entidad.id_entidad,
    )

    if (positionEntidad !== -1) {
      state.entidades[positionEntidad] = entidad
      this.notify(
        'Entidad actualizada',
        `Se ha actualizado la entidad ${entidad.entidad_titular}`,
      )
    }
    Vue.set(state.entidades, positionEntidad, entidad)
  },

  UPDATE_LISTA_ENTIDADES(state, entidad) {
    const positionEntidad = state.listaEntidades.findIndex(
      (x) => x.id_entidad === entidad.id_entidad,
    )

    if (positionEntidad !== -1) {
      state.listaEntidades[positionEntidad] = entidad
      this.notify(
        'Entidad actualizada',
        `Se ha actualizado la entidad ${entidad.entidad_titular}`,
      )
    }
    Vue.set(state.listaEntidades, positionEntidad, entidad)
  },

  DELETE_LISTA_ENTIADES_DELEGACION(state, delegacion) {
    // Posicion entidad
    const positionEntidad = state.listaEntidades.findIndex(
      (x) => x.id_entidad === delegacion.id_entidad,
    )

    let positionDelegacion
    if (positionEntidad !== -1) {
      // Posicion delegacion
      positionDelegacion = state.listaEntidades[
        positionEntidad
      ].Delegacions.findIndex(
        (x) => x.id_delegacion === delegacion.id_delegacion,
      )

      if (positionDelegacion !== -1) {
        state.listaEntidades[positionEntidad].Delegacions.splice(
          positionEntidad,
          1,
        )
      }
    }
  },

  DELETE_ENTIDAD(state, entidad) {
    const positionEntidad = state.entidades.findIndex(
      (x) => x.id_entidad === entidad.id_entidad,
    )

    if (positionEntidad !== -1) {
      state.entidades.splice(positionEntidad, 1)
      this.notify(
        'Entidad borrada',
        `Se ha borrado la entidad ${entidad.entidad_titular}`,
      )
    }
  },

  ADD_DELEGACION(state, data) {
    if (data) {
      const positionEntidad = state.entidades.findIndex(
        (x) => x.id_entidad === data.entidad.id_entidad,
      )

      if (positionEntidad !== -1) {
        state.entidades[positionEntidad].Delegacions.push(data.delegacion)
        this.notify(
          'Nueva delegacion',
          `Se ha añadido la delegacion ${data.delegacion.nombre} a la entidad ${data.entidad.entidad_titular}`,
        )
      }
    }
  },

  UPDATE_DELEGACION(state, delegacion) {
    // Posicion entidad
    const positionEntidad = state.entidades.findIndex(
      (x) => x.id_entidad === delegacion.id_entidad,
    )
    let positionDelegacion

    if (positionEntidad !== -1) {
      // Posicion delegacion
      positionDelegacion = state.entidades[
        positionEntidad
      ].Delegacions.findIndex(
        (x) => x.id_delegacion === delegacion.id_delegacion,
      )

      if (positionDelegacion !== -1) {
        state.entidades[positionEntidad].Delegacions[positionDelegacion] =
          delegacion
        this.notify('Delegacion actualizada', 'Se ha actualizado la delegacion')
      }
    }
    Vue.set(
      state.entidades[positionEntidad].Delegacions,
      positionDelegacion,
      delegacion,
    )
  },

  DELETE_DELEGACION(state, delegacion) {
    // Posicion entidad
    const positionEntidad = state.entidades.findIndex(
      (x) => x.id_entidad === delegacion.id_entidad,
    )

    let positionDelegacion
    if (positionEntidad !== -1) {
      // Posicion delegacion
      positionDelegacion = state.entidades[
        positionEntidad
      ].Delegacions.findIndex(
        (x) => x.id_delegacion === delegacion.id_delegacion,
      )

      if (positionDelegacion !== -1) {
        state.entidades[positionEntidad].Delegacions.splice(positionEntidad, 1)
        this.notify(
          'Delegación borrada',
          `Se ha borrado la delegación ${delegacion.nombre}`,
        )
      }
    }
  },

  ADD_DEPARTAMENTO(state, data) {
    // Posicion entidad
    const positionEntidad = state.entidades.findIndex(
      (x) => x.id_entidad === data.delegacion.id_entidad,
    )

    let positionDelegacion

    if (positionEntidad !== -1) {
      // Posicion delegacion
      positionDelegacion = state.entidades[
        positionEntidad
      ].Delegacions.findIndex(
        (x) => x.id_delegacion === data.delegacion.id_delegacion,
      )

      if (positionDelegacion !== -1) {
        // Guardar el nuevo departamento, para la entidad y delegacion a la que pertenece
        state.entidades[positionEntidad].Delegacions[
          positionDelegacion
        ].Departamentos.push(data.departamento)
        this.notify(
          'Nuevo departamento',
          `Se ha añadido el departamento ${data.departamento.nombre}`,
        )
      }
    }
  },

  UPDATE_DEPARTAMENTO(state, departamento) {
    // Recorrer entidades
    let positionDelegacion
    let positionDepartamento
    for (let i = 0; i < state.entidades.length; i++) {
      // Buscar la delegacion a la que pertenece la entidad
      const entidad = state.entidades[i]
      positionDelegacion = entidad.Delegacions.findIndex(
        (x) => x.id_delegacion === departamento.id_delegacion,
      )
      if (positionDelegacion !== -1) {
        // Buscar el departamento al que pertenece la delegacion
        positionDepartamento = entidad.Delegacions[
          positionDelegacion
        ].Departamentos.findIndex(
          (x) => x.id_departamento === departamento.id_departamento,
        )
        if (positionDepartamento !== -1) {
          // Actualizar el departamento
          state.entidades[i].Delegacions[positionDelegacion].Departamentos[
            positionDepartamento
          ] = departamento
          this.notify(
            'Departamento actualizado',
            'Se ha actualizado el departamento',
          )
        }
        Vue.set(
          state.entidades[i].Delegacions[positionDelegacion].Departamentos,
          positionDepartamento,
          departamento,
        )
      }
    }
  },

  DELETE_DEPARTAMENTO(state, departamento) {
    // Recorrer entidades
    for (let i = 0; i < state.entidades.length; i++) {
      const delegaciones = state.entidades[i].Delegacions
      // Recorrer delegaciones de la entidad
      for (let j = 0; j < delegaciones.length; j++) {
        // Si encontramos la delegacion a la que pertenece el departamento
        if (delegaciones[j].id_delegacion === departamento.id_delegacion) {
          // Buscamos la posicion del departamento
          const positionDepartamento = state.entidades[i].Delegacions[
            j
          ].Departamentos.findIndex(
            (x) => x.id_departamento === departamento.id_departamento,
          )
          if (positionDepartamento !== -1) {
            // Borrar el departamento de la store
            state.entidades[i].Delegacions[j].Departamentos.splice(
              positionDepartamento,
              1,
            )
            this.notify(
              'Departamento borrado',
              `Se ha borrado el departamento ${departamento.nombre}`,
            )
          }
        }
      }
    }
  },

  ERROR_AUTORIZACION(state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER(state, data) {
    this.notify(data.title, data.text, 'error')
  },
}

const actions = {
  async fetchEntidades({ commit }) {
    const deferred = Q.defer()

    try {
      const response = await api.entidad.getEntidades()
      if (response.status === 200) {
        commit('SET_ENTIDADES', response.data.body)
      }

      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Entidades.getEntidades,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async fetchListaEntidades({ commit }) {
    const deferred = Q.defer()

    try {
      const response = await api.entidad.getListaEntidades()
      if (response.status === 200) {
        commit('SET_LISTA_ENTIDADES', response.data.body)
      }

      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Entidades.getEntidades,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async deleteEntidadByIdListaStore({ commit }, data) {
    const deferred = Q.defer()
    try {
      if (data) {
        commit('DELETE_ENTIDAD_LISTA_STORE', data)
      }
      deferred.resolve()
    } catch (error) {
      console.error(error)
      deferred.reject(error)
    }

    return deferred.promise
  },

  async addEntidad({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.entidad.addEntidad(data)
      if (response.status === 200) {
        // Asignar delegaciones y fecha de guardado a la entidad
        data.Delegacions = []
        data.Delegacions.push(response.data.body.Delegacions)
        data.fechastamp = response.data.body.fechastamp
        data.recogida_info = response.data.body.recogida_info
        data.is_chat_default = response.data.body.is_chat_default
        data.Usuario = response.data.body.Usuario
        commit('ADD_ENTIDAD', data)
      }

      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Entidades.nuevaEntidad,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async updateEntidad({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.entidad.updateEntidad(data)
      if (response.status === 200) {
        if (data.documentacion) {
          data.documentacion = data.documentacion.nombre
        } else {
          data.documentacion = null
        }
        commit('UPDATE_ENTIDAD', data)
      }
      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Entidades.editarEntidad,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async updateListaEntidades({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.entidad.updateEntidad(data)
      if (response.status === 200) {
        if (data.documentacion) {
          data.documentacion = data.documentacion.nombre
        } else {
          data.documentacion = null
        }
        commit('UPDATE_LISTA_ENTIDADES', data)
      }
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
  },

  async addDelegacion({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.entidad.addDelegacion(data.delegacion)
      if (response.status === 200) {
        data.delegacion.Departamentos = response.data.body.Departamentos
        // Añadir objeto Usuario que ha creado el departamento
        data.delegacion.Departamentos.Usuario = data.delegacion.Usuario
        commit('ADD_DELEGACION', data)
      }
      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Delegaciones.nuevaDelegacion,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async addDepartamento({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.entidad.addDepartamento(data.departamento)
      if (response.status === 200) {
        data.departamento.fechastamp = response.data.body.fechastamp
        commit('ADD_DEPARTAMENTO', data)
      }
      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Departamentos
            .nuevoDepartamento,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async updateDelegacion({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.entidad.updateDelegacion(data)
      if (response.status === 200) {
        commit('UPDATE_DELEGACION', data)
      }
      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Delegaciones.editarDelegacion,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async updateDepartamento({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.entidad.updateDepartamento(data)
      if (response.status === 200) {
        commit('UPDATE_DEPARTAMENTO', data)
      }

      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Departamentos
            .editarDepartamento,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async deleteEntidad({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.entidad.deleteEntidad(data)

      if (response.status === 200) {
        commit('DELETE_ENTIDAD', data)

        deferred.resolve()
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Entidades.borrarEntidad,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async deleteDelegacion({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.entidad.deleteDelegacion(data)

      if (response.status === 200) {
        commit('DELETE_DELEGACION', data)
        commit('DELETE_LISTA_ENTIADES_DELEGACION', data)
      }

      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Delegaciones.borrarDelegacion,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async deleteDepartamento({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.entidad.deleteDepartamento(data)
      if (response.status === 200) {
        commit('DELETE_DEPARTAMENTO', data)
      }

      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Departamentos
            .borrarDepartamento,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default module
