<template>
  <v-fade-transition>
    <div id="maptip_item" class="maptip_item elevation-6" v-show="showPopup">
      <div v-show="!isOnTop" class="top-arrow" />

      <v-card elevation="6">
        <v-toolbar color="primaryLight" dark height="32px">
          <v-toolbar-title class="text-body-2">
            {{ properties.titulo }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pt-1 pb-0 px-0">
          <v-simple-table dense style="max-height: 20vh; overflow: auto">
            <template #default>
              <tbody>
                <tr v-for="attribute in attributes" :key="attribute.name">
                  <td class="black--text">{{ attribute.name }}</td>
                  <td>{{ attribute.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions v-show="buttons.length > 0">
          <v-container class="pa-0">
            <v-row dense align="center" justify="center">
              <v-col
                v-for="button in buttons"
                :key="button.id"
                v-show="button.visible"
                align="center"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      fab
                      icon
                      small
                      v-on="on"
                      @click="clickBtn(button)"
                      v-show="button.visible"
                    >
                      <v-icon>{{ button.icon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ button.label }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>

      <div v-show="isOnTop" class="bottom-arrow" />
    </div>
  </v-fade-transition>
</template>

<script>
  export default {
    props: {
      showPopup: Boolean,
      datosPopup: Object,
    },

    data: () => ({
      properties: {},
      attributes: [],
      buttons: [],

      isOnTop: false,
    }),

    computed: {},

    watch: {
      datosPopup() {
        if (this.datosPopup) {
          // console.log(this.datosPopup)
          this.properties = this.datosPopup.properties

          this.attributes = this.datosPopup.attributes.filter(
            (x) => x.value && x.value !== '',
          )
          this.buttons = this.datosPopup.buttons

          // this.moveTooltip()
        }
      },
      showPopup(val, oldVal) {
        if (val !== oldVal && val) {
          this.moveTooltip()
        }
      },
    },

    methods: {
      moveTooltip() {
        const doc = document.getElementById('maptip_item')

        const x = this.properties.x
        const y = this.properties.y

        // Get screen size
        // let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        const screenHeight =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight
        // let midWidth = screenWidth / 2
        const midHeight = screenHeight / 2

        // let tamHeight = doc.offsetHeight
        const tamWidth = 300 // doc.offsetWidth

        // Place popup on top / under the graphic depending on the position
        const isTop = y < midHeight
        this.isOnTop = !isTop
        // let isLeft = x < midWidth

        if (isTop) {
          doc.style.top = y + 25 + 'px'
          doc.style.bottom = ''
        } else {
          doc.style.top = ''
          doc.style.bottom = screenHeight - y - 40 + 'px'
        }

        /* let newLeft = x + (tamWidth / 2)
      if (isLeft) {
        newLeft = x - (tamWidth / 2)
      } */

        // doc.style.top = newTop + 'px'
        doc.style.left = x - tamWidth / 2 + 'px'
      },

      clickBtn(btn) {
        this.$emit('clickBtn', btn.id)
      },
    },
  }
</script>

<style scoped>
  .maptip_item {
    width: 300px;
    height: auto;
    position: absolute !important;
  }

  .top-arrow:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -25px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-bottom: 25px solid var(--v-primaryLight-base);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }

  .bottom-arrow:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 25px solid white;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
</style>
