import request from '../request'

export default {
  /* login: function (credentials) {
    return request.post('login', credentials)
  } */

  login: function (credentials) {
    return request.post('token', credentials)
  },

  changeExpiredPassword: function (credentials) {
    return request.post('token/updatedExpiredPassword', credentials)
  },
}
