import request from '../request'

export default {
  getUsuarios: function () {
    return request.get('usuario')
  },

  getChatUsers: function () {
    return request.get('usuario/usernames')
  },

  addUsuario: function (data) {
    return request.post('usuario', data)
  },

  updateUsuario: function (data) {
    return request.put(`usuario/${data.id_usuario}`, data)
  },

  existUsuario: function (username) {
    return request.get(`usuario/exist/${username}`)
  },

  getPassUpdateUsuario: function (data) {
    return request.get(`usuario/getPass/${data}`)
  },

  checkCurrentPassUsuario: function (data) {
    return request.post('usuario/currentPass', data)
  },

  resetPasswordUsuario: function (data) {
    return request.post('usuario/resetPass', data)
  },

  resetPasswordPerfilUsuario: function (data) {
    return request.post('usuario/resetPassPerfil', data)
  },

  deleteUsuario: function (data) {
    return request.delete(`usuario/${data.id_usuario}`)
  },

  reactivateUsuario: function (data) {
    return request.put(`usuario/reactivateUser/${data.usuarioId}`)
  },

  updateCaducidadPassword: function (data) {
    return request.post('usuario/updateCaducidadPassword', data)
  },
}
