<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title>Gestión de cargos</v-toolbar-title>

      <!-- AÑADIR CARGO -->
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            bottom
            right
            absolute
            color="primary"
            v-show="comprobarPermisos(['MOD_USUARIOS'])"
            @click="showAddCargoDialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Añadir cargo</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col xs="3" sm="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Búsqueda"
              :dense="$vuetify.breakpoint.mobile"
              outlined
              clearable
              hide-details
            />
          </v-col>
        </v-row>

        <v-row>
          <v-data-table
            :class="{
              table: !$vuetify.breakpoint.mobile,
              tableMobile: $vuetify.breakpoint.mobile,
            }"
            :headers="headers"
            :items="cargos"
            height="100%"
            :search="search"
            :loading="isLoading"
            loading-text="Cargando datos... por favor, espere"
            fixed-header
            disable-pagination
            hide-default-footer
          >
            <!-- NO DATA -->
            <template #no-data>
              <p>Datos no disponibles.</p>
            </template>

            <!-- FECHA ULT. MODIFICACION -->
            <template #[`item.fechastamp`]="{ item }">
              <span>{{ item.fechastamp | formatDate }}</span>
            </template>

            <!-- USUARIO -->
            <template #[`item.UsuarioModificador`]="{ item }">
              <span v-if="item.UsuarioModificador">
                {{
                  item.UsuarioModificador.nombre +
                    ' ' +
                    item.UsuarioModificador.apellidos
                }}
              </span>
            </template>

            <!-- ACCIONES CARGOS -->
            <template #[`item.ACCIONES`]="{ item }">
              <!-- EDITAR CARGO -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-show="comprobarPermisos(['MOD_USUARIOS'])"
                    @click="showEditCargoDialog(item)"
                  >
                    <v-icon color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <!-- BORRAR CARGO -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-show="comprobarPermisos(['MOD_USUARIOS'])"
                    @click="acceptDeleteCargo(item)"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Borrar</span>
              </v-tooltip>

              <!-- LISTADO CARGO - USUARIOS -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :disabled="item.Usuarios && !item.Usuarios.length"
                    v-show="comprobarPermisos(['MOD_USUARIOS', 'VER_USUARIOS'])"
                    @click="showListCargoUsuariosDialog(item)"
                  >
                    <v-icon color="primary">mdi-list-box</v-icon>
                  </v-btn>
                </template>
                <span>Ver usuarios asociados al cargo</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </v-card-text>

    <vx-dialog-add-edit-cargo
      :show="showDialog"
      :isEdit="isEdit"
      :editedCargo="editCargo"
      @cancelar="showDialog = false"
      @addCargo="acceptAddCargo"
      @updateCargo="acceptEditCargo"
    />

    <vx-dialog-list-cargo-usuarios
      :showList="showListDialog"
      :listUsuarios="dataUsuarios"
      :cargo="nombreCargo"
      @cancelar="showListDialog = false"
    />
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex'
  import DialogAddEditCargo from './DialogAddEditCargo.vue'
  import DialogListCargoUsuarios from './DialogListCargoUsuarios.vue'
  export default {
    components: {
      'vx-dialog-add-edit-cargo': DialogAddEditCargo,
      'vx-dialog-list-cargo-usuarios': DialogListCargoUsuarios,
    },

    data: () => ({
      search: '',
      isLoading: false,

      isEdit: false,
      editCargo: {},
      showDialog: false,
      showListDialog: false,
      dataUsuarios: [],
      nombreCargo: null,

      currentPage: 1,
      itemsPerPage: 15,
      perPageOptions: [15, 50, 75, 100],
    }),

    computed: {
      headers() {
        return [
          { text: 'Nombre', value: 'nombre', align: 'left', sortable: true },
          {
            text: 'Descripcion',
            value: 'descripcion',
            align: 'left',
            sortable: false,
          },
          {
            text: 'Útl. actualización',
            value: 'fechastamp',
            align: 'left',
            sortable: true,
          },
          {
            text: '(Usuario) Últ. actualización',
            value: 'UsuarioModificador',
            align: 'left',
            sortable: true,
          },
        ]
      },

      cargos() {
        return this.$store.getters['cargo/cargos']
      },
    },

    methods: {
      ...mapActions('cargo', [
        'fetchCargos',
        'addCargo',
        'updateCargo',
        'deleteCargo',
      ]),

      async loadData() {
        this.isLoading = true
        try {
          await this.fetchCargos()
        } catch (err) {
          if (
            err &&
            err.response.status !== 200 &&
            err.response.status !== 500
          ) {
            this.$notify({
              title: err.response.statusText,
              text: 'El usuario no dispone de permisos para acceder a la gestión de cargos',
              type: 'error',
            })
          }
          this.$router.push('/')
        }
        this.isLoading = false
      },

      comprobarPermisos(listaRolesPermisos) {
        return this.$store.getters['usuario/rolesUsuario'](listaRolesPermisos)
      },

      showAddCargoDialog() {
        this.editCargo = null
        this.showDialog = true
        this.isEdit = false
      },

      async acceptAddCargo(cargo) {
        this.showDialog = false
        await this.addCargo(cargo)
      },

      showEditCargoDialog(item) {
        this.editCargo = Object.assign({}, item)
        this.showDialog = true
        this.isEdit = true
      },

      async acceptEditCargo(cargo) {
        this.showDialog = false
        await this.updateCargo(cargo)
        await this.loadData()
      },

      async acceptDeleteCargo(item) {
        if (await this.cargoTieneUsuariosAsignados(item)) {
          this.$notify({
            title: 'Usuarios asignados al cargo',
            text: `Para borrar el cargo ningún usuario debe tener asignado el cargo "${item.nombre}"`,
            type: 'error',
          })
        } else {
          const msg = `Eliminando el cargo: ${item.nombre}`
          this.$root.$confirmDialog
            .open('¿Está seguro?', msg)
            .then((confirmacionUsuario) => {
              if (confirmacionUsuario) {
                this.deleteCargo(item).then(() => {
                  this.loadData()
                })
              }
            })
        }
      },

      async cargoTieneUsuariosAsignados(cargo) {
        return await this.$store.getters['cargo/compruebaCargoTieneUsuarios'](
          cargo.id,
        )
      },

      showListCargoUsuariosDialog(item) {
        this.showListDialog = true
        this.dataUsuarios = item.Usuarios
        this.nombreCargo = item.nombre
      },
    },

    async created() {
      // Mostrar acciones si currenUsuario tiene el rol admin o rol mod_usuarios
      if (this.comprobarPermisos('MOD_USUARIOS')) {
        // Acciones para la gestion de cargos
        this.headers.push({
          text: 'Acciones',
          value: 'ACCIONES',
          align: 'center',
          width: '140px',
          sortable: false,
        })
      }
    },

    async activated() {
      await this.loadData()
    },
  }
</script>

<style lang="scss" scoped>
  /* Estilos para que la tabla tenga un max-height y pueda crecer */
  .table {
    width: 100vw;
    max-height: calc(100vh - 215px);
  }
  .tableMobile {
    width: 100vw;
    max-height: calc(100vh - 209px);
  }

  .v-data-table {
    overflow: auto;
  }

  .v-data-table >>> .v-data-table__wrapper {
    overflow: unset;
  }

  /* Hover transparente para todas las filas */
  ::v-deep tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
  /* Hover en color gris para las filas impares */
  ::v-deep tbody {
    tr:hover:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
  /* Color gris fila data table impar */
  ::v-deep tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
</style>
