import api from '@/api'
import Vue from 'vue'
import constants from '@/helpers/constants'
import typedefs from '@/typedefs'

const Q = require('q')

const state = {
  currentUsuario: null,

  usersLogged: [],
  /**
   * List of users
   * @type {typedefs.User[]}
   */
  usuarios: [],
  chatUsers: [],
}

const getters = {
  currentUsuario: (state) => state.currentUsuario,

  isCurrentUsuarioAdmin: (state) => {
    let isAdmin = false
    if (state.currentUsuario) {
      isAdmin = state.currentUsuario.ROL.find((x) => x.codigo === 'ADMIN')
    }
    return isAdmin
  },

  rolesUsuario: (state) => (listaRolesPermisos) => {
    const rolesUser = state.currentUsuario.ROL
    if (rolesUser.find((x) => x.codigo === 'ADMIN')) {
      return true
    } else {
      for (const rolFind of listaRolesPermisos) {
        if (rolesUser.find((x) => x.codigo === rolFind)) {
          return true
        }
      }
    }
    return false
  },

  permisosEntidad: (state) => (gestionIdEntidad) => {
    const entidadCurrentUser = state.currentUsuario.ENTIDAD
    let comprueba = false
    if (entidadCurrentUser.id_entidad === gestionIdEntidad) {
      comprueba = true
    }
    return comprueba
  },

  usersLogged: (state) => {
    return state.usersLogged.filter(
      (x) => x.USUARIO !== state.currentUsuario.USUARIO,
    )
  },

  /**
   * Get all users
   * @returns {typedefs.User[]} List of users
   */
  usuarios: (state) => state.usuarios,

  getUserLoggedInMedioByID: (state) => (idMedioLogged) => {
    const user = state.usersLogged.find((x) => x.ID_MEDIO === idMedioLogged)
    return !!user
  },
}

const mutations = {
  SET_CURRENT_USUARIO(state, currentUsuario) {
    state.currentUsuario = currentUsuario
  },

  SET_USERS_LOGGED(state, usersLogged) {
    state.usersLogged = usersLogged
  },

  SOCKET_usersLoggedIn(state, data) {
    const currentUsuario = state.currentUsuario

    if (!currentUsuario.ID_SOCKET) {
      for (let i = 0; i < data.length; i++) {
        if (currentUsuario.USUARIO === data[i].USUARIO) {
          currentUsuario.ID_SOCKET = data[i].ID_SOCKET
          state.currentUsuario = currentUsuario
        }
      }
    }

    state.usersLogged = data
  },

  SET_USUARIOS(state, listaUsuarios) {
    if (listaUsuarios) {
      state.usuarios = listaUsuarios
    }
  },

  SET_CHAT_USERS(state, chatUsersList) {
    if (chatUsersList) {
      state.chatUsers = chatUsersList
    }
  },

  SOCKET_addUsuario(state, usuario) {
    state.usuarios.push(usuario)
    this.notify('Nuevo usuario', `Se ha añadido el usuario ${usuario.username}`)
  },

  SOCKET_updateUsuario(state, usuario) {
    const positionUsuario = state.usuarios.findIndex(
      (x) => x.id_usuario === usuario.id_usuario,
    )

    if (positionUsuario !== -1) {
      state.usuarios[positionUsuario] = usuario
      this.notify(
        'Usuario actualizado',
        `Se ha actualizado el usuario ${usuario.username}`,
      )
    }
    Vue.set(state.usuarios, positionUsuario, usuario)
  },

  UPDATE_CURRENT_USUARIO(state, data) {
    if (data) {
      ;(state.currentUsuario.NOMBRE = data.nombre),
        (state.currentUsuario.APELLIDOS = data.apellidos),
        (state.currentUsuario.USUARIO = data.usuario)
    }
  },

  SOCKET_deleteUsuario(state, usuario) {
    const positionUsuario = state.usuarios.findIndex(
      (x) => x.id_usuario === usuario.id_usuario,
    )

    if (positionUsuario !== -1) {
      state.usuarios.splice(positionUsuario, 1)
      this.notify(
        'Usuario borrado',
        `Se ha borrado el usuario ${usuario.username}`,
      )
    }
  },

  REACTIVATE_USUARIO() {
    this.notify(
      'Usuario reactivado',
      'El usuario ha sido reactivado correctamente',
    )
  },

  ERROR_AUTORIZACION(state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER(state, data) {
    this.notify(data.title, data.text, 'error')
  },
}

const actions = {
  // userLoggedIn ({ state }) {
  //   this._vm.$socket.emit('loggedIn', state.currentUsuario)
  // },

  setCurrentUsuario({ commit }, currentUsuario) {
    commit('SET_CURRENT_USUARIO', currentUsuario)
  },

  async fetchChatUsers({ commit }) {
    return new Promise((resolve, reject) => {
      api.usuario
        .getChatUsers()
        .then((response) => {
          if (response.status === 200) {
            commit('SET_CHAT_USERS', response.data.body)
            resolve(response.data.body)
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.status !== 200 &&
            err.response.status !== 500
          ) {
            commit('ERROR_AUTORIZACION', {
              title: err.response.statusText,
              text: constants.notificacionesPermisos.Usuarios.getUsuarios,
            })
          } else if (err.request) {
            commit('ERROR_RESPUESTA_SERVER', {
              title: err.request.statusText,
              text: constants.notificacionesPermisos.Error[500],
            })
          }
          reject(err)
        })
    })
  },

  async fetchUsuarios({ commit }) {
    const deferred = Q.defer()

    try {
      const response = await api.usuario.getUsuarios()
      if (response.status === 200) {
        commit('SET_USUARIOS', response.data.body)

        deferred.resolve()
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios.getUsuarios,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async addUsuario({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.usuario.addUsuario(data)
      if (response.status === 200) {
        // Asignar uuid del usuario creado
        data.id_usuario = response.data.body.id_usuario
        // Asignar fecha de alta del usuario en bbdd
        data.fechastamp = response.data.body.fechastamp
        commit('SOCKET_addUsuario', data)
        // this._vm.$socket.emit("addUsuario", data)
      }

      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios.nuevoUsuario,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async updateUsuario({ commit, state }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.usuario.updateUsuario(data)

      if (response.status === 200) {
        // Gestion usuarios
        if (state.currentUsuario.ID !== response.data.body.id_usuario) {
          commit('SOCKET_updateUsuario', response.data.body)
          // this._vm.$socket('updateUsuario', data)
        } else {
          // CurrentUsuario
          commit('SOCKET_updateUsuario', response.data.body)
          const updateCurrent = {
            nombre: response.data.body.nombre,
            apellidos: response.data.body.apellidos,
            usuario: response.data.body.username,
          }
          commit('UPDATE_CURRENT_USUARIO', updateCurrent)
        }
      }
      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios.editarUsuario,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async existUsuario({}, data) {
    const deferred = Q.defer()

    try {
      const response = await api.usuario.existUsuario(data)

      if (response.status === 200) {
        if (response.data.body === true) {
          return response.data.body
        } else {
          return response.data.body
        }
      }
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async getPassUpdateUsuario({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.usuario.getPassUpdateUsuario(data)
      if (response.status === 200) {
        return response.data.body
      }
      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios.getPassUpdateUsuario,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async checkCurrentPassUsuario({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.usuario.checkCurrentPassUsuario(data)
      if (response.status === 200) {
        return response.data.body.validate
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios
            .checkCurrentPassUsuario,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async resetPasswordUsuario({ commit, state, dispatch }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.usuario.resetPasswordUsuario(data)

      if (response.status === 200) {
        if (data.id_usuario !== state.currentUsuario.ID) {
          this.notify(
            'Contraseña reseteada',
            `Se ha reseteado la contraseña del usuario ${data.username}`,
          )
        } else {
          this.notify('Contraseña reseteada', 'Se ha reseteado su contraseña')
          dispatch('auth/logout', true, { root: true })
        }
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios.resetearPass,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async resetPasswordPerfilUsuario({ commit, state, dispatch }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.usuario.resetPasswordPerfilUsuario(data)

      if (response.status === 200) {
        if (data.id_usuario !== state.currentUsuario.ID) {
          this.notify(
            'Contraseña reseteada',
            `Se ha reseteado la contraseña del usuario ${data.username}`,
          )
        } else {
          this.notify('Contraseña reseteada', 'Se ha reseteado su contraseña')
          dispatch('auth/logout', true, { root: true })
        }
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios.resetPasswordPerfil,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      sole.error(err)
      deferred - reject(err)
    }

    return deferred.promise
  },

  async deleteUsuario({ commit, state, dispatch }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.usuario.deleteUsuario(data)

      if (response.status === 200) {
        // Comprobar si el usuario borrado es el mismo que el logueado, cerrar sesion
        if (state.currentUsuario.ID === data.id_usuario) {
          dispatch('auth/logout', true, { root: true })
        } else {
          commit('SOCKET_deleteUsuario', data)
        }
        // this._vm.$socket.emit('deleteUsuario', data)
      }
      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios.borrarUsuario,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async reactivateUsuarioById({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.usuario.reactivateUsuario(data)
      if (response.status === 200) {
        commit('REACTIVATE_USUARIO')
      }

      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios.reactivarUsuario,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async updateCaducidadPassword({ commit, state }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.usuario.updateCaducidadPassword(data)
      if (response.status === 200) {
        if (response.data) {
          // Actualizar currentUsuario
          const updateCurrentUsuario = Object.assign({}, state.currentUsuario)
          updateCurrentUsuario.CADUCIDAD_PASSWORD =
            data.solicitar_cambio_password
          commit('SET_CURRENT_USUARIO', updateCurrentUsuario)
          return response.data
        } else {
          // no hay actualización
          return response.data
        }
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Usuarios.reactivarUsuario,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default module
