<template>
  <v-data-table
    id="userList"
    :headers="headers"
    :items="usersShareMap"
    v-show="isSharingMap && usersShareMap.length > 0"
    hide-default-footer
    disable-pagination
  >
    <template #no-data>
      <p align="center">Datos no disponibles.</p>
    </template>

    <template slot="items" slot-scope="cliente">
      <td>
        {{ cliente.item.USUARIO }}
      </td>
      <td>
        <v-icon v-show="cliente.item.ESTADO == 'Aceptada'">
          esri-icon-play
        </v-icon>
        <v-icon v-show="cliente.item.ESTADO == 'Pendiente'">
          esri-icon-question
        </v-icon>
        <v-icon v-show="cliente.item.ESTADO == 'Exit'">exit_to_app</v-icon>
      </td>
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data: () => ({
      headers: [
        { text: 'Usuario', value: 'USUARIO', align: 'left', sortable: false },
        { text: 'Estado', value: 'ESTADO', align: 'left', sortable: false },
      ],
    }),

    computed: {
      ...mapGetters('shareMap', ['isSharingMap', 'usersShareMap']),
    },
  }
</script>

<style scoped>
  #userList {
    position: absolute;
    top: 77px;
    right: 70px;
  }
</style>
