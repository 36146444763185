<template>
  <v-dialog v-model="show" persistent max-width="300px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Track entre fechas</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form v-model="isValid">
          <v-container>
            <v-row>
              <v-col>
                <vx-date-text-edit
                  v-model="fechaInit"
                  label="Fecha inicio"
                  icon="esri-icon-calendar"
                  :min="minFechaInit"
                  :max="maxFechaInit"
                  not-future
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <vx-date-text-edit
                  v-model="fechaFin"
                  label="Fecha fin"
                  icon="esri-icon-calendar"
                  :min="fechaInit"
                  :max="maxFechaFin"
                  not-future
                  required
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      show: Boolean,
    },

    data: () => ({
      isValid: false,

      maxMarginDates: 30, // days

      fechaInit: null,
      fechaFin: null,
    }),

    watch: {
      show() {
        this.fechaInit = null
        this.fechaFin = null
      },
    },

    computed: {
      minFechaInit() {
        if (!this.fechaFin) {
          return null
        }

        const momentFin = this.$date.parseDate(this.fechaFin)
        return momentFin
          .subtract(this.maxMarginDates, 'days')
          .format('DD/MM/YYYY HH:mm')
      },

      maxFechaInit() {
        const now = this.$date.now()

        let fechaReturn = now.format('DD/MM/YYYY HH:mm')
        if (this.fechaFin) {
          fechaReturn = this.fechaFin
        }

        return fechaReturn
      },

      maxFechaFin() {
        const now = this.$date.now()
        const momentInit = this.$date.parseDate(this.fechaInit)
        let fechaReturn = momentInit.add(this.maxMarginDates, 'days')

        if (now.diff(momentInit, 'days') < this.maxMarginDates) {
          fechaReturn = now
        }

        return fechaReturn.format('DD/MM/YYYY HH:mm')
      },
    },

    methods: {
      cancelar() {
        this.$emit('cancelar')
      },
      aceptar() {
        const data = {
          fechaInit: this.$date.parseDate(this.fechaInit).toISOString(),
          fechaFin: this.$date.parseDate(this.fechaFin).toISOString(), // + ' 23:59:59'
        }
        this.$emit('aceptar', data)
      },
    },
  }
</script>

<style scoped></style>
