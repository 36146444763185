<template>
  <div class="entidades-content">
    <dialog-info-entidad
      :modelValue="isShowEntidadInfoActive"
      @update:modelValue="isShowEntidadInfoActive = $event"
      :entidad="entidadShow"
    />
    <div class="entidades">
      <v-list>
        <v-list-item-group v-model="selectedEntidades" multiple active-class="">
          <v-list-item v-for="entidad in entidades" :key="entidad.id_entidad">
            <template #default="{ active }">
              <v-list-item-content>
                <v-list-item-title>
                  {{ entidad.entidad_titular }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action clas="row">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon v-on="on" @click.stop="showEntidadInfo(entidad)">
                      mdi-account-multiple
                    </v-icon>
                  </template>
                  <span>Ver contactos</span>
                </v-tooltip>

                <v-checkbox
                  v-if="userCanEditEmergencias"
                  :input-value="active"
                  color="primary"
                />
              </v-list-item-action>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    <div v-if="userCanEditEmergencias" class="buttons">
      <v-btn @click="cancelar" color="primary" elevation="2"> Cancelar </v-btn>
      <v-btn @click="guardar" color="primary" elevation="2"> Guardar </v-btn>
    </div>
  </div>
</template>
<script>
  import DialogInfoEntidad from './DialogInfoEntidad.vue'
  export default {
    components: {
      DialogInfoEntidad,
    },

    data: () => ({
      selectedEntidades: [],
      isShowEntidadInfoActive: false,
      entidadShow: null,
    }),

    destroyed() {
      if (this.userCanEditEmergencias) {
        this.selectedEntidades = this.getIndexEntidadesSelected(
          this.entidades,
          this.entidadesChat,
        )
      }
    },

    async mounted() {
      await this.$store.dispatch('entidad/fetchEntidades')
      if (this.userCanEditEmergencias) {
        this.selectedEntidades = this.getIndexEntidadesSelected(
          this.entidades,
          this.entidadesChat,
        )
      }
    },

    computed: {
      entidadesChat() {
        return this.$store.state.chat.entidades.map((entidadChat) => ({
          entidad_titular: entidadChat.Entidad.entidad_titular,
          id_entidad: entidadChat.idEntidad,
          active: false,
          ...entidadChat,
        }))
      },
      entidades() {
        if (this.userCanEditEmergencias) {
          return this.$store.state.entidad.entidades
        } else {
          return this.entidadesChat
        }
      },
      userCanEditEmergencias() {
        return this.$store.getters['usuario/rolesUsuario'](['MOD_EMERGENCIAS'])
      },
    },

    watch: {
      selectedEntidades(newValue) {
        if (newValue && newValue.length > 0 && !this.userCanEditEmergencias) {
          this.selectedEntidades = []
        }
      },
      entidadesChat(newValue) {
        if (this.entidades) {
          this.selectedEntidades = this.getIndexEntidadesSelected(
            this.entidades,
            newValue,
          )
        }
      },
    },

    methods: {
      showEntidadInfo(entidad) {
        this.entidadShow = entidad
        this.isShowEntidadInfoActive = true
      },

      cancelar() {
        this.selectedEntidades = this.getIndexEntidadesSelected(
          this.entidades,
          this.entidadesChat,
        )
        this.$emit('close')
      },

      async guardar() {
        try {
          await this.$store.dispatch(
            'chat/SET_ENTIDADES',
            this.getEntidadesIdSelectedByIndex(this.selectedEntidades),
          )
          this.$notify({
            title: 'Entidades modificadas',
            text: 'Se han modificado las entidades del chat',
          })
          this.$emit('close')
        } catch (error) {
          this.$notify({
            title: 'Error al modificar entidades de chat',
            type: 'error',
          })
        }
      },

      isEntidadSelected(entidad) {
        return this.entidadesChat.some(
          (item) => item.idEntidad == entidad.id_entidad,
        )
      },

      getEntidadesIdSelectedByIndex(selectedIndex) {
        const result = selectedIndex.map(
          (index) => this.entidades.at(index).id_entidad,
        )
        return result
      },

      getIndexEntidadesSelected(entidades, entidadesChat) {
        if (entidadesChat) {
          const result = entidadesChat.map((entidad) => {
            const indexFound = entidades.findIndex(
              (item) => item.id_entidad == entidad.idEntidad,
            )
            if (indexFound > -1) {
              return indexFound
            }
          })
          return result
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .entidades-content {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .entidades {
      flex-grow: 1;
      height: 100%;
      overflow: auto;
      ::v-deep {
        .v-list-item__title {
          text-overflow: inherit;
          white-space: normal;
        }
        .v-list-item__action {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 6px;
        }
      }
    }
    .buttons {
      padding: 8px;
      display: flex;
      gap: 6px;
      justify-content: space-between;
      width: 100%;
    }
  }
</style>
