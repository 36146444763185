import api from '@/api'
import constants from '@/helpers/constants'

const Q = require('q')

const state = {
  delegaciones: null,
}

const getters = {
  delegaciones: (state) => state.delegaciones,
}

const mutations = {
  SET_DELEGACIONES(state, delegaciones) {
    state.delegaciones = delegaciones
  },

  ERROR_AUTORIZACION(state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER(state, data) {
    this.notify(data.title, data.text, 'error')
  },
}

const actions = {
  async fetchDelegaciones({ commit }) {
    const deferred = Q.defer()

    try {
      const response = await api.delegacion.getDelegaciones()
      if (response.status === 200) {
        commit('SET_DELEGACIONES', response.data.body)
      }

      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Delegaciones.getDelegaciones,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default module
