<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text v-show="!!message" class="pa-4 black--text text-body-1">
        {{ message }}
      </v-card-text>

      <v-card-actions class="pt-3">
        <v-spacer />
        <v-btn color="red" text @click="cancelar">Cancelar</v-btn>
        <v-btn color="primary darken-1" text @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'ConfirmDialog',

    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: 'primaryLight',
        width: 400,
        zIndex: 1000,
      },
    }),

    methods: {
      open(title, message, options) {
        this.dialog = true
        this.title = title
        this.message = message
        this.options = Object.assign(this.options, options)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      cancelar() {
        this.resolve(false)
        this.dialog = false
      },
      aceptar() {
        this.resolve(true)
        this.dialog = false
      },
    },
  }
</script>
