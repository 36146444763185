<template>
  <v-dialog v-model="show" persistent width="450px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Información adicional de emergencia</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="dialog-content">
        <v-form ref="form">
          <v-container>
            <!-- NUMERO DE VICTIMAS -->
            <v-row class="mt-0">
              <v-col xs="12" sm="12" md="12">
                <v-text-field
                  type="text"
                  label="Víctimas"
                  placeholder="Número de víctimas"
                  v-model="victimas"
                  counter
                  maxlength="250"
                  dense
                />
              </v-col>
            </v-row>

            <!-- VEHICULOS AFECTADOS -->
            <v-row class="inputText">
              <v-col xs="12" sm="12" md="12">
                <v-text-field
                  type="text"
                  label="Vehículos afectados"
                  placeholder="Número de vehículos afectados"
                  v-model="vehiculosAfectados"
                  counter
                  maxlength="250"
                  dense
                />
              </v-col>
            </v-row>

            <!-- OPERATIVOS INTERVINIENDO -->
            <v-row class="inputText">
              <v-col xs="12" sm="12" md="12">
                <v-text-field
                  type="text"
                  label="Operativos interviniendo"
                  placeholder="Número de operativos interviniendo"
                  v-model="operativosIntervencion"
                  counter
                  maxlength="250"
                  dense
                />
              </v-col>
            </v-row>

            <!-- PERSONAS EVACUADAS -->
            <v-row class="inputText">
              <v-col xs="12" sm="12" md="12">
                <v-text-field
                  type="text"
                  label="Personas evacuadas"
                  placeholder="Número de personas evacuadas"
                  v-model="personasEvacuadas"
                  counter
                  maxlength="250"
                  dense
                />
              </v-col>
            </v-row>

            <!-- EDIFICIOS E INFRAESTRUCTURAS AFECTADAS -->
            <v-row class="inputText">
              <v-col xs="12" sm="12" md="12">
                <v-text-field
                  type="text"
                  label="Edificios e infraestructuras afectadas"
                  placeholder="Número de infraestructuras afectadas"
                  v-model="infraestructurasAfectadas"
                  counter
                  maxlength="250"
                  dense
                />
              </v-col>
            </v-row>

            <!-- SERVICIOS INVOLUCRADOS -->
            <v-row class="inputText">
              <v-col xs="12" sm="12" md="12">
                <v-text-field
                  type="text"
                  label="Servicios involucrados"
                  placeholder="Número de servicios involucrados"
                  v-model="serviciosInvolucrados"
                  counter
                  maxlength="250"
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { EmergenciaRequest } from '@/api/emergencia'

  export default {
    props: {
      editEmergencia: {
        type: Object,
        default: null,
      },
      show: Boolean,
    },

    data: () => ({
      victimas: null,
      vehiculosAfectados: null,
      operativosIntervencion: null,
      personasEvacuadas: null,
      infraestructurasAfectadas: null,
      serviciosInvolucrados: null,
    }),

    computed: {
      ...mapGetters('usuario', ['currentUsuario']),
    },

    methods: {
      cancelar() {
        this.$refs.form.reset()
        this.$emit('cancelar')
      },

      aceptar() {
        const emergencia = JSON.parse(JSON.stringify(this.editEmergencia))
        emergencia.victimas = this.victimas
        emergencia.vehiculosAfectados = this.vehiculosAfectados
        emergencia.operativosIntervencion = this.operativosIntervencion
        emergencia.personasEvacuadas = this.personasEvacuadas
        emergencia.infraestructurasAfectadas = this.infraestructurasAfectadas
        emergencia.serviciosInvolucrados = this.serviciosInvolucrados

        this.updateInformacionEmergencia(emergencia)
      },

      asignEditInfoEmergencia(editEmergencia) {
        this.victimas = editEmergencia.victimas
        this.vehiculosAfectados = editEmergencia.vehiculosAfectados
        this.operativosIntervencion = editEmergencia.operativosIntervencion
        this.personasEvacuadas = editEmergencia.personasEvacuadas
        this.infraestructurasAfectadas =
          editEmergencia.infraestructurasAfectadas
        this.serviciosInvolucrados = editEmergencia.serviciosInvolucrados
      },

      updateInformacionEmergencia(emergencia) {
        EmergenciaRequest.update(emergencia)
          .then((updatedEmergencia) => {
            if (updatedEmergencia) {
              this.$notify({
                title: 'Información actualizada',
                text: `Información adicional de la emergencia ${emergencia.nombre} actualizada`,
              })
              this.$emit('emergenciaEdited', emergencia)
              this.$emit('cancelar')
            }
          })
          .catch(() => {
            this.$notify({
              title: 'Información no modificada',
              text: 'Ha ocurrido un error desconocio al modificar la información de le emergencia',
              type: 'error',
            })
          })
      },
    },

    watch: {
      async show() {
        if (this.show && this.editEmergencia) {
          this.asignEditInfoEmergencia(this.editEmergencia)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .inputText {
    margin-top: -15px;
  }
</style>
