<template>
  <div class="content">
    <div class="bitacora-container">
      <RegistroBitacoraVue
        v-for="registro in registros"
        :key="registro.id"
        :registro="registro"
      />
    </div>
  </div>
</template>

<script>
  import RegistroBitacoraVue from './RegistroBitacora.vue'

  export default {
    components: {
      RegistroBitacoraVue,
    },

    data: () => ({}),

    computed: {
      registros() {
        return this.$store.state.bitacora.registrosBitacoraEmergencia
      },
    },

    methods: {},

    mounted() {
      console.log('mounted')
    },

    activated() {
      // TODO: refresh de eventos bitacora
      console.log('Activacion')
    },
  }
</script>

<style></style>
