<template>
  <div>
    <v-snackbar v-model="showSnackBarClick" button :timeout="-1">
      Indica la posición en el mapa
      <template #action="{ attrs }">
        <v-btn text color="red" v-on="attrs" @click="cancelar">Cancelar</v-btn>
      </template>
    </v-snackbar>

    <v-snackbar top :timeout="3000" color="error" v-model="showSnackBarError">
      Posición fuera del ambito de actuación.
    </v-snackbar>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import * as ArcGIS from '../../helpers/ArcGIS'
  import * as MunicipioAPI from '../../helpers/MunicipioAPI'

  export default {
    data: () => ({
      coordenadas: {},

      showSnackBarClick: false,
      showSnackBarError: false,

      eventViewClick: null,
    }),

    computed: {
      ...mapGetters('map', ['gettingNewCoordenadas']),
    },

    watch: {
      gettingNewCoordenadas() {
        this.showSnackBarClick = this.gettingNewCoordenadas
        this.capturarPunto()
      },
    },

    methods: {
      ...mapActions('map', ['stopNewCoordenadas', 'setNewCoordenadas']),

      cancelar() {
        this.stopNewCoordenadas()
      },

      async capturarPunto() {
        const view = ArcGIS.getMapView()
        if (!view) return

        if (this.showSnackBarClick) {
          this.eventViewClick = view.on('click', async (event) => {
            const pointData = await MunicipioAPI.getPointBarrioDistrito(
              event.mapPoint.x,
              event.mapPoint.y,
            )
            this.coordenadas = pointData

            // Comprobar si esta en buffer
            const isCorrecto = await ArcGIS.pointInBuffer(
              pointData.LATITUD,
              pointData.LONGITUD,
            )

            if (isCorrecto) {
              this.setNewCoordenadas(this.coordenadas)
              this.showSnackBarClick = false
              this.showSnackBarError = false
            } else {
              this.showSnackBarError = true
            }
          })
        } else {
          this.eventViewClick.remove()
        }
      },
    },
  }
</script>

<style scoped></style>
