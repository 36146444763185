<template>
  <div class="alto">
    <vx-left-emergencia
      v-if="userCanSeeEmergencias"
      v-show="isShowEmergencias && !gettingNewCoordenadas"
      :emergencia="emergenciaSelected"
      :show="showLeftMenu"
      @exit="hideLeftMenu"
      @openEditEmergencia="openEditEmergencia"
      @openEditInfoEmergencia="openEditInfoEmergencia"
    />
    <vx-dialog-add-edit-emergencia
      v-if="userCanEditEmergencias"
      v-show="isShowEmergencias && showDialogAddEditEmergencia"
      :show="showDialogAddEditEmergencia"
      :editEmergencia="editEmergencia"
      :coords="coords"
      @cancelar="cancelarAddEmergencia()"
      @nuevaEmergenciaCreada="addEmergencia"
      @emergenciaEdited="updateEmergencia"
    />
    <vx-map :emergencias="emergencias" @openAddEmergencia="openAddEmergencia" />

    <!-- dialog edit info emergencia -->
    <vx-dialog-add-edit-info-emergencia
      :editEmergencia="editEmergencia"
      :show="showDialogAddEditInfoEmergencia"
      @emergenciaEdited="updateInfoEmergencia"
      @cancelar="showDialogAddEditInfoEmergencia = false"
    />
  </div>
</template>

<script>
  import Map from './Map'
  import MenuLateralEmergenciaVue from './emergencias/MenuLateralEmergencia.vue'
  import DialogAddEditEmergenciaVue from './emergencias/DialogAddEditEmergencia.vue'
  import constants from '@/helpers/constants'
  import DialogAddEditInfoEmergenciaVue from './emergencias/DialogAddEditInfoEmergencia.vue'

  export default {
    name: 'MainMap',

    components: {
      'vx-left-emergencia': MenuLateralEmergenciaVue,
      'vx-dialog-add-edit-emergencia': DialogAddEditEmergenciaVue,
      'vx-map': Map,
      'vx-dialog-add-edit-info-emergencia': DialogAddEditInfoEmergenciaVue,
    },

    data: () => ({
      emergenciasRefresher: null,
      coords: null,
      showDialogAddEditEmergencia: false,
      showLeftMenu: false,
      editEmergencia: null,
      showDialogAddEditInfoEmergencia: false,
    }),

    mounted() {
      if (this.userCanSeeEmergencias) {
        this.reloadEmergencias()
        this.emergenciasRefresher = setInterval(() => {
          this.reloadEmergencias()
        }, constants.EMERGENCIAS_REFRESH_TIME)
      }
    },

    beforeDestroy() {
      if (this.emergenciasRefresher) {
        clearInterval(this.emergenciasRefresher)
      }
    },

    computed: {
      isShowEmergencias: {
        get() {
          return this.$store.state.emergencia.isShowEmergencias
        },
        set(newValue) {
          this.$store.dispatch('emergencia/changeShowEmergencias', newValue)
        },
      },
      gettingNewCoordenadas() {
        return this.$store.state.map.gettingNewCoordenadas
      },

      emergencias: {
        get() {
          return this.$store.state.emergencia.emergencias
        },
      },
      emergenciaSelected: {
        get() {
          return this.$store.state.emergencia.emergenciaSelected
        },
        set(value) {
          this.$store.commit('emergencia/setEmergenciaSelected', value)
        },
      },
      userCanSeeEmergencias() {
        return this.$store.getters['usuario/rolesUsuario']([
          'VER_EMERGENCIAS',
          'MOD_EMERGENCIAS',
        ])
      },
      userCanEditEmergencias() {
        return this.$store.getters['usuario/rolesUsuario'](['MOD_EMERGENCIAS'])
      },
    },

    watch: {
      emergenciaSelected(newValue) {
        this.showLeftMenu = Boolean(newValue)
      },
    },

    methods: {
      async reloadEmergencias() {
        if (this.userCanSeeEmergencias) {
          this.$store.dispatch('emergencia/fetchEmergencias')
        }
      },
      openAddEmergencia(coords) {
        if (coords) {
          this.coords = coords
        }
        this.isShowEmergencias = true
        this.showDialogAddEditEmergencia = true
      },
      openEditEmergencia(emergencia) {
        this.editEmergencia = emergencia
        this.showDialogAddEditEmergencia = true
      },
      updateEmergencia(nueva) {
        this.showDialogAddEditEmergencia = false
        this.editEmergencia = null
        this.emergenciaSelected = nueva
        this.reloadEmergencias()
      },

      addEmergencia() {
        this.reloadEmergencias()
        this.showDialogAddEditEmergencia = false
      },

      cancelarAddEmergencia() {
        this.coords = null
        this.editEmergencia = null
        this.showDialogAddEditEmergencia = false
      },

      hideLeftMenu() {
        this.emergenciaSelected = null
      },

      openEditInfoEmergencia(emergencia) {
        this.editEmergencia = emergencia
        this.showDialogAddEditInfoEmergencia = true
      },

      updateInfoEmergencia(nueva) {
        this.editEmergencia = null
        this.emergenciaSelected = nueva
        this.reloadEmergencias()
      },
    },
  }
</script>

<style scoped>
  .alto {
    height: 100%;
    /*
  height: 100vh; /* Fallback for browsers that do not support Custom Propertie */
    /* height: calc(var(--vh, 1vh) * 100);*/
    overflow: hidden;
  }
</style>
