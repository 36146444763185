import Vue from 'vue'
import DateService from '../helpers/DateService'

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return DateService
    },
  },
})
