import { ArcgisRequest } from '@/api/arcgis'
import * as ArcGIS from '@/helpers/ArcGIS'

export class ServerHelper {
  static async deletePoints(petitionUrl, whereClause) {
    try {
      return ArcgisRequest.deletePoints(
        await ArcGIS.getToken(),
        petitionUrl,
        whereClause,
      )
    } catch (error) {
      throw error
    }
  }

  static async sendData(petitionUrl, atributes) {
    try {
      return await ArcgisRequest.sendData(
        await ArcGIS.getToken(),
        petitionUrl,
        atributes,
      )
    } catch (error) {
      throw error
    }
  }

  static getBasicPOSTOptions() {
    return {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
  }
}
