export class Relevance {
  id
  codigo
  prioridad
  nombre

  static ID_NORMAL = 1
  static ID_IMPORTANTE = 2
  static ID_MUY_IMPORTANTE = 3
  static ID_BAJA = 0
}
