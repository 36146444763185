<template>
  <div class="alto">
    <div id="mapNode" @contextmenu="test" />

    <!-- Overview map -->
    <div
      v-show="isOverviewMapActive && !$vuetify.breakpoint.mobile"
      id="overviewDiv"
      style="
        background-color: white;
        box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
          0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
      "
      ref="overviewDiv"
    >
      <v-icon id="overviewMapCloseIcon" @click="isOverviewMapActive = false">
        mdi-close
      </v-icon>
      <div id="extentDiv" />
    </div>

    <!-- <v-toolbar floating absolute rounded :class="showLayerList ? 'searchDisplace ma-3' : 'searchNotDisplace ma-3'"> -->
    <div id="searchWidget" class="searchNotDisplace mt-0" ref="searchWidget" />

    <div id="atributos" v-if="atributosMap">
      <v-row
        class="ml-4 mt-4"
        style="background-color: white; box-shadow: 0 1px 2px rgb(0 0 0 / 30%)"
      >
        <div id="buttonOrientacion" class="ml-3 mt-1 mb-2 mr-2">
          <!-- Orientar norte -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <!-- <v-btn fab tile> -->
              <v-icon v-bind="attrs" v-on="on">esri-icon-compass</v-icon>
              <!-- </v-btn> -->
            </template>
            <span>Orientación norte</span>
          </v-tooltip>
        </div>
        <div id="escala" v-if="escala" class="ml-2 mt-2 mr-4">
          Escala: &nbsp; 1:{{ escala }}
        </div>
      </v-row>
    </div>

    <!-- Leyenda ArcGIS -->
    <div id="infoDiv" class="esri-widget">
      <div id="legendDiv2">
        <div
          style="
            float: right;
            margin-right: 6px;
            margin-top: 1px;
            position: relative;
          "
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                v-if="!expandLeyenda"
                small
                right
                v-show="nCapasActivas() && showLeyenda"
                @click="expandCollapseLegend(expandLeyenda)"
              >
                mdi-arrow-expand
              </v-icon>
              <v-icon
                v-else
                v-bind="attrs"
                v-on="on"
                small
                right
                v-show="nCapasActivas() && showLeyenda"
                @click="expandCollapseLegend(expandLeyenda)"
              >
                mdi-arrow-collapse
              </v-icon>
            </template>
            <span v-if="!expandLeyenda">Expandir leyenda</span>
            <span v-else>Contraer leyenda</span>
          </v-tooltip>
        </div>
      </div>
    </div>

    <!-- Leyenda Riesgos-->
    <div id="legendDiv">
      <img
        alt="leyenda"
        id="legend"
        src="../../assets/leyenda/Leyenda_PEMAM.svg"
        v-if="estadoLeyendaTileLayer"
      >
    </div>

    <vx-emergencias
      v-if="isShowEmergencias && userCanSeeEmergencias"
      v-show="!gettingNewCoordenadas"
      :show="showEmergencias"
      :emergencias="emergencias"
      @addEmergencia="$emit('openAddEmergencia')"
      @selectEmergencia="selectEmergencia"
    />
    <vx-context-menu @openAddEmergencia="$emit('openAddEmergencia', $event)" />

    <vx-coordenadas-new />

    <vx-buttons-map
      :class="showLayerList ? 'buttonsMapDisplace ma-3' : 'buttonsMap ma-3'"
      :isOverviewMapActive="isOverviewMapActive"
      @showLegend="mostrarLeyendaArcGIS()"
      @showLayerList="showLayerList = true"
      @showSearchCoords="showSearchCoords = true"
      @showDialogSimulacion="showDialogSimulacion = true"
      @showEmergencias="showEmergencias = true"
      @showDialogGeofencing="showDialogGeofencing = true"
      @showHideOverviewMap="isOverviewMapActive = !isOverviewMapActive"
    />
    <!-- @shareMap="toggleShareMap"  -->
    <vx-search-coords
      :show="showSearchCoords"
      @hide="showSearchCoords = false"
    />
    <vx-layer-list :show="showLayerList" @hide="showLayerList = false" />

    <vx-popup-manager />

    <!-- Simulacion -->
    <vx-simulacion-manager
      :showDialog="showDialogSimulacion"
      @openDialog="showDialogSimulacion = true"
      @closeDialog="showDialogSimulacion = false"
    />

    <vx-dialog-geofencing
      :show="showDialogGeofencing"
      @cerrar="showDialogGeofencing = false"
    />

    <vx-symbology />

    <!-- Compartir mapa -->
    <vx-share-map-manager />

    <vx-footer-map />

    <!-- Mensaje al usuario para que haga zoom -->
    <div v-if="showAlertMessage" id="above-map-msg">
      Haz zoom para cargar los datos
    </div>

    <v-overlay :value="isLoading" :absolute="true">
      <!-- <v-progress-circular color="primary" indeterminate size="100" width="10" /> -->
      <v-progress-circular
        :rotate="90"
        :size="100"
        :width="10"
        :value="value"
        color="primary"
      >
        {{ value }}%
      </v-progress-circular>
      <!-- <span v-if="mensaje">{{ mensaje }}</span> -->
    </v-overlay>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import * as ArcGIS from '@/helpers/ArcGIS'

  import ContextMenu from './ContextMenu'
  import CoordenadasNew from './CoordenadasNew'

  import ButtonsMap from './ButtonsMap'
  import SearchCoords from './SearchCoords'
  import FooterMap from './FooterMap'
  import LayerList from './layerMenu/LayerList'

  import SimulacionManager from './simulador/SimulacionManager'
  import DialogGeofencing from './DialogGeofencing'

  import PopupManager from './popup/PopupManager'
  import Symbology from './Symbology'
  import ShareMapManager from './sharemap/ShareMapManager'
  import EmergenciasVue from './emergencias/Emergencias.vue'
  import { Emergencia } from '@/models/Emergencia'

  export default {
    name: 'MainMap',

    components: {
      'vx-emergencias': EmergenciasVue,
      'vx-context-menu': ContextMenu,
      'vx-coordenadas-new': CoordenadasNew,

      'vx-buttons-map': ButtonsMap,
      'vx-search-coords': SearchCoords,
      'vx-layer-list': LayerList,

      'vx-popup-manager': PopupManager,
      'vx-simulacion-manager': SimulacionManager,
      'vx-dialog-geofencing': DialogGeofencing,

      'vx-share-map-manager': ShareMapManager,

      'vx-symbology': Symbology,

      'vx-footer-map': FooterMap,
    },

    props: {
      showDialogAddEditEmergencia: {
        default: false,
        type: Boolean,
      },
      emergencias: Array,
    },

    data: () => ({
      isLoading: null,
      interval: {},
      value: 0,
      mensaje: null,
      showSearchCoords: false,
      showLayerList: false,
      showDialogAddIncendio: false,
      addIncendioPoint: null,
      showDialogSimulacion: false,
      showDialogGeofencing: false,
      showEmergencias: true,
      isOverviewMapActive: true,
      showLeyenda: false,
      estadoLeyendaTileLayer: false,
      expandLeyenda: false,
      capasActivas: 0,
      showAlertMessage: false,
      escala: null,
      atributosMap: false,
    }),

    computed: {
      ...mapGetters('incendio', ['incendios']),

      ...mapGetters('medio', ['medios']),

      ...mapGetters('shareMap', ['mapExtent']),
      ...mapGetters('map', ['layersPrueba']),

      isShowEmergencias() {
        return this.$store.state.emergencia.isShowEmergencias
      },

      isButtonXSmall() {
        return (
          this.$vuetify.breakpoint.name === 'md' ||
          this.$vuetify.breakpoint.mobile
        )
      },
      gettingNewCoordenadas() {
        return this.$store.state.map.gettingNewCoordenadas
      },
      userCanSeeEmergencias() {
        return this.$store.getters['usuario/rolesUsuario']([
          'VER_EMERGENCIAS',
          'MOD_EMERGENCIAS',
        ])
      },
      userCanEditEmergencias() {
        return this.$store.getters['usuario/rolesUsuario'](['MOD_EMERGENCIAS'])
      },
    },

    watch: {
      showLayerList() {
        if (this.showLayerList) {
          const divSearchWidget = this.$refs.searchWidget
          divSearchWidget.classList.remove('searchNotDisplace')
          divSearchWidget.classList.add('searchDisplace')
          // Mover overviewmap
          const divOverview = this.$refs.overviewDiv
          divOverview.classList.remove('overviewNotDisplace')
          divOverview.classList.add('overviewDisplace')
        } else {
          const divSearchWidget = this.$refs.searchWidget
          divSearchWidget.classList.remove('searchDisplace')
          divSearchWidget.classList.add('searchNotDisplace')
          // Mover overviewmap
          const divOverview = this.$refs.overviewDiv
          divOverview.classList.remove('overviewDisplace')
          divOverview.classList.add('overviewNotDisplace')
        }
      },

      medios() {
        this.redrawMedios()
      },
    },

    methods: {
      ...mapActions('map', ['setMapLoading']),

      async initMap() {
        //this.setMapLoading(true)
        this.isLoading = true

        // try {
        await ArcGIS.createMap()
        this.actualizarCargaMapa(80)
        // } finally {
        // this.setMapLoading(false)
        // this.isLoading = false
        //}
      },

      mostrarLeyendaArcGIS() {
        if (!this.showLeyenda) {
          // Si esta activo SHOW LEYENDA
          // Comprobar si hay capas de tipo vector-tileLayer activas (Peligro, Riesgo y Vulnerabilidad)
          this.showLeyenda = true
          ArcGIS.closeArcGISLegend(true)

          // Mostrar cabecera azul de la leyenda
          let widgetLeyenda
          setTimeout(function () {
            widgetLeyenda = document.getElementsByClassName(
              'esri-ui-bottom-left esri-ui-corner',
            )
            if (widgetLeyenda[0]) {
              // Quitar background color azul
              widgetLeyenda[0].style.backgroundColor = '#003DF6'
            }
          })

          this.compruebaTileLayer()
        } else {
          this.showLeyenda = false

          // Ocultar cabecera azul de la leyenda
          let widgetLeyenda
          setTimeout(function () {
            widgetLeyenda = document.getElementsByClassName(
              'esri-ui-bottom-left esri-ui-corner',
            )
            if (widgetLeyenda[0]) {
              // Quitar background color azul
              widgetLeyenda[0].style.backgroundColor = 'transparent'
            }
          })

          // Al cerrar la leyenda, dejar botón expandir leyenda a la altura de la leyenda
          if (this.nCapasActivas()) {
            // Cambiar estilo botón expandir leyenda y leyenda
            this.expandCollapseLegend(true)
          }
          ArcGIS.closeArcGISLegend(false)
          this.estadoLeyendaTileLayer = false
          // this.mostrarLeyendaTileLayer()
        }
      },

      compruebaTileLayer() {
        const orderLayers = JSON.parse(JSON.stringify(this.layersPrueba))
        let capasActivas = false
        this.capasActivas = 0
        // console.log(orderLayers)

        // Recorro las capas del nivel 0
        for (const layer1 of orderLayers.filter(
          (x) =>
            x.title !== null && x.type !== 'vector-tileLayer' && x.subLayers,
        )) {
          if (layer1.type === 'groupLayer') {
            // Recorro las capas hijas de layer1
            for (const layer2 of layer1.subLayers) {
              if (layer2.type === 'groupLayer') {
                // Recorro las capas hijas de layer2
                for (const layer3 of layer2.layers) {
                  if (layer3.type === 'groupLayer') {
                    // Recorro las hijas de layer 3
                    for (const layer4 of layer3.layers) {
                      if (
                        layer4.visible &&
                        layer4.type === 'vector-tileLayer'
                      ) {
                        capasActivas = true
                        // console.log('Activo nivel 4', layer4.title)
                      } else {
                        // Se activan otras capas, para controlar el expand de la leyenda
                        if (
                          layer4.visible &&
                          layer4.type !== 'vector-tileLayer'
                        ) {
                          this.capasActivas++
                        }
                      }
                    }
                  } else {
                    if (layer3.visible && layer3.type === 'vector-tileLayer') {
                      capasActivas = true
                      // console.log('Activo nivel 3', layer3.title)
                    } else {
                      // Se activan otras capas, para controlar el expand de la leyenda
                      if (
                        layer3.visible &&
                        layer3.type !== 'vector-tileLayer'
                      ) {
                        this.capasActivas++
                      }
                    }
                  }
                }
              } else {
                if (layer2.visible && layer2.type === 'vector-tileLayer') {
                  capasActivas = true
                  // console.log('Activo nivel 2', layer2.title)
                } else {
                  // Se activan otras capas, para controlar el expand de la leyenda
                  if (layer2.visible && layer2.type !== 'vector-tileLayer') {
                    this.capasActivas++
                  }
                }
              }
            }
          } else {
            if (layer1.visible && layer1.type === 'vector-tileLayer') {
              capasActivas = true
              // console.log('Activo nivel 1', layer1.title)
            }
          }
        }

        this.estadoLeyendaTileLayer = capasActivas
        // console.log(this.estadoLeyendaTileLayer)
      },

      mostrarLeyendaTileLayer() {
        if (this.showLeyenda) {
          this.compruebaTileLayer()
        } else {
          this.compruebaTileLayer()
        }
      },

      mostrarMensajeZoom(display) {
        // console.log('Mostrar mensajito al usuario: ', display)
        this.showAlertMessage = display
      },

      setNewEscalaMap(escala) {
        this.escala = Math.floor(escala).toLocaleString('es-ES')
      },

      showAttributesMap() {
        if (!this.atributosMap) {
          this.atributosMap = true
        } else {
          this.atributosMap = false
        }
      },

      actualizarCargaMapa(value) {
        // Se completa la carga
        this.value = value
        if (this.value === 80) {
          this.carga(this.value)
        }
      },

      carga() {
        this.interval = setInterval(() => {
          if (this.value === 100) {
            this.isLoading = false
            return (this.value = 0)
          }
          this.value += 2
        }, 100)
      },

      expandCollapseLegend(value) {
        if (!value) {
          // Contraida, expandir leyenda
          const prueba = document.getElementById('infoDiv')
          prueba.style.maxHeight = 'calc(100vh - 250px)'
          this.expandLeyenda = true
        } else {
          // Expandida, contraer leyenda
          const prueba = document.getElementById('infoDiv')
          prueba.style.maxHeight = '350px'
          this.expandLeyenda = false
        }
      },

      nCapasActivas() {
        if (this.capasActivas >= 0) {
          return true
        } else {
          return false
        }
      },

      test() {
        // console.log(e)
      },

      redrawMedios() {
        ArcGIS.redrawMedios()
      },

      /**
       * @param {Emergencia} emergencia
       */
      selectEmergencia(emergencia) {
        this.$emit('selectEmergencia', emergencia)
        if (emergencia) {
          ArcGIS.setCenterMap({
            target: [emergencia.longitude, emergencia.latitude],
            zoom: 15,
          })
        }
      },

      showAddIncendio(pointData) {
        this.showDialogAddIncendio = true
        this.addIncendioPoint = pointData
      },
      cancelarAddIncendio() {
        this.showDialogAddIncendio = false
      },
      addIncendio(incendio) {
        this.showDialogAddIncendio = false
        this.$store.dispatch('incendio/addIncendio', incendio)
      },
    },

    async created() {
      await this.$store.dispatch('map/fetchParamsComunidad')
      this.initMap()
    },

    async mounted() {
      // Porcentaje de carga inicial
      this.$eventHub.$on('loadMap', this.actualizarCargaMapa)
      // Alert zoom
      this.$eventHub.$on('mostrarMensajeZoom', this.mostrarMensajeZoom)
      // Escala del mapa
      this.$eventHub.$on('escalaMap', this.setNewEscalaMap)
      // Atributos mapa
      this.$eventHub.$on('attributesMap', this.showAttributesMap)
      // Leyenda Vector-TileLayer
      this.$eventHub.$on('tileLayerActivas', this.mostrarLeyendaTileLayer)
    },

    beforeDestroy() {
      clearInterval(this.interval)
    },
  }
</script>

<style lang="scss" scoped>
  #mapNode {
    height: calc(100% - 25px); /* 26 px footer */
    z-index: 1;
  }

  .esri-attribution {
    background-color: #00486f85 !important;
    color: white;
  }

  #searchWidget {
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
      0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    right: 0px;
    margin-right: -3px;
  }

  ::v-deep .esri-input[type='text'],
  .esri-input[type='password'],
  .esri-input[type='number'] {
    height: auto;
    padding: 0 0.5em;
  }

  #atributos {
    position: absolute;
    /* text-align: center; */
    top: 0;
    left: 0;
    height: auto;
    width: auto;
    /* background-color: #f5f5f5; */
  }
  /** Orientacion */
  #buttonOrientacion {
    top: 0;
    left: 0;
  }

  #escala {
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    /* text-align: center; */
    /* top: 90px; */
    color: #000000;
    /* font-size: 25px; */
  }

  /** Leyenda */
  #legend {
    position: absolute;
    bottom: 30px;
    height: 60px;
    width: 100%;
  }
  .alto {
    /*height: 80%;
  /*
  height: 100vh; /* Fallback for browsers that do not support Custom Propertie */
    /*height: calc(var(--vh, 1vh) * 100);*/
    overflow: hidden;
  }

  .buttonsMap {
    position: absolute;
    top: 70px;
    right: 0px;
    /* right: 354px; Mover a la izquierda al abrir el menu de capas */
    /* overflow-y: auto; */
    height: calc(100% - 120px) !important; /* 26 px footer */
  }

  .buttonsMapDisplace {
    position: absolute;
    top: 70px;
    right: 354px; /*Mover a la izquierda al abrir el menu de capas*/
    /* overflow-y: auto; */
    height: calc(100% - 120px) !important; /* 26 px footer */
  }

  .searchDisplace {
    position: absolute;
    top: 0px;
    right: 354px !important;
  }

  .searchNotDisplace {
    right: 0px !important;
  }

  #above-map-msg {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    text-align: center;
    top: 90px;
    color: #1d71b8;
    font-size: 25px;
    background-color: rgba(255, 255, 255, 0.6);
  }

  /* Z-index popup de emergencias */
  ::v-deep .esri-ui {
    z-index: 2;
  }

  /** Estilos botones 3D */
  ::v-deep .esri-ui-corner {
    display: flex;
    flex-direction: row;
    background-color: #003df6;
  }
  ::v-deep .esri-ui-top-right {
    align-items: flex-end;
    background-color: transparent;
  }

  ::v-deep .esri-widget--button {
    font-size: 14px;
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.87);
    width: 40px;
    height: 40px;
    /* padding: 0;
    margin: 0; */
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    float: right;
    transition: background-color 125ms ease-in-out;
  }

  /** Icono widget contraible*/
  /* ::v-deep [class*=" esri-icon-"], .esri-building-phase-picker__arrow-left, .esri-building-phase-picker__arrow-right, .esri-building-level-picker__arrow-up, .esri-building-level-picker__arrow-down {
    font-size: 24px;
    right: 0px;
} */

  ::v-deep .esri-ui-corner .esri-component {
    display: flex;
    flex-direction: row;
    margin: 7px 0px 0px;
    background: whitesmoke; /** #2e2e2e */
  }
  /** Ancho de la layenda de ArcGIS */
  /* ::v-deep .esri-ui-corner .esri-component.esri-widget--panel { 
    width: auto !important;
  } */
  ::v-deep .esri-widget__heading {
    /* color: white; */
    font-weight: 600;
    margin: 0 0 0.5rem 0;
  }
  ::v-deep .esri-widget {
    /* color: white; */
    font-size: 14px;
    font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.3em;
  }
  ::v-deep .esri-legend__layer-caption {
    display: table-caption;
    padding: 6px 0;
    word-break: break-word;
    text-decoration-color: white;
  }

  ::v-deep .esri-navigation-toggle:hover .esri-navigation-toggle__button,
  .esri-navigation-toggle:focus .esri-navigation-toggle__button {
    background-color: #f5f5f5;
    color: #2e2e2e;
  }
  ::v-deep .esri-navigation-toggle__button--rotate {
    background-color: #f5f5f5;
    margin-left: 8px;
  }
  ::v-deep .esri-navigation-toggle__button--pan {
    background-color: #f5f5f5;
  }

  /** Estilos overview map */
  #overviewDiv {
    position: absolute;
    bottom: 74px;
    right: 60px;
    width: 300px;
    height: 200px;
    border: 0px solid black;
    z-index: 1;
    overflow: hidden;
    #overviewMapCloseIcon {
      position: absolute;
      top: 5px;
      right: 5px;
      background-color: white;
    }
  }

  #extentDiv {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 2;
  }

  .overviewDisplace {
    position: absolute;
    bottom: 74px;
    width: 300px;
    height: 200px;
    border: 0px solid black;
    z-index: 1;
    overflow: hidden;
    right: 420px !important;
  }

  .overviewNotDisplace {
    position: absolute;
    bottom: 74px;
    right: 60px;
    width: 300px;
    height: 200px;
    border: 0px solid black;
    z-index: 1;
    overflow: hidden;
  }
  /** Fin estilos overviewmap */

  #expandLegend {
    position: absolute;
    bottom: 400px;
    left: 320px;
    z-index: 1;
    overflow: hidden;
  }

  #infoDiv {
    background-color: white;
    bottom: 74px;
    max-height: 350px;
    overflow: auto;
  }
</style>
