<template>
  <div>
    <v-snackbar v-model="isPaintingSimulacionSketch" bottom :timeout="-1">
      Dibuja la zona de simulación
      <template #action="{ attrs }">
        <v-btn text v-on="attrs" @click="stopSimulacionSketch">Cancelar</v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="isLoadingSimulacion" bottom :timeout="-1">
      Cargando simulación...
      <template #action="{ attrs }">
        <v-progress-circular v-on="attrs" indeterminate />
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="isErrorSimulacion"
      :color="errorSimulacion === 406 ? 'warning' : 'error'"
      bottom
      :timeout="3000"
    >
      <v-icon dark>mdi-alert-circle</v-icon>
      Error en la simulación:
      {{
        errorSimulacion === 404
          ? 'no existe'
          : errorSimulacion === 406
            ? 'en progreso'
            : 'error interno'
      }}
    </v-snackbar>

    <vx-dialog-simulacion
      :show="showDialogSimulacion"
      @initSimulacionSketch="initSimulacionSketch"
      @stopSimulacionSketch="stopSimulacionSketch"
      @paintSimulacion="paintSimulacion"
      @open="openDialog"
      @close="closeDialog"
    />
    <vx-simulacion-slider
      v-show="isPaintingSimulacion"
      :simulacion="simulacion"
      @updateSimulacionTime="updateSimulacionTime"
      @stopSimulacion="stopSimulacion"
    />
  </div>
</template>

<script>
  import DialogSimulacion from './DialogSimulacion'
  import SimulacionSlider from './SimulacionSlider'

  import * as ArcGIS from '../../../helpers/ArcGIS'

  export default {
    props: {
      showDialog: Boolean,
    },

    components: {
      'vx-dialog-simulacion': DialogSimulacion,
      'vx-simulacion-slider': SimulacionSlider,
    },

    data: () => ({
      showDialogSimulacion: false,

      isPaintingSimulacionSketch: false,

      isLoadingSimulacion: false,

      isPaintingSimulacion: false,
      simulacion: null,

      isErrorSimulacion: false,
      errorSimulacion: null,
    }),

    computed: {},

    watch: {
      showDialog() {
        this.showDialogSimulacion = this.showDialog
      },
    },

    methods: {
      openDialog() {
        this.$emit('openDialog')
        // this.showDialogSimulacion = true
      },
      closeDialog() {
        this.$emit('closeDialog')
        // this.showDialogSimulacion = false
      },

      initSimulacionSketch(data) {
        // Clear SVM y capa de dibujos anteriores
        ArcGIS.clearSimulacionSketch()

        this.isPaintingSimulacionSketch = true
        ArcGIS.initSimulacionSketchViewModel(data)
      },
      stopSimulacionSketch() {
        // Finaliza el sketch
        this.isPaintingSimulacionSketch = false
        ArcGIS.stopSimulacionSketchViewModel()
        ArcGIS.clearSimulacionSketch()
        this.openDialog()
      },

      paintSimulacion(simulacion) {
        this.isLoadingSimulacion = true

        ArcGIS.clearSimulacion()

        ArcGIS.paintSimulacion(simulacion)
          .then(() => {
            this.closeDialog()

            this.isPaintingSimulacion = true
            this.simulacion = simulacion
          })
          .catch((err) => {
            this.isErrorSimulacion = true
            this.errorSimulacion = err // TODO: leer el mensaje del error, aqui llega el http code
          })
          .finally(() => {
            this.isLoadingSimulacion = false
          })
      },
      updateSimulacionTime(elapsedMin) {
        ArcGIS.updateSimulacionTime(elapsedMin)
      },
      stopSimulacion() {
        this.isPaintingSimulacion = false
        this.simulacion = null
        ArcGIS.clearSimulacion()
      },
    },

    mounted() {
      // this.$eventHub.$on('showPopup', this.onShowPopup)
    },
  }
</script>

<style scoped></style>
