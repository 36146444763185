var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message",class:{
    self: _vm.isMyMessage,
    selected: _vm.messageIsSelected,
    'not-valid': _vm.message.isErroneo,
    'relevance-low': _vm.isRelevanceLow,
    'relevance-medium': _vm.isRelevanceMedium,
    'relevance-important': _vm.isRelevanceImportant,
    'relevance-very-important': _vm.isRelevanceVeryImportant,
  },on:{"click":function($event){return _vm.selectMessage(!_vm.messageIsSelected)}}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"avatar"},[_c('v-avatar',{attrs:{"size":"24"}},[_c('img',{attrs:{"src":_vm.$options.filters.base64Image(_vm.message.Usuario.Entidad.logo) ||
              require('@/assets/icons/avatar_default.svg')}})])],1),_c('div',{staticClass:"message-info"},[_c('div',{staticClass:"entity"},[_vm._v(" "+_vm._s(_vm.mostrarCargoPrincipalOusuario)+" ")]),_c('div',{staticClass:"relevance"},[_vm._v(" "+_vm._s(`${_vm.message.Relevancium.nombre}`)+" ")])]),_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.message.fechaCreacion)))])]),_c('div',{staticClass:"content"},[_c('span',[_vm._v(_vm._s(_vm.message.texto))]),(_vm.userCanSelectMessages)?_c('v-simple-checkbox',{attrs:{"value":_vm.messageIsSelected,"ripple":false},on:{"click":function($event){return _vm.selectMessage(!_vm.messageIsSelected)}}}):_vm._e()],1),(_vm.message.Documento && !_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"contentDocument"},[(_vm.formatDocument !== '.pdf')?_c('div',[(_vm.message.Documento.ruta)?_c('img',{staticClass:"ficheroAdjunto",attrs:{"src":_vm.image}}):_vm._e()]):_c('div',[_c('vx-pdf-preview',{attrs:{"fileUrl":_vm.pdfPreview}})],1)]):_vm._e(),(_vm.message.idDocumento)?_c('div',{staticClass:"document",on:{"click":function($event){$event.stopPropagation();return _vm.downloadDocument(_vm.message.Documento)}}},[_c('img',{attrs:{"src":_vm.iconoTipoArchivo(_vm.message.Documento)}}),_c('span',[_vm._v(_vm._s(_vm.message.Documento.nombre))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }