<template>
  <div class="container">
    <div class="content ml-2 mr-2 mt-2 pb-1" style="background-color: #e3e3e3">
      <div class="d-flex flex-row justify-space-between align-center">
        <div class="d-flex flex-column text-left mt-2 ml-4">
          <span class="font-weight-bold">{{
            registro.fechastamp | formatDate
          }}</span>
        </div>

        <div class="d-flex flex-column text-right mt-2 mr-4">
          <span class="font-weight-bold">{{
            registro.tipo_evento.codigo
          }}</span>
        </div>
      </div>

      <div class="d-flex flex-row justify-start mt-3 ml-4 pb-5">
        <span>{{ registro.mensaje_evento }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data: () => ({}),

    props: {
      registro: null,
    },

    methods: {
      formatDate(inputDate) {
        const date = this.$date.parseDate(inputDate, 'YYYY-MM-DD HH:MM:SS')
        return date.isValid()
          ? this.$date.formatDate(date, 'DD/MM/YYYY HH:MM:SSS')
          : ''
      },
    },

    mounted() {},
  }
</script>

<style scoped lang="scss"></style>
