import { setDefaultOptions, loadModules } from 'esri-loader'

import store from '@/store/index'
import constants from './constants'
import VueInst from '../main'

// import MedioPopup from '../components/map/popup/MedioPopup'
// import Vue from 'vue'

import proj4 from 'proj4'

import DateService from './DateService'
import { EmergenciaArcGISHelper } from './ArcGISHelpers/EmergenciaArcGISHelper'
import { ArcgisLegendHelper } from './ArcGISHelpers/ArcgisLegendHelper'

setDefaultOptions({ css: true })

const Q = require('q')

/**
 * Global variables
 */
let map
let mapView
let minScale
let baseMapWM
let legend
let overviewMap
let mapViewOver
let dispositivo
let idMapByDispositivo

// #region MAP
export const createMap = async () => {
  dispositivo = await tipoDispositivo()
  // console.log('Tipo de dispositivo: ', dispositivo)

  // Obtener el contenido del fichero de capas correspondiente al entorno y el
  // tipo de dispositivo
  const contentLocalFileWebMap = await getContentFileWebMap(dispositivo)
  // const contentLocalFileWebMap = null

  // Guardar el contenido del fichero del webmap en la store de map
  if (contentLocalFileWebMap) {
    await compruebaSiExistenLasCapasYguardarEnStore(contentLocalFileWebMap)
  }

  try {
    const [WebMap] = await loadModules(['esri/WebMap'])

    idMapByDispositivo = await getIdWebMap(dispositivo)

    await registerToken()
    VueInst.$eventHub.$emit('loadMap', 10)
    map = new WebMap({
      portalItem: {
        id: idMapByDispositivo,
        portal: constants.webMap.portal, // Default: The ArcGIS Online Portal
      },
    })

    await use2D()
    VueInst.$eventHub.$emit('loadMap', 20)
    // await createLayers()
    VueInst.$eventHub.$emit('loadMap', 30)
    // await reorderLayers()
    VueInst.$eventHub.$emit('loadMap', 40)
    await setSearchWidget()
    VueInst.$eventHub.$emit('loadMap', 50)

    if (contentLocalFileWebMap) {
      VueInst.$eventHub.$emit('loadMap', 60)
      await map.when(() => {
        // Cuando termina el mapView de crearse
        // Do nothing
      })
      await drawContorno(false)
      VueInst.$eventHub.$emit('loadMap', 65)
      await setLocalBaseMapWebMap(map)
      EmergenciaArcGISHelper.showLayer(store.state.emergencia.isShowEmergencias)
    } else {
      // Comprobante de que se han cargado las capas desde el webmap
      VueInst.$eventHub.$emit('loadMap', 60)
      await map
        .loadAll()
        .catch(function () {
          // Ignore any failed resources
        })
        .then(async function () {
          await drawContorno(true)
          await loadLayerListWebMap()
          EmergenciaArcGISHelper.showLayer(
            store.state.emergencia.isShowEmergencias,
          )
        })
    }
  } catch (err) {
    VueInst.$log.error(err)
  }
}

async function getContentFileWebMap(dispositivo) {
  if (dispositivo === constants.Dispositivo.PC) {
    return require(process.env.VUE_APP_WEBMAP)
  } else {
    return require(process.env.VUE_APP_MOBILE_WEBMAP)
  }
}

/*
async function getContentFileWebMapCompletoByEntorno (entorno) {
  if (entorno === constants.Entorno.Dev || entorno === constants.Entorno.Pre) {
    return require('@/webmap/complete/devPre/webmap_devPre_PEMAM.json')
  } else {
    return require('@/webmap/complete/Prd/webmap_prd_PEMAM.json')
  }
}

async function getContentFileWebMapMobileByEntorno (entorno) {
  if (entorno === constants.Entorno.Dev || entorno === constants.Entorno.Pre) {
    return require('@/webmap/mobile/devPre/webmap_devPre_movil_PEMAM.json')
  } else {
    return require('@/webmap/mobile/Prd/webmap_prd_movil_PEMAM.json')
  }
}
  */

async function compruebaSiExistenLasCapasYguardarEnStore(
  contentLocalFileWebMap,
) {
  // Recorrer el array de json de capas del webmap y guardar en la store
  contentLocalFileWebMap.forEach((capa) => {
    store.dispatch('map/addLayersPrueba', capa)
  })
}

function tipoDispositivo() {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'Tablet'
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return 'Mobile'
  }
  return 'PC'
}

function getIdWebMap(dispositivo) {
  return dispositivo === 'Mobile'
    ? constants.webMap.mobile
    : dispositivo === 'Tablet'
    ? constants.webMap.mobile
    : constants.webMap.id
}

async function createOverViewMap() {
  const [WebMap] = await loadModules(['esri/WebMap'])
  const [MapView] = await loadModules(['esri/views/MapView'])

  overviewMap = new WebMap({
    portalItem: {
      id: constants.overViewWebMap,
      portal: constants.webMap.portal,
    },
  })

  mapViewOver = new MapView({
    container: 'overviewDiv',
    map: overviewMap,
    center: mapView.extent,
    constraints: {
      rotationEnabled: false,
      maxZoom: constants.maxZoom,
      minZoom: constants.minZoomOVMap,
    },
  })

  // Remove the default widgets
  mapViewOver.ui.components = []
  mapViewOver.when(() => {
    mapView.when(() => {
      setup()
    })
  })
}

export function getLayerSymbols(layer) {
  return ArcgisLegendHelper.getLayerSymbols(layer)
}

async function createLegend() {
  const [Legend] = await loadModules(['esri/widgets/Legend'])
  VueInst.$eventHub.$emit('loadMap', 70)
  legend = await new Legend({
    view: mapView,
    container: 'legendDiv2',
    visible: false,
  })
}

async function setup() {
  const [promiseUtils, Graphic, reactiveUtils] = await loadModules([
    'esri/core/promiseUtils',
    'esri/Graphic',
    'esri/core/reactiveUtils',
  ])
  console.log('reactiveUtils', reactiveUtils)

  const extentDebouncer = promiseUtils.debounce(() => {
    // if (mapView.stationary) {
    mapViewOver.goTo({
      center: mapView.extent,
      scale:
        mapView.scale *
        2 *
        Math.max(
          mapView.width / mapViewOver.width,
          mapView.height / mapViewOver.height,
        ),
    })
    // }
  })

  const extent3Dgraphic = new Graphic({
    geometry: null,
    symbol: {
      type: 'simple-fill',
      color: [0, 61, 246, 0],
      outline: {
        color: [0, 61, 246, 1],
        width: 2,
      },
    },
  })
  mapViewOver.graphics.add(extent3Dgraphic)

  reactiveUtils.watch(
    () => mapView.extent,
    (extent) => {
      // Sync the overview map location
      // whenever the 3d view is stationary
      extentDebouncer().then(() => {
        extent3Dgraphic.geometry = extent
      })
    },
    {
      initial: true,
    },
  )
}

export const closeArcGISLegend = async (value) => {
  // Cambiar visibilidad leyenda arcgis
  legend.visible = value

  // Cambiar tamaño de la leyenda, solo en modo movil
  mobileSize =
    mapView.heightBreakpoint === 'xsmall' ||
    mapView.widthBreakpoint === 'xsmall'
  // legendWidget ArcGIS
  if (legend.visible && mobileSize) {
    let legendWidget
    setTimeout(function () {
      legendWidget = document.getElementById('infoDiv')
      if (legendWidget) {
        legendWidget.style.maxHeight = '150px'
        legendWidget.style.width = 'auto'
      }
    }, 100)

    // Ubicacion ui izquierda
    let uiLeft
    setTimeout(function () {
      uiLeft = document.getElementsByClassName('esri-ui-bottom-left')
      if (uiLeft[0]) {
        uiLeft[0].style.bottom = '20px'
      }
    })
  } else if (legend.visible && !mobileSize) {
    let legendWidget
    setTimeout(function () {
      legendWidget = document.getElementById('infoDiv')
      if (legendWidget) {
        // legendWidget.style.maxHeight = '350px'
        legendWidget.style.width = '300px'
      }
    }, 100)

    // Ubicacion ui izquierda
    let uiLeft
    setTimeout(function () {
      uiLeft = document.getElementsByClassName('esri-ui-bottom-left')
      if (uiLeft[0]) {
        uiLeft[0].style.bottom = '20px'
      }
    })
  }
}

export const getLayerById = (layerId) =>
  map?.allLayers.find((x) => x.id === layerId)

export const gerLayerByTitle = (layerTitle) =>
  map?.allLayers.find((x) => x.title === layerTitle)

export const getLayerByIdLocal = async (layerId) => {
  let busquedaLayer
  const allLayers = await store.getters['map/layersPrueba']
  // Buscar en el nivel 1
  for (let i = 0; i < allLayers.length; i++) {
    const item = allLayers[i]
    if (item.id === layerId && esFeatureLayerOvectorTileLayer(item)) {
      busquedaLayer = item
    } else {
      // Buscar en el nivel 2. Comprobar si tiene SubLayers
      if (item.subLayers) {
        for (let j = 0; j < item.subLayers.length; j++) {
          const subLayer = item.subLayers[j]
          if (
            subLayer.id === layerId &&
            esFeatureLayerOvectorTileLayer(subLayer)
          ) {
            busquedaLayer = subLayer
          } else {
            // Buscar en el nivel 3. Comprobar si tiene Layers
            if (subLayer.layers) {
              for (let k = 0; k < subLayer.layers.length; k++) {
                const layer = subLayer.layers[k]
                if (
                  layer.id === layerId &&
                  esFeatureLayerOvectorTileLayer(layer)
                ) {
                  busquedaLayer = layer
                } else {
                  // Buscar en el nivel 4. Comprobar si tiene layers
                  if (
                    layer.id === layerId &&
                    esFeatureLayerOvectorTileLayer(layer)
                  ) {
                    busquedaLayer = layer
                  } else {
                    if (layer.layers) {
                      for (let l = 0; l < layer.layers.length; l++) {
                        const layerLevel4 = layer.layers[l]
                        if (
                          layerLevel4.id === layerId &&
                          esFeatureLayerOvectorTileLayer(layerLevel4)
                        ) {
                          busquedaLayer = layerLevel4
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return busquedaLayer
}

async function esFeatureLayerOvectorTileLayer(layer) {
  return layer.type === 'featureLayer' || layer.type === 'vector-tileLayer'
    ? true
    : false
}

export const getLayerByTitle = (layerId) =>
  map?.allLayers.find((x) => x.title === layerId)

export const changeOpacityWebMap = async (layerId, value) => {
  const layer = getLayerById(layerId)
  layer.opacity = value
  // layer.parent.opacity = value
}

export const changeVisibilityWebMap = async (layerId, value) => {
  let layer = getLayerById(layerId)
  if (layer) {
    // Buscar por nombre
    const localLayer = await getLayerByIdLocal(layerId)
    layer = await gerLayerByTitle(localLayer.title)
  }
  // console.log('MinScaleLayer: ', layer.minScale, ', value: ', value)
  // TODO: por el momento solo se contempla no mostrar el mensaje para las capas operaciones de: zona externa (ContornoExterior) y 5km de actuacion (ContornoAnillos)
  if (layer.id !== 'ContornoExterior' && layer.id !== 'ContornoAnillos') {
    minScale = layer.minScale
    if (minScale === 0) {
      // Hay capas que no tienen escala mínima para mostrarse (no mostramos mensaje de zoom)
      minScale = null
      VueInst.$eventHub.$emit('mostrarMensajeZoom', false)
    } else {
      if (minScale < mapView.scale && value) {
        mobileSize =
          mapView.heightBreakpoint === 'xsmall' ||
          mapView.widthBreakpoint === 'xsmall'
        // console.log('MobileSize: ', mobileSize)
        let divMensaje
        setTimeout(function () {
          divMensaje = document.getElementById('above-map-msg')
          // console.log('divMensaje: ', divMensaje)
          if (divMensaje && mobileSize) {
            // console.log('DivMensajeMovil: ', divMensaje)
            divMensaje.style.width = '200px'
            divMensaje.style.fontSize = '18px'
          } else if (divMensaje && !mobileSize) {
            // console.log('DivMensajeDesktop: ', divMensaje)
            divMensaje.style.width = '400px'
            divMensaje.style.fontSize = '25px'
          }
        }, 100)
        VueInst.$eventHub.$emit('mostrarMensajeZoom', true)
      } else {
        // console.log('No mostrar mensaje de zoom')
        minScale = null
        VueInst.$eventHub.$emit('mostrarMensajeZoom', false)
      }
    }
    /* if (layer.parent.visible === false) {
      layer.parent.visible = value
    } */
  }
  layer.visible = value
}

export const setLocalBaseMapWebMap = async (map) => {
  // Guardar el mapa base del Geoportal
  baseMapWM = map.basemap
}

export const loadLayerListWebMap = async () => {
  await map.when(async function () {
    // Cuando termina el mapView de crearse
    await mapView.when(async function () {
      // Cuando termina el map de crearse
      baseMapWM = map.basemap
      // let layersWebMap = [] // Lista de capas definitivas anidadas
      const allLayers = map.allLayers // Lista de todas las capas del mapa sin anidaciones
      let contadorPos = 0
      const operationalLayers = {
        id: 'operationalLayer',
        title: 'Delimitación territorial',
        visible: true,
        url: undefined,
        opacity: 1,
        position: 999,
        addListLayer: true,
        type: 'groupLayer',
        subLayers: [],
      }

      const emergencyLayers = {
        id: 'emergencyLayers',
        title: 'Emergencias',
        visible: false,
        url: undefined,
        opacity: 1,
        position: 999,
        addListLayer: true,
        type: 'groupLayer',
        subLayers: [],
      }

      // Recorro todas las capas
      for (let i = 0; i < allLayers.length; i++) {
        // PRIMER NIVEL
        const item = allLayers.items[i]
        contadorPos++
        // PRIMER NIVEL
        const layerNivel1 = {
          id: item.id,
          title: item.title,
          visible: item.visible,
          url: item.url,
          opacity: item.opacity,
          position: contadorPos,
          addListLayer: true,
          type: item.type + 'Layer',
        }

        // Si la capa del NIVEL1 es de tipo GROUP tendrá subcapas
        if (item.type === 'group') {
          let hijasNivel1 = [] // Lista de capas hijas del nivel 1 (capas nivel 2) con anidaciones
          const hijasNivel1WebMap = item.layers.items // Lista de hijas del nivel 1 definidas en el webMap

          // Recorro las hijas del primer nivel
          for (let j = 0; j < hijasNivel1WebMap.length; j++) {
            // SEGUNDO NIVEL
            const itemNivel2 = hijasNivel1WebMap[j]

            const layerNivel2 = {
              id: itemNivel2.id,
              title: itemNivel2.title,
              visible: false,
              url: itemNivel2.url,
              opacity: itemNivel2.opacity,
              position: j,
              addListLayer: true,
              type: itemNivel2.type + 'Layer',
            }

            // Si la capa del NIVEL2 es de tipo GROUP tendrá subcapas
            if (itemNivel2.type && itemNivel2.type === 'group') {
              layerNivel1.visible = false // Y su padre estará desactivado

              const hijasNivel2 = [] // Lista de capas hijas del nivel 2 (capas nivel 3) con anidaciones
              const hijasNivel2WebMap = itemNivel2.layers.items // Lista de hijas del nivel 2 definidas en el webMap

              // Recorro las hijas del segundo nivel
              for (let k = 0; k < hijasNivel2WebMap.length; k++) {
                // TERCER NIVEL
                const itemNivel3 = hijasNivel2WebMap[k]

                const layerNivel3 = {
                  id: itemNivel3.id,
                  title: itemNivel3.title,
                  visible: false,
                  url: itemNivel3.url,
                  opacity: itemNivel3.opacity,
                  position: k,
                  addListLayer: true,
                  type: itemNivel3.type + 'Layer',
                  maxScale: itemNivel3.maxScale,
                  minScale: itemNivel3.minScale,
                }

                if (itemNivel3.type && itemNivel3.type === 'group') {
                  // CUARTO NIVEL
                  // layerNivel2.visible = false // Y su padre estará desactivado
                  const hijasNivel3 = [] // Lista de capas hijas del nivel 3 (capas nivel 4) con anidaciones
                  const hijasNivel3WebMap = itemNivel3.layers.items // Lista de hijas del nivel 3 definidas en el webMap

                  for (let l = 0; l < hijasNivel3WebMap.length; l++) {
                    const itemNivel4 = hijasNivel3WebMap[l]
                    const layerNivel4 = {
                      id: itemNivel4.id,
                      title: itemNivel4.title,
                      visible: false,
                      url: itemNivel4.url,
                      opacity: itemNivel4.opacity,
                      position: k,
                      addListLayer: true,
                      type: itemNivel4.type + 'Layer',
                      maxScale: itemNivel4.maxScale,
                      minScale: itemNivel4.minScale,
                    }

                    if (itemNivel4.type && itemNivel4.type === 'group') {
                      // QUINTO NIVEL
                      // layerNivel3.visible = false // Y su padre estará desactivado
                      const hijasNivel4 = [] // Lista de capas hijas del nivel 4 (capas nivel 5) con anidaciones
                      const hijasNivel4WebMap = itemNivel4.layers.items // Lista de hijas del nivel 4 definidas en el webMap

                      for (let m = 0; m < hijasNivel4WebMap.length; m++) {
                        const itemNivel5 = hijasNivel4WebMap[m]
                        const layerNivel5 = {
                          id: itemNivel5.id,
                          title: itemNivel5.title,
                          visible: false,
                          url: itemNivel5.url,
                          opacity: itemNivel5.opacity,
                          position: k,
                          addListLayer: true,
                          type: itemNivel5.type + 'Layer',
                          maxScale: itemNivel5.maxScale,
                          minScale: itemNivel5.minScale,
                        }

                        if (itemNivel5.type && itemNivel5.type === 'group') {
                          // SEXTO NIVEL
                          // layerNivel4.visible = false // Y su padre estará desactivado
                          const hijasNivel5 = [] // Lista de capas hijas del nivel 5 (capa nivel 6) con anidaciones
                          const hijasNivel5WebMap = itemNivel5.layers.items // Lista de hijas del nivel 5 definidas en el webMap

                          for (let n = 0; n < hijasNivel5WebMap.length; n++) {
                            const itemNivel6 = hijasNivel5WebMap[n]
                            const layerNivel6 = {
                              id: itemNivel6.id,
                              title: itemNivel6.title,
                              visible: false,
                              url: itemNivel6.url,
                              opacity: itemNivel6.opacity,
                              position: k,
                              addListLayer: true,
                              type: itemNivel6.type + 'Layer',
                              maxScale: itemNivel6.maxScale,
                              minScale: itemNivel6.minScale,
                            }

                            if (
                              itemNivel6.type &&
                              itemNivel6.type === 'group'
                            ) {
                              // SEPTIMO NIVEL
                              // layerNivel5.visible = false // Y su padre estará desactivado
                              const hijasNivel6 = [] // Lista de capas hijas del nivel 6 (capa nivel 7) con anidaciones
                              const hijasNivel6WebMap = itemNivel6.layers.items // Lista de hijas del nivel 6 definidas en el webMap

                              for (let o = 0; o < hijasNivel6WebMap; o++) {
                                const itemNivel7 = hijasNivel6WebMap[o] // Lista de capas hijas del nivel 6 (capa del nivel 7) con anidaciones
                                const layerNivel7 = {
                                  id: itemNivel7.id,
                                  title: itemNivel7.title,
                                  visible: false,
                                  url: itemNivel7.url,
                                  opacity: itemNivel7.opacity,
                                  position: k,
                                  addListLayer: true,
                                  type: itemNivel7.type + 'Layer',
                                  maxScale: itemNivel7.maxScale,
                                  minScale: itemNivel7.minScale,
                                }

                                if (
                                  itemNivel7.type &&
                                  itemNivel7.type === 'group'
                                ) {
                                  // OCTAVO NIVEL
                                  // layerNivel6.visible = false // Y su padre estará desactivado
                                  const hijasNivel7 = [] // Lista de capas hijas del nivel 7 (capa nivel 8) con anidaciones
                                  const hijasNivel7WebMap =
                                    itemNivel7.layers.items // Lista de hijas del nivel 7 definidas en el webMap

                                  for (let p = 0; p < hijasNivel7WebMap; p++) {
                                    const itemNivel8 = hijasNivel7WebMap[p]
                                    const layerNivel8 = {
                                      id: itemNivel8.id,
                                      title: itemNivel8.title,
                                      visible: false,
                                      url: itemNivel8.url,
                                      opacity: itemNivel8.opacity,
                                      position: k,
                                      addListLayer: true,
                                      type: itemNivel8.type + 'Layer',
                                      maxScale: itemNivel8.maxScale,
                                      minScale: itemNivel8.minScale,
                                    }

                                    if (
                                      itemNivel8.type &&
                                      itemNivel8.type === 'group'
                                    ) {
                                      //NOVENO NIVEL
                                      // layerNivel7.visible = false // Y su padre estará desactivado
                                      const hijasNivel8 = [] // Lista de capas hijas del nivel 8 (capa nivel 9) con anidaciones
                                      const hijasNivel8WebMap =
                                        itemNivel8.layers.items // Lista de hijas del nivel 8 definidas en el webMap

                                      for (
                                        let q = 0;
                                        q < hijasNivel8WebMap.length;
                                        q++
                                      ) {
                                        const itemNivel9 = hijasNivel8WebMap[q]
                                        const layerNivel9 = {
                                          id: itemNivel9.id,
                                          title: itemNivel9.title,
                                          visible: false,
                                          url: itemNivel9.url,
                                          opacity: itemNivel9.opacity,
                                          position: k,
                                          addListLayer: true,
                                          type: itemNivel9.type + 'Layer',
                                          maxScale: itemNivel9.maxScale,
                                          minScale: itemNivel9.minScale,
                                        }

                                        if (
                                          itemNivel9.type &&
                                          itemNivel9.type === 'group'
                                        ) {
                                          // DECIMO NIVEL
                                          // layerNivel8.visible = false // Y su padre estará desactivado
                                          const hijasNivel9 = [] // Lista de capas hijas del nivel 9 (capa nivel 10) con anidaciones
                                          const hijasNivel9WebMap =
                                            itemNivel9.layers.items // Lista de hijas del nivel 9 definidas en el webMap

                                          for (
                                            let r = 0;
                                            r < hijasNivel9WebMap.length;
                                            r++
                                          ) {
                                            const itemNivel10 =
                                              hijasNivel9WebMap[r]

                                            if (
                                              itemNivel10.type &&
                                              itemNivel10.type === 'group'
                                            ) {
                                              // UNDECIMO NIVEL (FIN)
                                              layerNivel9.visible = false // Y su padre estará desactivado
                                            }

                                            i = i + hijasNivel10WebMap.length // Avanzo la lista de capas del web map tantas posiciones como hijas tiene el nivel 10
                                          }

                                          i = i + hijasNivel9WebMap.length // Avanzo la lista de capas del web map tantas posiciones como hijas tiene el nivel 9
                                          layerNivel9.layers = hijasNivel9
                                        }

                                        hijasNivel8.push(layerNivel9)
                                      }

                                      i = i + hijasNivel8WebMap.length // Avanzo la lista de capas del web map tantas posiciones como hijas tiene el nivel 8
                                      layerNivel8.layers = hijasNivel8
                                    }

                                    hijasNivel7.push(layerNivel8)
                                  }

                                  i = i + hijasNivel7WebMap.length // Avanzo la lista de capas del web map tantas posiciones como hijas tiene el nivel 7
                                  layerNivel7.layers = hijasNivel7
                                }

                                hijasNivel6.push(layerNivel7)
                              }

                              i = i + hijasNivel6WebMap.length // Avanzo la lista de capas del web map tantas posiciones como hijas tiene el nivel 6
                              layerNivel6.layers = hijasNivel6
                            }

                            hijasNivel5.push(layerNivel6)
                          }

                          i = i + hijasNivel5WebMap.length // Avanzo la lista de capas del web map tantas posiciones como hijas tiene el nivel 5
                          layerNivel5.layers = hijasNivel5
                        }

                        hijasNivel4.push(layerNivel5)
                      }

                      i = i + hijasNivel4WebMap.length // Avanzo la lista de capas del web map tantas posiciones como hijas tiene el nivel 4
                      hijasNivel4.reverse()
                      layerNivel4.layers = hijasNivel4
                    }

                    hijasNivel3.push(layerNivel4)
                  }
                  i = i + hijasNivel3WebMap.length // Avanzo la lista de capas del web map tantas posiciones como hijas tiene el nivel 3
                  hijasNivel3.reverse()
                  layerNivel3.layers = hijasNivel3
                }

                hijasNivel2.push(layerNivel3)
              }
              hijasNivel2.reverse() // TODO: Esto sobraría supongo
              layerNivel2.layers = hijasNivel2

              i = i + hijasNivel2WebMap.length // Avanzo la lista de capas del web map tantas posiciones como hijas tiene el nivel 2
            }

            hijasNivel1.push(layerNivel2) // Almacenamos el nivel2 dentro de las hijas del nivel1
          }

          hijasNivel1 = hijasNivel1.reverse() // TODO: Quitar ??
          i = i + hijasNivel1WebMap.length // Avanzo la lista de capas del web map tantas posiciones como hijas tiene el nivel 1
          layerNivel1.subLayers = hijasNivel1 // Almaceno las capas hijas en el NIVEL 1
        } else {
          if (item.title !== null && item.type !== 'vector-tile') {
            if (item.id !== 'ContornoAnillos') {
              item.visible = false
            }

            // Capa emergencias
            // TODO: controlar nombre de capa en PRE Y PRO
            if (item.title === EmergenciaArcGISHelper.EMERGENCIA_LAYER_TITLE) {
              const capaEmergencias = {
                id: item.id,
                title: item.title,
                visible: false,
                url: item.url,
                opacity: item.opacity,
                position: 0,
                addListLayer: true,
                type: item.type + 'Layer',
              }

              emergencyLayers.subLayers.push(capaEmergencias)
              // Almaceno la capa del primer nivel en la store
              await store.dispatch('map/addLayersPrueba', emergencyLayers)
            }

            const layerNivelop = {
              id: item.id,
              title: item.title,
              visible: item.visible,
              url: item.url,
              opacity: item.opacity,
              position: i,
              addListLayer: true,
              type: item.type + 'Layer',
            }

            if (
              layerNivelop.title !==
              EmergenciaArcGISHelper.EMERGENCIA_LAYER_TITLE
            ) {
              operationalLayers.subLayers.push(layerNivelop)
            }
          }
        }

        // Almaceno la capa del primer nivel en la store
        await store.dispatch('map/addLayersPrueba', layerNivel1)
        // }
      }

      // Comprobar visibilidad capas operacionales
      if (operationalLayers.subLayers) {
        let visible = false
        for (let i = 0; i < operationalLayers.subLayers.length; i++) {
          const layer = operationalLayers.subLayers[i]
          if (
            layer.title !== null &&
            layer.type != 'vector-tileLayer' &&
            layer.visible
          ) {
            visible = true
          }
        }

        // Visibilidad abuelo
        operationalLayers.visible = visible
      }

      // Almacenar capa OperationalLayers
      await store.dispatch('map/addLayersPrueba', operationalLayers)
    })
  })
}

/* async function initExaggeratedElevationLayerSubClass () {
  try {
    const [ElevationLayer, BaseElevationLayer] = await loadModules([
      'esri/layers/ElevationLayer',
      'esri/layers/BaseElevationLayer',
    ])

    ExaggeratedElevationLayerSubClass = BaseElevationLayer.createSubclass({
      properties: {
        id: 'elevation-layer',
        exaggeration: 1,
      },

      // The load() method is called when the layer is added to the map prior to it being rendered in the view.
      load: function () {
        this._elevation = new ElevationLayer({
          id: 'elevation-layer',
          url: 'https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer',
        })

        // wait for the elevation layer to load before resolving load()
        this.addResolvingPromise(
          this._elevation.load().then(() => {
            // get tileInfo, spatialReference and fullExtent from the elevation service
            // this is required for elevation services with a custom spatialReference
            this.tileInfo = this._elevation.tileInfo
            this.spatialReference = this._elevation.spatialReference
            this.fullExtent = this._elevation.fullExtent
          })
        )

        return this
      },

      // Fetches the tile(s) visible in the view
      fetchTile: function (level, row, col, options) {
        // calls fetchTile() on the elevationlayer for the tiles
        // visible in the view
        return this._elevation.fetchTile(level, row, col, options).then(
          function (data) {
            const exaggeration = this.exaggeration
            // `data` is an object that contains the
            // the width and the height of the tile in pixels,
            // and the values of each pixel
            for (let i = 0; i < data.values.length; i++) {
              // Multiply the given pixel value
              // by the exaggeration value
              data.values[i] = data.values[i] * exaggeration
            }

            return data
          }.bind(this)
        )
      },
    })
  } catch (err) {
    VueInst.$log.error(err)
  }
} */

// Limita el extent del mapa volviendo a la ultima coordenada si se sale de la geometría del extent
async function limitMapExtent() {
  const [Extent, SpatialReference] = await loadModules([
    'esri/geometry/Extent',
    'esri/geometry/SpatialReference',
  ])
  const extent = constants.mapExtent

  var initialExtent = mapView.extent

  if (mapView.extent === null) {
    mapView.extent = new Extent({
      xmin: extent.xmin,
      ymin: extent.ymin,
      xmax: extent.xmax,
      ymax: extent.ymax,
      spatialReference: new SpatialReference({ wkid: extent.wkid }),
    })

    initialExtent = mapView.extent
  }

  mapView.watch('stationary', function (event) {
    if (!event) {
      return
    }

    var currentCenter = mapView.extent.center
    if (!initialExtent.contains(currentCenter)) {
      var newCenter = mapView.extent.center

      if (currentCenter.x < initialExtent.xmin) {
        newCenter.x = initialExtent.xmin
      }
      if (currentCenter.x > initialExtent.xmax) {
        newCenter.x = initialExtent.xmax
      }
      if (currentCenter.y < initialExtent.ymin) {
        newCenter.y = initialExtent.ymin
      }
      if (currentCenter.y > initialExtent.ymax) {
        newCenter.y = initialExtent.ymax
      }
      mapView.goTo(newCenter)
    }
  })
}

export const use2D = async () => {
  // Crea el viewMap 2d
  try {
    const [MapView, Extent, SpatialReference] = await loadModules([
      'esri/views/MapView',
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference',
    ])
    //          -578971.1131,4835743.1849,-239590.7136,5050989.8545

    const extent = constants.mapExtent
    const newMapView = new MapView({
      map: map,
      zoom: constants.initialZoom,
      container: 'mapNode',
      extent: new Extent({
        xmin: extent.xmin,
        ymin: extent.ymin,
        xmax: extent.xmax,
        ymax: extent.ymax,
        spatialReference: new SpatialReference({ wkid: extent.wkid }),
      }),
      constraints: {
        rotationEnabled: false,
        maxZoom: constants.maxZoom,
        minZoom: constants.minZoom,
      },
    })

    newMapView.ui.remove('zoom')

    onMapViewChange(newMapView)
  } catch (err) {
    VueInst.$log.error(err)
  }
}
// export const use3D = async () => { // Comentar función (NO SE USA)
//   try {
//     const [SceneView] = await loadModules(['esri/views/SceneView'])

//     closeMapPopups() // Si no se deseleccionan los graficos antes de cambiar de mapview, da error

//     let newMapView = new SceneView({
//       map: map,
//       container: 'mapNode',
//       constraints: {
//         rotationEnabled: false,
//       },
//     })

//     // newMapView.ui.components = (['attribution'])
//     let navigation = newMapView.ui.find('navigation-toggle')
//     // let compass = newMapView.ui.find('compass')
//     newMapView.ui.remove('zoom')
//     newMapView.ui.remove('compass')

//     newMapView.ui.move(navigation, 'top-right')
//     // newMapView.ui.move(compass, 'top-right')

//     newMapView.ui.padding = { top: 70, left: 0, right: 65, bottom: 0 }

//     onMapViewChange(newMapView)
//   } catch (err) {
//     VueInst.$log.error(err)
//   }
// }

async function onMapViewChange(newMapView) {
  /* let extent =  mapView && mapView.extent
      ? mapView.extent
      :  store.getters["map/paramsComunidad"].EXTENT; */

  // Destruir contenedor anterior
  if (mapView) {
    // mapView.container = null
    mapView = null
  }
  mapView = newMapView

  const extent = constants.mapExtent
  setExtentMap(extent)

  clearMeasurement()

  // setSearchWidget()
  updateSearchWidgetView()

  assignMapViewEvents()

  limitMapExtent()

  createOverViewMap()

  await createLegend()

  initShareMapSketchViewModel()

  await mapView.ui.add('infoDiv', 'bottom-left')
}

export const getMapView = () => {
  return mapView
}
// #endregion

// #region MAPVIEW EVENTS
let graphicHighlight = null
async function assignMapViewEvents() {
  // Evento Emit ChangeExtent (Compartir mapa)
  let pointerDown = false

  mapView.watch('extent', (event) => {
    if (event) {
      store.dispatch('shareMap/changeMapExtent', {
        xmax: event.xmax,
        xmin: event.xmin,
        ymax: event.ymax,
        ymin: event.ymin,
        wkid: event.spatialReference.wkid,
      })
    }
  })

  // Contraer o expandir searchWidget en funcion del tamaño del mapView
  // mapView.watch('heightBreakpoint', (event) => {

  //   mobileSize = mapView.heightBreakpoint === 'xsmall' || mapView.widthBreakpoint === 'xsmall'
  //   let smallSize = mapView.heightBreakpoint === 'small' || mapView.widthBreakpoint === 'small'
  //   if (mobileSize) {
  //     // Boton searchWidget
  //     bgExpand.collapse()
  //     let widgetMobile = document.getElementsByClassName('esri-widget--button')
  //     widgetMobile[0].style.height = '32px'
  //     widgetMobile[0].style.width = '32px'

  //     // Icono searchWidget
  //     let iconWidget
  //     setTimeout(function () {
  //       iconWidget = document.getElementsByClassName('esri-collapse__icon esri-icon-search')
  //       if (iconWidget[0]) {
  //         iconWidget[0].style.fontSize = '18px'
  //       }
  //     })

  //     // Leyenda
  //     let legendWidget
  //     setTimeout(function () {
  //       legendWidget = document.getElementsByClassName('esri-legend')
  //       if (legendWidget[0]) {
  //         legendWidget[0].style.maxHeight = '150px'
  //         legendWidget[0].style.width = 'auto'
  //       }

  //     }, 100)

  //   } else {
  //     // Boton searchWidget
  //     bgExpand.expand()
  //     let widgetMobile = document.getElementsByClassName('esri-widget--button')
  //     widgetMobile[0].style.height = '40px'
  //     widgetMobile[0].style.width = '40px'

  //     // Icono searchWidget
  //     let iconWidget
  //     setTimeout(function () {
  //       iconWidget = document.getElementsByClassName('esri-collapse__icon esri-icon-search')
  //       if (iconWidget[0]) {
  //         iconWidget[0].style.fontSize = '24px'
  //       }
  //     })

  //     // Leyenda
  //     let legendWidget
  //     setTimeout(function () {
  //       legendWidget = document.getElementsByClassName('esri-legend')
  //       if (legendWidget[0]) {
  //         legendWidget[0].style.maxHeight = '350px'
  //         legendWidget[0].style.width = '300px'
  //       }

  //     }, 100)
  //   }
  // })

  window.addEventListener('resize', () => {
    const anchoVentana = document.documentElement.offsetWidth

    if (legend && !legend.visible) {
      // Ocultar cabecera azul de la leyenda
      hideBlueHeaderLegend()
    }

    if (anchoVentana < 600) {
      // Movil
      // Boton searchWidget
      bgExpand && bgExpand.collapse()
      let widgetMobile
      setTimeout(function () {
        widgetMobile = document.getElementsByClassName('esri-widget--button')
        if (widgetMobile[0]) {
          widgetMobile[0].style.height = '32px'
          widgetMobile[0].style.width = '32px'
        }
      }, 100)

      // Icono searchWidget
      let iconWidget
      setTimeout(function () {
        iconWidget = document.getElementsByClassName(
          'esri-collapse__icon esri-icon-search',
        )
        if (iconWidget[0]) {
          iconWidget[0].style.fontSize = '18px'
        }
      }, 100)

      // Widget de leyenda
      let legendWidget
      setTimeout(function () {
        legendWidget = document.getElementById('infoDiv')
        if (legendWidget) {
          legendWidget.style.maxHeight = '150px'
          legendWidget.style.width = 'auto'
        }
      }, 100)

      // Ubicacion ui izquierda
      let uiLeft
      setTimeout(function () {
        uiLeft = document.getElementsByClassName('esri-ui-bottom-left')
        if (uiLeft[0]) {
          uiLeft[0].style.bottom = '20px'
        }
      }, 100)

      // Mensaje de zoom en mapa
      const divMensaje = document.getElementById('above-map-msg')
      if (divMensaje) {
        divMensaje.style.width = '200px'
        divMensaje.style.fontSize = '16px'
      }

      // OverViewMap -> Ocultar
      let overViewDiv
      setTimeout(function () {
        overViewDiv = document.getElementById('overviewDiv')
        if (overViewDiv) {
          overViewDiv.style.visibility = 'hidden'
        }
      })
    } else if (anchoVentana >= 600) {
      // PC
      bgExpand && bgExpand.expand()
      // Boton searchWidget
      let widgetDesktop
      setTimeout(function () {
        widgetDesktop = document.getElementsByClassName('esri-widget--button')
        if (widgetDesktop[0]) {
          widgetDesktop[0].style.height = '40px'
          widgetDesktop[0].style.width = '40px'
        }
      }, 100)

      // Icono searchWidget
      let iconWidget
      setTimeout(function () {
        iconWidget = document.getElementsByClassName(
          'esri-collapse__icon esri-icon-search',
        )
        if (iconWidget[0]) {
          iconWidget[0].style.fontSize = '24px'
        }
      }, 100)

      // Widget de leyenda
      let legendWidget
      setTimeout(function () {
        legendWidget = document.getElementById('infoDiv')
        // legendWidget.style.maxHeight = '350px'
        if (legendWidget) {
          legendWidget.style.maxHeight = 'calc(100vh - 250px)'
          legendWidget.style.width = '300px'
        }
      }, 100)

      // Ubicacion ui izquierda
      let uiLeft
      setTimeout(function () {
        uiLeft = document.getElementsByClassName('esri-ui-bottom-left')
        // console.log('uiLeft: ', uiLeft)
        if (uiLeft[0]) {
          uiLeft[0].style.bottom = '20px'
        }
      }, 100)

      // Mensaje de zoom en mapa
      const divMensaje = document.getElementById('above-map-msg')
      if (divMensaje) {
        divMensaje.style.width = '400px'
        divMensaje.style.fontSize = '25px'
      }

      // OverViewMap -> Mostrar
      let overViewDiv
      setTimeout(function () {
        overViewDiv = document.getElementById('overviewDiv')
        if (overViewDiv) {
          overViewDiv.style.visibility = 'visible'
        }
      })
    }
  })

  // mapView.watch('widthBreakpoint', (event) => {

  //   // window.addEventListener('resize', () => {
  //   //   let anchoVentana = document.documentElement.offsetWidth
  //   //   console.log('anchoVentanaoo: ', anchoVentana)
  //   // })

  //   mobileSize = mapView.heightBreakpoint === 'xsmall' || mapView.widthBreakpoint === 'xsmall'

  //   console.log('MapView: ', mapView.widthBreakpoint)

  //   let anchoVentana = document.documentElement.offsetWidth
  //   console.log('anchoVentana: ', anchoVentana)

  //   let smallSize = mapView.heightBreakpoint === 'small' || mapView.widthBreakpoint === 'small'
  //   if (anchoVentana < 600) {
  //     // Boton searchWidget
  //     bgExpand.collapse()
  //     let widgetMobile = document.getElementsByClassName('esri-widget--button')
  //     widgetMobile[0].style.height = '32px'
  //     widgetMobile[0].style.width = '32px'

  //     // Icono searchWidget
  //     let iconWidget
  //     setTimeout(function () {
  //       iconWidget = document.getElementsByClassName('esri-collapse__icon esri-icon-search')
  //       if (iconWidget[0]) {
  //         iconWidget[0].style.fontSize = '18px'
  //       }
  //     }, 100)

  //     // Widget de leyenda
  //     let legendWidget
  //     setTimeout(function () {
  //       legendWidget = document.getElementsByClassName('esri-legend')
  //       if (legendWidget[0]) {
  //         legendWidget[0].style.maxHeight = '150px'
  //         legendWidget[0].style.width = 'auto'
  //       }

  //     }, 100)

  //     // Ubicacion ui izquierda
  //     let uiLeft
  //     setTimeout(function () {
  //       uiLeft = document.getElementsByClassName('esri-ui-bottom-left')
  //       if (uiLeft[0]) {
  //         uiLeft[0].style.bottom = '20px'
  //       }
  //     }, 100)

  //     // Mensaje de zoom en mapa
  //     let divMensaje = document.getElementById('above-map-msg')
  //     if (divMensaje) {
  //       divMensaje.style.width = '200px'
  //       divMensaje.style.fontSize = '16px'
  //     }

  //   } else if (anchoVentana > 600){
  //     bgExpand.expand()
  //     // Boton searchWidget
  //     let widgetDesktop = document.getElementsByClassName('esri-widget--button')
  //     widgetDesktop[0].style.height = '40px'
  //     widgetDesktop[0].style.width = '40px'

  //     // Icono searchWidget
  //     let iconWidget
  //     setTimeout(function () {
  //       iconWidget = document.getElementsByClassName('esri-collapse__icon esri-icon-search')
  //       if (iconWidget[0]) {
  //         iconWidget[0].style.fontSize = '24px'
  //       }
  //     }, 100)

  //     // Widget de leyenda
  //     let legendWidget
  //     setTimeout(function () {
  //       legendWidget = document.getElementsByClassName('esri-legend')
  //       if (legendWidget[0]) {
  //         legendWidget[0].style.maxHeight = '350px'
  //         legendWidget[0].style.width = '300px'
  //       }

  //     }, 100)

  //     // Ubicacion ui izquierda
  //     let uiLeft
  //     setTimeout(function () {
  //       uiLeft = document.getElementsByClassName('esri-ui-bottom-left')
  //       // console.log('uiLeft: ', uiLeft)
  //       if (uiLeft[0]) {
  //         uiLeft[0].style.bottom = '20px'
  //       }
  //     }, 100)

  //     // Mensaje de zoom en mapa
  //     let divMensaje = document.getElementById('above-map-msg')
  //     if (divMensaje) {
  //       divMensaje.style.width = '400px'
  //       divMensaje.style.fontSize = '25px'
  //     }
  //   }
  // })

  // Scale
  mapView.watch('scale', (event) => {
    VueInst.$eventHub.$emit('escalaMap', event)

    closeMapPopups()
  })

  // Legend
  mapView.watch('legend', () => {
    // console.log('Cambia la leyenda en los eventos del mapa: ', legend.visible)
  })

  // Move mouse -> update coordinates
  mapView.on('pointer-move', (event) => {
    if (!pointerDown) {
      sendCoordinates(mapView.toMap(event))
    }
  })

  mapView.on('click', (event) => {
    /* (event.mapPoint.x, event.mapPoint.y).then(addres => {
      console.log(addres)
    }) */

    // Enviar coordenadas para representarlas en el MapaWeb
    sendCoordinates(mapView.toMap(event))

    // Right click -> Context Menu
    if (event.button !== 0) {
      const position = {
        x: event.x,
        y: event.y,
      }

      const attributes = {}
      attributes.X = event.mapPoint.x
      attributes.Y = event.mapPoint.y
      attributes.LATITUDE = event.mapPoint.latitude
      attributes.LONGITUDE = event.mapPoint.longitude
      VueInst.$eventHub.$emit('showContextMenu', {
        attributes: attributes,
        position: position,
      })
    }

    // Click -> tooltip layer
    mapView
      .hitTest(event)
      .then((response) => {
        const showCustomTooltip = false
        let showSimpleTooltip = false

        const graphicsFilter = response.results.filter((x) => {
          if (event.button === 0) {
            const layerId = x.graphic.layer.id

            // Capas con tooltip custom
            const layersCustomTooltip = [
              'layer-medios-activos',
              'layer-medios-inactivos',
              'layer-track',
              'layer-incendios',
            ]
            let showCustomTooltip = layersCustomTooltip.includes(layerId)
            if (
              layerId === 'layer-track' &&
              x.graphic.symbol.type === 'simple-line'
            ) {
              // Si es la linea del track, no mostrar
              showCustomTooltip = false
            }

            if (showCustomTooltip) {
              return true
            }

            // Capas con coordenadas
            // ['layer-avion', 'layer-helicoptero', 'layer-pick-up']
            const layersWithPopup = [
              'layer-autobomba',
              'layer-bomberos',
              'layer-BRIF',
              'layer-central-comunicaciones',
              'layer-repetidor-comunicaciones',
              'layer-reten',
              'layer-SOS',
              'layer-torreta-vigilancia',
              'layer-estaciones-meteorologicas',
              'layer-punto-agua-aer',
              'layer-punto-agua-ter',
              'layer-punto-encuentro',
              'layer-montes-utilidad',
              'layer-plan-operaciones',
              'layer-emergencias',
            ]

            showSimpleTooltip = layersWithPopup.includes(layerId)
            return showSimpleTooltip
          }
        })

        if (graphicsFilter.length > 0) {
          const graphic = graphicsFilter[0].graphic

          mapView.whenLayerView(graphic.layer).then((layerView) => {
            graphicHighlight = layerView.highlight(graphic)
          })

          const position = {
            x: event.x,
            y: event.y + 3,
          }

          const attributes = JSON.parse(JSON.stringify(graphic.attributes))

          if (showCustomTooltip) {
            attributes.LATITUDE = graphic.geometry.latitude
            attributes.LONGITUDE = graphic.geometry.longitude
            // VueInst.$eventHub.$emit('showPopup', { capa: graphic.layer.id, attributes: attributes, position: position })
          }

          if (attributes) {
            const popupData = {
              layer: graphic.layer.id,
              attributes: attributes,
              position: position,
            }
            VueInst.$eventHub.$emit('showPopup', popupData)
          }
        }
      })
      .catch((err) => {
        VueInst.$log.error(err)
        // VueInst.$eventHub.$emit('showPopup', false)
      })
  })

  mapView.on('pointer-up', () => {
    pointerDown = false
  })
  mapView.on('pointer-down', () => {
    pointerDown = true
    closeMapPopups()
  })

  // Popup functions
  mapView.popup.on('trigger-action', (event) => {
    // TODO: Esto es para los popups de ArcGIS con los medios, no se usa
    if (event.action.id === 'measure-this') {
      // Do nothing
    }
  })

  // Visualizar capas dependiendo del zoom
  mapView.watch('scale', function (newValue) {
    // console.log('Escala mapa: ', newValue, ', minLayerScale: ', minScale)
    if (minScale) {
      if (parseFloat(newValue) < parseFloat(minScale)) {
        // console.log('No mostramos el mansaje de zoom')
        VueInst.$eventHub.$emit('mostrarMensajeZoom', false)
      } else {
        // console.log('Mostramos el mensaje de zoom')
        VueInst.$eventHub.$emit('mostrarMensajeZoom', true)
      }
    }
  })
}

// export const checkActualLayerScale = async (minLayerScale) => {
//   // Visualizar capas dependiendo del zoom
//   mapView.watch('scale', function (newValue) {
//     console.log('Escala mapa: ', newValue, ', minLayerScale: ', minLayerScale)
//     if (parseFloat(newValue) < parseFloat(minLayerScale)) {
//       console.log('No mostramos el mansaje de zoom')
//     } else {
//       console.log('Mostramos el mensaje de zoom')
//     }
//   })
// }

function hideBlueHeaderLegend() {
  let widgetHeaderLegend
  setTimeout(function () {
    widgetHeaderLegend = document.getElementsByClassName(
      'esri-ui-bottom-left esri-ui-corner',
    )
    if (widgetHeaderLegend[0]) {
      // Quitar background color azul
      widgetHeaderLegend[0].style.backgroundColor = 'transparent'
    }
  }, 100)
}

function closeMapPopups() {
  // Cierra el popup / context menu y deselecciona el grafico seleccionado
  VueInst.$eventHub.$emit('showPopup', false)
  VueInst.$eventHub.$emit('showContextMenu', false)

  if (graphicHighlight) {
    graphicHighlight.remove()
    graphicHighlight = null
  }
}
function sendCoordinates(point) {
  if (point) {
    const X = point.x.toFixed(2)
    const Y = point.y.toFixed(2)
    const LAT = point.latitude.toFixed(4).toString().replace('.', ',')
    const LON = point.longitude.toFixed(4).toString().replace('.', ',')
    const LATGMS = convertirLatLonGMS(point.latitude, 'LATITUDE').valor
    const LONGMS = convertirLatLonGMS(point.longitude, 'LONGITUDE').valor
    const ETRS89 = convertirETRS89(X, Y)
    const X_ETRS89 = ETRS89[0].toFixed(0).toString().replace('.', ',')
    const Y_ETRS89 = ETRS89[1].toFixed(0).toString().replace('.', ',')

    const coordenadas = {
      X: X,
      Y: Y,
      LATITUDE: LAT,
      LONGITUDE: LON,
      LATITUDE_GMS: LATGMS,
      LONGITUDE_GMS: LONGMS,
      X_ETRS89: X_ETRS89,
      Y_ETRS89: Y_ETRS89,
    }
    VueInst.$eventHub.$emit('coordenadasMap', coordenadas)
  }
}

export const calculateAddress = async (x, y) => {
  const [locator] = await loadModules(['esri/rest/locator'])

  // location
  const serviceUrl =
    'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer'
  // let deferred = Q.defer();
  const params = {
    location: {
      spatialReference: { latestWkid: 3857, wkid: 102100 },
      x: x,
      y: y,
    },
  }
  const response = await locator.locationToAddress(serviceUrl, params)

  // console.log(response)
  if (response) {
    return response.address
  } else {
    return ''
  }
}
// #endregion

// #region LAYERS
export const reorderLayers = async () => {
  const layersAdd = store.getters['map/layersAdd']
  const layersMap = map.layers.items

  for (let i = 0; i < layersAdd.length; i++) {
    const layerInMap = layersMap.find((x) => x.id === layersAdd[i].id)

    if (layerInMap) {
      map.reorder(layerInMap, layersAdd[i].posicion)
    }
  }
}
export const createLayers = async () => {
  const thePromises = []
  const layerStore = store.getters['map/layersAdd']

  for (let i = 0; i < layerStore.length; i++) {
    const layerItem = layerStore[i]

    /* if (layerItem.type === 'WMS') {
      thePromises.push(createWMS(layerItem).then((layer) => {
        map.add(layer)
        store.dispatch('addLayer', {id: layerItem.id, title: layerItem.title, visible: layerItem.visible, posicion: layerItem.posicion, addListLayer: layerItem.addListLayer})
      }))
    } else if (layerItem.type === 'wmtsLayer') {
      thePromises.push(createWMTS(layerItem).then((layer) => {
        map.add(layer)
        store.dispatch('addLayer', {id: layerItem.id, title: layerItem.title, visible: layerItem.visible, posicion: layerItem.posicion, addListLayer: layerItem.addListLayer})
      }))
    } else if (layerItem.type === 'geoJsonPuntos') {
      thePromises.push(createGeoJsonPuntos(layerItem).then((layer) => {
        map.add(layer)
        store.dispatch('addLayer', {id: layerItem.id, title: layerItem.title, visible: layerItem.visible, posicion: layerItem.posicion, addListLayer: layerItem.addListLayer})
      }))
    } else if (layerItem.type === 'geoJson') {
      // thePromises.push(createGeoJson(layerItem).then((layer) => {
      thePromises.push(createGeoJson(layerItem).then((layer) => {
        map.add(layer)
        store.dispatch('addLayer', {id: layerItem.id, title: layerItem.title, visible: layerItem.visible, posicion: layerItem.posicion, addListLayer: layerItem.addListLayer})
      }))
    } else */ if (layerItem.type === 'featureLayer') {
      thePromises.push(
        createFeatureLayer(layerItem).then((layer) => {
          map.add(layer)
          store.dispatch('map/addLayer', {
            id: layerItem.id,
            title: layerItem.title,
            visible: layerItem.visible,
            opacity: layerItem.opacity,
            posicion: layerItem.posicion,
            addListLayer: layerItem.addListLayer,
          })
        }),
      )
    } else if (layerItem.type === 'graphicsLayer') {
      thePromises.push(
        createGraphicsLayer(layerItem).then((layer) => {
          map.add(layer)
          store.dispatch('map/addLayer', {
            id: layerItem.id,
            title: layerItem.title,
            visible: layerItem.visible,
            opacity: layerItem.opacity,
            posicion: layerItem.posicion,
            addListLayer: layerItem.addListLayer,
          })
        }),
      )
    } else if (layerItem.type === 'vectorTileLayer') {
      thePromises.push(
        createVectorTileLayer(layerItem).then((layer) => {
          map.add(layer)
          store.dispatch('map/addLayer', {
            id: layerItem.id,
            title: layerItem.title,
            visible: layerItem.visible,
            opacity: layerItem.opacity,
            posicion: layerItem.posicion,
            addListLayer: layerItem.addListLayer,
          })
        }),
      )
    } /* else if (layer.type === 'simif') {
      thePromises.push(createWMSSimif(layer).then((layer) => {
        map.add(layer)
        store.dispatch('addLayer', {id: layerItem.id, title: layerItem.title, visible: layerItem.visible})
      }))
    } */
  }
  return Q.all(thePromises)
}
export const createLayer = async (layerItem) => {
  //console.log('layerItem', layerItem)
  if (layerItem.type === 'featureLayer') {
    thePromises.push(
      createFeatureLayer(layerItem).then((layer) => {
        map.add(layer)
        store.dispatch('map/addLayer', {
          id: layerItem.id,
          title: layerItem.title,
          visible: layerItem.visible,
          opacity: layerItem.opacity,
          posicion: layerItem.posicion,
          addListLayer: layerItem.addListLayer,
        })
      }),
    )
  } else if (layerItem.type === 'graphicsLayer') {
    thePromises.push(
      createGraphicsLayer(layerItem).then((layer) => {
        map.add(layer)
        store.dispatch('map/addLayer', {
          id: layerItem.id,
          title: layerItem.title,
          visible: layerItem.visible,
          opacity: layerItem.opacity,
          posicion: layerItem.posicion,
          addListLayer: layerItem.addListLayer,
        })
      }),
    )
  } else if (layerItem.type === 'vectorTileLayer') {
    thePromises.push(
      createVectorTileLayer(layerItem).then((layer) => {
        map.add(layer)
        store.dispatch('map/addLayer', {
          id: layerItem.id,
          title: layerItem.title,
          visible: layerItem.visible,
          opacity: layerItem.opacity,
          posicion: layerItem.posicion,
          addListLayer: layerItem.addListLayer,
        })
      }),
    )
  }
  return Q.all(thePromises)
}
/* export const setExageration3D = (exageration) => {
  let layerEx = map.ground.layers.getItemAt(0)

  if (layerEx) {
    let layer = new ExaggeratedElevationLayerSubClass({
      exaggeration: exageration,
      url: layerEx.url,
    })
    map.ground.layers.remove(layerEx)
    map.ground.layers.add(layer)
    // layer.exaggeration = exageration
  }
} */
export const setVisibilityLayer = (id, visibility) => {
  if (map) {
    const layer = map.findLayerById(id)
    if (layer) {
      layer.visible = visibility
    }
  }
}
export const setOpacityLayer = (id, opacity) => {
  if (map) {
    const layer = map.findLayerById(id)

    if (layer) {
      layer.opacity = opacity
    }
  }
}

async function createFeatureLayer(layerItem) {
  const deferred = Q.defer()

  try {
    const [FeatureLayer, SpatialReference] = await loadModules([
      'esri/layers/FeatureLayer',
      'esri/geometry/SpatialReference',
    ])

    let layer
    if (layerItem.url) {
      layer = new FeatureLayer({
        id: layerItem.id,
        url: layerItem.url,
        title: layerItem.title,
        visible: layerItem.visible,
        opacity: layerItem.opacity,
        spatialReference: SpatialReference.WGS84,
        outFields: ['*'],
      })
    } else {
      layer = new FeatureLayer({
        id: layerItem.id,
        source: [],
        title: layerItem.title,
        visible: layerItem.visible,
        opacity: layerItem.opacity,
        objectIdField: 'ID',
        render: {},
        geometryType: 'point',
        spatialReference: SpatialReference.WGS84,
        fields: [],
      })
    }

    deferred.resolve(layer)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

async function createGraphicsLayer(layerItem) {
  const deferred = Q.defer()

  try {
    const [GraphicsLayer] = await loadModules(['esri/layers/GraphicsLayer'])

    const layer = new GraphicsLayer({
      id: layerItem.id,
      title: layerItem.title,
      visible: layerItem.visible,
      opacity: layerItem.opacity,
    })

    deferred.resolve(layer)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}

async function createVectorTileLayer(layerItem) {
  const deferred = Q.defer()

  try {
    const [VectorTileLayer] = await loadModules(['esri/layers/VectorTileLayer'])

    const layer = new VectorTileLayer({
      id: layerItem.id,
      url: layerItem.url,
      title: layerItem.title,
      visible: layerItem.visible,
      opacity: layerItem.opacity,
    })

    deferred.resolve(layer)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}
// #endregion

export const paintPositionIncendio = async (point) => {
  try {
    const [Graphic, Point] = await loadModules([
      'esri/Graphic',
      'esri/geometry/Point',
    ])

    mapView.graphics = []

    if (point) {
      const symbol = {
        type: 'simple-marker',
        style: 'x',
        color: 'white',
        size: '15px',
        outline: {
          color: 'red',
          width: '2px',
        },
      }

      const geometry = new Point({
        x: parseFloat(point.LONGITUD),
        y: parseFloat(point.LATITUD),
      })

      const graphic = new Graphic({
        geometry: geometry,
        symbol: symbol,
      })

      mapView.graphics.add(graphic)
    }
  } catch (err) {
    VueInst.$log.error(err)
  }
}
// #endregion

// #region MEDIOS
let isPaintingMedios = false // Evitar que pinte multiples veces // TODO: dara problemas con hermes?
export const redrawMedios = async () => {
  if (!isPaintingMedios) {
    isPaintingMedios = true

    // console.time('REDRAWMEDIOS')
    if (map) {
      const layerMediosInactivos = map.findLayerById('layer-medios-inactivos')
      const layerMediosActivos = map.findLayerById('layer-medios-activos')
      const layerEtiquetasAct = map.findLayerById('layer-etiquetas-activos')
      const layerEtiquetasInact = map.findLayerById('layer-etiquetas-inactivos')

      if (
        layerMediosInactivos &&
        layerMediosActivos &&
        layerEtiquetasInact &&
        layerEtiquetasAct
      ) {
        const graphicsMedios = await createGraphicsMedios()
        layerMediosInactivos.graphics.items = graphicsMedios.INACTIVOS
        layerMediosActivos.graphics.items = graphicsMedios.ACTIVOS
        layerEtiquetasAct.graphics.items = graphicsMedios.ETIQUETAS_ACTIVOS
        layerEtiquetasInact.graphics.items = graphicsMedios.ETIQUETAS_INACTIVOS
      }
    }
    // console.timeEnd('REDRAWMEDIOS')

    isPaintingMedios = false
  }
}
async function createGraphicsMedios() {
  const deferred = Q.defer()

  const verMultiplesDispositivos = constants.verMultiplesDispositivos

  try {
    const [Point, Graphic] = await loadModules([
      'esri/geometry/Point',
      'esri/Graphic',
    ])

    const now = VueInst.$date.now()
    const tiempoInactivo = parseInt(
      store.getters['parametro/getParametro']('TIEMPO_POS_INACTIVO'),
    )
    const tiempoDesactivado = parseInt(
      store.getters['parametro/getParametro']('TIEMPO_POS_DESACTIVADO'),
    )
    const fechaMargenInactivo = VueInst.$date.subtract(
      now,
      tiempoInactivo,
      'minute',
    )
    const fechaMargenDesactivado = VueInst.$date.subtract(
      now,
      tiempoDesactivado,
      'day',
    )

    const estadosMedios = constants.estadosMedio
    const medios = store.getters['medio/medios']
    const incendios = store.getters['incendio/incendios']

    const sourcesMediosInactivos = []
    const sourcesMediosActivos = []
    const sourcesEtiquetasActivos = []
    const sourcesEtiquetasInactivos = []

    /* const popupActions = [
      { title: 'Track', id: 'measure-this', className: 'esri-icon-tracking' },
      { title: 'Track entre fechas', id: 'measure-this', className: 'esri-icon-calendar' }
    ]

    let popupTemplate = {
      title: 'Medio {MEDIO}',
      content: [{
        type: 'fields',
        fieldInfos: [
          { fieldName: 'NOMBRE', label: 'Nombre' },
          { fieldName: 'TELEFONO_INTERNO', label: 'Tel. interno' },
          { fieldName: 'ZONA', label: 'Zona' },
          { fieldName: 'FECHA', label: 'Fecha ult. señal' },
          { fieldName: 'TIPO', label: 'Tipo' },
          { fieldName: 'CATEGORIA', label: 'Categoría' },
          { fieldName: 'HORA_ENTRADA', label: 'Hora entrada' },
          { fieldName: 'HORA_SALIDA', label: 'Hora salida' }
        ]
      }],
      actions: popupActions
    } */

    /* let medio = {}
    var ComponentClass = Vue.extend(MedioPopup)
    var instance = new ComponentClass({
      propsData: { medio: medio }
    })
    instance.$mount() // pass nothing
    let popupTemplate = {
      title: 'Medio {MEDIO}',
      content: instance.$el
    } */

    for (let i = 0; i < medios.length; i++) {
      const medio = medios[i]

      const lastPosition = medio.ULT_POSICION

      if (lastPosition && lastPosition.LATITUD && lastPosition.LONGITUD) {
        // Icono segun tipo
        let icon = constants.iconosTiposMedios.find(
          (x) => x.TIPO === medio.TIPO,
        ).URL

        // Color segun estado
        const color = estadosMedios.find((x) => x.ID === medio.ESTADO).COLOR
        const colorId = estadosMedios.find((x) => x.ID === medio.ESTADO).ID

        // Atributos
        const attr = {
          ID_MEDIO: medio.ID_MEDIO,
          NOMBRE: medio.NOMBRE,
          ZONA: medio.ZONA,
          GUARDIAS: medio.GUARDIAS,
          TELEFONO_INTERNO: medio.TELEFONO_INTERNO,
          TELEFONO_EXTERNO: medio.TELEFONO_EXTERNO,
          // FECHA: DateService.formatDate(medio.FECHA, 'DD/MM/YYYY HH:mm'),
          TIPO: medio.TIPO,
          CATEGORIA: medio.CATEGORIA,
          MEDIO: medio.MEDIO,
          HORA_ENTRADA: medio.HORA_ENTRADA,
          HORA_SALIDA: medio.HORA_SALIDA,

          ID_DISPOSITIVO: lastPosition.ID_GEO_DISPOSITIVO,
          CODIGO_EXTERNO: lastPosition.CODIGO_EXTERNO,
          FECHA: DateService.formatDate(lastPosition.FECHA, 'DD/MM/YYYY HH:mm'),
          PROVEEDOR: lastPosition.PROVEEDOR,
        }

        // Punto
        const geometry = new Point({
          x: lastPosition.LONGITUD,
          y: lastPosition.LATITUD,
        })

        // Rombo emergencia
        const graphicRombo = {
          geometry: geometry,
          attributes: attr,
          symbol: {
            type: 'simple-marker',
            style: 'diamond',
            color: 'yellow',
            size: '52px',
            outline: {
              color: 'black',
              width: '4px',
            },
          },
        }

        let yoffset = -22
        if (medio.EN_EMERGENCIA) {
          yoffset = -30
        }

        // Label texto
        const graphicLabel = {
          geometry: geometry,
          attributes: attr,
          symbol: {
            type: 'text', // autocasts as new TextSymbol()
            color: 'black',
            haloColor: 'white',
            haloSize: '2px',
            text: medio.MEDIO,
            xoffset: 0,
            yoffset: yoffset,
            font: {
              // autocasts as new Font()
              size: 8,
              family: 'Arial Unicode MS',
              weight: 'bold',
            },
          },
        }

        // Linea a incendio
        let graphicLine = null
        const graphicAlerta = null
        for (let k = 0; k < incendios.length; k++) {
          // Si esta asignado a un incendio, crea una linea que los une (A LA ULTIMA POSICION)
          const incendio = incendios[k]

          if (incendio.ID_INCENDIO === medio.ID_INCENDIO) {
            if (medio.ESTADO === 1 || medio.ESTADO === 2) {
              // Solo pinta linea para aviso y llegada inc
              graphicLine = {
                geometry: {
                  type: 'polyline',
                  paths: [
                    [lastPosition.LONGITUD, lastPosition.LATITUD],
                    [incendio.LONGITUD, incendio.LATITUD],
                  ],
                },
                attributes: {
                  notShowMaptip: true,
                },
                symbol: {
                  type: 'simple-line',
                  style: 'short-dot',
                  color: color,
                  width: 2,
                },
              }
            }

            // Comprobar fecha salida planificada
            /* let actuaciones = []
            for (let m = 0; m < incendio.SECTORES.length; m++) {
              for (let n = 0; n < incendio.SECTORES[m].MEDIOS.length; n++) {
                let medioSector = incendio.SECTORES[m].MEDIOS[n]

                if (medioSector.ID_MEDIO === medio.ID_MEDIO) {
                  actuaciones.push(medioSector)
                }
              }
            }
            actuaciones = actuaciones.sort((a, b) => {
              return moment(a.FECHA_AVISO).valueOf() - moment(b.FECHA_AVISO).valueOf()
            })
            let lastActuacion = actuaciones[actuaciones.length - 1]
            let planificacion = lastActuacion.PLANIFICACION
            if (planificacion !== null && planificacion !== undefined) {
              let inicioJornada = moment(planificacion.INICIO_JORNADA).locale('es')
              let tiempoOperativo = planificacion.TIEMPO_OPERATIVO
              let fechaMaximaActuacion = moment(inicioJornada).add(tiempoOperativo, 'hours')
              let horaMovilizacion = lastActuacion.FECHA_AVISO ? moment(lastActuacion.FECHA_AVISO) : moment(lastActuacion.FECHA_AVISO_PLANIF)
              let horaLlegadaInc = lastActuacion.FECHA_LLEGADA ? moment(lastActuacion.FECHA_LLEGADA) : moment(lastActuacion.FECHA_LLEGADA_PLANIF)
              let itinere = horaLlegadaInc.diff(horaMovilizacion, 'minutes')
              let fechaSalidaPlanif = moment(fechaMaximaActuacion).subtract(itinere, 'minutes')
              // let horaSalidaPlanif = fechaSalidaPlanif.format('HH:mm')

              // Comprobar si esta dentro de horario
              // let horaSalidaMedio = moment(medio.HORA_SALIDA, 'HH:mm').format('HH:mm')
              let fechaActual = moment()
              // let horaActual = fechaActual.format('HH:mm')
              if ((fechaSalidaPlanif && (fechaActual > fechaSalidaPlanif))) { // || horaActual > horaSalidaMedio
                graphicAlerta = {
                  geometry: geometry,
                  attributes: attr,
                  symbol: {
                    type: 'simple-marker',
                    path: constants.iconAlertaMedio,
                    color: 'red',
                    size: '15px',
                    outline: {
                      color: 'red'
                    },
                    xoffset: '15px',
                    yoffset: '15px'
                  }
                }
              }
            } else {
              console.error(lastActuacion)
            } */
          }
        }

        if (medio.MEDIO_DISPOSITIVOS) {
          if (verMultiplesDispositivos) {
            // Pintar cada dispositivo como un punto separado
            for (let j = 0; j < medio.MEDIO_DISPOSITIVOS.length; j++) {
              const dispositivo = medio.MEDIO_DISPOSITIVOS[j]

              // Atributos del dispositivo
              const attrDisp = JSON.parse(JSON.stringify(attr))
              attrDisp.ID_DISPOSITIVO = dispositivo.ID_DISPOSITIVO
              attrDisp.CODIGO_EXTERNO = dispositivo.CODIGO_EXTERNO
              attrDisp.FECHA = DateService.formatDate(
                dispositivo.FECHA,
                'DD/MM/YYYY HH:mm',
              )
              attrDisp.PROVEEDOR = dispositivo.PROVEEDOR

              const geometryDisp = new Point({
                x: dispositivo.LONGITUD,
                y: dispositivo.LATITUD,
              })

              // Label
              const graphicLabelDisp = new Graphic(graphicLabel)
              graphicLabelDisp.geometry = geometryDisp
              graphicLabelDisp.attributes = attrDisp
              graphicLabelDisp.symbol.text += ' - ' + dispositivo.PROVEEDOR

              // LINEA MEDIO -> DISPOSITIVOS
              /* let graphicLineMedioConDispositivos = null
              // Si el medio tienen asignados uno o varios dispositivos, se crea una línea que los une
              graphicLineMedioConDispositivos = {
                geometry: {
                  type: 'polyline',
                  paths: [[medio.LONGITUD, medio.LATITUD], [dispositivo.LONGITUD, dispositivo.LATITUD]]
                },
                attributes: {
                  notShowMaptip: true
                },
                symbol: {
                  type: 'simple-line',
                  style: 'short-dot',
                  color: colorDispositivo,
                  width: 8
                }
              } */

              const lastPosFecha = VueInst.$date.parseDate(dispositivo.FECHA)
              const graficos = []

              if (medio.EN_EMERGENCIA) {
                const graphicRomboDisp = new Graphic(graphicRombo)
                graphicRomboDisp.geometry = geometryDisp
                graficos.push(graphicRomboDisp)
              }

              /* if (graphicLineMedioConDispositivos) {
                graficos.push(graphicLineMedioConDispositivos)
              } */

              let iconDispositivo = icon
              if (!iconDispositivo) {
                iconDispositivo = 'tecnico_extincion'
              }
              iconDispositivo =
                iconDispositivo +
                (lastPosFecha >= fechaMargenInactivo ? '_' + colorId : '_4') +
                '.png'

              // Graphic principal
              const graphic = {
                geometry: geometryDisp,
                attributes: attrDisp,
                symbol: {
                  type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
                  // url: constants.server.URLSERVER + '/images/icons/' + icon,
                  url: 'icons/' + iconDispositivo,
                  width: '40px',
                  height: '40px',
                },
                // popupTemplate: popupTemplate
              }
              graficos.push(graphic)

              if (graphicLine) graficos.push(graphicLine)
              if (graphicAlerta) graficos.push(graphicAlerta)

              // Comprobar si el dispositivo asociado está activo, inactivo o desactivado.
              if (lastPosFecha < fechaMargenInactivo) {
                // Inactivo
                if (lastPosFecha >= fechaMargenDesactivado) {
                  // No desactivado
                  sourcesMediosInactivos.push(...graficos)
                  sourcesEtiquetasInactivos.push(graphicLabelDisp)
                }
              } else {
                // Activo
                sourcesMediosActivos.push(...graficos)
                sourcesEtiquetasActivos.push(graphicLabelDisp)
              }
            }
          } else {
            // Pintar la ultima posicion del medio
            // Label
            const graphicLabelDisp = new Graphic(graphicLabel)
            graphicLabelDisp.symbol.text += ' - ' + lastPosition.PROVEEDOR

            const lastPosFecha = VueInst.$date.parseDate(lastPosition.FECHA)
            const graficos = []

            if (medio.EN_EMERGENCIA) {
              graficos.push(graphicRombo)
            }

            // Color del icono
            if (!icon) {
              icon = 'tecnico_extincion'
            }
            icon =
              icon +
              (lastPosFecha >= fechaMargenInactivo ? '_' + colorId : '_4') +
              '.png'

            // Graphic principal
            const graphic = {
              geometry: geometry,
              attributes: attr,
              symbol: {
                type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
                // url: constants.server.URLSERVER + '/images/icons/' + icon,
                url: 'icons/' + icon,
                width: '40px',
                height: '40px',
              },
              // popupTemplate: popupTemplate
            }
            graficos.push(graphic)

            if (graphicLine) graficos.push(graphicLine)
            if (graphicAlerta) graficos.push(graphicAlerta)

            // Comprobar si esta activo, inactivo o desactivado
            if (lastPosFecha < fechaMargenInactivo) {
              // Inactivo
              if (lastPosFecha >= fechaMargenDesactivado) {
                // No desactivado
                sourcesMediosInactivos.push(...graficos)
                sourcesEtiquetasInactivos.push(graphicLabelDisp)
              }
            } else {
              // Activo
              sourcesMediosActivos.push(...graficos)
              sourcesEtiquetasActivos.push(graphicLabelDisp)
            }
          }
        }
      }
    }

    deferred.resolve({
      INACTIVOS: sourcesMediosInactivos,
      ACTIVOS: sourcesMediosActivos,
      ETIQUETAS_ACTIVOS: sourcesEtiquetasActivos,
      ETIQUETAS_INACTIVOS: sourcesEtiquetasInactivos,
    })
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}
// #endregion

// #region TRACKS
export const drawTrack = async function (idMedio, medio, positions) {
  if (map) {
    const layer = map.findLayerById('layer-track')
    const newPositions = await calcularVelocidad(positions)

    const graphics = await createGraphicsTrack(idMedio, medio, newPositions)
    layer.addMany(graphics)
    mapView.goTo(graphics, {
      duration: 3000,
      easing: 'in-expo',
    })
    layer.visible = true
  }
}

export const hideTrack = function (idMedio) {
  if (map) {
    const layer = map.findLayerById('layer-track')
    layer.removeMany(
      layer.graphics.items.filter((x) => x.attributes.ID_MEDIO === idMedio),
    )
    layer.visible = true
  }
}

export const exportTrack = function (data) {
  if (map) {
    const layer = map.findLayerById('layer-track')

    const points = layer.graphics.items.filter(
      (x) => x.attributes.ID_MEDIO === data.idMedio,
    )
    points.sort((a, b) => (a.attributes.FECHA > b.attributes.FECHA ? 1 : -1))

    const fechas = points.map((x) => x.attributes.FECHA).filter((x) => x)
    const fechaInicio = fechas[0]

    // Construir GEOJSON
    const features = [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: points
            .map((x) => x.geometry)
            .map((x) => [x.longitude, x.latitude]),
        },
        properties: {
          MEDIO: data.medio,
        },
      },
    ]

    for (let i = 0; i < points.length; i++) {
      if (!points[i].attributes.VELOCIDAD) {
        points[i].attributes.VELOCIDAD = 0
      }
      features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            points[i].geometry.longitude,
            points[i].geometry.latitude,
          ],
        },
        properties: points[i].attributes,
      })
    }

    const geoJson = {
      type: 'FeatureCollection',
      features: features,
    }

    // Fichero SHP
    const nombre = data.medio + '_' + fechaInicio
    const shpwrite = require('shp-write')
    const options = {
      folder: 'Track_' + data.medio,
      types: {
        point: 'PUNTOS_' + nombre,
        polygon: 'POLIGONOS' + nombre,
        line: 'LINEAS' + nombre,
        polyline: 'POLYLINE_' + nombre,
      },
    }
    shpwrite.download(geoJson, options)

    // Fichero KML
    const tokml = require('tokml')
    const kml = tokml(geoJson)
    const element = document.createElement('a')
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(kml),
    )
    element.setAttribute('download', 'Track_' + nombre + '.kml')
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }
}
async function createGraphicsTrack(idMedio, medio, posiciones) {
  const deferred = Q.defer()

  try {
    const [Point] = await loadModules(['esri/geometry/Point'])

    const rgbRandom = [
      Math.floor(Math.random() * 255),
      Math.floor(Math.random() * 255),
      Math.floor(Math.random() * 255),
    ]
    posiciones.reverse() // Poner en orden creciente

    const sources = []
    const pathPosiciones = []
    for (let i = 0; i < posiciones.length; i++) {
      let rumbo = 0
      if (i < posiciones.length - 1) {
        // Calcula el rumbo para todas las posiciones menos para la ultima
        rumbo = calcularRumbo(
          posiciones[i].LATITUD,
          posiciones[i].LONGITUD,
          posiciones[i + 1].LATITUD,
          posiciones[i + 1].LONGITUD,
        )
      }
      rumbo = (rumbo + 180) % 360 // La flecha original apunta hacia abajo

      pathPosiciones.push([posiciones[i].LONGITUD, posiciones[i].LATITUD])
      sources.push({
        // Cada punto
        geometry: new Point({
          x: posiciones[i].LONGITUD,
          y: posiciones[i].LATITUD,
        }),
        attributes: {
          ID_MEDIO: idMedio,
          MEDIO: medio,
          FECHA: posiciones[i].FECHA,
          VELOCIDAD: posiciones[i].VELOCIDAD,
        },
        symbol: {
          type: 'simple-marker',
          path: 'M14.5,29 23.5,0 14.5,9 5.5,0z',
          angle: rumbo,
          // style: 'circle',
          color: 'black',
          size: '15px',
          outline: {
            color: 'black',
          },
        },
      })
    }
    sources.unshift({
      // Linea
      geometry: {
        type: 'polyline',
        paths: pathPosiciones,
      },
      attributes: {
        ID_MEDIO: idMedio,
      },
      symbol: {
        type: 'simple-line',
        style: 'short-dot',
        color: rgbRandom,
        width: 1,
      },
    })

    deferred.resolve(sources)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

async function calcularVelocidad(puntos) {
  // TODO: hacer en backend
  const deferred = Q.defer()

  try {
    const [Point, geometryEngine, SpatialReference, webMercatorUtils] =
      await loadModules([
        'esri/geometry/Point',
        'esri/geometry/geometryEngine',
        'esri/geometry/SpatialReference',
        'esri/geometry/support/webMercatorUtils',
      ])

    const sr = new SpatialReference({ wkid: 102100 })
    for (let i = 0; i < puntos.length; i++) {
      if (i === 0) {
        puntos[i].VELOCIDAD = 0
      } else if (i < puntos.length - 1) {
        const posPoint = webMercatorUtils.lngLatToXY(
          puntos[i].LONGITUD,
          puntos[i].LATITUD,
        )
        const posFollowPoint = webMercatorUtils.lngLatToXY(
          puntos[i + 1].LONGITUD,
          puntos[i + 1].LATITUD,
        )

        const point = new Point({
          x: posPoint[0],
          y: posPoint[1],
          spatialReference: sr,
        })

        const followPoint = new Point({
          x: posFollowPoint[0],
          y: posFollowPoint[1],
          spatialReference: sr,
        })

        const distancia = geometryEngine.distance(
          point,
          followPoint,
          'kilometers',
        )

        const fechaPoint = DateService.parseDate(puntos[i].FECHA)
        const fechaFollowPoint = DateService.parseDate(puntos[i + 1].FECHA)

        const tiempo = fechaPoint.diff(fechaFollowPoint, 'seconds') / 3600

        const velocidad = distancia / tiempo

        puntos[i].VELOCIDAD = Math.trunc(velocidad)
      }
    }
    deferred.resolve(puntos)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

function toRadians(degrees) {
  return (degrees * Math.PI) / 180
}

function toDegrees(radians) {
  return (radians * 180) / Math.PI
}
function calcularRumbo(startLat, startLng, destLat, destLng) {
  // TODO: hacer en backend
  startLat = toRadians(startLat)
  startLng = toRadians(startLng)
  destLat = toRadians(destLat)
  destLng = toRadians(destLng)

  const y = Math.sin(destLng - startLng) * Math.cos(destLat)
  const x =
    Math.cos(startLat) * Math.sin(destLat) -
    Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng)
  let brng = Math.atan2(y, x)
  brng = toDegrees(brng)
  return (brng + 360) % 360
}
// #endregion

// #region PLANES_OPERACIONES
// TODO: Sketch 3d: https://developers.arcgis.com/javascript/latest/api-reference/esri-widgets-Sketch-SketchViewModel.html
let planOpSVM = null

export const initPlanOperacionesSketchViewModel = async function (data) {
  try {
    const [SketchViewModel] = await loadModules([
      'esri/widgets/Sketch/SketchViewModel',
    ])
    const callbackCreate = data.callbackCreate
    const callbackUpdate = data.callbackUpdate
    const callbackDelete = data.callbackDelete

    const layer = map.findLayerById('layer-plan-operaciones')

    planOpSVM = new SketchViewModel({
      view: mapView,
      layer: layer,
    })
    planOpSVM.on(['create', 'delete', 'update'], async (evt) => {
      // Arrow logic
      if (
        isDrawingArrow &&
        evt.type === 'create' &&
        evt.graphic &&
        evt.graphic.geometry.type === 'polyline'
      ) {
        if (evt.state === 'active') {
          if (evt.graphic.geometry.paths[0].length === 3) {
            // Si esta dibujando flecha y tiene 3 puntos (2 reales) terminar el dibujo
            planOpSVM.complete()
          }
        }
      }

      if (evt.state === 'complete' && evt.type === 'create') {
        // Crear grafico
        const attributes = {
          ID_GRAFICO: VueInst.$uuid.createUUID(),
        }

        evt.graphic.attributes = JSON.parse(JSON.stringify(attributes)) // Hack para quitar el __observer__ que causa un bucle infinito a arcgis

        let geometry
        let type = evt.graphic.geometry.type
        let symbol
        if (
          evt.graphic.geometry.type === 'point' &&
          evt.graphic.symbol.type === 'simple-marker'
        ) {
          // Punto
          const defaultSymbol = planOpSVM.pointSymbol

          geometry = [evt.graphic.geometry.x, evt.graphic.geometry.y]
          symbol = {
            type: defaultSymbol.type,
            color: defaultSymbol.color,
            // 'angle': defaultSymbol.angle,
            size: defaultSymbol.size,
            style: defaultSymbol.style,
          }
        } else if (
          evt.graphic.geometry.type === 'point' &&
          evt.graphic.symbol.type === 'picture-marker'
        ) {
          // Icono
          const defaultSymbol = planOpSVM.pointSymbol

          geometry = [evt.graphic.geometry.x, evt.graphic.geometry.y]
          type = 'icon'
          symbol = {
            type: 'picture-marker',
            url: defaultSymbol.url,
            height: defaultSymbol.height,
            width: defaultSymbol.width,
          }
        } else if (
          evt.graphic.geometry.type === 'point' &&
          evt.graphic.symbol.type === 'text'
        ) {
          // Texto
          const defaultSymbol = planOpSVM.pointSymbol

          geometry = [evt.graphic.geometry.x, evt.graphic.geometry.y]
          type = 'text'
          symbol = {
            type: 'text',
            color: defaultSymbol.color,
            text: defaultSymbol.text,
            xoffset: 0,
            yoffset: 0,
            haloColor: 'white',
            haloSize: '20px',
            font: {
              size: defaultSymbol.font.size,
              family: 'Arial Unicode MS',
              weight: 'bold',
            },
          }
        } else if (
          !isDrawingArrow &&
          evt.graphic.geometry.type === 'polyline'
        ) {
          // Linea
          const defaultSymbol = planOpSVM.polylineSymbol

          geometry = evt.graphic.geometry.paths[0]
          symbol = {
            type: defaultSymbol.type,
            color: defaultSymbol.color,
            width: defaultSymbol.width,
            style: defaultSymbol.style,
            join: defaultSymbol.join,
            miterLimit: defaultSymbol.miterLimit,
          }
        } else if (isDrawingArrow || evt.graphic.geometry.type === 'polygon') {
          // Poligonos y flecha
          const defaultSymbol = planOpSVM.polygonSymbol

          if (isDrawingArrow) {
            // Convertir la linea a flecha y crear el graphic
            isDrawingArrow = false
            const g = await makeArrow(
              evt.graphic.geometry.paths[0],
              defaultSymbol.color,
            )
            evt.graphic.geometry = g.geometry
            evt.graphic.symbol = g.symbol
            type = 'polygon'
          }

          geometry = evt.graphic.geometry.rings[0]
          symbol = {
            type: defaultSymbol.type,
            color: defaultSymbol.color,
            style: defaultSymbol.style,
            outline: {
              color: defaultSymbol.outline.color,
              width: defaultSymbol.outline.width,
            },
          }
        }

        const graphic = {
          // TODO: poner los mismos nombres que en BBDD y cambiar en backend en (plan_operaciones addGraphics)
          attributes: attributes,
          coordenadas: geometry,
          type: type,
          symbol: symbol,
        }

        callbackCreate(graphic)
      } else if (evt.state === 'complete' && evt.type === 'update') {
        // Actualizar grafico
        const graphic = evt.graphics[0]
        const geom = graphic.geometry

        let newGeometry
        if (geom.type === 'point') {
          newGeometry = [geom.x, geom.y]
        } else if (geom.type === 'polyline') {
          newGeometry = geom.paths[0]
        } else if (geom.type === 'polygon') {
          newGeometry = geom.rings[0]
        }

        // geometry.type = graphic.geometry.type
        const newGraphic = {
          ATRIBUTOS: graphic.attributes,
          GEOMETRIA: newGeometry,
        }

        callbackUpdate(newGraphic)
      } else if (evt.type === 'delete') {
        // Borrar grafico
        callbackDelete(evt.graphics[0].attributes.ID_GRAFICO)
      }
    })
  } catch (err) {
    VueInst.$log.error(err)
  }
}

export const paintEventPlanOperaciones = (event) => {
  let typeEvent = event.function
  const symbolColor = event.color ? event.color : [255, 0, 0, 0.5]

  isDrawingArrow = false

  if (typeEvent === 'cancel') {
    planOpSVM.cancel()
  } else if (typeEvent === 'point') {
    planOpSVM.pointSymbol = {
      type: 'simple-marker',
      style: 'circle',
      color: symbolColor,
      size: '16px',
      outline: {
        color: symbolColor,
        width: 3,
      },
    }
  } else if (typeEvent === 'polyline') {
    const lineType = event.type_line ? event.type_line : 'solid'
    planOpSVM.polylineSymbol.color = symbolColor
    planOpSVM.polylineSymbol.style = lineType
  } else if (typeEvent === 'polygon') {
    planOpSVM.polygonSymbol.color = symbolColor
    planOpSVM.polygonSymbol.outline = {
      color: symbolColor,
      width: 2,
    }
  } else if (typeEvent === 'icon') {
    typeEvent = 'point'
    planOpSVM.pointSymbol = {
      type: 'picture-marker',
      url: event.src,
      width: '25px',
      height: '25px',
    }
  } else if (typeEvent === 'text') {
    typeEvent = 'point'
    planOpSVM.pointSymbol = {
      type: 'text',
      color: symbolColor,
      text: event.text,
      xoffset: 0,
      yoffset: 0,
      haloColor: 'white',
      haloSize: '20px',
      font: {
        size: event.size,
        family: 'Arial Unicode MS',
        weight: 'bold',
      },
    }
  } else if (typeEvent === 'arrow') {
    typeEvent = 'polyline'
    isDrawingArrow = true

    planOpSVM.polygonSymbol.color = symbolColor
    planOpSVM.polygonSymbol.outline.color = symbolColor
  }
  planOpSVM.create(typeEvent)
}

export const stopPlanOperacionesSketchViewModel = function () {
  const layer = map.findLayerById('layer-plan-operaciones')
  layer.removeAll()

  planOpSVM.cancel()
  planOpSVM.destroy()
}

let isDrawingArrow = false
// Crea un poligono con forma de flecha a partir de una linea de dos puntos
async function makeArrow(geom, color) {
  const deferred = Q.defer()

  try {
    const [Point, Polygon, Graphic, GeometryEngine] = await loadModules([
      'esri/geometry/Point',
      'esri/geometry/Polygon',
      'esri/Graphic',
      'esri/geometry/geometryEngine',
    ])

    const start = new Point({ x: geom[0][0], y: geom[0][1] })
    const end = new Point({ x: geom[1][0], y: geom[1][1] })

    const dist = start.distance(end)
    const dy = end.y - start.y
    const dx = end.x - start.x
    let rads = Math.atan2(dx, dy)

    if (rads < 0) {
      rads = Math.abs(rads)
    } else {
      rads = 2 * Math.PI - rads
    }

    const degrees = (rads * 180) / Math.PI

    const width = 0.2 * dist // 1/5, can adjust

    const bottomLeft = [start.x - width / 10, start.y]
    const bottomRight = [start.x + width / 10, start.y]
    const midLeft = [bottomLeft[0], [bottomLeft[1] + dist - width]]
    const midRight = [bottomRight[0], [bottomRight[1] + dist - width]]
    const edgeLeft = [midLeft[0] - width / 2, midLeft[1]]
    const edgeRight = [midRight[0] + width / 2, midRight[1]]
    const point = [start.x, start.y + dist]

    const rings = [
      point,
      edgeLeft,
      midLeft,
      bottomLeft,
      bottomRight,
      midRight,
      edgeRight,
    ]

    const poly = new Polygon({
      rings: [rings],
      spatialReference: { wkid: 102100 },
    })

    const rotatedPoly = GeometryEngine.rotate(poly, degrees, start)
    const symbol = {
      type: 'simple-fill',
      color: color,
      outline: {
        color: color,
        width: '2',
      },
    }

    deferred.resolve(
      new Graphic({
        geometry: rotatedPoly,
        symbol,
      }),
    )
  } catch (err) {
    deferred.reject(err)
    VueInst.$log.error(err)
  }

  return deferred.promise
}
async function createGraphicsDraw(graphicList) {
  const deferred = Q.defer()

  try {
    const [Graphic, Point, Polyline, Polygon, GeometryEngine] =
      await loadModules([
        'esri/Graphic',
        'esri/geometry/Point',
        'esri/geometry/Polyline',
        'esri/geometry/Polygon',
        'esri/geometry/geometryEngine',
      ])

    const graphicsDraw = graphicList
    let geometry
    const graphics = []
    for (let i = 0; i < graphicsDraw.length; i++) {
      const tipo = graphicsDraw[i].type || graphicsDraw[i].TIPO // TODO: esto no mola
      const coords = graphicsDraw[i].coordenadas || graphicsDraw[i].GEOMETRIA
      const simbolo = graphicsDraw[i].symbol || graphicsDraw[i].SIMBOLO
      let atributos = graphicsDraw[i].attributes || graphicsDraw[i].ATRIBUTOS
      atributos = JSON.parse(JSON.stringify(atributos)) // HACK __observer__

      if (tipo === 'point' || tipo === 'icon' || tipo === 'text') {
        geometry = new Point({
          x: coords[0],
          y: coords[1],
          spatialReference: { wkid: 3857 },
        })
      } else if (tipo === 'polyline') {
        geometry = new Polyline({
          hasZ: false,
          hasM: true,
          paths: coords,
          spatialReference: { wkid: 3857 },
        })

        atributos.LONGITUD = GeometryEngine.geodesicLength(geometry)
      } else if (tipo === 'polygon') {
        geometry = new Polygon({
          hasZ: false,
          hasM: true,
          rings: coords,
          spatialReference: { wkid: 3857 },
        })

        atributos.LONGITUD = GeometryEngine.geodesicLength(geometry)
        atributos.AREA = Math.abs(GeometryEngine.geodesicArea(geometry))
      }

      const graphic = new Graphic({
        geometry: geometry,
        symbol: simbolo,
        attributes: atributos,
      })
      graphics.push(graphic)
    }

    deferred.resolve(graphics)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}
function sortGraphics(graphics) {
  return graphics.sort((a, b) => {
    if (a.geometry.type === 'point') {
      return 1
    }
    if (b.geometry.type === 'point') {
      return -1
    }
    if (a.geometry.type === 'polyline' && b.geometry.type === 'polygon') {
      return 1
    }
    if (b.geometry.type === 'polyline' && a.geometry.type === 'polygon') {
      return -1
    }
    return 1
  })
}

export const drawGraphicsPlanOperaciones = async function (graphicList) {
  const deferred = Q.defer()

  const layer = map.findLayerById('layer-plan-operaciones')
  layer.removeAll()

  const graphics = await createGraphicsDraw(graphicList)
  const graphicsSorted = sortGraphics(graphics)
  layer.addMany(graphicsSorted)

  deferred.resolve()

  return deferred.promise
}
// #endregion

// #region SIMULACION
let simulacionSVM = null
let simulacionGraphics = []
export const initSimulacionSketchViewModel = async function (data) {
  try {
    const [SketchViewModel, projection] = await loadModules([
      'esri/widgets/Sketch/SketchViewModel',
      'esri/geometry/projection',
    ])
    const event = data.event
    const callback = data.callback

    const layer = map.findLayerById('layer-sketch-simulacion')

    simulacionSVM = new SketchViewModel({
      view: mapView,
      layer: layer,
    })
    simulacionSVM.on(['create', 'delete', 'update'], async function (evt) {
      if (evt.state === 'complete' && evt.type === 'create') {
        await projection.load()
        const geometry = projection.project(evt.graphic.geometry, {
          wkid: 25830,
        })

        let symbol, coordinates
        if (
          evt.graphic.geometry.type === 'point' &&
          evt.graphic.symbol.type === 'simple-marker'
        ) {
          symbol = simulacionSVM.pointSymbol
          coordinates = [geometry.x, geometry.y]
        } else if (evt.graphic.geometry.type === 'polyline') {
          symbol = simulacionSVM.polylineSymbol
          coordinates = geometry.paths[0]
        } else if (evt.graphic.geometry.type === 'polygon') {
          symbol = simulacionSVM.polygonSymbol
          coordinates = geometry.rings[0]
        }

        const graphic = {
          coordinates: coordinates,
          type: 'polygon',
          symbol: symbol,
        }
        callback(graphic)
      }
    })

    // Create graphic
    simulacionSVM.create(event)
  } catch (err) {
    VueInst.$log.error(err)
  }
}
export const clearSimulacionSketch = function () {
  const layer = map.findLayerById('layer-sketch-simulacion')
  layer.removeAll()
}
export const stopSimulacionSketchViewModel = function () {
  simulacionSVM.cancel()
}

export const paintSimulacion = async function (simulacion) {
  const deferred = Q.defer()
  // let url = constants.server.URLSERVER + '/simulacion_4326.geojson'
  const url =
    constants.urlSimulacion +
    '/result_json/' +
    simulacion.ID_SIMULACION +
    '/28530'

  if (map) {
    const layer = map.findLayerById('layer-simulacion')

    try {
      const response = await VueInst.$http.get(url)
      const graphics = await createGraphicsSimulacion(response.data)

      simulacionGraphics = graphics

      const g = simulacionGraphics[0]
      layer.graphics.items = [g] // Solo pinta el primer anillo
      mapView.goTo(g, {
        duration: 3000,
        easing: 'in-expo',
      })
      layer.visible = true

      deferred.resolve()
    } catch (err) {
      deferred.reject(err)
    }
  } else {
    deferred.reject()
  }

  return deferred.promise
}
async function createGraphicsSimulacion(response) {
  const deferred = Q.defer()

  try {
    const [Polygon, Graphic, GeometryEngine] = await loadModules([
      'esri/geometry/Polygon',
      'esri/Graphic',
      'esri/geometry/geometryEngine',
    ])

    const featuresJson = response.features

    // Agrupar por ElapsedMin
    const groups = featuresJson.reduce((groups, item) => {
      const elapsedMin = item.properties.Elapsed_Mi
      const group = groups[elapsedMin] || []
      group.push(item)
      groups[elapsedMin] = group
      return groups
    }, {})

    const initColor = [255, 255, 0] // Amarillo
    const endColor = [255, 0, 0] // Rojo
    const colorGradient = getColorGradient(
      initColor,
      endColor,
      Object.keys(groups).length,
    )

    // Crear las geometrias juntando los poligonos del mismo ElapsedMin
    const mergedFeatures = []
    Object.entries(groups).forEach(([_k, v]) => {
      const features = v

      const geometry = new Polygon({
        hasZ: false,
        hasM: false,
        rings: features.map((x) => x.geometry.coordinates).flat(),
        spatialReference: {
          wkid: 4326,
        },
      })

      mergedFeatures.push({
        geometry: geometry,
        properties: v[0].properties,
      })
    })

    // Crear los graficos
    const graphics = []
    for (let i = 0; i < mergedFeatures.length; i++) {
      const f = mergedFeatures[i]
      let geometry = f.geometry

      if (i > 0) {
        const prevFeature = mergedFeatures[i - 1]
        const prevGeometry = prevFeature.geometry
        geometry = GeometryEngine.difference(geometry, prevGeometry)

        if (!geometry) {
          continue
        }
      }

      const color = colorGradient[i]
      const symbol = {
        type: 'simple-fill',
        color: [color[0], color[1], color[2], 1],
        style: 'solid',
        outline: {
          color: 'white',
          width: 1,
        },
      }
      const popupTemplate = {
        title: 'Simulación',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'Elapsed_Mi',
                label: 'Elapsed Mins',
              },
            ],
          },
        ],
      }

      const graphic = new Graphic({
        geometry: geometry,
        attributes: f.properties, // attributes
        symbol: symbol,
        popupTemplate: popupTemplate,
      })

      graphics.push(graphic)
    }

    deferred.resolve(graphics)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

export const updateSimulacionTime = function (elapsedMin) {
  if (map) {
    const layer = map.findLayerById('layer-simulacion')
    layer.removeAll()

    const graphics = simulacionGraphics.filter(
      (x) => x.attributes.Elapsed_Mi <= elapsedMin,
    )
    layer.addMany(graphics)
    // layer.visible = true

    loadModules(['esri/geometry/geometryEngine']).then(([geometryEngine]) => {
      let superficie = 0
      for (let i = 0; i < graphics.length; i++) {
        const geometry = graphics[i].geometry
        superficie += Math.abs(geometryEngine.geodesicArea(geometry))
      }
      superficie = superficie / 10000 // hectareas
      VueInst.$eventHub.$emit(
        'superficieSimulacion',
        Math.round(superficie * 100) / 100,
      )
    })
  }
}

export const centerSimulacion = function () {
  if (map) {
    const layer = map.findLayerById('layer-simulacion')
    mapView.goTo(layer.graphics.items, {
      duration: 3000,
      easing: 'in-expo',
    })
  }
}

export const clearSimulacion = function () {
  if (map) {
    const layer = map.findLayerById('layer-simulacion')
    layer.removeAll()
  }
}

/**
 * @param {*} from => [r, g, b, a]
 * @param {*} to => [r, g, b, a]
 * @param {*} steps => n
 */
function getColorGradient(from, to, totalNumberOfColors) {
  if (totalNumberOfColors === 0) {
    return [[0, 0, 0]]
  }
  if (totalNumberOfColors === 1) {
    return [from]
  }
  if (totalNumberOfColors === 2) {
    return [from, to]
  }

  const diffR = to[0] - from[0]
  const diffG = to[1] - from[1]
  const diffB = to[2] - from[2]
  // let diffA = to[3] - from[3]

  const steps = totalNumberOfColors - 1

  const stepR = diffR / steps
  const stepG = diffG / steps
  const stepB = diffB / steps
  // let stepA = diffA / steps

  const gradient = []
  gradient.push(from)
  for (let i = 1; i < steps; ++i) {
    const r = Math.round(from[0] + stepR * i)
    const g = Math.round(from[1] + stepG * i)
    const b = Math.round(from[2] + stepB * i)
    // let a = Math.round(from[3] + stepA * i)

    gradient.push([r, g, b])
  }
  gradient.push(to)

  return gradient
}
// #endregion

// #region CONVERT_COORDINATES
export const convertirGMSLatLon = (gmsLat, gmsLon) => {
  const partsLat = gmsLat.split(/[^\d\w]+/)
  const partsLon = gmsLon.split(/[^\d\w]+/)
  const lat = convertirDMSToDD(
    Number(partsLat[0]),
    Number(partsLat[1]),
    Number(partsLat[2] + '.' + partsLat[3]),
    partsLat[4],
  )
  const lng = convertirDMSToDD(
    Number(partsLon[0]),
    Number(partsLon[1]),
    Number(partsLon[2] + '.' + partsLon[3]),
    partsLon[4],
  )
  return [lat, lng]
}
function convertirDMSToDD(degrees, minutes, seconds, direction) {
  let dd = degrees + minutes / 60 + seconds / (60 * 60)

  if (
    direction === 'S' ||
    direction === 's' ||
    direction === 'W' ||
    direction === 'w'
  ) {
    dd = dd * -1
  } // Don't do anything for N or E
  return dd
}
export const convertirLatLonGMS = (valor, tipo) => {
  let grados = Math.abs(parseInt(valor))
  let minutos = (Math.abs(valor) - grados) * 60
  let segundos = minutos
  minutos = Math.abs(parseInt(minutos))
  segundos = Math.round((segundos - minutos) * 60 * 1000000) / 1000000
  const signo = valor < 0 ? -1 : 1
  const direccion =
    tipo === 'LATITUDE' ? (signo > 0 ? 'N' : 'S') : signo > 0 ? 'E' : 'W'

  if (isNaN(direccion)) {
    grados = grados * signo
  }

  return {
    grados: grados,
    minutos: minutos,
    segundos: segundos,
    direccion: direccion,
    valor:
      grados +
      'º ' +
      minutos.toString().padStart(2, '0') +
      '\' ' +
      segundos.toFixed(2).toString().padStart(5, '0').replace('.', ',') +
      '" ' +
      (isNaN(direccion) ? ' ' + direccion : ''),
  }
}

export const convertirETRS89 = (x, y) => {
  const zone = constants.HUSO_UTM
  const firstProjection = 'EPSG:3857'
  const secondProjection =
    '+proj=utm +zone=' + zone + ' +ellps=GRS80 +units=m +no_defs '
  return proj4(firstProjection, secondProjection, [
    parseFloat(x),
    parseFloat(y),
  ])
}
export const convertirLatLonToETRS89 = (lat, lon) => {
  const zone = constants.HUSO_UTM
  const firstProjection = 'EPSG:4326'
  const secondProjection =
    '+proj=utm +zone=' + zone + ' +ellps=GRS80 +units=m +no_defs '
  return proj4(firstProjection, secondProjection, [
    parseFloat(lon),
    parseFloat(lat),
  ])
}

export const convertirLatLonToEPSG3857 = (lat, lon) => {
  const firstProjection = 'EPSG:4326'
  const secondProjection = 'EPSG:3857'
  return proj4(firstProjection, secondProjection, [
    parseFloat(lon),
    parseFloat(lat),
  ])
}

export const convertirETRS89ToLatLon = (x, y) => {
  const zone = constants.HUSO_UTM
  const firstProjection =
    '+proj=utm +zone=' + zone + ' +ellps=GRS80 +units=m +no_defs'
  const secondProjection = 'EPSG:4326'
  return proj4(firstProjection, secondProjection, [
    parseFloat(x),
    parseFloat(y),
  ])
}
// #endregion

// #region TOKEN
export function getToken() {
  return new Promise((resolve, reject) => {
    const tokenData = constants.ArcGISToken

    const params = new FormData()
    params.append('client_id', tokenData.client_id)
    params.append('client_secret', tokenData.client_secret)
    params.append('grant_type', tokenData.grant_type)

    VueInst.$http
      .post(tokenData.urlToken, params)
      .then((response) => {
        const accessToken = response.data.access_token
        resolve(accessToken)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
async function registerToken() {
  const [IdentityManager] = await loadModules(['esri/identity/IdentityManager'])
  setBaseMap
  const urlSharePoint = constants.ArcGISToken.sharePoint + constants.webMap.id
  if ((await IdentityManager.findCredential(urlSharePoint)) === undefined) {
    const accessToken = await getToken()

    await IdentityManager.registerToken({
      server: urlSharePoint,
      token: accessToken,
    })
  }
}
// #endregion

// #region MAP MOVEMENT
export const setBaseMap = async (basemap) => {
  try {
    if (basemap.webMap && basemap.webMap === true) {
      map.basemap = baseMapWM
    } else {
      map.basemap = basemap.map
      /* mapView.constraints =  {
        rotationEnabled: false,
        maxZoom: constants.maxZoom,
        minZoom: constants.minZoom,
      } */
    }
    // console.log(mapView.zoom)
  } catch (err) {
    VueInst.$log.error(err)
  }
}
// Metodo antiguo: lo añade a cada mapview/sceneview segun se cambia
/* export const setSearchWidget = async () => {
  try {
    const [Search, Locator, Extent] = await loadModules(['esri/widgets/Search', 'esri/tasks/Locator', 'esri/geometry/Extent'])

    // Filtrar locator por extent de zona
    let paramsComunidad = store.getters['map/paramsComunidad']
    let extent = paramsComunidad ? new Extent(paramsComunidad.EXTENT) : null
    let searchFilter = {
      geometry: extent
    }

    let searchWidget = new Search({
      sources: [{
        locator: new Locator({ url: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer' }),
        countryCode: 'ES',
        singleLineFieldName: 'SingleLine',
        name: 'Geocoding España',
        localSearchOptions: {
          minScale: 300000,
          distance: 50000
        },
        filter: searchFilter,
        placeholder: 'Buscar',
        maxResults: 3,
        maxSuggestions: 6,
        suggestionsEnabled: true,
        minSuggestCharacters: 0,
        enableInfoWindow: false,
        popupEnabled: false
      }],
      view: mapView,
      includeDefaultSources: false,
      id: 'search'
    })

    // Add the search widget to the top right corner of the view if not exist
    if (!mapView.ui.find('search')) {
      mapView.ui.add(searchWidget, {
        position: 'top-right'
      })
    }
  } catch (err) {
    VueInst.$log.error(err)
  }
} */

// Metodo nuevo: crea uno al principio en la toolbar del mapa
let searchWidget = null
let bgExpand = null
let mobileSize = null
export const setSearchWidget = async () => {
  const deferred = Q.defer()

  try {
    const [Search, Expand, Extent, SpatialReference] = await loadModules([
      'esri/widgets/Search',
      'esri/widgets/Expand',
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference',
    ])

    // Filtrar locator por extent de zona
    // let paramsComunidad = store.getters['map/paramsComunidad']
    const extentConst = constants.mapExtent
    const extent = extentConst
      ? new Extent({
          xmin: extentConst.xmin,
          ymin: extentConst.ymin,
          xmax: extentConst.xmax,
          ymax: extentConst.ymax,
          spatialReference: new SpatialReference({ wkid: extentConst.wkid }),
        })
      : null
    const searchFilter = {
      geometry: extent,
    }

    searchWidget = new Search({
      sources: [
        {
          // locator: new Locator({ url: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer' }),
          url: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer',
          countryCode: 'ES',
          singleLineFieldName: 'SingleLine',
          name: 'Geocoding España',
          localSearchOptions: {
            minScale: 300000,
            distance: 50000,
          },
          filter: searchFilter,
          placeholder: 'Buscar',
          maxResults: 3,
          maxSuggestions: 6,
          suggestionsEnabled: true,
          minSuggestCharacters: 0,
          enableInfoWindow: false,
          popupEnabled: false,
        },
      ],
      view: mapView,
      includeDefaultSources: false,
      id: 'search',
    })

    bgExpand = new Expand({
      id: 'expandSearch',
      view: mapView,
      content: searchWidget,
      mode: 'floating',
      container: 'searchWidget',
      expandTooltip: 'Buscar en el mapa',
      collapseTooltip: 'Cerrar buscador',
      collapseIconClass: 'esri-icon-close',
    })

    mapView.when(() => {
      mobileSize = mapView.widthBreakpoint === 'xsmall'

      if (mobileSize) {
        bgExpand.collapse()
        // Boton searchWidget
        let widgetMobile
        setTimeout(function () {
          widgetMobile = document.getElementsByClassName('esri-widget--button')
          if (widgetMobile[0]) {
            widgetMobile[0].style.height = '32px'
            widgetMobile[0].style.width = '32px'
          }
        }, 100)

        // Icono searchWidget
        let iconWidget
        setTimeout(function () {
          iconWidget = document.getElementsByClassName(
            'esri-collapse__icon esri-icon-search',
          )
          if (iconWidget[0]) {
            iconWidget[0].style.fontSize = '18px'
          }
        }, 100)

        // Widget de leyenda
        let legendWidget
        setTimeout(function () {
          legendWidget = document.getElementsByClassName('esri-legend')
          if (legendWidget[0]) {
            legendWidget[0].style.maxHeight = '150px'
            legendWidget[0].style.width = 'auto'
          }
        }, 100)

        // Ubicacion ui izquierda
        let uiLeft
        setTimeout(function () {
          uiLeft = document.getElementsByClassName('esri-ui-bottom-left')
          if (uiLeft[0]) {
            uiLeft[0].style.bottom = '20px'
          }
        }, 100)

        // Mensaje de zoom en mapa
        const divMensaje = document.getElementById('above-map-msg')
        if (divMensaje) {
          divMensaje.style.width = '200px'
          divMensaje.style.fontSize = '16px'
        }

        // OverViewMap -> Ocultar
        let overViewDiv
        setTimeout(function () {
          overViewDiv = document.getElementById('overviewDiv')
          if (overViewDiv) {
            overViewDiv.style.visibility = 'hidden'
          }
        }, 100)
      } else {
        bgExpand.expand()
        // Boton searchWidget
        let widgetDesktop
        setTimeout(function () {
          widgetDesktop = document.getElementsByClassName('esri-widget--button')
          if (widgetDesktop[0]) {
            widgetDesktop[0].style.height = '40px'
            widgetDesktop[0].style.width = '40px'
          }
        }, 100)

        // Icono searchWidget
        let iconWidget
        setTimeout(function () {
          iconWidget = document.getElementsByClassName(
            'esri-collapse__icon esri-icon-search',
          )
          if (iconWidget[0]) {
            iconWidget[0].style.fontSize = '24px'
          }
        }, 100)

        // Widget de leyenda
        let legendWidget
        setTimeout(function () {
          legendWidget = document.getElementsByClassName('esri-legend')
          if (legendWidget[0]) {
            legendWidget[0].style.maxHeight = '350px'
            legendWidget[0].style.width = '300px'
          }
        }, 100)

        // Ubicacion ui izquierda
        let uiLeft
        setTimeout(function () {
          uiLeft = document.getElementsByClassName('esri-ui-bottom-left')
          // console.log('uiLeft: ', uiLeft)
          if (uiLeft[0]) {
            uiLeft[0].style.bottom = '20px'
          }
        }, 100)

        // Mensaje de zoom en mapa
        const divMensaje = document.getElementById('above-map-msg')
        if (divMensaje) {
          divMensaje.style.width = '400px'
          divMensaje.style.fontSize = '25px'
        }

        // OverViewMap -> Mostrar
        let overViewDiv
        setTimeout(function () {
          overViewDiv = document.getElementById('overviewDiv')
          if (overViewDiv) {
            overViewDiv.style.visibility = 'visible'
          }
        }, 100)
      }
    })
    mapView.ui.add(bgExpand, 'top-right')

    deferred.resolve()
  } catch (err) {
    VueInst.$log.error(err)
    deferred.reject(err)
  }

  return deferred.promise
}

function updateSearchWidgetView() {
  if (searchWidget) {
    searchWidget.view = mapView
  }
}

export const setZoom = (value) => {
  // console.log('Zoom: ', value)
  // console.log('Zoom actual: ', mapView.zoom)
  const newZoom = mapView.zoom + value
  // console.log('newZoom: ', newZoom)
  mapView.goTo({
    target: mapView.center,
    zoom: newZoom,
  })
}
export const setExtent = (extent) => {
  setExtentMap(extent)
}

export const setExtentHome = (extent) => {
  setExtentHomeDefault(extent)
}

async function setExtentMap(extent) {
  try {
    const [SpatialReference] = await loadModules([
      'esri/geometry/SpatialReference',
    ])

    const properties = {
      xmin: extent.xmin,
      ymin: extent.ymin,
      xmax: extent.xmax,
      ymax: extent.ymax,
    }

    if (extent.wkid) {
      properties.spatialReference = new SpatialReference({ wkid: extent.wkid })
    } else {
      properties.spatialReference = extent.spatialReference
    }

    /**
     * Comentar las siguienes dos líneas para que al cambiar de mapa base,
     * no se pierda la posición del usuario en el mapa ni el zoom.
     *
     * mapView.extent = new Extent(properties)
     * mapView.zoom = constants.initialZoom
     *
     * Si se descomentan, al cambiar de mapa base se pierde el zoom y el extent
     * del mapa, devuelve al usuario a la posición inicial.
     */
    // mapView.extent = new Extent(properties)
    // mapView.zoom = constants.initialZoom

    // if (mapView.zoom === -1 || mapView.zoom < 10) {
    //   mapView.zoom = 0
    // }

    // mapView.constraints.minZoom = 10

    // mapView.constraints.minZoom = 10
    // setCenterMap({target: mapView.extent, zoom: 0})
  } catch (err) {
    VueInst.$log.error(err)
  }
}

async function setExtentHomeDefault(extent) {
  try {
    const [Extent, SpatialReference] = await loadModules([
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference',
    ])

    const properties = {
      xmin: extent.xmin,
      ymin: extent.ymin,
      xmax: extent.xmax,
      ymax: extent.ymax,
    }

    if (extent.wkid) {
      properties.spatialReference = new SpatialReference({ wkid: extent.wkid })
    } else {
      properties.spatialReference = extent.spatialReference
    }

    mapView.extent = new Extent(properties)
    mapView.zoom = constants.initialZoomButtonHome
  } catch (err) {
    VueInst.$log.error(err)
  }
}

export const setCenterMap = (data) => {
  if (mapView) {
    mapView.goTo({
      target: data.target,
      zoom: data.zoom,
    })
  }
}
// export const centerCompass = () => {
//   if (mapView.rotation !== undefined) {
//     mapView.rotation = 0
//   } else {
//     mapView.goTo({
//       heading: 0,
//     })
//   }
// }
// #endregion

// #region CAPTURE MAP
export const captureMap = () => {
  const deferred = Q.defer()

  const pixelRatio = 3
  mapView
    .takeScreenshot({
      width: mapView.width * pixelRatio,
      height: mapView.height * pixelRatio,
      format: 'jpg',
    })
    .then((screenshot) => {
      deferred.resolve(screenshot.dataUrl)
    })

  return deferred.promise
}
export const setCenterAndCaptureMap = async (data) => {
  const deferred = Q.defer()

  if (mapView) {
    await mapView.goTo({
      target: data.target,
      zoom: data.zoom,
    })
    setTimeout(async () => {
      const image = await captureMap(mapView)
      deferred.resolve(image)
    }, 500)
  }

  return deferred.promise
}
// #endregion

// #region MEASUREMENT
let measurementTool
export const measure = async (type) => {
  try {
    const [Measurement] = await loadModules(['esri/widgets/Measurement'])

    if (!measurementTool) {
      // Se inicializa si no lo está ya
      measurementTool = new Measurement()

      mapView.ui.add(measurementTool, 'bottom-right')
      measurementTool.view = mapView
    }

    if (type === 'distance') {
      measurementTool.activeTool =
        mapView.type.toUpperCase() === '2D' ? type : 'direct-line'
    } else if (type === 'area') {
      measurementTool.activeTool = type
    }
  } catch (err) {
    VueInst.$log.error(err)
  }
}
export const clearMeasurement = () => {
  if (measurementTool) {
    measurementTool.clear()
    measurementTool = null
  }
}
// #endregion

// # LOAD GEOJSON
async function createGeoJsonLayer(layerItem) {
  const deferred = Q.defer()
  try {
    const [FeatureLayer] = await loadModules(['esri/layers/FeatureLayer'])
    const json = await VueInst.$http.get(layerItem.url)
    // Pos 0 -> Anillos, Pos 1 -> Exterior (contorno)
    const graphics = await createGraphicsPoligonos(json.data)
    //  let posGraphic = layerItem.pos
    const rendererGraphic = layerItem.renderer
    const opacityGraphic = layerItem.opacity
    const layer = new FeatureLayer({
      // TODO Cambiar por GeoJSONLayer ?? Revisar
      id: layerItem.id,
      title: layerItem.title,
      visible: layerItem.visible,
      source: graphics,
      objectIdField: 'Nombre',
      spatialReference: {
        wkid: 4326,
      },
      opacity: opacityGraphic,
      renderer: rendererGraphic,
      // contorno.renderer = renderer
    })
    deferred.resolve(layer)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}
async function createGraphicsPoligonos(response) {
  const deferred = Q.defer()
  try {
    const [Polygon] = await loadModules(['esri/geometry/Polygon'])
    var features = response.features.map(function (feature) {
      return {
        geometry: new Polygon({
          hasZ: false,
          hasM: false,
          rings: feature.geometry.rings,
          spatialReference: {
            wkid: 3857,
          },
        }),
        attributes: {
          // Nombre: feature.properties.Nombre
        },
      }
    })
    deferred.resolve(features)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}

// #region CONTORNO
// Carga las capas de contorno en formato GeoJSON
export const drawContorno = async (isWebMap) => {
  let numLayers
  const capasContorno = store.getters['map/layersContorno']
  if (isWebMap) {
    // Si no se han podido cargar localmente las capas, se usa el webmap
    numLayers = map.allLayers.length

    for (let i = 0; i < capasContorno.length; i++) {
      const capa = capasContorno[i]
      const geoJSON = await createGeoJsonLayer(capa, isWebMap)
      map.add(geoJSON, numLayers + i)
    }

    await createGraphicsContorno()
  } else {
    // Si se hace uso de los archivos de capas locales del webmap
    numLayers = store.getters['map/layersPrueba'].length

    for (let i = 0; i < capasContorno.length; i++) {
      const capa = capasContorno[i]
      const geoJSON = await createGeoJsonLayer(capa)
      map.add(geoJSON, numLayers + i)
    }

    await createGraphicsContorno()
  }
}

async function createGraphicsContorno() {
  const deferred = Q.defer()

  try {
    const [GeometryEngine] = await loadModules(['esri/geometry/geometryEngine'])

    /*let symbol = {
      type: 'simple-fill',
      color: [157, 198, 216, 1], // 0.3
      width: 1,
      /* outline: {
        // autocasts as new SimpleLineSymbol()
        color: [0, 0, 0, 1],
        width: 4
      } 
    }*/

    /* let worldExtent = new Extent({
      xmin: -20037508.34278924, // -20037508.3427892430765884088807
      ymin: -19971868.8804086,
      xmax: 20037508.34278924, // 20037508.3427892430765884088807
      ymax: 19971868.8804086,
      spatialReference: {
        wkid: 3857,
      },
    }) */

    // let worldPolygon = Polygon.fromExtent(worldExtent)

    const comunidad = {
      type: 'polygon',
      rings: store.getters['map/paramsComunidad'].RINGS,
      spatialReference: {
        wkid: 102100,
      },
    }

    const buffer = await GeometryEngine.buffer(comunidad, 10000)
    store.dispatch('map/setBufferComunidad', buffer) // Guardar poligono buffer

    /* let contorno = await GeometryEngine.difference(worldPolygon, comunidad)
    let contornoGraphic = new Graphic({
      geometry: contorno,
      symbol: symbol,
    }) */

    deferred.resolve()
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

export const pointInBuffer = async (lat, lon) => {
  const deferred = Q.defer()

  try {
    const [Point, GeometryEngine, projection] = await loadModules([
      'esri/geometry/Point',
      'esri/geometry/geometryEngine',
      'esri/geometry/projection',
    ])

    let point = new Point(lon, lat)
    point = projection.project(point, { wkid: 3857 })
    const buffer = store.getters['map/bufferComunidad']
    const contains = GeometryEngine.contains(buffer, point)

    deferred.resolve(contains)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}
// #endregion

// #region COORDS_MAP
export const drawPointCoords = async (lat, lon) => {
  try {
    const [Graphic, Point] = await loadModules([
      'esri/Graphic',
      'esri/geometry/Point',
    ])

    mapView.graphics = []

    if (lat && lon) {
      const symbol = {
        type: 'simple-marker',
        style: 'cross',
        outline: {
          color: 'yellow',
          width: '3px',
        },
      }

      const geometry = new Point({
        x: lon,
        y: lat,
      })

      const graphic = new Graphic({
        geometry: geometry,
        symbol: symbol,
      })
      mapView.graphics.add(graphic)
      setTimeout(deletePointCoords, 60 * 1000)
    }
  } catch (err) {
    VueInst.$log.error(err)
  }
}
function deletePointCoords() {
  mapView.graphics = []
}
// #endregion

// #region SHARE MAP SKETCH VIEW MODEL
let shareMapSVM = null
export const initShareMapSketchViewModel = async () => {
  try {
    const [SketchViewModel] = await loadModules([
      'esri/widgets/Sketch/SketchViewModel',
    ])

    const layer = map.findLayerById('layer-drawSketcher')

    shareMapSVM = new SketchViewModel({
      view: mapView,
      layer: layer,
    })

    shareMapSVM.on(['create', 'delete', 'update'], async (evt) => {
      if (evt.state === 'complete' && evt.type === 'create') {
        let graphic

        const attributes = {
          ID: VueInst.$uuid.createUUID(),
        }

        const color = [255, 100, 100, 0.4] // REMINDER: si no se pone el color en este formato, al convertirse a JSON el alpha se 'rompe'

        if (evt.graphic.geometry.type === 'multipoint') {
          for (let i = 0; i < evt.graphic.geometry.points.length; i++) {
            graphic = {
              attributes: attributes,
              coordenadas: evt.graphic.geometry.points[i],
              type: 'point',
              symbol: {
                type: shareMapSVM.pointSymbol.type,
                color: color, // shareMapSVM.pointSymbol.color,
                angle: shareMapSVM.pointSymbol.angle,
                size: shareMapSVM.pointSymbol.size,
                style: shareMapSVM.pointSymbol.style,
              },
            }
            store.dispatch('shareMap/addGraphics', graphic)
            return
          }
        } else if (evt.graphic.geometry.type === 'point') {
          graphic = {
            attributes: attributes,
            coordenadas: [evt.graphic.geometry.x, evt.graphic.geometry.y],
            type: evt.graphic.geometry.type,
            symbol: {
              type: shareMapSVM.pointSymbol.type,
              color: color, // shareMapSVM.pointSymbol.color,
              size: shareMapSVM.pointSymbol.size,
              style: shareMapSVM.pointSymbol.style,
            },
          }
        } else if (evt.graphic.geometry.type === 'polyline') {
          graphic = {
            attributes: attributes,
            coordenadas: evt.graphic.geometry.paths[0],
            type: 'polyline',
            symbol: {
              type: shareMapSVM.polylineSymbol.type,
              color: color, // shareMapSVM.polylineSymbol.color,
              width: shareMapSVM.polylineSymbol.width,
              style: shareMapSVM.polylineSymbol.style,
              join: shareMapSVM.polylineSymbol.join,
              miterLimit: shareMapSVM.polylineSymbol.miterLimit,
            },
          }
        } else if (evt.graphic.geometry.type === 'polygon') {
          graphic = {
            attributes: attributes,
            coordenadas: evt.graphic.geometry.rings[0],
            type: 'polygon',
            symbol: {
              type: shareMapSVM.polygonSymbol.type,
              color: color, // shareMapSVM.polygonSymbol.color,
              style: shareMapSVM.polygonSymbol.style,
              outline: {
                color: 'white',
                width: 0,
              },
            },
          }
        }

        store.dispatch('shareMap/addGraphics', graphic)
      } else if (evt.type === 'delete') {
        store.dispatch('shareMap/deleteGraphics', evt.graphics)
      } else if (evt.state === 'complete' && evt.type === 'update') {
        const geometry = JSON.parse(JSON.stringify(evt.graphics[0].geometry))
        geometry.type = evt.graphics[0].geometry.type

        const g = {
          attributes: evt.graphics[0].attributes,
          geometry: geometry,
        }
        store.dispatch('shareMap/updateGraphics', [g])
      }
    })
  } catch (err) {
    VueInst.$log.error(err)
  }
}

async function createSketch() {
  try {
    const [SketchViewModel] = await loadModules([
      'esri/widgets/Sketch/SketchViewModel',
    ])

    const layer = map.findLayerById('layer-drawSketcher')
    layer.visible = true

    shareMapSVM = new SketchViewModel({
      view: mapView,
      layer: layer,
    })

    shareMapSVM.on(['create', 'delete', 'update'], async (evt) => {
      if (evt.state === 'complete' && evt.type === 'create') {
        let graphic

        const attributes = {
          ID: VueInst.$uuid.createUUID(),
        }

        const color = [255, 100, 100, 0.4] // REMINDER: si no se pone el color en este formato, al convertirse a JSON el alpha se 'rompe'

        if (evt.graphic.geometry.type === 'multipoint') {
          for (let i = 0; i < evt.graphic.geometry.points.length; i++) {
            graphic = {
              attributes: attributes,
              coordenadas: evt.graphic.geometry.points[i],
              type: 'point',
              symbol: {
                type: shareMapSVM.pointSymbol.type,
                color: color, // shareMapSVM.pointSymbol.color,
                angle: shareMapSVM.pointSymbol.angle,
                size: shareMapSVM.pointSymbol.size,
                style: shareMapSVM.pointSymbol.style,
              },
            }
            // store.dispatch('shareMap/addGraphics', graphic)
            return
          }
        } else if (evt.graphic.geometry.type === 'point') {
          graphic = {
            attributes: attributes,
            coordenadas: [evt.graphic.geometry.x, evt.graphic.geometry.y],
            type: evt.graphic.geometry.type,
            symbol: {
              type: shareMapSVM.pointSymbol.type,
              color: color, // shareMapSVM.pointSymbol.color,
              size: shareMapSVM.pointSymbol.size,
              style: shareMapSVM.pointSymbol.style,
            },
          }
        } else if (evt.graphic.geometry.type === 'polyline') {
          graphic = {
            attributes: attributes,
            coordenadas: evt.graphic.geometry.paths[0],
            type: 'polyline',
            symbol: {
              type: shareMapSVM.polylineSymbol.type,
              color: color, // shareMapSVM.polylineSymbol.color,
              width: shareMapSVM.polylineSymbol.width,
              style: shareMapSVM.polylineSymbol.style,
              join: shareMapSVM.polylineSymbol.join,
              miterLimit: shareMapSVM.polylineSymbol.miterLimit,
            },
          }
        } else if (evt.graphic.geometry.type === 'polygon') {
          graphic = {
            attributes: attributes,
            coordenadas: evt.graphic.geometry.rings[0],
            type: 'polygon',
            symbol: {
              type: shareMapSVM.polygonSymbol.type,
              color: color, // shareMapSVM.polygonSymbol.color,
              style: shareMapSVM.polygonSymbol.style,
              outline: {
                color: 'white',
                width: 0,
              },
            },
          }
        }
      } else if (evt.state === 'complete' && evt.type === 'update') {
        const geometry = JSON.parse(JSON.stringify(evt.graphics[0].geometry))
        geometry.type = evt.graphics[0].geometry.type

        const g = {
          attributes: evt.graphics[0].attributes,
          geometry: geometry,
        }
      }
    })
  } catch (err) {
    VueInst.$log.error(err)
  }
}

export const paintEventShareMap = async (event) => {
  const layer = map.findLayerById('layer-drawSketcher')

  const typeEvent = event.function

  isDrawingArrow = false
  if (typeEvent === 'cancel') {
    shareMapSVM.cancel()
  } else if (typeEvent === 'reset') {
    layer.removeAll()
    shareMapSVM.delete()
  } else {
    await createSketch()
    shareMapSVM.create(typeEvent)
  }
}

export const drawShareMapGraphics = async (isNotEditingMode) => {
  const graphicsList = store.getters['shareMap/graphics']
  const graphics = await createGraphicsDraw(graphicsList)

  if (isNotEditingMode) {
    shareMapSVM.updateOnGraphicClick = false
  } else {
    shareMapSVM.updateOnGraphicClick = true
  }

  const graphicsSorted = sortGraphics(graphics)

  const layer = map.findLayerById('layer-drawSketcher')
  layer.removeAll()
  layer.addMany(graphicsSorted)
}

export const stopShareMapSketchViewModel = function () {
  store.dispatch('shareMap/removeAllGraphics')

  shareMapSVM.cancel()
  shareMapSVM.destroy()
}
// #endregion
