<template>
  <v-card tile>
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title>Administrar tipos de emergencia</v-toolbar-title>
    </v-toolbar>

    <v-card-text class="scrollable">
      <v-form ref="form" v-model="isValid">
        <v-container fluid>
          <!-- SELECT TIPO EMERGENCIA, SITUACION OPERATIVA -->
          <!-- CABECERA ASIGNACION TIPO DE EMERGENCIA -->
          <v-row>
            <v-col xs="12" sm="12">
              <span class="colorText">Asignación de tipo de emergencia</span>
              <v-divider class="colorDivider" />
            </v-col>
          </v-row>
          <!-- CABECERA TIPO EMERGENCIA y SITUACION OPERATIVA -->
          <v-row>
            <v-col xs="6" sm="6">
              <span>Tipo de emergencia</span>
            </v-col>
            <v-col xs="6" sm="6">
              <span>Situación operativa</span>
            </v-col>
          </v-row>
          <v-row class="rowSelect">
            <!-- SELECT TIPO EMERGENCIA -->
            <v-col xs="6" sm="6">
              <v-select
                label="Tipo emergencia"
                :items="tipoEmergencia"
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="nombre"
                item-value="nombre"
                v-model="tipoEmergenciaSelected"
                return-object
                :dense="$vuetify.breakpoint.mobile"
                outlined
                hide-details
                :rules="[globalRules.required]"
                @change="getListaAccionesDeSituacionOpertivaTipoEmer()"
              />
            </v-col>

            <!-- SELECT SITUACION OPERATIVA -->
            <v-col xs="6" sm="6">
              <v-select
                label="Situación operativa"
                :items="situacionOperativa"
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="nombre"
                item-value="nombre"
                v-model="situacionOperativaSelected"
                return-object
                :dense="$vuetify.breakpoint.mobile"
                outlined
                hide-details
                :rules="[globalRules.required]"
                @change="getListaAccionesDeSituacionOpertivaTipoEmer()"
              />
            </v-col>
          </v-row>

          <!-- CABECERA ASIGNACION DE ACCIONES -->
          <v-row>
            <v-col xs="12" sm="12">
              <span class="colorText">Asignación de acciones</span>
              <v-divider class="colorDivider" />
            </v-col>
          </v-row>

          <!-- CABECERA ACCIONES DISPONIBLES Y ACCIONES SLECCIONADAS -->
          <v-row>
            <!-- ACCIONES DISPONIBLES -->
            <v-col xs="6" sm="6">
              <span>Acciones disponibles</span>
              <v-row align="center" justify="center">
                <!-- LISTA ACCIONES DISPONIBLES -->
                <v-col xs="10" sm="10">
                  <v-card class="mx-auto" height="100%">
                    <v-list min-width="200px">
                      <v-list-item-group
                        v-model="modelDisponibles"
                        color="indigo"
                      >
                        <v-list-item
                          v-for="(item, i) in tipoEmergenciaSelected &&
                            situacionOperativaSelected &&
                            accionesDisponibles"
                          :key="i"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.nombre }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col>

                <!-- BOTONES ACCIONES -->
                <v-col xs="1" sm="2">
                  <!-- ASIGNAR ACCION -->
                  <v-row justify="center">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            fab
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="asignarAccionSeleccionada"
                          >
                            <!-- <v-icon>mdi-arrow-right</v-icon> -->
                            <img
                              :src="
                                hover
                                  ? getIcono('flecha_asignar_hover', hover)
                                  : getIcono('flecha_asignar', hover)
                              "
                            >
                          </v-btn>
                        </v-hover>
                      </template>
                      <span>Asignar acción seleccionada</span>
                    </v-tooltip>
                  </v-row>
                  <!-- ASIGNAR TODAS LAS ACCIONES -->
                  <!-- <v-row justify="center" class="rowButtons">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="asignarTodasAccionesDisponibles"
                    >
                      <v-icon>mdi-arrow-right-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Asignar todas las acciones</span>
                </v-tooltip>
              </v-row> -->
                  <!-- QUITAR ACCION ASIGNADA -->
                  <v-row justify="center">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            fab
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="quitarAccionSeleccionada"
                          >
                            <!-- <v-icon>mdi-arrow-left</v-icon> -->
                            <img
                              :src="
                                hover
                                  ? getIcono('flecha_quitar_hover', hover)
                                  : getIcono('flecha_quitar', hover)
                              "
                            >
                          </v-btn>
                        </v-hover>
                      </template>
                      <span>Quitar acción seleccionada</span>
                    </v-tooltip>
                  </v-row>
                  <!-- QUITAR TODAS LAS ACCIONES ASIGNADAS-->
                  <!-- <v-row justify="center" class="rowButtons">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="quitarTodasAccionesAsignadas"
                    >
                      <v-icon>mdi-arrow-left-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Quitar todas las acciones</span>
                </v-tooltip>
              </v-row> -->
                </v-col>
              </v-row>
            </v-col>

            <!-- ACCIONES SELECCIONADAS -->
            <v-col xs="8" sm="6">
              <span>Acciones seleccionadas</span>
              <v-row align="center" justify="center">
                <!-- LISTA ACCIONES ASIGNADAS -->
                <v-col xs="10" sm="10">
                  <v-card class="mx-auto" height="100%">
                    <v-list min-width="200px">
                      <v-list-item-group
                        v-model="modelAsignadas"
                        color="indigo"
                      >
                        <v-list-item
                          v-for="(item, i) in accionesAsignadas"
                          :key="i"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.nombre }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col>

                <!-- BOTONES ORDENAR ACCIONES -->
                <v-col xs="1" sm="2">
                  <!-- SUBIR ACCION A LA PRIMERA POSICION -->
                  <v-row justify="center" class="rowButtons">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            fab
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="subirPrimeraPosicion()"
                          >
                            <!-- <v-icon>mdi-arrow-up-circle</v-icon> -->
                            <img
                              :src="
                                hover
                                  ? getIcono('flecha_inicio_hover', hover)
                                  : getIcono('flecha_inicio', hover)
                              "
                            >
                          </v-btn>
                        </v-hover>
                      </template>
                      <span>Subir al inicio</span>
                    </v-tooltip>
                  </v-row>
                  <!-- SUBIR ACCION SELECCIONADA 1 POSICION -->
                  <v-row justify="center" class="rowButtons">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            fab
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="subirPosicion()"
                          >
                            <!-- <v-icon>mdi-arrow-up</v-icon> -->
                            <img
                              :src="
                                hover
                                  ? getIcono('flecha_arriba_hover', hover)
                                  : getIcono('flecha_arriba', hover)
                              "
                            >
                          </v-btn>
                        </v-hover>
                      </template>
                      <span>Subir 1 posición</span>
                    </v-tooltip>
                  </v-row>
                  <!-- BAJAR ACCION SELECCIONADA 1 POSICION -->
                  <v-row justify="center" class="rowButtons">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            fab
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="bajarPosicion()"
                          >
                            <!-- <v-icon>mdi-arrow-down</v-icon> -->
                            <img
                              :src="
                                hover
                                  ? getIcono('flecha_abajo_hover', hover)
                                  : getIcono('flecha_abajo', hover)
                              "
                            >
                          </v-btn>
                        </v-hover>
                      </template>
                      <span>Bajar 1 posición</span>
                    </v-tooltip>
                  </v-row>
                  <!-- BAJAR ACCION A LA ULTIMA POSICION -->
                  <v-row justify="center" class="rowButtons">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            fab
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="bajarUltimaPosicion()"
                          >
                            <!-- <v-icon>mdi-arrow-down-circle</v-icon> -->
                            <img
                              :src="
                                hover
                                  ? getIcono('flecha_fin_hover', hover)
                                  : getIcono('flecha_fin', hover)
                              "
                            >
                          </v-btn>
                        </v-hover>
                      </template>
                      <span>Bajar al final</span>
                    </v-tooltip>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- ACCIONES TIPO EMERGENCIA DE SITUACION OPERATIVA -->
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="red" outlined @click="cancelar" class="mr-5">
        <v-icon>mdi-window-close</v-icon>
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        :disabled="!isValid || !accionesAsignadas.length"
        @click="aceptar"
        class="mr-5 ml-5"
      >
        <v-icon>mdi-check</v-icon>
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import constants from '@/helpers/constants'

  import { SituacionOperativaRequest } from '@/api/situacionOperativa'
  import { TipoEmergenciaRequest } from '@/api/tipoEmergencia'

  export default {
    data: () => ({
      isValid: false,
      isLoading: false,

      tipoEmergencia: [],
      tipoEmergenciaSelected: null,

      situacionOperativa: [],
      situacionOperativaSelected: null,

      accionesDisponibles: [],
      accionesAsignadas: [],

      modelDisponibles: undefined,
      modelAsignadas: undefined,
    }),

    watch: {},

    computed: {
      ...mapGetters('tipoAccion', ['tiposAccion']),
      ...mapGetters('usuario', ['currentUsuario']),
    },

    methods: {
      ...mapActions('tipoAccion', [
        'fetchTiposAccion',
        'addTipoAccion',
        'updateTipoAccion',
      ]),
      ...mapActions('accionSituacionTipoEmergencia', [
        'assignUnassignAccionSituacionTipoEmergencia',
      ]),

      /**
       * INICIO. Mover acciones disponibles a asignadas
       */
      async asignarAccionSeleccionada() {
        if (this.modelDisponibles !== undefined) {
          // Accion disponible seleccionada
          const accionSelected = this.accionesDisponibles[this.modelDisponibles]

          // Añadir preseleccionados en la lista derecha
          this.accionesAsignadas.push(Object.assign({}, accionSelected))

          // Eliminar accion disponible seleccionada en la lista izquierda
          const indexDisponible = this.accionesDisponibles.findIndex(
            (x) => x.id === accionSelected.id,
          )

          // Eliminar accion disponible asignada
          indexDisponible !== -1 &&
            (await this.eliminarAccionDisponible(indexDisponible))
        }
      },

      eliminarAccionDisponible(posAccionDisponible) {
        // Buscar el indice del objeto en el array de acciones disponibles y eliminarlo
        if (posAccionDisponible !== -1) {
          this.accionesDisponibles.splice(posAccionDisponible, 1)
          this.modelDisponibles = undefined
        }
      },
      /**
       * FIN. Mover acciones disponibles a asignadas
       */

      /**
       * INICIO. Mover acciones asignadas a disponibles.
       */
      async quitarAccionSeleccionada() {
        if (this.modelAsignadas !== undefined) {
          // Accion asignada seleccionada
          const accionSelected = this.accionesAsignadas[this.modelAsignadas]

          // Añadir preselecionadas en la lista izquierda
          this.accionesDisponibles.push(Object.assign({}, accionSelected))

          // Eliminar accion asignada seleccionada en la lista derecha
          const indexAsignada = this.accionesAsignadas.findIndex(
            (x) => x.id === accionSelected.id,
          )

          // Eliminar accion asignada seleccionada
          indexAsignada !== -1 &&
            (await this.eliminarAccionAsignada(indexAsignada))

          // Ordenar lista izquierda de acciones disponibles
          await this.ordenarListaAcciones(this.accionesDisponibles)
        }
      },

      eliminarAccionAsignada(posAccionAsignada) {
        // Buscar el indice del objeto en el array de acciones asignadas y eliminarlo
        if (posAccionAsignada !== -1) {
          this.accionesAsignadas.splice(posAccionAsignada, 1)
          this.modelAsignadas = undefined
        }
      },
      /**
       * FIN. Mover acciones asignadas a disponibles.
       */

      ordenarListaAcciones(listaAcciones) {
        // Ordenar por nombre asc la lista recibida (disponibles o asignadas)
        listaAcciones.sort((a, b) => {
          const aOrden = a.nombre
          const bORden = b.nombre
          return aOrden < bORden ? -1 : 1
        })
      },

      /**
       * INICIO. Ordenar lista de acciones asignadas.
       */
      async subirPosicion() {
        if (this.modelAsignadas !== undefined) {
          // Accion asignada seleccionada
          const accionSelected = this.accionesAsignadas[this.modelAsignadas]

          // Buscar indice de la accion en la lista de acciones asignadas
          const indexAccion = this.accionesAsignadas.findIndex(
            (x) => x.id === accionSelected.id,
          )

          // Mover accion en la lista
          if (indexAccion > 0) {
            await this.actualizarPosicionLista(
              'subir',
              accionSelected,
              indexAccion,
            )
            await this.actualizarPosicionModel('subir')
            await this.refrescarLista()
          }
        }
      },

      async bajarPosicion() {
        if (this.modelAsignadas !== undefined) {
          // Accion asignada seleccionada
          const accionSelected = this.accionesAsignadas[this.modelAsignadas]

          // Buscar indice de la accion en la lista de acciones asignadas
          const indexAccion = this.accionesAsignadas.findIndex(
            (x) => x.id === accionSelected.id,
          )

          // Mover accion en la lista
          if (indexAccion < this.accionesAsignadas.length - 1) {
            await this.actualizarPosicionLista(
              'bajar',
              accionSelected,
              indexAccion,
            )
            await this.actualizarPosicionModel('bajar')
            await this.refrescarLista()
          }
        }
      },

      async subirPrimeraPosicion() {
        if (this.modelAsignadas !== undefined) {
          // Accion asignada seleccionada
          const accionSelected = this.accionesAsignadas[this.modelAsignadas]

          // Buscar indice de la accion en la lista de acciones asignadas
          const indexAccion = this.accionesAsignadas.findIndex(
            (x) => x.id === accionSelected.id,
          )

          // Mover accion en la lista
          if (indexAccion > 0) {
            await this.actualizarPosicionLista(
              'subirInicio',
              accionSelected,
              indexAccion,
            )
            await this.actualizarPosicionModel('subirInicio')
            await this.refrescarLista()
          }
        }
      },

      async bajarUltimaPosicion() {
        if (this.modelAsignadas !== undefined) {
          // Accion asignada seleccionada
          const accionSelected = this.accionesAsignadas[this.modelAsignadas]

          // Buscar indice de la accion en la lista de acciones asignadas
          const indexAccion = this.accionesAsignadas.findIndex(
            (x) => x.id === accionSelected.id,
          )

          // Mover accion en la lista
          if (indexAccion < this.accionesAsignadas.length - 1) {
            await this.actualizarPosicionLista(
              'bajarFinal',
              accionSelected,
              indexAccion,
            )
            await this.actualizarPosicionModel('bajarFinal')
            await this.refrescarLista()
          }
        }
      },

      actualizarPosicionLista(ordenacion, accionSelected, indexAccion) {
        if (ordenacion === 'subir') {
          const accion = accionSelected
          this.accionesAsignadas[indexAccion] =
            this.accionesAsignadas[indexAccion - 1]
          this.accionesAsignadas[indexAccion - 1] = accion
        } else if (ordenacion === 'bajar') {
          const accion = accionSelected
          this.accionesAsignadas[indexAccion] =
            this.accionesAsignadas[indexAccion + 1]
          this.accionesAsignadas[indexAccion + 1] = accion
        } else if (ordenacion === 'subirInicio') {
          // Eliminar accion de la posicion actual en la lista
          const moverAccionInicio = this.accionesAsignadas.splice(
            indexAccion,
            1,
          )[0]
          // Insertar accion al inicio de la lista
          this.accionesAsignadas.unshift(moverAccionInicio)
        } else if (ordenacion === 'bajarFinal') {
          // Eliminar accion de la posicion inicial en la lista
          const moverAccionFinal = this.accionesAsignadas.splice(
            indexAccion,
            1,
          )[0]
          // Insertar accion al final de la lista
          this.accionesAsignadas.push(moverAccionFinal)
        }
      },

      actualizarPosicionModel(ordenacion) {
        if (ordenacion === 'subir') {
          this.modelAsignadas = this.modelAsignadas - 1
        } else if (ordenacion === 'bajar') {
          this.modelAsignadas = this.modelAsignadas + 1
        } else if (ordenacion === 'subirInicio') {
          this.modelAsignadas = 0
        } else if (ordenacion === 'bajarFinal') {
          this.modelAsignadas = this.accionesAsignadas.length - 1
        }
      },

      refrescarLista() {
        // Actualizar lista acciones asignadas
        const tmp = this.accionesAsignadas
        this.accionesAsignadas = []
        this.accionesAsignadas = tmp
      },
      /**
       * FIN. Ordenar lista de acciones asignadas.
       */

      async aceptar() {
        const accionSituacionTipoEmer = {
          id_tipo_emergencia: this.tipoEmergenciaSelected.id,
          id_tipo_situacion: this.situacionOperativaSelected.id,
          acciones: this.accionesAsignadas,
          usuariostamp: this.currentUsuario.ID,
        }

        try {
          await this.assignUnassignAccionSituacionTipoEmergencia(
            accionSituacionTipoEmer,
          )

          // Actualizar lista de acciones de la vista principal
          await this.fetchTiposAccion()
        } catch (err) {
          // Do nothing, se gestiona la excepción en accionSituacionTipoEmergencia.js
        }
      },

      cancelar() {
        this.clean()
        // Redirigir al usuario al modulo de acciones
        this.$router.push('/acciones')
      },

      clean() {
        this.accionesAsignadas = []
        this.accionesDisponibles = []
        this.$refs.form.reset()
      },

      getIcono(icono) {
        // console.log('HOVER: ', hover)
        switch (icono) {
          case 'flecha_asignar':
            return constants.icons.flechaAsignar
          case 'flecha_asignar_hover':
            return constants.icons.flechaAsignarHover
          case 'flecha_quitar':
            return constants.icons.flechaQuitar
          case 'flecha_quitar_hover':
            return constants.icons.flechaQuitarHover
          case 'flecha_inicio':
            return constants.icons.flechaInicio
          case 'flecha_inicio_hover':
            return constants.icons.flechaInicioHover
          case 'flecha_arriba':
            return constants.icons.flechaArriba
          case 'flecha_arriba_hover':
            return constants.icons.flechaArribaHover
          case 'flecha_abajo':
            return constants.icons.flechaAbajo
          case 'flecha_abajo_hover':
            return constants.icons.flechaAbajoHover
          case 'flecha_fin':
            return constants.icons.flechaFin
          case 'flecha_fin_hover':
            return constants.icons.flechaFinHover
        }
      },

      async getListaAccionesDeSituacionOpertivaTipoEmer() {
        if (this.tipoEmergenciaSelected && this.situacionOperativaSelected) {
          // Reiniciar modelsListas
          this.modelDisponibles = undefined
          this.modelAsignadas = undefined

          const listaAcciones = await this.$store.getters[
            'accionSituacionTipoEmergencia/accionesByTipoEmerSitOperativaId'
          ]({
            tipoEmergenciaId: this.tipoEmergenciaSelected.id,
            situacionOperativaId: this.situacionOperativaSelected.id,
          })

          // Cargar lista de acciones de la situacion operativa
          await this.cargarAccionesAsignadasTipoEmerSitOp(listaAcciones)
        }
      },

      async cargarAccionesAsignadasTipoEmerSitOp(listaAcciones) {
        if (listaAcciones && listaAcciones.Acciones.length) {
          this.accionesAsignadas = []
          for (const accion of listaAcciones.Acciones) {
            // Mostrar acciones asignadas a la situacion operativa y tipo emergencia
            this.accionesAsignadas.push(accion)
          }
          // Quitar acciones disponibles
          await this.quitarAccionesDisponibles()
        } else {
          // Si no tiene acciones, limpiar lista de acciones asignadas
          this.accionesAsignadas = []
          this.accionesDisponibles = JSON.parse(
            JSON.stringify(this.tiposAccion),
          )
        }
      },

      quitarAccionesDisponibles() {
        this.accionesDisponibles = JSON.parse(JSON.stringify(this.tiposAccion))
        for (const accionAsignada of this.accionesAsignadas) {
          const posEncontrado = this.accionesDisponibles.findIndex(
            (x) => x.id === accionAsignada.id,
          )

          posEncontrado !== -1 &&
            this.accionesDisponibles.splice(posEncontrado, 1)
        }
      },

      async loadData() {
        this.sLoading = true
        try {
          // TipoEmergencia
          this.tipoEmergencia = await TipoEmergenciaRequest.getAll()
          // SituacionOperativa
          this.situacionOperativa = await SituacionOperativaRequest.getAll()
          // TipoAccion
          await this.fetchTiposAccion()
          if (this.tiposAccion.length) {
            this.accionesDisponibles = JSON.parse(
              JSON.stringify(this.tiposAccion),
            )
          }
        } catch (err) {
          if (err.response.status !== 200 && err.response.status !== 500) {
            this.$notify({
              title: err.response.statusText,
              text: 'El usuario no dispone de permisos para acceder al módulo de Administración de flujos de trabajo',
              type: 'error',
            })
          }
          this.$router.push('/')
          // Do nothing, gestionamos la excepción en tipoEmergencia.js y situacionOperativa.js
        }
        this.isLoading = false
      },
    },

    async created() {},

    async activated() {
      await this.loadData()
    },

    deactivated() {
      this.clean()
    },
  }
</script>

<style scoped>
  .scrollable {
    height: calc(100vh - 165px);
    overflow-y: auto;
  }

  /* Estilo cabecera asignacion de tipo de emergencia */
  .colorText {
    color: #003df6;
    font-weight: bold;
  }

  /* Estilo divider */
  .colorDivider {
    background: #003df6;
    border-bottom-width: thin;
  }

  /* Espaciado de las cabeceras de tipo emergencia y situacion operativa */
  .row + .row {
    margin-top: -3px;
  }

  /* Separacion entre row con select y row asignacion de acciones con divider */
  .rowSelect {
    margin-bottom: 20px;
  }

  /* limitar alto de la lista y añadir scroll */
  .v-list {
    height: calc(100vh - 458px);
    overflow-y: auto;
  }

  /* Ancho filas de botones */
  .rowButtons {
    /* max-width: 20px; */
    margin-bottom: 1px;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }
</style>
