<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <a :href="fileUrl">
          <canvas :id="_uid" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import * as pdfjsLib from 'pdfjs-dist'
  pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`

  export default {
    name: 'PdfPreview',
    props: {
      fileUrl: {
        type: String,
      },
      width: {
        type: Number,
        default: 200,
      },
      height: {
        type: Number,
        default: 200,
      },
    },

    watch: {
      fileUrl(newValue) {
        if (newValue) {
          this.renderThumbnails()
        }
      },
    },
    mounted() {},
    methods: {
      async renderThumbnails() {
        // const worker = new pdfjsLib.PDFWorker()
        const pdfAtob = atob(this.fileUrl)

        const pdf = await pdfjsLib
          .getDocument({ data: pdfAtob })
          .promise.then((pdf) => pdf)

        const page = await pdf.getPage(1)

        let viewport = page.getViewport({ scale: 1.5 })
        viewport = page.getViewport({ scale: this.width / viewport.width })

        const canvas = document.getElementById(this._uid)
        canvas.height = viewport.height
        canvas.width = viewport.width

        const context = canvas.getContext('2d')

        await page.render({ canvasContext: context, viewport: viewport })
      },
    },
  }
</script>

<style></style>
