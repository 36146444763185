<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title>Gestión de entidades</v-toolbar-title>

      <!-- AÑADIR DELEGACION -->
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            bottom
            right
            absolute
            color="primary"
            v-show="comprobarPermisos(['MOD_ENTIDADES'])"
            @click="showAddEntidadDialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Añadir entidad</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="6" md="3" sm="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Búsqueda"
              outlined
              :dense="$vuetify.breakpoint.mobile"
              clearable
              hide-details
              @input="searchEntidades"
            />
          </v-col>

          <v-col cols="6" md="3" sm="3">
            <v-select
              label="Entidad"
              :items="entidades"
              :menu-props="{ bottom: true, offsetY: true }"
              item-text="entidad_titular"
              item-value="entidad_titular"
              v-model="entidadSelected"
              return-object
              outlined
              :dense="$vuetify.breakpoint.mobile"
              hide-details
              :clearable="true"
            />
          </v-col>
        </v-row>

        <v-row>
          <!-- <v-col xs="12" sm="12"> -->
          <v-data-table
            :class="{
              table: !$vuetify.breakpoint.mobile,
              tableMobile: $vuetify.breakpoint.mobile,
            }"
            :headers="headersEntidad"
            :items="
              entidadesFilter ? entidadesFilter : entidades ? entidades : []
            "
            height="100%"
            :loading="isLoading"
            loading-text="Cargando datos... por favor, espere"
            item-key="id_entidad"
            fixed-header
            hide-default-footer
            :page.sync="currentPage"
            :items-per-page="itemsPerPage"
          >
            <!-- NO DATA -->
            <template #no-data>
              <p>Datos no disponibles.</p>
            </template>

            <!-- ENTIDAD -->
            <template #[`item.entidad_titular`]="{ item }">
              <span v-if="item.logo">
                <v-avatar size="30">
                  <img :src="$options.filters.base64Image(item.logo)">
                </v-avatar>
                {{ item.entidad_titular }}
              </span>
              <span v-else>{{ item.entidad_titular }}</span>
            </template>

            <!-- LISTA GRUPO ACCIONES -->
            <template #[`item.Grupo_accions`]="{ item }">
              <!-- <ul> -->
              <div
                v-for="(subItem, iSub) in item.Grupo_accions"
                :key="subItem.id_grupo_accion"
              >
                <span v-if="iSub < item.Grupo_accions.length - 1">
                  {{ subItem.nombre }},</span>
                <span v-else> {{ subItem.nombre }} </span>
                <!-- <li> {{ subItem.nombre }} </li> -->
              </div>
              <!-- </ul> -->
            </template>

            <!-- CONVENIO DE COLABORACION -->
            <template #[`item.convenio`]="{ item }">
              <span v-if="item.convenio === false || item.convenio === null">No</span>
              <span v-else-if="item.convenio && item.documentacion">
                Sí
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="getDocumento(item.id_entidad)"
                    >
                      <!-- <v-icon width="40px" height="40px" color="primary">mdi-file-document</v-icon> -->
                      <img :src="iconoTipoArchivo(item)">
                    </v-btn>
                  </template>
                  <span>Descargar documento</span>
                </v-tooltip>
              </span>
              <span v-else>Sí</span>
            </template>

            <!-- Fecha ultima actualizacion -->
            <template #[`item.fechastamp`]="{ item }">
              <span>{{ item.fechastamp | formatDate }}</span>
            </template>

            <!-- Fecha de recogida de info -->
            <template #[`item.recogida_info`]="{ item }">
              <span> {{ item.recogida_info | formatDate }} </span>
            </template>

            <!-- USUARIO -->
            <template #[`item.Usuario`]="{ item }">
              <span>{{
                item.Usuario.nombre + ' ' + item.Usuario.apellidos
              }}</span>
            </template>

            <!-- ACCIONES ENTIDAD -->
            <template #[`item.ACCIONES`]="{ item }">
              <!-- EDITAR ENTIDAD -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :disabled="
                      !entidadCurrentUser(item.id_entidad) &&
                        !comprobarPermisos([])
                    "
                    v-show="comprobarPermisos(['MOD_ENTIDADES'])"
                    @click="showEditEntidadDialog(item)"
                  >
                    <v-icon color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <!-- BORRAR ENTIDAD -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :disabled="
                      !entidadCurrentUser(item.id_entidad) &&
                        !comprobarPermisos([])
                    "
                    v-show="comprobarPermisos(['MOD_ENTIDADES'])"
                    @click="acceptDeleteEntidad(item)"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Borrar</span>
              </v-tooltip>

              <!-- Visualizar delegaciones de entidad -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="primary"
                    v-show="
                      comprobarPermisos(['VER_ENTIDADES', 'MOD_ENTIDADES'])
                    "
                    @click="verDelegacionesEntidad(item)"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Ver delegaciones de entidad</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <!-- </v-col> -->
        </v-row>

        <!-- SECCIÓN PAGINACIÓN -->
        <v-row>
          <v-col xs="2" sm="3" md="2" v-if="!$vuetify.breakpoint.mobile">
            <v-row>
              <div class="text-center align-center mt-6 pl-6 ml-6">
                <span>Total {{ totalRecords }} registros</span>
              </div>
            </v-row>
          </v-col>
          <v-col xs="1" sm="4" md="3">
            <v-row>
              <v-col sm="4" md="4" class="textMostrar">
                <div>
                  <span>Mostrar</span>
                </div>
              </v-col>
              <v-col xs="4" sm="5" md="4">
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="itemsPerPage"
                  :items="perPageOptions"
                  :class="{
                    select: !$vuetify.breakpoint.mobile,
                    selectMobile: $vuetify.breakpoint.mobile,
                  }"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="6">
            <div>
              <v-pagination v-model="currentPage" :length="pageCount" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- DIALOG ADD/EDIT ENTIDAD -->
    <vx-dialog-add-edit-entidad
      :show="showDialog"
      :isEdit="isEdit"
      :editedEntidad="editEntidad"
      @cancelar="showDialog = false"
      @addEntidad="acceptAddEntidad"
      @editEntidad="acceptEditEntidad"
    />
  </v-card>
</template>

<script>
  import constants from '@/helpers/constants'
  import { mapActions, mapGetters } from 'vuex'
  import { StringHelper } from '@/helpers/StringHelper'
  import DialogaddEditEntidad from './DialogAddEditEntidad.vue'

  export default {
    components: {
      'vx-dialog-add-edit-entidad': DialogaddEditEntidad,
    },

    data: () => ({
      isLoading: false,
      search: '',
      entidadesFilter: null,
      entidadSelected: null,
      showDialog: false,
      editEntidad: {},
      isEdit: false,
      currentPage: 1,
      itemsPerPage: 15,
      perPageOptions: [15, 50, 75, 100],
    }),

    watch: {},

    computed: {
      entidades() {
        return this.$store.getters['entidad/entidades']
      },
      ...mapGetters('entidad', ['getDocumentoByEntidad']),
      ...mapGetters('usuario', ['permisosEntidad']),

      headersEntidad() {
        return [
          {
            text: 'Entidad',
            value: 'entidad_titular',
            align: 'left',
            width: '300px',
            sortable: true,
            filter: this.filterEntidad,
          },
          {
            text: 'Tipo entidad',
            value: 'Tipo_entidad.nombre',
            align: 'left',
            width: '120px',
            sortable: true,
          },
          {
            text: 'Grupo acción',
            value: 'Grupo_accions',
            align: 'left',
            width: '130px',
            sortable: true,
          },
          {
            text: 'Dirección',
            value: 'domicilio',
            align: 'left',
            width: '120px',
            sortable: true,
          },
          {
            text: 'Distrito',
            value: 'Distrito.nombre',
            align: 'left',
            width: '140px',
            sortable: true,
          },
          //   { text: 'Delegación', value: 'delegacion', align: 'left', sortable: true },

          {
            text: 'Convenio de colaboración',
            value: 'convenio',
            align: 'left',
            width: '130px',
            sortable: true,
          },
          {
            text: 'Actualización de datos',
            value: 'Tipo_actualizacion.nombre',
            align: 'left',
            width: '50px',
            sortable: true,
          },
          {
            text: 'Periodicidad',
            value: 'Periodicidad.nombre',
            align: 'left',
            width: '120px',
            sortable: true,
          },
          {
            text: 'Fecha recogida información',
            value: 'recogida_info',
            align: 'left',
            width: '120px',
            sortable: true,
          },
          {
            text: 'Ult. actualización',
            value: 'fechastamp',
            align: 'left',
            width: '120px',
            sortable: true,
          },
          {
            text: 'Usuario',
            value: 'Usuario',
            align: 'left',
            width: '140px',
            sortable: true,
          },
        ]
      },

      totalRecords() {
        return this.entidadesFilter
          ? this.entidadesFilter.length
          : this.entidades
          ? this.entidades.length
          : 0
      },

      pageCount() {
        return parseInt(this.totalRecords / this.itemsPerPage + 1)
      },
    },

    methods: {
      ...mapActions('entidad', [
        'fetchEntidades',
        'fetchListaEntidades',
        'addEntidad',
        'updateEntidad',
        'addDelegacion',
        'updateDelegacion',
      ]),
      ...mapActions('distrito', ['fetchDistritos']),
      ...mapActions('tipoEntidad', ['fetchTiposEntidad']),
      ...mapActions('grupoAccion', ['fetchGruposAccion']),
      ...mapActions('periodicidad', ['fetchPeriodicidades']),
      ...mapActions('tipoActualizacion', ['fetchTiposActualizacion']),

      async loadEntidades() {
        this.isLoading = true
        try {
          await this.fetchEntidades()
          await this.fetchTiposEntidad()
          await this.fetchDistritos()
          await this.fetchGruposAccion()
          await this.fetchPeriodicidades()
          await this.fetchTiposActualizacion()
        } catch (err) {
          if (err.response.status !== 200 && err.response.status !== 500) {
            this.$notify({
              title: err.response.statusText,
              text: 'El usuario no dispone de permisos para acceder al módulo de Gestión de entidades',
              type: 'error',
            })
          }
          this.$router.push('/')
        }
        this.isLoading = false
      },

      comprobarPermisos(listaRolesPermisos) {
        return this.$store.getters['usuario/rolesUsuario'](listaRolesPermisos)
      },

      entidadCurrentUser(id_entidad) {
        return this.$store.getters['usuario/permisosEntidad'](id_entidad)
      },

      verDelegacionesEntidad(entidad) {
        this.$router
          .push({
            path: '/entidades/delegaciones/',
            query: { id_entidad: entidad.id_entidad },
          })
          .catch(() => {})
      },

      showAddEntidadDialog() {
        this.editEntidad = null
        this.showDialog = true
        this.isEdit = false
      },

      showEditEntidadDialog(item) {
        this.editEntidad = Object.assign({}, item)
        this.showDialog = true
        this.isEdit = true
      },

      async acceptAddEntidad(entidad) {
        this.showDialog = false
        await this.addEntidad(entidad)
        await this.fetchEntidades()
        await this.fetchListaEntidades()
      },

      async acceptEditEntidad(entidad) {
        this.showDialog = false
        await this.updateEntidad(entidad)
        await this.fetchEntidades()
        await this.fetchListaEntidades()
      },

      async acceptDeleteEntidad(entidad) {
        if (this.departamentoEntidadTieneUnidadesDeMedioAsignadas(entidad)) {
          this.notificarDepartamentoEntidadTieneUnidadMedioAsignada()
        } else {
          const msg = 'Eliminando la entidad: ' + entidad.entidad_titular
          this.$root.$confirmDialog
            .open('¿Está seguro?', msg)
            .then((confirmacionUsuario) => {
              if (confirmacionUsuario) {
                this.eliminarEntidad(entidad)
              }
            })
        }
      },

      async eliminarEntidad(entidad) {
        await this.$store.dispatch('entidad/deleteEntidad', entidad)
        await this.fetchEntidades()
        await this.fetchListaEntidades()
        await this.$store.dispatch(
          'entidad/deleteEntidadByIdListaStore',
          entidad,
        )
      },

      departamentoEntidadTieneUnidadesDeMedioAsignadas(entidad) {
        // Comprobar que los departamentos de las delegaciones de la entidad a borrar, no tienen unidades de medios asignadas
        let tieneUnidadMedio = false
        entidad.Delegacions.forEach((delegacion) => {
          delegacion.Departamentos.forEach((departamento) => {
            departamento.Unidad_medios.forEach((unidadMedio) => {
              if (unidadMedio) {
                tieneUnidadMedio = true
              }
            })
          })
        })

        return tieneUnidadMedio
      },

      notificarDepartamentoEntidadTieneUnidadMedioAsignada() {
        this.$notify({
          title: 'Departamento de la entidad con medios',
          text: 'Es necesario mover las unidades de los departamentos, antes de borrar la entidad.',
          type: 'error',
        })
      },

      async getDocumento(id_entidad) {
        try {
          const docPdf = await this.$store.getters[
            'entidad/getDocumentoByEntidad'
          ](id_entidad)
          this.downloadPDF(docPdf.documento, docPdf.nombre)
        } catch (err) {
          if (err.response) {
            if (err.response.status !== 200) {
              this.$notify({
                title: 'Se ha producido un error',
                text: err.response.data.message,
                type: 'error',
              })
            }
          }
        }
      },

      downloadPDF(url, name) {
        const linkSource = `data:application/pdf;base64, ${url}`
        const downloadLink = document.createElement('a')
        const filename = name
        downloadLink.href = linkSource
        downloadLink.download = filename
        downloadLink.click()
      },

      iconoTipoArchivo(entidad) {
        const splitExtension = this.getExtensionNombreArchivo(
          entidad.documentacion,
        )
        return (
          {
            '.doc': constants.icons.word,
            '.dot': constants.icons.word,
            '.docx': constants.icons.word,
            '.xls': constants.icons.excel,
            '.xlsx': constants.icons.excel,
            '.csv': constants.icons.defaultDocument,
            '.txt': constants.icons.defaultDocument,
            '.pdf': constants.icons.pdf,
            '.png': constants.icons.imagen,
            '.jpeg': constants.icons.imagen,
            '.jpe': constants.icons.imagen,
            '.jpg': constants.icons.imagen,
            '.mp4': constants.icons.video,
            '.avi': constants.icons.video,
            '.wmv': constants.icons.video,
            '.webm': constants.icons.video,
          }[splitExtension] ?? constants.icons.defaultDocument
        )
      },

      getExtensionNombreArchivo(nombreArchivo) {
        let nombre = nombreArchivo
        // Reverse del nombre del archivo
        nombre = [...nombre].reverse().join('')
        /**
         * Obtener la extension del archivo hasta el primer .
         *
         * De esta forma, evitamos que el nombre de la imagen contenga puntos y no podamos obter correctamente la extension
         */
        const corteExtension = nombre.split('.')[0]
        const reverseExtension = [...corteExtension].reverse().join('')

        return '.' + reverseExtension
      },

      filterEntidad(value) {
        if (!this.entidadSelected) {
          return true
        }

        return value === this.entidadSelected.entidad_titular
      },

      searchEntidades() {
        // Atributos no validos para filtrar
        function atributoValido(atributo) {
          const atributosNoValidos = [
            'borrado',
            'fechastamp',
            'usuariostamp',
            'recogida_info',
            'logo',
            'is_chat_default',
            'id_tipo_entidad',
            'id_tipo_actualizacion',
            'id_periodicidad',
            'id_distrito',
            'convenio',
            'id_periodicidad',
            'codigo',
            'id_tipo_actualizacion',
            'id_distrito',
            'id_grupo_accion',
            'Entidad_grupo_accion',
            'id_tipo_entidad',
            'id_entidad',
            'id_default',
            'id_delegacion',
            'id_departamento',
            'id_unidad_medio',
            'activacion_movilizacion',
            'id_medio',
            'info_protocolo',
            'numero_unidades',
            'observaciones',
            'ruta_protocolo',
          ]

          const encontrado = atributosNoValidos.find((x) => x === atributo)
          return encontrado ? false : true
        }

        // Filtro de busqueda para todas las entidades
        const searchText = this.search ? this.search : ''

        this.entidadesFilter = this.entidades.filter(function (entidades) {
          for (var attrEntidad in entidades) {
            if (atributoValido(attrEntidad)) {
              // Usuario
              if (attrEntidad === 'Usuario') {
                for (var attrUsuario in entidades[attrEntidad]) {
                  if (atributoValido(attrUsuario)) {
                    const atributo =
                      entidades[attrEntidad]['nombre'] +
                      ' ' +
                      entidades[attrEntidad]['apellidos']

                    if (StringHelper.compareString(searchText, atributo))
                      return entidades[attrEntidad]
                  }
                }
              } else if (attrEntidad === 'Periodicidad') {
                // Periodicidad
                if (entidades[attrEntidad]) {
                  for (var attrPeriodicidad in entidades[attrEntidad]) {
                    if (atributoValido(attrPeriodicidad)) {
                      const atributo = entidades[attrEntidad][attrPeriodicidad]

                      if (StringHelper.compareString(searchText, atributo))
                        return entidades[attrEntidad]
                    }
                  }
                }
              } else if (attrEntidad === 'Tipo_actualizacion') {
                // Tipo_actualizacion
                if (entidades[attrEntidad]) {
                  for (var attrTipoActu in entidades[attrEntidad]) {
                    if (atributoValido(attrTipoActu)) {
                      const atributo = entidades[attrEntidad][attrTipoActu]

                      if (StringHelper.compareString(searchText, atributo))
                        return entidades[attrEntidad]
                    }
                  }
                }
              } else if (attrEntidad === 'Distrito') {
                // Distrito
                if (entidades[attrEntidad]) {
                  for (var attrDistrito in entidades[attrEntidad]) {
                    if (atributoValido(attrDistrito)) {
                      const atributo = entidades[attrEntidad][attrDistrito]

                      if (StringHelper.compareString(searchText, atributo))
                        return entidades[attrEntidad]
                    }
                  }
                }
              } else if (attrEntidad === 'Grupo_accions') {
                // Grupo_accions
                if (entidades[attrEntidad]) {
                  for (var grupoAccion of entidades[attrEntidad]) {
                    for (var attrGrupoAccion in grupoAccion) {
                      if (atributoValido(attrGrupoAccion)) {
                        const atributo = grupoAccion[attrGrupoAccion]

                        if (StringHelper.compareString(searchText, atributo))
                          return entidades[attrEntidad]
                      }
                    }
                  }
                }
              } else if (attrEntidad === 'Tipo_entidad') {
                // Tipo_entidad
                if (entidades[attrEntidad]) {
                  for (var attrTipoEntidad in entidades[attrEntidad]) {
                    if (atributoValido(attrTipoEntidad)) {
                      const atributo = entidades[attrEntidad][attrTipoEntidad]

                      if (StringHelper.compareString(searchText, atributo))
                        return entidades[attrEntidad]
                    }
                  }
                }
              } else {
                // Atributos de la entidad
                if (atributoValido(attrEntidad)) {
                  if (entidades[attrEntidad]) {
                    const atributo = entidades[attrEntidad].toString() // CP es int

                    if (StringHelper.compareString(searchText, atributo))
                      return entidades
                  }
                }
              }
            }
          }
        })
      },
    },

    mounted() {},

    async created() {
      // Mostrar acciones si currentUsuario tiene rol admin o rol mod_entidades
      if (this.comprobarPermisos(['MOD_ENTIDADES'])) {
        // Acciones entidad
        this.headersEntidad.push({
          text: 'Acciones',
          value: 'ACCIONES',
          align: 'center',
          width: '140px',
          sortable: false,
        })
      }

      // await this.loadEntidades()
    },

    async activated() {
      await this.loadEntidades()
    },
  }
</script>

<style lang="scss" scoped>
  /* Estilos para que la tabla tenga un max-height y pueda crecer */
  .table {
    width: 100vw;
    max-height: calc(100vh - 270px);
  }
  .tableMobile {
    width: 100vw;
    max-height: calc(100vh - 330px);
  }

  .v-data-table {
    overflow: auto;
  }

  .v-data-table >>> .v-data-table__wrapper {
    /* Header fijo */
    overflow: unset;
  }

  .headerToolbarTitle {
    font-size: 1.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }

  .headerToolbar {
    height: 25px !important;
  }

  /* Hover transparente para todas las filas */
  ::v-deep tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
  /* Hover en color gris para las filas impares */
  ::v-deep tbody {
    tr:hover:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
  /* Color gris fila data table impar */
  ::v-deep tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  /* Sticky column data-table - Entidad */
  ::v-deep thead tr th:nth-of-type(1) {
    position: sticky;
    left: 0;
  }

  ::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(1) {
    background: whitesmoke;
    position: sticky;
    left: 0;
  }

  ::v-deep tbody tr td:nth-of-type(1) {
    background: white;
    position: sticky;
    left: 0;
  }

  /* Sticky column data-table - Acciones */
  ::v-deep thead tr th:nth-of-type(12) {
    position: sticky;
    right: 0;
  }

  ::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(12) {
    background: whitesmoke;
    position: sticky;
    right: 0;
  }

  ::v-deep tbody tr td:nth-of-type(12) {
    background: white;
    position: sticky;
    right: 0;
  }

  /* Tamnaño inputs y select */
  .inputSelectMobile {
    // margin-top: -15px;
    margin-bottom: 0px;
  }

  /* Tamaño select mostrar por página */
  .select {
    width: 85px;
  }
  .selectMobile {
    width: 85px;
  }

  /* Mostrar */
  .textMostrar {
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    justify-content: flex-end;
  }

  /* Resultados */
  .textResultados {
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -15px;
    margin-right: 0px;
    display: flex;
    justify-content: flex-start;
  }

  /* Paginacion */
  .paginacion {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    justify-content: flex-end;
  }
</style>
