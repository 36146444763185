export class ImageHelper {
  static getImagenExtension(nombreImagen) {
    let nombre = nombreImagen
    // Reverse del nombre de la imagen
    nombre = [...nombre].reverse().join('')

    /**
     * Obtener la extension de la imagen hasta el primer .
     *
     * De esta forma, evitamos que el nombre de la imagen contenga puntos y no
     * podamos obtener correctamente la extension
     */
    const corteExtension = nombre.split('.')[0]
    const reverseExtension = [...corteExtension].reverse().join('')

    switch (reverseExtension) {
      case 'jpg':
        return 'data:image/jpeg;base64,'
      case 'jpeg':
        return 'data:image/jpeg;base64,'
      case 'jpe':
        return 'data:image/jpeg;base64,'
      case 'png':
        return 'data:image/png;base64,'
    }
  }

  static imagenTieneCabecera(imagen) {
    if (imagen) {
      const corteCabecera = imagen.logo.split('data:')
      return corteCabecera.length > 1 ? true : false
    }
  }
}
