<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template #activator="{ on, attrs }">
      <v-btn color="white" icon v-bind="attrs" v-on="on">
        <v-icon color="white">mdi-arrow-expand</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-arrow-collapse</v-icon>
        </v-btn>
        <v-toolbar-title>Mensajes</v-toolbar-title>
      </v-toolbar>
      <div class="content">
        <slot v-if="dialog" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      title: null,
    },

    data() {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
      }
    },

    methods: {
      clearSelectedMessages() {
        this.$store.commit('chat/CLEAR_SELECTED_MESSAGES')
      },
    },

    watch: {
      dialog() {
        if (this.dialog) {
          this.$eventHub.$emit('openFullScreen', this.dialog)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .content {
    display: flex;
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    flex-direction: column;
  }

  .v-card {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .v-toolbar {
    height: 40px !important;
    flex-grow: 0;
    ::v-deep .v-toolbar__content {
      height: 40px !important;
    }
  }
</style>
