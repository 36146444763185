<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2572 22C13.4718 21.0491 13 19.8296 13 18.5C13 16.8 13.7713 15.2801 14.9831 14.2712L10 10L3 16H5V22H8V17H12V22H14.2572Z"
      :fill="fillColor"
    />
    <path d="M10 6L6 2L2 6H3V9H5V6H7V9H9V6H10Z" :fill="fillColor" />
    <path
      d="M23 8L18 3L13 8H14V10.87L15.34 12H17V9H19V12H22V8H23Z"
      :fill="fillColor"
    />
    <path
      d="M17.6888 14.5C17.5577 14.5 17.4921 14.564 17.4921 14.692L17.2792 15.7C17.0989 15.844 16.8368 15.972 16.6402 16.1L15.5916 15.7C15.5261 15.7 15.395 15.7 15.3295 15.78L14.543 17.188C14.4775 17.252 14.4775 17.38 14.6086 17.444L15.4605 18.1V18.9L14.6086 19.556C14.543 19.62 14.4775 19.748 14.543 19.812L15.3295 21.22C15.395 21.3 15.5261 21.3 15.5916 21.3L16.6402 20.9C16.8368 21.028 17.0989 21.156 17.2792 21.3L17.4921 22.308C17.4921 22.436 17.5577 22.5 17.6888 22.5H19.3271C19.3927 22.5 19.5238 22.436 19.5238 22.308L19.6548 21.3C19.917 21.156 20.1791 21.028 20.3757 20.9L21.3751 21.3C21.4898 21.3 21.6209 21.3 21.6209 21.22L22.4729 19.812C22.5384 19.748 22.4729 19.62 22.4073 19.556L21.5554 18.9V18.1L22.4073 17.444C22.4729 17.38 22.5384 17.252 22.4729 17.188L21.6209 15.78C21.6209 15.7 21.4898 15.7 21.3751 15.7L20.3757 16.1C20.1791 15.972 19.917 15.844 19.6548 15.7L19.5238 14.692C19.5238 14.564 19.3927 14.5 19.3271 14.5H17.6888ZM18.5407 17.3C19.1961 17.3 19.7367 17.828 19.7367 18.532C19.7367 19.172 19.1961 19.7 18.5407 19.7C17.8198 19.7 17.2792 19.172 17.2792 18.532C17.2792 17.828 17.8198 17.3 18.5407 17.3Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      active: null,
    },

    computed: {
      fillColor() {
        const result = this.active ? '#1AF4C0' : 'white'
        return result
      },
    },
  }
</script>
