export class ArcgisLegendHelper {
  static #getLayerVectorLegend() {
    return [
      {
        label: 'Muy baja',
        colorSymbol: {
          color: {
            r: 1,
            g: 97,
            b: 0,
            a: 1,
          },
          type: 'simple-fill',
        },
      },
      {
        label: 'Baja',
        colorSymbol: {
          color: {
            r: 121,
            g: 171,
            b: 1,
            a: 1,
          },
          type: 'simple-fill',
        },
      },
      {
        label: 'Moderada',
        colorSymbol: {
          color: {
            r: 255,
            g: 170,
            b: 1,
            a: 1,
          },
          type: 'simple-fill',
        },
      },
      {
        label: 'Alta',
        colorSymbol: {
          color: {
            r: 253,
            g: 2,
            b: 0,
            a: 1,
          },
          type: 'simple-fill',
        },
      },
      {
        label: 'Muy alta',
        colorSymbol: {
          color: {
            r: 190,
            g: 2,
            b: 36,
            a: 1,
          },
          type: 'simple-fill',
        },
      },
    ]
  }

  /**
   *
   * @param {*} layer
   * @returns {{label?: string, base64Symbol?: any, legendURL?:string, colorSymbol?:any}[]}
   */
  static getLayerSymbols(layer) {
    let symbols = []
    if (layer && layer.renderer) {
      if (layer.renderer.symbol) {
        if (layer.renderer.symbol.color) {
          symbols.push({
            label: layer.renderer.label,
            colorSymbol: layer.renderer.symbol,
          })
        } else {
          symbols.push({
            label: layer.renderer.label,
            base64Symbol: layer.renderer.symbol,
          })
        }
      } else if (layer.renderer.uniqueValueInfos) {
        layer.renderer.uniqueValueInfos.forEach((info) => {
          if (info.symbol.color) {
            symbols.push({ label: info.label, colorSymbol: info.symbol })
          } else {
            symbols.push({ label: info.label, base64Symbol: info.symbol })
          }
        })
      } else if (layer.renderer.classBreakInfos) {
        layer.renderer.classBreakInfos.forEach((info) => {
          if (info.symbol.color) {
            symbols.push({ label: info.label, colorSymbol: info.symbol })
          } else {
            symbols.push({ label: info.label, base64Symbol: info.symbol })
          }
        })
      }
    }
    if (!symbols.length) {
      if (layer.resourceInfo) {
        layer.resourceInfo.layers.forEach((layer) => {
          symbols.push({
            legendURL: layer.legendURL,
          })
        })
      } else if (layer.layers) {
        layer.layers._items.forEach((layer) => {
          if (layer.renderer) {
            symbols.push({
              label: layer.renderer.symbol.label,
              colorSymbol: layer.renderer.symbol,
            })
          }
        })
        if (layer.layers.items && layer.layers.items.length) {
          symbols = this.searchLegendURLRecursive(layer, symbols)
          //layers.items[0].allSublayers.items[0].layer.resourceInfo.layers[0].legendURL
        }
      } else if (
        layer.type === 'vector-tileLayer' ||
        layer.type === 'vector-tile'
      ) {
        symbols.push(...this.#getLayerVectorLegend())
      }
    }
    symbols.forEach((symbol) => {
      if (symbol.colorSymbol && symbol.colorSymbol.url) {
        symbol.base64Symbol = symbol.colorSymbol
        symbol.colorSymbol = undefined
      }
    })
    return symbols
  }

  /**Search legends url inside layer and sublayers */
  static searchLegendURLRecursive(layer, symbols) {
    // Posible locations:
    //layer.layers.items[0].allSublayers.items[0].layer.resourceInfo.layers[0].legendURL
    //layer.allSublayers.items[0].legendUrl
    //layer.layers.items[0].allSublayers.items[0].legendURL
    if (layer.layers && layer.layers.items && layer.layers.items.length) {
      layer.layers.items.forEach((layer) => {
        if (layer.legendUrl) {
          symbols.push({
            legendURL: layer.legendUrl,
          })
        }
        if (
          layer.allSublayers &&
          layer.allSublayers.items &&
          layer.allSublayers.items.length
        ) {
          symbols = this.searchLegendURLRecursive(layer, symbols)
        }
      })
    } else if (
      layer.allSublayers &&
      layer.allSublayers.items &&
      layer.allSublayers.items.length
    ) {
      layer.allSublayers.items.forEach((layer) => {
        if (layer.legendUrl) {
          symbols.push({
            legendURL: layer.legendUrl,
          })
        }
        if (
          layer.allSublayers &&
          layer.allSublayers.items &&
          layer.allSublayers.items.length
        ) {
          symbols = this.searchLegendURLRecursive(layer, symbols)
        }
      })
    }
    return symbols
  }
}
