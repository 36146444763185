// import store from '@/store/index'
import constants from './constants'
import Vue from '../main'
import * as ArcGIS from './ArcGIS'

const Q = require('q')

// var municipioRequest = null

export const getPointData = async (x, y) => {
  const deferred = Q.defer()
  let servicioMunicio = constants.servicios.consultaMunicipios
  // let USER_COD_CCAA = parseInt(constants.comunidad.CODIGO_INE_CCAA)
  // let USER_COD_PROV = parseInt(constants.comunidad.CODIGO_INE_PROVINCIA)

  servicioMunicio = servicioMunicio.replace('/**Point_X**/', x)
  servicioMunicio = servicioMunicio.replace('/**Point_Y**/', y)

  try {
    const response = await Vue.$http.get(
      servicioMunicio /* , {
      before (request) {
        if (municipioRequest) {
          municipioRequest.abort()
        }
        municipioRequest = request
      }
    } */,
    )

    const features = response.data.features

    if (features && features[0]) {
      const xyH30 = ArcGIS.convertirETRS89(x, y)
      const latLon = ArcGIS.convertirETRS89ToLatLon(xyH30[0], xyH30[1])
      const lat = latLon[1].toFixed(5)
      const lon = latLon[0].toFixed(5)

      const COD_CCAA = parseInt(features[0].attributes.Cod_CCAA)
      const COD_PROV = parseInt(features[0].attributes.COD_PROV)

      deferred.resolve({
        LATITUD: lat,
        LONGITUD: lon,
        X: Math.round(xyH30[0]),
        Y: Math.round(xyH30[1]),
        MUNICIPIO: features[0].attributes.NOMBRE,
        LOCALIDAD: features[0].attributes.NOMBRE,
        COD_PROV: COD_PROV,
        COD_CCAA: COD_CCAA,
      })
    } else {
      deferred.reject()
    }
  } catch (err) {
    deferred.reject(err)
  }

  return deferred.promise
}

export const getPointBarrioDistrito = async (x, y) => {
  const deferred = Q.defer()
  let servicioBarrios = constants.servicios.consultaBarrios
  let servicioDistritos = constants.servicios.consultaDistritos

  servicioBarrios = servicioBarrios.replace('/**Point_X**/', x)
  servicioBarrios = servicioBarrios.replace('/**Point_Y**/', y)

  servicioDistritos = servicioDistritos.replace('/**Point_X**/', x)
  servicioDistritos = servicioDistritos.replace('/**Point_Y**/', y)

  try {
    const responseBarrio = await Vue.$http.get(servicioBarrios)
    const responseDistrito = await Vue.$http.get(servicioDistritos)

    if (responseBarrio.status === 200 && responseDistrito.status === 200) {
      const featuresBarrio = responseBarrio.data.features
      const featuresDistrito = responseDistrito.data.features
      const xyH30 = ArcGIS.convertirETRS89(x, y)
      const latLon = ArcGIS.convertirETRS89ToLatLon(xyH30[0], xyH30[1])
      const lat = latLon[1].toFixed(5)
      const lon = latLon[0].toFixed(5)

      const fuera = 'Fuera de municipio'

      if (
        featuresBarrio &&
        featuresDistrito &&
        featuresBarrio.length > 0 &&
        featuresDistrito.length > 0
      ) {
        /* calculateAddress(event).then(addres => {
          console.log(addres)
        }) */

        const BARRIO_NOMBRE = featuresBarrio[0].attributes.NOMBRE
        const DISTRITO_NOMBRE = featuresDistrito[0].attributes.NOMBRE

        if (BARRIO_NOMBRE && DISTRITO_NOMBRE) {
          deferred.resolve({
            X_MAP: x,
            Y_MAP: y,
            LATITUD: lat,
            LONGITUD: lon,
            X: Math.round(xyH30[0]),
            Y: Math.round(xyH30[1]),
            BARRIO: BARRIO_NOMBRE,
            DISTRITO: DISTRITO_NOMBRE,
          })
        } else {
          deferred.resolve({
            X_MAP: x,
            Y_MAP: y,
            LATITUD: lat,
            LONGITUD: lon,
            X: Math.round(xyH30[0]),
            Y: Math.round(xyH30[1]),
            BARRIO: fuera,
            DISTRITO: fuera,
          })
        }
      } else {
        deferred.resolve({
          X_MAP: x,
          Y_MAP: y,
          LATITUD: lat,
          LONGITUD: lon,
          X: Math.round(xyH30[0]),
          Y: Math.round(xyH30[1]),
          BARRIO: fuera,
          DISTRITO: fuera,
        })
      }
    }
  } catch (err) {
    deferred.reject(err)
  }

  return deferred.promise
}
