import api from '@/api'
// import Vue from 'vue'
import constants from '@/helpers/constants'
// import typedefs from '@/typedefs'

const Q = require('q')

const state = {
  registrosBitacoraEmergencia: null,
}

const mutations = {
  SET_REGISTROS_BITACORA_EMERGENCIA(state, data) {
    state.registrosBitacoraEmergencia = data
  },
}

const actions = {
  async addEventoBitacora({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.bitacora.addBitacora(data)
      if (response.status === 200) {
      } else {
      }

      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Bitacora.addBitacora,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.resolve()
    }

    return deferred.promise
  },

  async getRegistrosBitacoraByEmergencia({ commit }, data) {
    const deferred = Q.defer()
    try {
      const response = await api.bitacora.getBitacorasByEmergencia(data)
      if (response.status === 200) {
        commit('SET_REGISTROS_BITACORA_EMERGENCIA', response.data.body)
      } else {
        // TODO:
        console.log('error')
      }

      deferred.resolve()
    } catch (err) {
      if (
        err.response &&
        err.response.status !== 200 &&
        err.response.status !== 500
      ) {
        commit('ERROR_AUTORIZACION', {
          title: err.response.statusText,
          text: constants.notificacionesPermisos.Bitacora.addBitacora,
        })
      } else if (err.request) {
        commit('ERROR_RESPUESTA_SERVER', {
          title: err.request.statusText,
          text: constants.notificacionesPermisos.Error[500],
        })
      }
      console.error(err)
      deferred.resolve()
    }
  },
}

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default module
