export class StringHelper {
  /**
   * Compare two strings, ignoring accents and case
   * @param {string} string1 - The first string
   * @param {string} string2 - The second string
   * @returns {boolean} - Returns true if the strings are equal, false otherwise
   */
  static compareString(string1, string2) {
    const str1Normalize = string1
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
    const str2Normalize = string2
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')

    if (str2Normalize.toLowerCase().indexOf(str1Normalize.toLowerCase()) > -1) {
      return true
    } else {
      return false
    }
  }
}
