import api from '@/api'
import constants from '@/helpers/constants'

const state = {
  categoriasDocumento: null,
}

const getters = {
  categoriasDocumento: (state) => {
    return state.categoriasDocumento
  },
}

const mutations = {
  SET_CATEGORIAS_DOCUMENTO(state, data) {
    state.categoriasDocumento = data
  },

  ERROR_AUTORIZACION(state, data) {
    this.notify(data.title, data.text, 'error')
  },

  ERROR_RESPUESTA_SERVER(state, data) {
    this.notify(data.title, data.text, 'error')
  },
}

const actions = {
  async fetchCategoriasDocumento({ commit }) {
    return new Promise((resolve, reject) => {
      api.categoriaDocumento
        .getCategoriasDocumento()
        .then((response) => {
          if (response.status === 200) {
            commit('SET_CATEGORIAS_DOCUMENTO', response.data.body)
          }
          resolve(response.data.body)
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.status !== 200 &&
            err.response.status !== 500
          ) {
            commit('ERROR_AUTORIZACION', {
              title: err.response.statusText,
              text: constants.notificacionesPermisos.CategoriaDocumento
                .getCategoriasDocumento,
            })
          } else if (err.request) {
            commit('ERROR_RESPUESTA_SERVER', {
              title: err.request.statusText,
              text: constants.notificacionesPermisos.Error[500],
            })
          }
          console.error(err)
          reject(err)
        })
    })
  },
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default module
