import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/es'

dayjs.locale('es')

// Registers dayJs plugins
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

// const formatDB = 'YYYY-MM-DDTHH:mm:ss'
const format1 = 'DD/MM/YYYY HH:mm'

const timeFormat = 'HH:mm'

export default {
  // #region Date
  parseDate(dateString, format = null) {
    if (format) {
      return dayjs(dateString, format, true)
    } else {
      // Check for 'DD/MM/YYYY HH:mm' format
      const pattern = /\d{1,2}[/]\d{1,2}[/]\d{4} (\d\d):[0-5]\d$/
      const hasFormat = pattern.test(dateString)
      if (hasFormat) {
        return dayjs(dateString, format1, true)
      } else {
        return dayjs(dateString, true)
      }
    }
  },

  formatDateTime(date, format = format1) {
    if (!date) {
      return ''
    }

    if (date === '') {
      return date
    } else {
      if (typeof date === 'string') {
        date = this.parseDate(date)
      }
      return date.format(format)
    }
  },

  formatDate(date, format = 'YYYY-MM-DD') {
    if (!date) {
      return ''
    }

    if (date === '') {
      return date
    } else {
      if (typeof date === 'string') {
        date = this.parseDate(date)
      }
      return date.format(format)
    }
  },

  getCurrentDateDB() {
    // return dayjs().format(formatDB)
    return dayjs().toISOString()
  },

  currentDate() {
    // return dayjs().format(formatDB)
    return dayjs().format(format1)
  },
  // #endregion

  // #region Time
  parseTime(timeString) {
    const time = dayjs(timeString, timeFormat, true)
    return time
  },

  formatTime(time) {
    if (!time) {
      return ''
    }

    if (time === '') {
      return time
    } else {
      if (typeof time === 'string') {
        time = this.parseTime(time)
      }
      return time.format(timeFormat)
    }
  },

  currentTime() {
    return dayjs().format(timeFormat)
  },
  // #endregion

  add(date, value, field) {
    return this.parseDate(date).add(value, field)
  },

  subtract(date, value, field) {
    return this.parseDate(date).subtract(value, field)
  },

  now() {
    return dayjs()
  },

  isValid(date) {
    return this.parseDate(date).isValid()
  },
}
