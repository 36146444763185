import Vue from 'vue'

const state = {
  isSharingMap: false,

  usersShareMap: [],

  requestShareMap: null,

  mapExtent: null,

  graphics: [],
}

const getters = {
  isSharingMap: (state) => state.isSharingMap,

  usersShareMap: (state) => state.usersShareMap,

  requestShareMap: (state) => state.requestShareMap,

  mapExtent: (state) => state.mapExtent,

  graphics: (state) => state.graphics,
}

const mutations = {
  SET_IS_SHARING_MAP(state, isSharingMap) {
    state.isSharingMap = isSharingMap
  },

  SET_USERS_SHARE_MAP(state, usersShareMap) {
    state.usersShareMap = usersShareMap
  },

  SET_REQUEST_SHARE_MAP(state, requestShareMap) {
    state.requestShareMap = requestShareMap
  },

  CANCEL_REQUEST_SHARE_MAP(state) {
    state.requestShareMap = null
    state.isSharingMap = false
  },

  ACCEPT_REQUEST_SHARE_MAP(state) {
    const requestShareMap = state.requestShareMap
    requestShareMap.response = true

    state.isSharingMap = true
  },

  // Recibir peticion de compartir mapa
  SOCKET_requestExtenTMap(state, data) {
    // La T no es una errata, es asi...
    state.requestShareMap = data
  },

  // Recibir nuevo extent del mapa
  SOCKET_changeExtentMap(state, data) {
    if (state.isSharingMap) {
      state.mapExtent = data
    }
  },

  // Alguien acepta el compartir pantalla
  SOCKET_acceptRequestExtentMap(state, data) {
    let user
    for (let i = 0; i < state.usersShareMap.length; i++) {
      if (state.usersShareMap[i].ID_SOCKET === data) {
        user = state.usersShareMap[i]
        user.ESTADO = 'Aceptada'
        break
      }
    }

    this.notify(
      'Compartir pantalla',
      `El usuario ${user.USUARIO} ha aceptado la solicitud`,
    )
  },

  // Alguien se ha salido del compartir mapa
  SOCKET_exitRequestExtentMap(state, data) {
    let user
    for (let i = 0; i < state.usersShareMap.length; i++) {
      if (state.usersShareMap[i].ID_SOCKET === data) {
        user = state.usersShareMap[i]
        user.ESTADO = 'Exit'
        break
      }
    }

    this.notify(
      'Compartir pantalla',
      `El usuario ${user.USUARIO} ha parado la visualización`,
    )
  },

  // Cerrar compartir mapa
  SOCKET_closeRequestExtentMap(state) {
    state.requestShareMap = null
    state.isSharingMap = false
    this.notify('Compartir pantalla', 'Se ha terminado la sesión')
  },

  SOCKET_addGraphicsDraw(state, data) {
    state.graphics.push(data)
  },
  SOCKET_updateGraphicsDraw(state, data) {
    const index = state.graphics.findIndex(
      (x) => x.attributes.ID === data[0].attributes.ID,
    )
    if (index !== -1) {
      const graphic = state.graphics[index]

      if (data[0].geometry.type === 'polyline') {
        graphic.coordenadas = data[0].geometry.paths
      } else if (data[0].geometry.type === 'polygon') {
        graphic.coordenadas = data[0].geometry.rings[0]
      } else if (data[0].geometry.type === 'point') {
        graphic.coordenadas = [data[0].geometry.x, data[0].geometry.y]
      }

      Vue.set(state.graphics, index, graphic)
    }
  },
  SOCKET_deleteGraphicsDraw(state, data) {
    const index = state.graphics.findIndex(
      (x) => x.attributes.ID === data[0].attributes.ID,
    )
    if (index !== -1) {
      state.graphics.splice(index, 1)
    }
  },

  SOCKET_removeGraphicsDraw(state) {
    state.graphics = []
  },
}

const actions = {
  setShareMap({ commit }, data) {
    if (!data) {
      if (state.requestShareMap) {
        Vue.prototype.$socket.emit('exitRequestExtentMap', {
          ID_SOCKET: state.requestShareMap.clientRequest.ID_SOCKET,
          USUARIO: state.requestShareMap.clientRequest.USUARIO,
        })
        commit('CANCEL_REQUEST_SHARE_MAP')
      } else {
        Vue.prototype.$socket.emit('closeRequestExtentMap')
        commit('SET_USERS_SHARE_MAP', [])
      }
    }

    commit('SET_IS_SHARING_MAP', data)
  },

  setUsersShareMap({ commit }, data) {
    commit('SET_USERS_SHARE_MAP', data)
    Vue.prototype.$socket.emit('requestUsersExtentMap', data)
  },

  cancelShareMapRequest({ commit }) {
    Vue.prototype.$socket.emit('exitRequestExtentMap', {
      ID_SOCKET: state.requestShareMap.clientRequest.ID_SOCKET,
      USUARIO: state.requestShareMap.clientRequest.USUARIO,
    })
    commit('CANCEL_REQUEST_SHARE_MAP')
  },

  acceptShareMapRequest({ commit }) {
    Vue.prototype.$socket.emit('acceptRequestExtentMap', {
      ID_SOCKET: state.requestShareMap.clientRequest.ID_SOCKET,
      USUARIO: state.requestShareMap.clientRequest.USUARIO,
    })
    commit('ACCEPT_REQUEST_SHARE_MAP')
  },

  changeMapExtent({}, data) {
    if (state.isSharingMap) {
      Vue.prototype.$socket.emit('changeExtentMap', data)
    }
  },

  // DIBUJOS
  addGraphics({ commit }, data) {
    commit('SOCKET_addGraphicsDraw', data)
    Vue.prototype.$socket.emit('addGraphicsDraw', data)
  },
  deleteGraphics({ commit, state }, data) {
    commit('SOCKET_deleteGraphicsDraw', data)
    if (state.isSharingMap) {
      Vue.prototype.$socket.emit('deleteGraphicsDraw', data)
    }
  },
  updateGraphics({ commit, state }, data) {
    commit('SOCKET_updateGraphicsDraw', data)
    if (state.isSharingMap) {
      Vue.prototype.$socket.emit('updateGraphicsDraw', data)
    }
  },
  removeAllGraphics({ commit }) {
    commit('SOCKET_removeGraphicsDraw')
    Vue.prototype.$socket.emit('removeGraphicsDraw')
  },
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default module
