import { ImageHelper } from './ImageHelper'
import dayjs from 'dayjs'

export const Filters = {
  base64Image: (imagen) => {
    if (imagen) {
      if (!ImageHelper.imagenTieneCabecera(imagen)) {
        const extension = ImageHelper.getImagenExtension(imagen.nombre)
        const base64 = extension + imagen.logo
        return base64
      } else {
        return imagen.logo
      }
    }
  },
  formatDate: (date) => {
    if (date) {
      return dayjs(date).format('DD/MM/YYYY HH:mm')
    } else {
      return ''
    }
  },
}
