import api from '@/api'
import constants from '../../helpers/constants'
import Vue from '../../main'

const Q = require('q')

const state = {
  simulaciones: [],
}

const getters = {
  simulaciones: (state) => state.simulaciones,
}

const mutations = {
  SET_SIMULACIONES(state, simulaciones) {
    state.simulaciones = simulaciones
  },

  ADD_SIMULACION(state, simulacion) {
    state.simulaciones.push(simulacion)
  },
}

const actions = {
  async fetchSimulaciones({ commit }, data) {
    const deferred = Q.defer()

    try {
      const response = await api.simulacion.getSimulaciones(data)
      commit('SET_SIMULACIONES', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async addSimulacion({ commit }, data) {
    const deferred = Q.defer()

    const datosSimulador = data.datosSimulador
    const datosBackend = data.datosBackend
    try {
      const responseSimulador = await Vue.$http.post(
        constants.urlSimulacion + '/simulate',
        datosSimulador,
      ) // Crear simulacion en server simulacion

      if (responseSimulador.status === 200) {
        datosBackend.ID_SIMULACION = responseSimulador.data
        const responseBackend = await api.simulacion.addSimulacion(datosBackend) // Insertar simulacion en BBDD backend

        if (responseBackend.status === 200) {
          commit('ADD_SIMULACION', responseBackend.data)
          deferred.resolve()
        } else {
          deferred.reject()
        }
      } else {
        deferred.reject()
      }
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default module
