<template>
  <v-dialog v-model="show" persistent max-width="650px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Geofencing</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-select
          v-model="incendioSelected"
          class="pt-4"
          :items="incendios"
          label="Emergencia"
          outlined
          item-text="LOCALIDAD"
          item-value="ID_INCENDIO"
          return-object
          :rules="[globalRules.required]"
        />

        <v-tabs
          v-model="tabActiva"
          color="white"
          background-color="primaryLight"
          dark
          slider-color="white"
        >
          <v-tab v-for="t in tabs" :key="t" ripple>
            {{ t }}
          </v-tab>

          <v-tab-item key="Aproximación">
            <v-data-table
              class="scroll"
              :headers="headers"
              :items="
                mediosGeofencing.filter(
                  (x) => x.GEOFENCING_ESTADO_TIPO.ORDEN === 1,
                )
              "
              item-key="ID_MEDIO"
              disable-pagination
              hide-default-footer
            >
              <template #no-data>
                <p>Datos no disponibles.</p>
              </template>

              <template #item="{ item }">
                <tr>
                  <td>{{ getMedioByID(item.ID_MEDIO).MEDIO }}</td>
                  <td>{{ item.FECHA | formatDate }}</td>
                  <td>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          v-show="!isMedioAsignadoIncendio(item.ID_MEDIO)"
                          @click="setAproximacionGeofencing(item)"
                        >
                          <v-icon>mdi-clock-time-four-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Asignar llegada</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item key="Entrada">
            <v-data-table
              :headers="headers"
              :items="
                mediosGeofencing.filter(
                  (x) => x.GEOFENCING_ESTADO_TIPO.ORDEN === 2,
                )
              "
              item-key="ID_MEDIO"
              disable-pagination
              hide-default-footer
            >
              <template #no-data>
                <p>Datos no disponibles.</p>
              </template>

              <template #item="{ item }">
                <tr>
                  <td>{{ getMedioByID(item.ID_MEDIO).MEDIO }}</td>
                  <td>{{ item.FECHA | formatDate }}</td>
                  <td>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          v-show="!hasMedioEntradaIncendio(item.ID_MEDIO)"
                          @click="setEntradaGeofencing(item)"
                        >
                          <v-icon>mdi-clock-time-four-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Asignar llegada</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cerrar">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import constants from '../../helpers/constants'

  export default {
    props: {
      show: Boolean,
    },
    data: () => ({
      incendioSelected: null,

      tabs: ['Aproximación', 'Entrada'],
      tabActiva: null,
      headers: [
        { text: 'Medio', align: 'left', sortable: true, value: 'MEDIO' },
        {
          text: 'Fecha llegada geofencing',
          align: 'left',
          sortable: true,
          value: 'FECHA',
        },
        { text: '', align: 'left', sortable: false, value: 'BOTON' },
      ],
    }),

    watch: {
      show() {
        if (this.show) {
          if (this.incendios.length > 0) {
            this.incendioSelected = this.incendios[0]
          } else {
            this.incendioSelected = null
          }
        }
      },

      incendios() {
        // Trato de actualizar el que tengo seleccionado
        if (this.incendioSelected) {
          this.incendioSelected = this.incendios.find(
            (x) => x.ID_INCENDIO === this.incendioSelected.ID_INCENDIO,
          )
        }
      },
    },

    computed: {
      incendios() {
        return this.$store.getters['incendio/incendios']
      },

      mediosGeofencing() {
        let medios = []
        if (this.incendioSelected) {
          medios = this.incendioSelected.GEOFENCING
        }
        return medios
      },
    },

    methods: {
      cerrar() {
        this.$emit('cerrar')
      },

      getMedioByID(idMedio) {
        return this.$store.getters['medio/getMedioByID'](idMedio)
      },

      getMedioSectorByIdMedio(idMedio) {
        // Devuelve el MedioSector SIN FINALIZAR del medio
        let medioSector = null

        if (this.incendioSelected) {
          for (let i = 0; i < this.incendioSelected.SECTORES.length; i++) {
            const sector = this.incendioSelected.SECTORES[i]

            for (let j = 0; j < sector.MEDIOS.length; j++) {
              const ms = sector.MEDIOS[j]

              if (ms.ID_MEDIO === idMedio && !ms.FECHA_SALIDA) {
                medioSector = ms
                break
              }
            }
          }
        }

        return medioSector
      },

      isMedioAsignadoIncendio(idMedio) {
        if (this.incendioSelected) {
          for (let i = 0; i < this.incendioSelected.SECTORES.length; i++) {
            const sector = this.incendioSelected.SECTORES[i]

            if (sector.MEDIOS.some((x) => x.ID_MEDIO === idMedio)) {
              return true
            }
          }
        }

        return false
      },

      hasMedioEntradaIncendio(idMedio) {
        if (this.incendioSelected) {
          for (let i = 0; i < this.incendioSelected.SECTORES.length; i++) {
            const sector = this.incendioSelected.SECTORES[i]

            for (let j = 0; j < sector.MEDIOS.length; j++) {
              const medioSector = sector.MEDIOS[j]

              if (
                medioSector.ID_MEDIO === idMedio &&
                medioSector.FECHA_LLEGADA
              ) {
                return true
              }
            }
          }
        }

        return false
      },

      setAproximacionGeofencing(eventoGeofencing) {
        // Añade el medio al incendio
        const medio = this.getMedioByID(eventoGeofencing.ID_MEDIO)

        const idSector = this.incendioSelected.SECTORES[0].ID_SECTOR
        const fecha = this.$date.parseDate(eventoGeofencing.FECHA)

        let tiempos = constants.tiemposTrabajoMedios
        if (
          medio.TIPO === 'Retén Tragsa' ||
          medio.TIPO === 'Cuadrilla Helitransportada'
        ) {
          tiempos = constants.tiemposTrabajoMediosReducido
        }

        const idMedioSector = this.$uuid.createUUID()
        const inicioJornada = this.$date.parseDate(
          fecha.format('DD/MM/YYYY') + ' ' + medio.HORA_ENTRADA,
          'DD/MM/YYYY HH:mm',
        )
        const planificacion = {
          ID_PLANIFICACION_MEDIO: this.$uuid.createUUID(),
          ID_MEDIO_SECTOR: idMedioSector,
          INICIO_JORNADA: inicioJornada,
          TIEMPO_OPERATIVO: tiempos.tiempoJornada,
          TIEMPO_DESCANSO: tiempos.tiempoDescanso,
        }

        const mediosIncendio = {
          ID_INCENDIO: this.incendioSelected.ID_INCENDIO,
          ID_SECTOR: idSector,
          MEDIOS: [],
        }

        const medioSector = {
          ID_MEDIO_SECTOR: idMedioSector,
          ID_SECTOR: idSector,
          ID_MEDIO: eventoGeofencing.ID_MEDIO,
          FECHA_AVISO: fecha,
          FECHA_LLEGADA: fecha,
          FECHA_LLEGADA_GEOFENCING: fecha,
          PLANIFICACION: planificacion,
        }
        mediosIncendio.MEDIOS.push(medioSector)

        this.$store.dispatch('incendio/addMediosIncendio', mediosIncendio)
      },

      setEntradaGeofencing(eventoGeofencing) {
        // Pone su fecha de llegada = fecha geofencing
        const medioSector = this.getMedioSectorByIdMedio(
          eventoGeofencing.ID_MEDIO,
        )

        medioSector.FECHA_LLEGADA = eventoGeofencing.FECHA
        medioSector.ID_INCENDIO = this.incendioSelected.ID_INCENDIO

        this.$store.dispatch('incendio/editMedioIncendio', medioSector)
      },
    },
  }
</script>

<style scoped></style>
