import { ChatRequest } from '@/api/chat'
import { RelevanciaRequest } from '@/api/relevancia'
import { EmergenciaRequest } from '@/api/emergencia'
import Vue from 'vue'
import constants from '@/helpers/constants'

export default {
  namespaced: true,
  state: {
    messages: null,
    chatActivo: null,
    entidades: [],
    chats: null,
    relevancias: null,
    selectedMessages: [],
    simulatedUser: null,
  },
  getters: {
    getEmergencia: (state, getters, rootState) => {
      return rootState.emergencia.emergenciaSelected
    },
    chatActiveIndex: (state) => {
      return state.chats.findIndex((item) => item.id === state.chatActivo.id)
    },
    getChatById: (state, chatId) => {
      return state.chats.find((chat) => chat.id === chatId)
    },
    getValidSelectedMessages: (state, getters) => {
      return getters.getSelectedMessages.filter((message) => !message.isErroneo)
    },
    getInvalidSelectedMessages: (state, getters) => {
      return getters.getSelectedMessages.filter((message) => message.isErroneo)
    },
    getSelectedMessages: (state) => {
      return state.messages.filter((message) =>
        state.selectedMessages.includes(message.id),
      )
    },
  },
  mutations: {
    SET_MESSAGES(state, messages) {
      state.messages = messages
    },

    SET_CHAT_ACTIVO(state, chatActivo) {
      state.chatActivo = chatActivo
    },

    SET_CHATS(state, chats) {
      state.chats = chats?.sort((a, b) =>
        a.fechaCreacion > b.fechaCreacion ? 1 : -1,
      )
    },

    SET_ENTIDADES(state, entidades) {
      state.entidades = entidades
    },

    SET_SIMULATED_USER(state, user) {
      state.simulatedUser = user
    },

    SET_RELEVANCIAS(state, relevancias) {
      state.relevancias = relevancias
    },

    CLEAR_SELECTED_MESSAGES(state) {
      state.selectedMessages = []
    },

    SELECT_MESSAGE(state, { messageId, isSelected }) {
      if (isSelected) {
        state.selectedMessages.push(messageId)
      } else {
        state.selectedMessages = state.selectedMessages.filter(
          (element) => element != messageId,
        )
      }
    },

    ERROR_AUTORIZACION(state, data) {
      this.notify(data.title, data.text, 'error')
    },

    ERROR_RESPUESTA_SERVER(state, data) {
      this.notify(data.title, data.text, 'error')
    },
  },
  actions: {
    async FETCH_MESSAGES({ state, commit, rootState }) {
      if (state.chatActivo && rootState.emergencia.emergenciaSelected) {
        try {
          const messages = await ChatRequest.getMessages(state.chatActivo.id)
          commit('SET_MESSAGES', messages)
          return messages
        } catch (err) {
          if (
            err.response &&
            err.response.status !== 200 &&
            err.response.status !== 500
          ) {
            commit('ERROR_AUTORIZACION', {
              title: err.response.statusText,
              text: constants.notificacionesPermisos.Chat.getMessages,
            })
          } else if (err.request) {
            commit('ERROR_RESPUESTA_SERVER', {
              title: err.request.statusText,
              text: constants.notificacionesPermisos.Error[500],
            })
          }
        }
      }
    },

    async FETCH_CHATS({ state, commit, dispatch }, emergenciaId) {
      if (!state.relevancias) {
        dispatch('FETCH_RELEVANCIAS')
      }
      try {
        await commit('SET_CHATS', null)
        const chats = await EmergenciaRequest.getChats(emergenciaId)
        await commit('SET_CHATS', chats)
        await dispatch('SET_CHAT_ACTIVO', state.chats[0])
      } catch (err) {
        // Do nothing, se gestiona la excepcion en emergencia.js -> getChats()
      }
    },

    async SET_ENTIDADES({ state, commit }, entidades) {
      try {
        const entidadesResult = await ChatRequest.setEntidades(
          state.chatActivo.id,
          entidades,
        )
        await commit('SET_ENTIDADES', entidadesResult)
      } catch (error) {
        if (
          error.response &&
          error.response.status !== 200 &&
          error.response.status !== 500
        ) {
          commit('ERROR_AUTORIZACION', {
            title: error.response.statusText,
            text: constants.notificacionesPermisos.Chat.addDeleteEntidadToChat,
          })
        } else if (error.request) {
          commit('ERROR_RESPUESTA_SERVER', {
            title: error.request.statusText,
            text: constants.notificacionesPermisos.Error[500],
          })
        }
        throw error
      }
    },

    async SET_NAME({ commit }, { chatId, newName }) {
      try {
        await ChatRequest.setName(chatId, newName)
      } catch (error) {
        if (
          error.response &&
          error.response.status !== 200 &&
          error.response.status !== 500
        ) {
          commit('ERROR_AUTORIZACION', {
            title: error.response.statusText,
            text: constants.notificacionesPermisos.Chat.cambiarNombre,
          })
        } else if (error.request) {
          commit('ERROR_RESPUESTA_SERVER', {
            title: error.request.statusText,
            text: constants.notificacionesPermisos.Error[500],
          })
        }
        throw error
      }
    },

    async SET_CHAT_ACTIVO({ commit, dispatch }, chat) {
      commit('SET_CHAT_ACTIVO', chat)
      if (chat) {
        dispatch('FETCH_MESSAGES')
        dispatch('FETCH_ENTIDADES')
      }
    },

    async FETCH_ENTIDADES({ state, commit }) {
      const entidades = await ChatRequest.getEntidades(state.chatActivo.id)
      await commit('SET_ENTIDADES', entidades)
    },

    async CLEAR_CHAT_DATA({ commit }) {
      await commit('SET_CHAT_ACTIVO', null)
      await commit('SET_CHATS', null)
      commit('CLEAR_SELECTED_MESSAGES')
    },

    async FETCH_RELEVANCIAS({ commit }) {
      try {
        const relevancias = await RelevanciaRequest.getAll()
        commit('SET_RELEVANCIAS', relevancias)
      } catch (error) {
        if (
          error.response &&
          error.response.status !== 200 &&
          error.response.status !== 500
        ) {
          commit('ERROR_AUTORIZACION', {
            title: error.response.statusText,
            text: constants.notificacionesPermisos.Relevancia.getRelevancias,
          })
        } else if (error.request) {
          commit('ERROR_RESPUESTA_SERVER', {
            title: error.request.statusText,
            text: constants.notificacionesPermisos.Error[500],
          })
        }
        console.error('ERROR AL CARGAR RELEVANCIAS', error)
      }
    },

    async MARK_MESSAGES_AS_INVALID({ state, commit, dispatch }) {
      try {
        await ChatRequest.markMessagesAsInvalid(state.selectedMessages)
        await commit('CLEAR_SELECTED_MESSAGES')
        dispatch('FETCH_MESSAGES')
      } catch (error) {
        if (
          error.response &&
          error.response.status !== 200 &&
          error.response.status !== 500
        ) {
          Vue.notify({
            title: error.response.statusText,
            text: constants.notificacionesPermisos.Chat
              .marcarMensajeComoNoValido,
            type: 'error',
          })
        } else if (error.request) {
          Vue.notify({
            title: error.request.statusText,
            text: constants.notificacionesPermisos.Error[500],
            type: 'error',
          })
        }
        console.error('ERROR AL MARCAR MENSAJES INVALIDOS', error)
      }
    },

    async MARK_MESSAGES_AS_VALID({ state, commit, dispatch }) {
      try {
        await ChatRequest.markMessagesAsValid(state.selectedMessages)
        await commit('CLEAR_SELECTED_MESSAGES')
        dispatch('FETCH_MESSAGES')
      } catch (error) {
        console.error('ERROR AL MARCAR MENSAJES VALIDOS', error)
        if (error.response.status === 401) {
          Vue.notify({
            title: 'Error',
            text: 'El usuario no tiene permisos para marcar mensajes como validos',
            type: 'error',
          })
        } else {
          Vue.notify({
            title: 'Error',
            text: 'Error al marcar mensajes como validos',
            type: 'error',
          })
        }
      }
    },
  },
}
