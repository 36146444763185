<template>
  <v-dialog class="dialog-simulate-user" v-model="isOpen" width="500px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Simular usuario</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="dialog-content">
        <v-form ref="form">
          <v-container>
            <v-row class="mt-0">
              <v-col xs="12" sm="12" md="12">
                <v-text-field
                  type="text"
                  label="Buscar"
                  placeholder="Buscar"
                  v-model="usernameFilter"
                  counter
                  maxlength="250"
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
          <div class="usuarios">
            <UsuariosSelectList
              ref="usuariosSelectList"
              :filter="usernameFilter"
              @userSelected="userSelected = $event"
            />
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn :disabled="!userSelected" text color="green" @click="aceptar">
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import UsuariosSelectList from './UsuariosSelectList.vue'
  import { mapState } from 'vuex'

  export default {
    expose: ['cancelar', 'open'],
    props: {
      show: Boolean,
    },

    components: {
      UsuariosSelectList,
    },

    data: () => ({
      isOpen: false,
      userSelected: null,
      usernameFilter: null,
    }),

    methods: {
      open() {
        this.isOpen = true
      },
      cancelar() {
        this.userSelected = undefined
        this.simulatedUser = undefined
        this.isOpen = false
      },
      aceptar() {
        this.simulatedUser = this.userSelected
        this.$emit('update:show', false)
        this.$emit('simulateUser', this.userSelected)
        this.isOpen = false
      },
    },

    computed: {
      ...mapState('chat', ['simulatedUser']),
      simulatedUser: {
        get() {
          return this.simulatedUser
        },
        set(value) {
          this.$store.commit('chat/SET_SIMULATED_USER', value)
        },
      },
    },

    watch: {
      userSelected(value) {
        console.log('usuarioSelected', value)
      },
    },
  }
</script>

<style scoped lang="scss">
  .inputText {
    margin-top: -15px;
  }
  .usuarios {
    height: 20rem;
  }
</style>
