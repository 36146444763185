<template>
  <div>
    <v-navigation-drawer
      v-model="show"
      class="lateral-menu"
      width="770"
      color="#AAAAAACC"
      absolute
      hide-overlay
      stateless
      style="z-index: 3"
    >
      <v-toolbar color="primaryLight" dark dense>
        <v-btn dark icon @click="exit">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="header-text" v-if="emergenciaSelected">
          <span>{{
            `${emergenciaSelected.nombre} - ID ${emergenciaSelected.codigo}`
          }}</span>
          <v-spacer />
          <span>{{ emergenciaSelected.activa ? 'Activa' : '' }}</span>
          <span>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  fab
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  color="secondary"
                  bottom
                  right
                  :class="!$vuetify.breakpoint.mobile ? 'descargarPDF' : ''"
                  @click="openDialogInformeEmergencia"
                >
                  <img
                    width="26px"
                    height="26px"
                    :src="require('@/assets/icons/download_informe.svg')"
                  />
                </v-btn>
              </template>
              <span>Informe emergencia</span>
            </v-tooltip>
          </span>

          <vx-dialog-descargar-informe
            :show="showGenerarInforme"
            :emergencia="emergenciaSelected"
            @cancelar="showGenerarInforme = false"
          />
        </v-toolbar-title>
      </v-toolbar>

      <v-container class="expansion-container" fluid style="overflow-y: auto">
        <v-expansion-panels v-model="openPanels" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header color="#1A33F4">
              <span class="expansion-title">Datos Emergencia</span>
              <template #actions>
                <v-icon color="white"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <vx-datos-emergencia
                v-if="emergenciaSelected"
                :emergencia="emergenciaSelected"
                @openProtocolo="showProtocolPanel"
                @showEditEmergencia="
                  $emit('openEditEmergencia', emergenciaSelected)
                "
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="#1A33F4">
              <span class="expansion-title">Información adicional</span>
              <template #actions>
                <v-icon color="white"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Componente información adicional -->
              <vx-informacion-adicional
                v-if="emergenciaSelected"
                :emergencia="emergenciaSelected"
                @showEditInfoEmergencia="
                  $emit('openEditInfoEmergencia', emergenciaSelected)
                "
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="#1A33F4">
              <span class="expansion-title">Protocolo de intervención</span>
              <template #actions>
                <v-icon color="white"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Componente de protocolo de intervencion -->
              <vx-protocolo-intervencion
                :protocoloIntervencion="dataProtocolo"
                :idEmergenciaSituacion="idEmergenciaSituacionOperativa"
                :isSituacionOperativaActiva="isActiva"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="chatActivo && emergenciaSelected">
            <v-expansion-panel-header color="#1A33F4">
              <span class="expansion-title">Mensajería</span>
              <div class="header-icons">
                <vx-fullscren-dialog>
                  <vx-emergencia_chat :chatActivo="chatActivo" />
                </vx-fullscren-dialog>
              </div>
              <template #actions>
                <v-icon color="white"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="expansion-content">
                <vx-emergencia_chat :chatActivo="chatActivo" />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="isSimulationEnviroment === 'yes'">
            <v-expansion-panel-header color="#1A33F4">
              <span class="expansion-title">Bitácora</span>
              <template #actions>
                <v-icon color="white"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Componente del cuaderno de bitácora -->
              <Bitacora
                v-if="emergenciaSelected"
                :emergencia="emergenciaSelected"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import DatosEmergenciaVue from './DatosEmergencia.vue'
  import ProtocoloIntervencion from './ProtocoloIntervencion.vue'
  import EmergenciaChatVue from './chat/EmergenciaChat.vue'
  import FullScreenDialogVue from '@/components/layout/FullScreenDialog.vue'
  import InformacionAdicionalVue from './InformacionAdicional.vue'
  import DialogDescargarInformeVue from './DialogDescargarInforme.vue'
  import Bitacora from './Bitacora.vue'

  import pdfMake from 'pdfmake/build/pdfmake'
  import pdfFonts from 'pdfmake/build/vfs_fonts'
  pdfMake.addVirtualFileSystem(pdfFonts)

  export default {
    components: {
      'vx-datos-emergencia': DatosEmergenciaVue,
      'vx-informacion-adicional': InformacionAdicionalVue,
      'vx-protocolo-intervencion': ProtocoloIntervencion,
      'vx-emergencia_chat': EmergenciaChatVue,
      'vx-fullscren-dialog': FullScreenDialogVue,
      'vx-dialog-descargar-informe': DialogDescargarInformeVue,
      Bitacora,
    },

    props: {
      show: Boolean,
      showIncendiosCards: Boolean,
      idIncendioSelected: String,
    },

    data: () => ({
      openPanels: [0],
      chatsEmergencia: [],
      dataProtocolo: [],
      showProtocolo: false,
      showGenerarInforme: false,
      idEmergenciaSituacionOperativa: null,
      isActiva: false,
      isSimulationEnviroment: process.env.VUE_APP_SANDBOX_ACTIVE,
    }),

    watch: {
      async emergenciaSelected() {
        this.openPanels = [0]
      },
    },

    computed: {
      emergenciaSelected() {
        return this.$store.state.emergencia.emergenciaSelected
      },

      chatActivo() {
        return this.$store.state.chat.chatActivo
      },
    },

    methods: {
      exit() {
        this.$el.scrollTop = 0
        this.$emit('exit')
        this.$store.dispatch('chat/CLEAR_CHAT_DATA')
      },

      showProtocolPanel(data) {
        if (data.showPanel === 1) {
          this.dataProtocolo = data.data
          this.showProtocolo = true
          // id_emergencia_situacion_operativa
          this.idEmergenciaSituacionOperativa =
            data.idEmergenciaSituacion && data.idEmergenciaSituacion

          // Es la situacion operativa activa? true, false
          this.isActiva =
            data.isSituacionOperativaActiva && data.isSituacionOperativaActiva
        }
      },

      openDialogInformeEmergencia() {
        this.showGenerarInforme = true
      },
    },
  }
</script>

<style scoped>
  .expansion-content {
    display: flex;
    height: 350px;
    overflow: hidden;
  }
  .header-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
    padding: 0 0 0 0;
  }

  ::v-deep .v-expansion-panels {
    gap: 8px;
  }

  .lateral-menu::v-deep .v-navigation-drawer__content {
    overflow: hidden;
  }

  .v-expansion-panel-header {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .expansion-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }

  .expansion-container {
    padding-bottom: 150px;
    overflow: scroll;
    height: 100%;
  }

  .header-icons {
    display: flex;
    justify-content: flex-end;
  }

  /* BOTON DESCARGAR PDF */
  .descargarPDF {
    margin-left: 4em;
    margin-right: 2em;
  }
</style>
