<template>
  <div
    :class="{
      self: isMyMessage,
      selected: messageIsSelected,
      'not-valid': message.isErroneo,
      'relevance-low': isRelevanceLow,
      'relevance-medium': isRelevanceMedium,
      'relevance-important': isRelevanceImportant,
      'relevance-very-important': isRelevanceVeryImportant,
    }"
    @click="selectMessage(!messageIsSelected)"
    class="message"
  >
    <div class="header">
      <div class="avatar">
        <v-avatar size="24">
          <img
            :src="
              $options.filters.base64Image(message.Usuario.Entidad.logo) ||
                require('@/assets/icons/avatar_default.svg')
            "
          >
        </v-avatar>
      </div>
      <div class="message-info">
        <div class="entity">
          <!-- TODO: información básica del cargo, si no hay cargo: entidad y nombre + apellidos del usuario -->
          {{ mostrarCargoPrincipalOusuario }}
        </div>
        <div class="relevance">
          {{ `${message.Relevancium.nombre}` }}
        </div>
      </div>
      <span class="date">{{ message.fechaCreacion | formatDate }}</span>
    </div>
    <div class="content">
      <span>{{ message.texto }}</span>
      <v-simple-checkbox
        v-if="userCanSelectMessages"
        @click="selectMessage(!messageIsSelected)"
        :value="messageIsSelected"
        :ripple="false"
      />
    </div>
    <div
      class="contentDocument"
      v-if="message.Documento && !$vuetify.breakpoint.mobile"
    >
      <div v-if="formatDocument !== '.pdf'">
        <img
          v-if="message.Documento.ruta"
          :src="image"
          class="ficheroAdjunto"
        >
      </div>
      <div v-else>
        <vx-pdf-preview :fileUrl="pdfPreview" />
      </div>
    </div>
    <div
      @click.stop="downloadDocument(message.Documento)"
      v-if="message.idDocumento"
      class="document"
    >
      <img :src="iconoTipoArchivo(message.Documento)">
      <span>{{ message.Documento.nombre }}</span>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { FileHelper } from '@/helpers/FileHelper'
  import { ImageHelper } from '@/helpers/ImageHelper'
  import { Relevance } from '@/models/Relevance'

  import PdfPreview from '@/components/map/emergencias/chat/PdfPreview.vue'
  import { UserHelper } from '@/helpers/UserHelper'

  export default {
    components: {
      'vx-pdf-preview': PdfPreview,
    },

    data: () => ({
      image: null,
      formatDocument: null,
      pdfPreview: null,
    }),

    props: {
      message: null,
      userCanSelectMessages: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapGetters('usuario', ['currentUsuario']),
      ...mapGetters('documento', ['getDocumentoLocalById']),
      ...mapState('chat', ['selectedMessages']),
      isMyMessage() {
        return this.currentUsuario.ID == this.message.Usuario.id_usuario
      },
      messageRelevance() {
        return this.message.Relevancium.codigo
      },
      messageIsSelected() {
        return this.selectedMessages?.some(
          (message) => message == this.message.id,
        )
      },
      isRelevanceLow() {
        return this.messageRelevance == Relevance.ID_BAJA
      },
      isRelevanceMedium() {
        return this.messageRelevance == Relevance.ID_NORMAL
      },
      isRelevanceImportant() {
        return this.messageRelevance == Relevance.ID_IMPORTANTE
      },
      isRelevanceVeryImportant() {
        return this.messageRelevance == Relevance.ID_MUY_IMPORTANTE
      },
      mostrarCargoPrincipalOusuario() {
        return this.getCargoPrincipalOnombreUsuario(this.message)
      },
    },

    methods: {
      /**
       * @param {import("@/typedefs").Message} message
       */
      getCargoPrincipalOnombreUsuario(message) {
        return UserHelper.getCargoPrincipalOnombreUsuario(message.Usuario)
      },

      async downloadDocument(documentData) {
        try {
          FileHelper.downloadFile(documentData)
        } catch (error) {
          console.error('ERROR AL DESCARGAR DOCUMENTO ASOCIADO', error)
          this.$notify({
            title: 'Error al descargar documento',
            text: 'Ha ocurrido un error desconocido al intentar descargar el documento, intentelo en unos minutos',
            type: 'error',
          })
        }
      },

      iconoTipoArchivo(archivo) {
        return FileHelper.getFileTypeIcon(archivo)
      },

      selectMessage(isSelected) {
        this.$store.commit('chat/SELECT_MESSAGE', {
          messageId: this.message.id,
          isSelected,
        })
      },

      async base64Adjunto(adjunto) {
        if (adjunto && adjunto.ruta) {
          const extension = await FileHelper.getExtensionNombreArchivo(
            adjunto.ruta,
          )
          const mimeType = await ImageHelper.getImagenExtension(extension)
          const type = await FileHelper.getFileType(extension)
          if (type === 'photo') {
            const imageStr = await FileHelper.getFileFromCacheOrServer({
              ruta: adjunto.ruta,
              id_documento: adjunto.id_documento,
            })

            return imageStr.storage === 'server'
              ? mimeType + '' + imageStr.document.ruta
              : imageStr.document.ruta
          } else if (type === 'document') {
            if (extension === '.pdf') {
              this.formatDocument = extension
              const content = await FileHelper.getFileFromCacheOrServer({
                ruta: adjunto.ruta,
                id_documento: adjunto.id_documento,
              })

              // PDF almacenado en cache local, enviar sin mimeType para previsualizacion
              let split = null
              if (content.storage === 'local') {
                split = content.document.ruta.split(
                  'data:application/pdf;base64,',
                )[1]
              }

              this.pdfPreview =
                content.storage === 'server' ? content.document.ruta : split
            }
          }
        }
      },
    },

    async mounted() {
      this.image = await this.base64Adjunto(this.message.Documento)
    },
  }
</script>

<style scoped lang="scss">
  .message {
    display: flex;
    gap: 4px;
    flex-direction: column;
    max-width: 520px;
    padding: 4px 12px;
    background: #ffffff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    border-left: 3px solid #747474;
    transition: background-color 0.3s ease;
    &.self {
      align-self: flex-end;
      margin-left: 30px;
      background-color: #e0e2f5;
      &.selected {
        background-color: var(--mesage-selected);
      }
      .header .avatar,
      .entity {
        display: none;
      }
    }
    &.selected {
      background-color: var(--mesage-selected);
    }
    &.not-valid {
      background-color: #d8d9e0;
      .content span {
        text-decoration: line-through;
      }
    }
    &.relevance-low {
      border-left: 3px solid var(--low-message);
      background-color: var(--low-message);
    }
    &.relevance-important {
      border-left: 3px solid var(--important-message);
      background-color: var(--important-message);
    }

    &.relevance-very-important {
      border-left: 3px solid var(--very-important-message);
      background-color: var(--very-important-message);
    }

    .header {
      display: flex;
      font-weight: 500;
      font-family: 'Lato';
      font-style: italic;
      font-size: 14px;
      gap: 12px;
      .message-info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-evenly;
      }
      .avatar {
        align-self: baseline;
        margin-top: 4px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        // background-image: url(../../../../assets/icons/avatar_default.svg);
      }
    }
    .content {
      display: flex;
      font-family: 'Lato';
      font-weight: 400;
      font-size: 16px;
      line-height: 19.2px;
      span {
        word-break: break-word;
        flex-grow: 1;
      }
      .v-simple-checkbox {
        align-self: end;
        right: -14px;
      }
    }
    .document {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      font-size: 14px;
      color: #747474;
    }

    .contentDocument {
      max-width: 200px;
      max-height: 200px;
      margin-bottom: 5px;
      .ficheroAdjunto {
        max-width: 100%;
        max-height: 200px;
      }
    }
  }
</style>
