import Vue from 'vue'

Vue.mixin({
  data: () => ({
    globalRules: {
      required: (v) => !!v || 'Campo obligatorio',

      fileInput: [
        (v) => !!v || 'File is required',
        (v) => (v && v.size > 0) || 'File is required',
      ],
    },

    emailRules: [
      (v) => !!v || 'Campo obligatorio',
      (v) =>
        !v ||
        /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'El email no es válido',
    ],

    passwordRules: {
      required: (value) => !!value || 'Campo obligatorio',
      min: (v) => (v && v.length) >= 8 || 'Mínimo 8 caracteres',
      match: (value, matchValue) =>
        value === matchValue || 'Las contraseñas no coinciden',
    },

    securePasswordRules: {
      complete: (v) => {
        if (!v) return 'Campo obligatorio'
        if (v.length < 8) return 'Mínimo 8 caracteres'
        if (!/[A-Z]/.test(v) || !/[a-z]/.test(v))
          return 'Debe contener al menos una letra mayúscula y otra minúscula'
        if (!/\d/.test(v)) return 'Debe contener al menos un número'
        if (!/[^A-Za-z0-9]/.test(v))
          return 'Debe contener al menos un carácter especial'
        // True si cumple con todas las condiciones
        return true
      },
    },

    fileMaxSize: [
      (v) =>
        !v || v.size < 30000000 || 'El documento excede el tamaño de 30 MB',
    ],

    documentMaxSizeRequired: [
      (v) => !!v || 'Campo obligatorio',
      (v) =>
        (v && v.size < 30000000) || 'El documento excede el tamaño de 30 MB',
    ],

    photoMaxSizeRequired: [
      (v) => !!v || 'Campo obligatorio',
      (v) => (v && v.size < 8000000) || 'La imagen excede el tamaño de 8 Mb',
    ],

    videoMaxSizeRequired: [
      (v) => !!v || 'Campo obligatorio',
      (v) => (v && v.size < 30000000) || 'El video excede el tamaño de 30 Mb',
    ],

    maxCharactersEmergencyMessage: [
      (v) => !v || v.length <= 1000 || 'Máximo 1000 caracteres',
    ],
  }) /* ,

  methods: {
    isDateValid (v) {
      return this.$date.isValid(v) || 'Fecha inválida.'
    },

    isDateFuture (v) {
      let now = this.$date.now()
      let date = this.$date.parseDate(v)
      return date <= now || 'La fecha no puede ser a futuro.'
    },
  } */,
})
