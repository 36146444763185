<template>
  <div>
    <v-snackbar top :timeout="3000" color="error" v-model="showSnackBarError">
      Posición fuera del ambito de actuación.
    </v-snackbar>

    <v-fade-transition>
      <div id="contextMenu" v-show="showContextMenu">
        <v-list class="pa-0" color="secondary" dense dark>
          <v-list-item
            class="contextOption"
            v-for="item in buttons"
            :key="item.action"
            @click="clickBtn(item.action)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import * as ArcGIS from '../../helpers/ArcGIS'
  import { EmergenciaArcGISHelper } from '@/helpers/ArcGISHelpers/EmergenciaArcGISHelper'

  export default {
    props: {
      showIncendiosCards: Boolean,
    },
    data: () => ({
      showContextMenu: false,
      buttons: [{ label: 'Zoom', icon: 'mdi-magnify', action: 'zoom' }],

      showSnackBarError: false,

      clickPoint: null,
    }),

    computed: {
      userCanSeeEmergencias() {
        return this.$store.getters['usuario/rolesUsuario']([
          'VER_EMERGENCIAS',
          'MOD_EMERGENCIAS',
        ])
      },
      userCanEditEmergencias() {
        return this.$store.getters['usuario/rolesUsuario'](['MOD_EMERGENCIAS'])
      },
      ...mapGetters('map', ['layersPrueba']),
    },

    methods: {
      contextMenu(data) {
        if (!data) {
          this.showContextMenu = false
          this.quitarAccionNuevaEmergenciaContextMenu()
        } else {
          this.clickPoint = data
          this.updatePosition()
          this.showContextMenu = true

          const layerEmergencia = EmergenciaArcGISHelper.getLayerEmergencia()
          const estadoLayerEmergencia = layerEmergencia.visible
          if (estadoLayerEmergencia) {
            if (this.userCanEditEmergencias) {
              this.addAccionNuevaEmergenciaContextMenu()
            }
          } else {
            if (this.userCanSeeEmergencias) {
              this.quitarAccionNuevaEmergenciaContextMenu()
            }
          }
        }
      },

      updatePosition() {
        const positionX = this.clickPoint.position.x
        const positionY = this.clickPoint.position.y

        const menu = document.getElementById('contextMenu')
        menu.style.top = positionY + 'px'
        menu.style.left = positionX + 'px'
      },

      clickBtn(action) {
        if (action === 'addEmergencia') {
          this.addEmergencia()
        } else if (action === 'zoom') {
          this.zoom()
        }
        this.showContextMenu = false
      },

      async addEmergencia() {
        /*
      let pointData = await MunicipioAPI.getPointBarrioDistrito(
        this.clickPoint.attributes.X, 
        this.clickPoint.attributes.Y
      )
      */
        const isCorrecto = await ArcGIS.pointInBuffer(
          this.clickPoint.attributes.LATITUDE,
          this.clickPoint.attributes.LONGITUDE,
        )
        if (isCorrecto) {
          this.$emit('openAddEmergencia', this.clickPoint.attributes)
        } else {
          this.showSnackBarError = true
        }
      },

      zoom() {
        const center = {
          target: [
            this.clickPoint.attributes.LONGITUDE,
            this.clickPoint.attributes.LATITUDE,
          ],
          zoom: 15,
        }
        ArcGIS.setCenterMap(center)
      },

      quitarAccionNuevaEmergenciaContextMenu() {
        // Comprobar si la accion esta en buttons
        const existe = this.buttons.findIndex(
          (x) => x.label === 'Nueva emergencia',
        )
        if (existe !== -1) {
          // Eliminar accion Nueva emergencia, capa emergencias no activa
          this.buttons.splice(existe, 1)
        }
      },

      addAccionNuevaEmergenciaContextMenu() {
        // Comprobar si la accion esta en buttons
        const existe = this.buttons.find((x) => x.label === 'Nueva emergencia')
        if (!existe) {
          this.buttons.push({
            label: 'Nueva emergencia',
            icon: 'mdi-plus',
            action: 'addEmergencia',
          })
        }
      },
    },

    mounted() {
      this.$eventHub.$on('showContextMenu', this.contextMenu)
      if (this.userCanEditEmergencias) {
        this.buttons.push({
          label: 'Nueva emergencia',
          icon: 'mdi-plus',
          action: 'addEmergencia',
        })
      }
    },
  }
</script>

<style scoped>
  #contextMenu {
    position: absolute !important;
    max-width: 400px;
    background-color: white;
  }
  .contextOption {
    transition: 0.3s;
  }
  .contextOption:hover {
    background-color: var(--v-primary-base);
    color: white;
  }
</style>
