<template>
  <v-dialog v-model="show" scrollable persistent max-width="800px">
    <v-card>
      <v-toolbar color="primary" dark dense>
        <v-toolbar-title>Simulaciones</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="px-0">
        <v-tabs
          v-model="tabActiva"
          background-color="primaryLight"
          dark
          slider-color="white"
        >
          <v-tab v-for="t in tabs" :key="t" ripple>
            {{ t }}
          </v-tab>

          <v-tab-item key="NuevaSimulacion">
            <v-card flat class="px-6">
              <v-card-text>
                <v-form v-model="isValid">
                  <v-container>
                    <p class="text-subtitle-1 black--text">
                      Dibujar geometría de inicio
                    </p>
                    <v-row no-gutters>
                      <v-col sm="2" align="center">
                        <v-btn
                          color="primaryLight"
                          dark
                          @click="initSketch('point')"
                        >
                          Punto
                        </v-btn>
                      </v-col>
                      <v-col sm="2" align="center">
                        <v-btn
                          color="primaryLight"
                          dark
                          @click="initSketch('polyline')"
                        >
                          Línea
                        </v-btn>
                      </v-col>
                      <v-col sm="2" align="center">
                        <v-btn
                          color="primaryLight"
                          dark
                          @click="initSketch('polygon')"
                        >
                          Polígono
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- <v-text-field v-model="geometria" prepend-icon="esri-icon-map-pin" readonly clearable label="Geometría" :rules="[rules.required]" required/> -->
                      <p>{{ textoGeometria }}</p>
                    </v-row>

                    <v-row>
                      <v-col>
                        <vx-date-text-edit
                          v-model="fechaInicio"
                          label="Fecha inicio"
                          icon="esri-icon-calendar"
                          required
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="numHoras"
                          @input="numHoras = parseInt(numHoras)"
                          label="Núm. horas"
                          type="number"
                          maxlength="2"
                          :rules="rulesNumHoras"
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="fechaFin"
                          label="Fecha fin"
                          readonly
                        />
                      </v-col>
                    </v-row>

                    <!-- <v-row>
                      <v-col>
                        <v-text-field v-model="humedadCombustible" prepend-icon="mdi-water-percent" label="Humedad comb. (%)" type="number" maxlength="3" :rules="rulesHumedad"/>
                      </v-col>

                      <v-col>
                        <v-text-field v-model="humedadVegetacion" label="Humedad veg. (%)" type="number" maxlength="3" :rules="rulesHumedad"/>
                      </v-col>

                      <v-col>
                        <v-select v-model="metodoCorona" :items="metodosCorona" label="Método corona" single-line autocomplete :rules="[rules.required]" required/>
                      </v-col>
                    </v-row> -->

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="intervaloTiempo"
                          prepend-icon="mdi-clock"
                          label="Intervalo de tiempo (min)"
                          type="number"
                          maxlength="3"
                          :rules="rulesIntervaloTiempo"
                        />
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-row>
                    <v-col>
                      <v-checkbox
                        v-model="useCustomMeteo"
                        label="Usar meteo personalizada"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <div v-show="useCustomMeteo">
                    <v-btn
                      class="my-3"
                      :loading="downloadingMeteo"
                      :disabled="!geometria || !fechaInicio"
                      @click="downloadMeteo"
                    >
                      <v-icon>mdi-download</v-icon>
                      Descargar datos
                    </v-btn>

                    <p class="mt-4 text-subtitle-1 black--text">
                      Humedad Combustible Vivo
                    </p>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="humedadHerbaceo"
                          prepend-icon="mdi-water-percent"
                          label="Herbáceo"
                          type="number"
                          maxlength="3"
                          :rules="rulesHumedad"
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="humedadLeñoso"
                          label="Leñoso"
                          type="number"
                          maxlength="3"
                          :rules="rulesHumedad"
                        />
                      </v-col>
                    </v-row>

                    <v-data-table
                      :headers="meteoHeaders"
                      :items="meteoData"
                      hide-default-footer
                      disable-pagination
                    >
                      <template #item="{ item }">
                        <tr>
                          <td class="text-xs-left">{{ item.VARIABLE }}</td>
                          <td v-for="(n, i) in parseInt(numHoras)" :key="i">
                            <v-edit-dialog
                              :return-value.sync="item.VALORES[i]"
                              lazy
                            >
                              {{ item.VALORES[i] }}
                              <template #input>
                                <v-text-field
                                  v-model="item.VALORES[i]"
                                  label="Editar"
                                  type="number"
                                  :rules="getMeteoRules(item.ID)"
                                />
                              </template>
                            </v-edit-dialog>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </div>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn text color="red" @click="resetCampos">Reset</v-btn>
                <v-btn
                  text
                  color="green"
                  @click="simular"
                  :disabled="!isValid || !geometria"
                >
                  Simular
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>

          <v-tab-item key="ListaSimulaciones">
            <v-card flat class="px-6">
              <v-card-text>
                <v-form>
                  <v-container>
                    <v-row align="center">
                      <v-col>
                        <vx-date-text-edit
                          v-model="fechaDesde"
                          label="Desde"
                          icon="esri-icon-calendar"
                          only-date
                          :max="fechaHasta"
                          not-future
                          required
                        />
                      </v-col>
                      <v-col>
                        <vx-date-text-edit
                          v-model="fechaHasta"
                          label="Hasta"
                          icon="esri-icon-calendar"
                          only-date
                          :min="fechaDesde"
                          not-future
                          required
                        />
                      </v-col>
                      <v-col>
                        <v-btn
                          icon
                          @click="refreshSimulaciones"
                          :loading="isLoadingSimulaciones"
                        >
                          <v-icon>mdi-reload</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-data-table
                          :headers="headers"
                          :items="simulaciones"
                          fixed-header
                          height="500px"
                          sort-by="FECHA_CREACION"
                          sort-desc
                          disable-pagination
                          hide-default-footer
                        >
                          <template #no-data>
                            <p>Datos no disponibles.</p>
                          </template>

                          <template #item="{ item }">
                            <tr>
                              <td>{{ item.FECHA_CREACION | formatDate }}</td>
                              <td>{{ item.FECHA_INICIO | formatDate }}</td>
                              <td>{{ item.NUM_HORAS }}h</td>
                              <td>{{ item.INTERVALO }} min</td>

                              <td>
                                <v-tooltip bottom>
                                  <template #activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      icon
                                      @click="paintSimulacion(item)"
                                    >
                                      <v-icon color="black">mdi-eye</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Visualizar</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cerrar">Cerrar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Snackbar loading -->
    <v-snackbar v-model="isLoadingRequest" bottom :timeout="-1">
      Enviando petición de simulación...
      <template #action="{ attrs }">
        <v-progress-circular v-on="attrs" indeterminate />
      </template>
    </v-snackbar>

    <!-- Snackbar error y success -->
    <v-snackbar
      v-model="showSnackbar"
      :color="snackbarColor"
      :top="true"
      :timeout="3000"
    >
      <v-icon>mdi-alert</v-icon>
      {{ snackbarText }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import constants from '../../../helpers/constants'

  export default {
    props: {
      show: Boolean,
    },

    data: () => ({
      isValid: false,

      rulesNumHoras: [
        (v) => !!v || 'Campo obligatorio.',
        (v) => (!!v && v >= 4 && v <= 12) || 'Núm. inválido.',
      ],
      rulesHumedad: [
        (v) => !!v || 'Campo obligatorio.',
        (v) => (!!v && v >= 0 && v <= 100) || 'Núm. inválido.',
      ],
      rulesIntervaloTiempo: [
        (v) => !!v || 'Campo obligatorio.',
        (v) => (!!v && v >= 15 && v <= 240) || 'Núm. inválido.',
      ],

      tabs: ['Nueva simulación', 'Lista de simulaciones'],
      tabActiva: null,

      // Nueva simulacion
      isLoadingRequest: false,

      showSnackbar: false,
      snackbarColor: 'green',
      snackbarText: '',

      tipoGeometria: null,
      geometria: null,
      fechaInicio: null,
      numHoras: 12,
      /* humedadCombustible: 50,
      humedadVegetacion: 50,
      metodosCorona: ['Metodo1', 'Metodo2'],
      metodoCorona: null, */
      intervaloTiempo: 15,

      // Meteo
      useCustomMeteo: false,
      downloadingMeteo: false,
      humedadLeñoso: 10,
      humedadHerbaceo: 10,
      meteoData: [
        { ID: 'temperatura', VARIABLE: 'Temperatura', VALORES: [] },
        { ID: 'humedad_relativa', VARIABLE: 'Hum. Relativa (%)', VALORES: [] },
        { ID: 'nubosidad', VARIABLE: 'Nubosidad (%)', VALORES: [] },
        { ID: 'mod_viento', VARIABLE: 'Mod. Viento', VALORES: [] },
        { ID: 'dir_viento', VARIABLE: 'Dir. Viento', VALORES: [] },
      ],

      // Listado simulaciones
      isLoadingSimulaciones: false,
      fechaDesde: null,
      fechaHasta: null,
      headers: [
        {
          text: 'Fecha creación',
          value: 'FECHA_CREACION',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Fecha de inicio',
          value: 'FECHA_INICIO',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Num. Horas',
          value: 'NUM_HORAS',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Intervalo',
          value: 'INTERVALO',
          align: 'left',
          sortable: false,
        },
        { text: '', value: 'ACCIONES', align: 'right', sortable: false },
      ],
    }),

    watch: {
      show() {
        if (this.show && !this.geometria) {
          this.fechaInicio = this.$date.currentDate()
          this.numHoras = 8

          this.fechaHasta = this.$date.now().format('DD/MM/YYYY')
          this.fechaDesde = this.$date
            .now()
            .subtract(7, 'days')
            .format('DD/MM/YYYY')
          this.refreshSimulaciones()
        }
      },

      numHoras() {
        for (let i = 0; i < this.meteoData.length; i++) {
          // this.meteoData[i].VALORES = new Array(parseInt(this.numHoras))
          const prevData = JSON.parse(JSON.stringify(this.meteoData[i].VALORES))
          this.meteoData[i].VALORES = []
          for (let j = 0; j < this.numHoras + 2; j++) {
            this.meteoData[i].VALORES.push(prevData[j] ? prevData[j] : 0)
          }
        }
      } /* ,

      meteoData: {
        deep: true,
        handler () {
          console.log(this.meteoData)
        }
      } */,
    },

    computed: {
      simulaciones() {
        return this.$store.getters['simulacion/simulaciones']
      },

      textoGeometria() {
        let txt = ''

        if (this.tipoGeometria !== null && this.geometria) {
          const numPuntos = this.geometria.length
          switch (this.tipoGeometria) {
            case 0:
              txt += 'Punto ' + this.geometria.map((x) => Math.trunc(x))
              break
            case 1:
              txt += 'Línea - ' + numPuntos + ' puntos'
              break
            case 2:
              txt += 'Polígono - ' + (numPuntos - 1) + ' puntos'
              break
          }
        }

        return txt
      },

      fechaFin() {
        if (this.fechaInicio) {
          const fechaFin = this.$date
            .parseDate(this.fechaInicio, 'DD/MM/YYYY HH:mm')
            .add(this.numHoras, 'hours')
          if (fechaFin.isValid()) {
            return fechaFin.format('DD/MM/YYYY HH:mm')
          }
        }

        return null
      },

      meteoHeaders() {
        const headers = []

        headers.push({ text: 'Variables', sortable: false, value: 'VARIABLE' })
        for (let i = 0; i < this.numHoras; i++) {
          let headerName = i < 10 ? '0' + i : i
          headerName += 'h'
          headers.push({ text: headerName, sortable: false, value: i })
        }

        return headers
      },
    },

    methods: {
      // #region NUEVA SIMULACION
      initSketch(evt) {
        this.geometria = null
        this.$emit('close')

        switch (evt) {
          case 'point':
            this.tipoGeometria = 0
            break
          case 'polyline':
            this.tipoGeometria = 1
            break
          case 'polygon':
            this.tipoGeometria = 2
            break
        }

        const data = {
          event: evt,
          callback: this.callbackGeometryDone,
        }

        this.$emit('initSimulacionSketch', data)
      },

      callbackGeometryDone(geometry) {
        this.$emit('stopSimulacionSketch') // Cerrar snackbar y parar sketchview
        this.geometria = geometry.coordinates
      },

      getMeteoRules(idVariable) {
        let rules = []

        rules.push((v) => !!v || 'Campo obligatorio')
        switch (idVariable) {
          case 'temperatura':
            rules.push((v) => (!!v && v >= -60 && v <= 60) || 'Núm. inválido.')
            break
          case 'humedad_relativa':
          case 'nubosidad':
            rules = JSON.parse(JSON.stringify(this.rulesHumedad))
            break
          case 'mod_viento':
            rules.push((v) => (!!v && v >= 0) || 'Núm. inválido.')
            break
          case 'dir_viento':
            rules.push((v) => (!!v && v >= 0 && v <= 360) || 'Núm. inválido.')
            break
        }

        return rules
      },

      async downloadMeteo() {
        if (this.geometria) {
          this.downloadingMeteo = true

          let x, y
          if (this.tipoGeometria === 0) {
            x = this.geometria[0]
            y = this.geometria[1]
          } else {
            const p = this.geometria[0]
            x = p[0]
            y = p[1]
          }

          const url = constants.urlSimulacion + '/getMeteoPunto'
          const params = {
            x: x,
            y: y,
            fecha: this.$date
              .parseDate(this.fechaInicio, 'DD/MM/YYYY HH:mm')
              .format('YYYYMMDDHHmm'),
            numHoras: this.numHoras,
          }
          try {
            const response = await this.$http.get(url, params)
            const datos = response.data

            for (let i = 0; i < datos.length; i++) {
              const p = datos[i]

              p.VALORES = p.VALORES.map((x) => Math.round(x * 100) / 100)

              switch (p.ID) {
                case 'temperatura':
                  this.meteoData[0].VALORES = p.VALORES
                  break
                case 'humedad_relativa':
                  this.meteoData[1].VALORES = p.VALORES
                  break
                case 'nubosidad':
                  this.meteoData[2].VALORES = p.VALORES
                  break
                case 'mod_viento':
                  this.meteoData[3].VALORES = p.VALORES
                  break
                case 'dir_viento':
                  this.meteoData[4].VALORES = p.VALORES
                  break
              }
            }
          } catch (err) {
            console.error(err)
            this.snackbarError('Error descargando los datos de meteo')
          } finally {
            this.downloadingMeteo = false
          }
        } else {
          this.snackbarError('Dibuje la geometría de inicio')
        }
      },

      resetCampos() {
        this.geometria = null
        this.fechaInicio = this.$date.currentDate()
        this.numHoras = 8

        /* this.humedadCombustible = 50
        this.humedadVegetacion = 50
        this.metodoCorona = null */

        this.intervaloTiempo = 15

        this.humedadLeñoso = 10
        this.humedadHerbaceo = 10

        for (let i = 0; i < this.meteoData.length; i++) {
          this.meteoData[i].VALORES = []
          for (let j = 0; j < this.numHoras; j++) {
            this.meteoData[i].VALORES.push(0)
          }
        }
      },

      async simular() {
        this.isLoadingRequest = true

        const meteoData = JSON.parse(JSON.stringify(this.meteoData))

        for (let i = 0; i < meteoData.length; i++) {
          // Convertir valores a numero
          const d = meteoData[i]
          d.VALORES = d.VALORES.map((x) => parseFloat(x))
        }

        const precipValores = []

        for (let i = 0; i < this.numHoras + 2; i++) {
          precipValores.push(0)
        }
        meteoData.push({
          ID: 'precipitacion',
          VARIABLE: 'Precipitación',
          VALORES: precipValores,
        })

        const datosSimulador = {
          geometry: this.geometria,
          typeGeometry: this.tipoGeometria,
          dateInit: this.$date
            .parseDate(this.fechaInicio, 'DD/MM/YYYY HH:mm')
            .format('YYYY_MM_DD_HH_mm'),
          dateEnd: this.$date
            .parseDate(this.fechaFin, 'DD/MM/YYYY HH:mm')
            .format('YYYY_MM_DD_HH_mm'),
          timeStep: this.intervaloTiempo,
          useCustomMeteo: this.useCustomMeteo ? 1 : 0,
          meteoData: meteoData,
        }
        const datosBackend = {
          ID_INCENDIO: null,
          GEOMETRIA: JSON.stringify(this.geometria),
          TIPO_GEOMETRIA: this.tipoGeometria,
          FECHA_CREACION: this.$date.now(),
          FECHA_INICIO: this.$date.parseDate(
            this.fechaInicio,
            'DD/MM/YYYY HH:mm',
          ),
          NUM_HORAS: this.numHoras,
          INTERVALO: this.intervaloTiempo,
          CUSTOM_METEO: this.useCustomMeteo,
        }

        const data = {
          datosSimulador: datosSimulador,
          datosBackend: datosBackend,
        }

        try {
          await this.$store.dispatch('simulacion/addSimulacion', data)
          this.snackbarSuccess('Simulación solicitada correctamente')
          // this.resetCampos()
        } catch (err) {
          this.snackbarError('Error solicitando simulacion')
        } finally {
          this.isLoadingRequest = false
        }
      },

      snackbarError(msg) {
        this.showSnackbar = true
        this.snackbarColor = 'error'
        this.snackbarText = msg
      },
      snackbarSuccess(msg) {
        this.showSnackbar = true
        this.snackbarColor = 'success'
        this.snackbarText = msg
      },
      // #endregion

      // #region LISTADO SIMULACIONES
      async refreshSimulaciones() {
        this.isLoadingSimulaciones = true

        try {
          const data = {
            fechaDesde: this.$date
              .parseDate(this.fechaDesde, 'DD/MM/YYYY')
              .format('YYYY-MM-DD HH:mm:ss.SSS'),
            fechaHasta: this.$date
              .parseDate(this.fechaHasta, 'DD/MM/YYYY')
              .endOf('day')
              .format('YYYY-MM-DD HH:mm:ss.SSS'),
          }
          await this.$store.dispatch('simulacion/fetchSimulaciones', data)
        } finally {
          this.isLoadingSimulaciones = false
        }
      },

      paintSimulacion(simulacion) {
        this.$emit('paintSimulacion', simulacion)
      },
      // #endregion

      cerrar() {
        this.resetCampos()

        this.$emit('close')
      },
    },
  }
</script>

<style scoped></style>
