import request from '../request'

export default {
  getAllDocumentos: function () {
    return request.get('documento')
  },

  addDocumento: function (data) {
    return request.post('documento', data)
  },

  getDocumentById: function (idDocumento) {
    return request.get(`documento/${idDocumento}`)
  },

  getFileByDocumentoId: function (data) {
    return request.get('documento/getFile', {
      params: { documentoId: data.id_documento, type: data.type },
    })
  },

  getCategoriaDocumentoIdByName: (nombreCategoria) => {
    return request.get(`documento/getIdCategoria/${nombreCategoria}`)
  },

  updateDocumento: function (data) {
    return request.put('documento', data)
  },

  validateInvalidateDocumento: function (data) {
    return request.delete('documento', {
      params: { documentoId: data.documento_id, valido: data.valido },
    })
  },
}
