import store from '@/store'
import constants from '@/helpers/constants'
import { Documento } from '@/models/Documento'
import api from '@/api'

export class FileHelper {
  static getColorByFileName(archivo) {
    const splitExtension = this.getExtensionNombreArchivo(archivo.ruta)
    return {
      '.doc': 'blue',
      '.dot': 'blue',
      '.docx': 'blue',
      '.xls': 'green',
      '.xlsx': 'green',
      '.csv': 'green',
      '.txt': 'black',
      '.pdf': 'red',
      '.png': 'gray',
      '.jpeg': 'gray',
      '.jpe': 'gray',
      '.jpg': 'gray',
      '.mp4': 'orange',
      '.avi': 'orange',
      '.wmv': 'orange',
      '.webm': 'orange',
    }[splitExtension]
  }

  static getFileType(extension) {
    const documentos = [
      '.doc',
      '.dot',
      '.docx',
      '.xls',
      '.xlsx',
      '.csv',
      '.txt',
      '.pdf',
      '.odt',
    ]
    const imagenes = ['.jpg', '.jpeg', '.jpe', '.png']
    const videos = ['.avi', '.mp4', '.wmv', '.webm']
    if (documentos.some((x) => x === extension)) return 'document'
    if (imagenes.some((x) => x === extension)) return 'photo'
    if (videos.some((x) => x === extension)) return 'video'
    return 'otros'
  }

  static getFileTypeTranslate(type) {
    if (type === 'photo') return 'imágenes'
    if (type === 'video') return 'vídeos'
    if (type === 'document') return 'documentos'
    if (type === 'otros') return 'otros tipos de archivo'
  }

  static getSizeType(type) {
    const documentos = 30000000 // 30 mb
    const imagenes = 8000000 // 8 mb
    const videos = 30000000 // 30 mb
    const otros = 30000000 // 30 mb

    if (type === 'document') return documentos
    if (type === 'photo') return imagenes
    if (type === 'video') return videos
    if (type === 'otros') return otros
  }

  static bytesToMegabytes(sizeBytes) {
    const megaByte = 1.0e-6
    const result = megaByte * sizeBytes
    // console.log('Result: ', result)
    return result
  }

  static getMimeTypeByExtension(extension) {
    return {
      '.doc': 'application/msword',
      '.dot': 'application/msword',
      '.docx':
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      '.xls': 'application/vnd.ms-excel',
      '.xlsx':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      '.csv': 'text/csv',
      '.txt': 'text/plain',
      '.pdf': 'application/pdf',
      '.png': 'image/png',
      '.jpeg': 'image/jpeg',
      '.jpe': 'image/jpeg',
      '.jpg': 'image/jpeg',
      '.mp4': 'video/mp4',
      '.avi': 'video/x-msvideo',
      '.wmv': 'video/x-ms-wmv',
      '.webm': 'video/webm',
    }[extension]
  }

  static getFileTypeIcon(archivo) {
    const splitExtension = FileHelper.getExtensionNombreArchivo(archivo.ruta)
    return (
      {
        '.doc': constants.icons.word,
        '.dot': constants.icons.word,
        '.docx': constants.icons.word,
        '.xls': constants.icons.excel,
        '.xlsx': constants.icons.excel,
        '.csv': constants.icons.defaultDocument,
        '.txt': constants.icons.defaultDocument,
        '.pdf': constants.icons.pdf,
        '.png': constants.icons.imagen,
        '.jpeg': constants.icons.imagen,
        '.jpe': constants.icons.imagen,
        '.jpg': constants.icons.imagen,
        '.mp4': constants.icons.video,
        '.avi': constants.icons.video,
        '.wmv': constants.icons.video,
        '.webm': constants.icons.video,
      }[splitExtension] ?? constants.icons.defaultDocument
    )
  }

  static async downloadFile(filePath) {
    const file = await this.getFileFromServer(filePath)
    if (file) {
      const fileNameWithoutTime = this.getFileNameWithOutTime(file.nombre)
      this.#getLinkArchivo(file.ruta, fileNameWithoutTime, file.mimeType)
    }
  }

  static #getLinkArchivo(url, name, mimeType) {
    const linkSource = 'data:' + mimeType + `;base64, ${url}`
    const downloadLink = document.createElement('a')
    const fileName = name
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  static getExtensionNombreArchivo(nombreArchivo) {
    let nombre = nombreArchivo
    nombre = [...nombre].reverse().join('')
    const corteExtension = nombre.split('.')[0]
    const reverseExtension = [...corteExtension].reverse().join('')
    return '.' + reverseExtension
  }

  static async getFileFromServer({ ruta, id_documento }) {
    const splitExtension = this.getExtensionNombreArchivo(ruta)
    const tipoArchivo = this.getFileType(splitExtension)
    const mimeType = tipoArchivo && this.getMimeTypeByExtension(splitExtension)
    const fileBase64FromBBDD = await store.getters[
      'documento/getFileByDocumentoId'
    ]({
      id_documento: id_documento,
      type: tipoArchivo,
    })
    fileBase64FromBBDD && (fileBase64FromBBDD.mimeType = mimeType)
    return fileBase64FromBBDD ? fileBase64FromBBDD : null
  }

  static async getFileFromCacheOrServer({ ruta, id_documento }) {
    let result = await store.getters['documento/getDocumentoLocalById']({
      id_documento: id_documento,
    })
    let almacenamiento = null
    if (!result) {
      // if (actualSizeLocalCacheDocuments < constants.MAX_ITEMS_ON_CACHE) {
      result = await this.getFileFromServer({
        ruta: ruta,
        id_documento: id_documento,
      })

      // Guardar item en cache local
      const save =
        result &&
        (await store.dispatch('documento/addCachedDocumentoBase64', result))

      // Consultar tam actual de local cache storage
      const tamito =
        save && (await store.getters['documento/getSizeCacheDocuments'])
      constants.contador++

      almacenamiento = 'server'

      // Check tamaño actual de cache local
      if (tamito > constants.MAX_ITEMS_ON_CACHE) {
        // Borrar primer item cacheado
        await store.dispatch('documento/deleteFirstCachedDocumentoBase64', true)
      }
    } else {
      almacenamiento = 'local'
    }
    return { document: result, storage: almacenamiento }
  }

  static async fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  /**
   * @returns {Documento}
   */
  static async createFileDataForChat(file) {
    const categoriaChatId = (
      await api.documento.getCategoriaDocumentoIdByName('Mensajería')
    ).data.body
    return {
      nombre: file.name,
      valido: true,
      id_categoria_documento: categoriaChatId,
      type: this.getFileType(this.getExtensionNombreArchivo(file.name)),
      ruta: {
        nombre: this.getFileNameWithTime(file.name),
        archivo: await this.fileToBase64(file),
      },
    }
  }

  static filesAccept(file) {
    switch (file) {
      case 'document':
        return [
          '.doc',
          '.dot',
          '.docx',
          '.xls',
          '.xlsx',
          '.csv',
          '.txt',
          '.pdf',
        ]
      case 'photo':
        return ['.jpg', '.jpeg', '.jpe', '.png']
      case 'video':
        return ['.avi', '.mp4', '.wmv', '.webm']
    }
  }

  static getFileNameWithTime(fileName) {
    const fileExtension = this.getExtensionNombreArchivo(fileName)
    return (
      fileName.split(fileExtension)[0] +
      '_' +
      new Date().getTime() +
      fileExtension
    )
  }

  static getFileNameWithOutTime(fileName) {
    let posCorte = null
    let fileNameSplit = ''
    let fileNameExtension = ''
    for (let i = fileName.length - 1; i >= 0; i--) {
      if (fileName[i] === '_') {
        posCorte = i
        break
      }
    }

    fileNameExtension = this.getExtensionNombreArchivo(fileName)

    fileNameSplit = fileName.substr(0, posCorte)

    return fileNameSplit + fileNameExtension
  }

  /**
   * @param {Documento} documento
   */
  static async uploadDocumento(documento) {
    store.dispatch('documento/addDocumento', documento)
  }
}
