<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense style="z-index: 2">
      <v-toolbar-title>Gestión de usuarios</v-toolbar-title>

      <div
        id="create"
        style="z-index: 2"
        v-show="comprobarPermisos(['MOD_ACCIONES'])"
      >
        <v-speed-dial
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
          title="Menú de acciones"
        >
          <template #activator>
            <v-btn v-model="fab" color="primary" dark fab>
              <v-icon v-if="fab"> mdi-close </v-icon>
              <img v-else :src="require('@/assets/icons/menu_emergente.svg')">
            </v-btn>
          </template>
          <!-- AÑADIR USUARIO -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                height="50"
                width="50"
                color="primary"
                v-show="comprobarPermisos(['MOD_USUARIOS'])"
                @click="showAddUsuarioDialog"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Añadir usuario</span>
          </v-tooltip>
          <!-- GESTION CARGOS -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                height="50"
                width="50"
                color="primary"
                v-show="comprobarPermisos(['MOD_USUARIOS'])"
                to="cargos"
                class="iconsSpeedDial"
              >
                <v-icon>mdi-clipboard-account</v-icon>
              </v-btn>
            </template>
            <span>Gestión cargos</span>
          </v-tooltip>
        </v-speed-dial>
      </div>
    </v-toolbar>

    <v-card-text>
      <v-container fluid>
        <v-row align="center">
          <!-- <v-btn fab small color="red" @click="exportarUsuariosPDF()">
            <v-icon color="white">mdi-file-pdf-box</v-icon>
          </v-btn> -->

          <v-col cols="6" md="3" sm="3">
            <v-text-field
              :class="{ inputSelectMobile: $vuetify.breakpoint.mobile }"
              v-model="search"
              append-icon="mdi-magnify"
              label="Búsqueda"
              :dense="$vuetify.breakpoint.mobile"
              outlined
              clearable
              hide-details
              @input="searchUsuarios"
            />
          </v-col>

          <v-col cols="6" md="3" sm="3">
            <v-select
              :class="{ inputSelectMobile: $vuetify.breakpoint.mobile }"
              label="Rol"
              :items="tiposRol"
              :menu-props="{ bottom: true, offsetY: true }"
              item-text="nombre"
              item-value="nombre"
              v-model="rolSelected"
              return-object
              :dense="$vuetify.breakpoint.mobile"
              outlined
              hide-details
              :clearable="true"
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
            sm="6"
            :class="$vuetify.breakpoint.mobile ? 'checkboxMobile' : ''"
          >
            <v-checkbox v-model="showUsuariosBorrados">
              <template #label>
                <div>Mostrar usuarios deshabilitados.</div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-data-table
            :class="{
              table: !$vuetify.breakpoint.mobile,
              tableMobile: $vuetify.breakpoint.mobile,
            }"
            height="100%"
            :headers="headers"
            :items="
              usuariosFilter
                ? usuariosFilter.filter(
                  (x) => x.borrado === showUsuariosBorrados,
                )
                : usuarios
                  ? usuarios.filter((x) => x.borrado === showUsuariosBorrados)
                  : []
            "
            :loading="isLoading"
            loading-text="Cargando datos... por favor, espere"
            fixed-header
            hide-default-footer
            :page.sync="currentPage"
            :items-per-page="itemsPerPage"
          >
            <!-- :items-per-page="30" -->
            <template #no-data>
              <p>Datos no disponibles.</p>
            </template>

            <template #[`item.estado`]="{ item }">
              <span v-if="item.estado === false">Activo</span>
              <span v-else>No activo</span>
            </template>

            <template #[`item.Rols`]="{ item }">
              <div v-for="(subitem, iSub) in item.Rols" :key="subitem.id_rol">
                <span v-if="iSub < item.Rols.length - 1">{{ subitem.nombre }},
                </span>
                <span v-else>{{ subitem.nombre }}</span>
              </div>
            </template>

            <template #[`item.fechastamp`]="{ item }">
              <span>{{ item.fechastamp | formatDate }}</span>
            </template>

            <!-- USUARIO -->
            <template #[`item.UsuarioModificador`]="{ item }">
              <span v-if="item.UsuarioModificador">
                {{
                  item.UsuarioModificador.nombre +
                    ' ' +
                    item.UsuarioModificador.apellidos
                }}
              </span>
            </template>

            <template #[`item.ACCIONES`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :disabled="
                      !userIsSameEntity(item) && !comprobarPermisos([])
                    "
                    v-show="
                      comprobarPermisos(['MOD_USUARIOS']) && !item.borrado
                    "
                    @click="showEditUsuarioDialog(item)"
                  >
                    <v-icon color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-show="comprobarPermisos([]) && !item.borrado"
                    @click="acceptDeleteUsuario(item)"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Borrar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-show="comprobarPermisos([]) && !item.borrado"
                    @click="showEditPassUsuarioDialog(item)"
                  >
                    <v-icon color="warning">mdi-lock-reset</v-icon>
                  </v-btn>
                </template>
                <span>Resetear contraseña</span>
              </v-tooltip>

              <!-- REACTIVAR USUARIO BORRADO -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-show="comprobarPermisos(['MOD_USUARIOS']) && item.borrado"
                    @click="acceptReactivateUsuario(item)"
                  >
                    <v-icon color="green">mdi-account-reactivate</v-icon>
                  </v-btn>
                </template>
                <span>Reactivar usuario</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-row>

        <!-- SECCIÓN PAGINACIÓN -->
        <v-row>
          <v-col xs="2" sm="3" md="2" v-if="!$vuetify.breakpoint.mobile">
            <v-row>
              <div class="text-center align-center mt-6 pl-6 ml-6">
                <span>Total {{ totalRecords }} registros</span>
              </div>
            </v-row>
          </v-col>
          <v-col xs="1" sm="4" md="3">
            <v-row>
              <v-col sm="4" md="4" class="textMostrar">
                <div>
                  <span>Mostrar</span>
                </div>
              </v-col>
              <v-col xs="4" sm="5" md="4">
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="itemsPerPage"
                  :items="perPageOptions"
                  :class="{
                    select: !$vuetify.breakpoint.mobile,
                    selectMobile: $vuetify.breakpoint.mobile,
                  }"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="6">
            <div>
              <v-pagination v-model="currentPage" :length="pageCount" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <vx-dialog-add-edit-usuario
      :show="showDialog"
      :isEdit="isEdit"
      :isEditPass="isEditPass"
      :editedUsuario="editUsuario"
      @cancelar="showDialog = false"
      @cancelarPass="isEditPass = false"
      @addUsuario="acceptAddUsuario"
      @editUsuario="acceptEditUsuario"
      @resetPassword="acceptResetPassword"
    />
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { StringHelper } from '@/helpers/StringHelper'
  import DialogAddEditUsuario from './DialogAddEditUsuario.vue'

  import pdfMake from 'pdfmake/build/pdfmake'
  import pdfFonts from 'pdfmake/build/vfs_fonts'
  pdfMake.addVirtualFileSystem(pdfFonts)

  export default {
    components: {
      'vx-dialog-add-edit-usuario': DialogAddEditUsuario,
    },
    data: () => ({
      rolSelected: null,
      showUsuariosBorrados: false,

      showDialog: false,
      isLoading: false,
      search: '',
      usuariosFilter: null,
      editUsuario: {},
      isEdit: false,
      isEditPass: false,

      // Speed dial buttons
      direction: 'left',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',

      currentPage: 1,
      itemsPerPage: 15,
      perPageOptions: [15, 50, 75, 100],
    }),
    watch: {
      search() {
        if (this.search === null) {
          this.usuariosFilter = null
          this.usuarios.filter((x) => x.borrado === this.showUsuariosBorrados)
        }
      },
      top(val) {
        this.bottom = !val
      },
      right(val) {
        this.left = !val
      },
      bottom(val) {
        this.top = !val
      },
      left(val) {
        this.right = !val
      },
    },

    computed: {
      usuarios() {
        return this.showUsuariosBorrados
          ? this.$store.getters['usuario/usuarios'].filter((x) => x.borrado)
          : this.$store.getters['usuario/usuarios'].filter((x) => !x.borrado)
      },
      ...mapGetters('usuario', ['currentUsuario', 'rolesUsuario']),

      tiposRol() {
        return this.$store.getters['rol/tiposRol']
      },

      headers() {
        return [
          { text: 'Usuario', value: 'username', align: 'left', sortable: true },
          { text: 'Nombre', value: 'nombre', align: 'left', sortable: true },
          {
            text: 'Apellidos',
            value: 'apellidos',
            align: 'left',
            width: '120px',
            sortable: true,
          },
          {
            text: 'Entidad',
            value: 'Entidad.entidad_titular',
            align: 'left',
            sortable: true,
          },
          {
            text: 'Rol',
            value: 'Rols',
            align: 'left',
            sortable: true,
            filter: this.filterRol,
          },
          // { text: 'Estado', value: 'estado', align: 'left', sortable: true },
          {
            text: 'Últ. actualización',
            value: 'fechastamp',
            align: 'left',
            width: '160px',
            sortable: true,
          },
          {
            text: 'Usuario (Últ.actualización)',
            value: 'UsuarioModificador',
            align: 'left',
            width: '160px',
            sortable: true,
          },
        ]
      },

      totalRecords() {
        return this.usuariosFilter
          ? this.usuariosFilter.length
          : this.usuarios
          ? this.usuarios.length
          : 0
      },

      pageCount() {
        return parseInt(this.totalRecords / this.itemsPerPage + 1)
      },
    },
    methods: {
      ...mapActions('usuario', [
        'fetchUsuarios',
        'addUsuario',
        'updateUsuario',
        'deleteUsuario',
        'resetPasswordUsuario',
        'reactivateUsuarioById',
      ]),

      ...mapActions('auth', ['logout']),

      ...mapActions('entidad', ['fetchEntidades']),

      ...mapActions('rol', ['fetchTiposRol']),

      userIsSameEntity(usuario) {
        return this.$store.getters['usuario/permisosEntidad'](
          usuario.Entidad.id_entidad,
        )
      },

      showAddUsuarioDialog() {
        this.editUsuario = null
        this.showDialog = true
        this.isEdit = false
      },

      showEditUsuarioDialog(item) {
        this.editUsuario = Object.assign({}, item)
        this.showDialog = true
        this.isEdit = true
      },

      showEditPassUsuarioDialog(item) {
        this.editUsuario = Object.assign({}, item)
        this.showDialog = true
        this.isEditPass = true
        this.isEdit = null
      },

      async acceptDeleteUsuario(usuario) {
        const msg = 'Eliminando el usuario: ' + usuario.username
        this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
          if (result) {
            this.$store.dispatch('usuario/deleteUsuario', usuario).then(() => {
              if (result) {
                this.fetchUsuarios()
              }
            })
          }
        })
      },

      acceptReactivateUsuario(usuario) {
        const msg = 'Reactivando el usuario ' + usuario.username
        this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
          if (result) {
            this.reactivateUsuarioById({ usuarioId: usuario.id_usuario }).then(
              () => {
                if (result) {
                  this.fetchUsuarios()
                }
              },
            )
          }
        })
      },

      async acceptAddUsuario(usuario) {
        this.showDialog = false
        await this.addUsuario(usuario)
        await this.loadUsuarios()
      },

      async acceptEditUsuario(usuario) {
        this.showDialog = false
        await this.updateUsuario(usuario)
        await this.loadUsuarios()
      },

      acceptResetPassword(usuario) {
        this.showDialog = false
        this.isEditPass = false
        this.resetPasswordUsuario(usuario)
      },

      async loadUsuarios() {
        this.isLoading = true
        try {
          await this.fetchUsuarios()
          await this.fetchEntidades()
          await this.fetchTiposRol()
        } catch (err) {
          if (err.response.status !== 200 && err.response.status !== 500) {
            this.$notify({
              title: err.response.statusText,
              text: 'El usuario no dispone de permisos para acceder al módulo de Gestión de usuarios',
              type: 'error',
            })
          }
          this.$router.push('/')
        }

        this.isLoading = false
      },

      comprobarPermisos(listaRolesPermisos) {
        return this.$store.getters['usuario/rolesUsuario'](listaRolesPermisos)
      },

      // isAdmin (usuario) {
      //   return usuario.Rols.find(x => x.codigo === 'ADMIN') ? true : false
      // },

      filterRol(value) {
        if (!this.rolSelected) {
          return true
        }

        return value.length
          ? value[0].nombre === this.rolSelected.nombre
          : false
      },

      searchUsuarios() {
        // Atributos no validos para filtrar
        function atributoValido(atributo) {
          const atributosNoValidos = [
            'borrado',
            'fechastamp',
            'usuariostamp',
            'id_rol',
            'codigo',
            'Usuario_rol',
            'convenio',
            'documentacion',
            'cp',
            'id_distrito',
            'id_entidad',
            'id_periodicidad',
            'id_tipo_actualizacion',
            'id_tipo_entidad',
            'is_chat_default',
            'logo',
            'recogida_info',
            'id_usuario',
            'domicilio',
            'logo',
            'Cargos',
            'Usuario_cargo',
            'solicitar_cambio_password',
          ]

          const encontrado = atributosNoValidos.find((x) => x === atributo)
          return encontrado ? false : true
        }

        // Filtro de busqueda para todos los usuarios
        const searchText = this.search ? this.search : ''

        this.usuariosFilter = this.usuarios.filter(function (usuarios) {
          for (var attrUsuario in usuarios) {
            if (atributoValido(attrUsuario)) {
              // Usuario
              if (attrUsuario === 'UsuarioModificador') {
                if (usuarios[attrUsuario]) {
                  for (var attrUsuarioMod in usuarios[attrUsuario]) {
                    if (atributoValido(attrUsuarioMod)) {
                      const atributo =
                        usuarios[attrUsuario]['nombre'] +
                        ' ' +
                        usuarios[attrUsuario]['apellidos']

                      if (StringHelper.compareString(searchText, atributo))
                        return usuarios[attrUsuario]
                    }
                  }
                }
              } else if (attrUsuario === 'Rols') {
                // Rols
                if (usuarios[attrUsuario]) {
                  for (var roles of usuarios[attrUsuario]) {
                    for (var attrRol in roles) {
                      if (atributoValido(attrRol)) {
                        const atributo = roles[attrRol]

                        if (StringHelper.compareString(searchText, atributo))
                          return usuarios[attrUsuario]
                      }
                    }
                  }
                }
              } else if (attrUsuario === 'Entidad') {
                // Entidad
                if (usuarios[attrUsuario]) {
                  for (var attrEntidad in usuarios[attrUsuario]) {
                    if (atributoValido(attrEntidad)) {
                      const atributo = usuarios[attrUsuario][attrEntidad]
                      if (atributo) {
                        if (StringHelper.compareString(searchText, atributo))
                          return usuarios[attrUsuario]
                      }
                    }
                  }
                }
              } else {
                // Atributos del usuario
                if (atributoValido(attrUsuario)) {
                  if (usuarios[attrUsuario]) {
                    let atributo
                    if (attrUsuario == 'nombre' || attrUsuario == 'apellidos') {
                      atributo =
                        usuarios['nombre'] + ' ' + usuarios['apellidos']
                    } else {
                      atributo = usuarios[attrUsuario]
                    }

                    if (StringHelper.compareString(searchText, atributo))
                      return usuarios
                  }
                }
              }
            }
          }
        })
      },
    },

    mounted() {},

    created() {
      // this.loadUsuarios()

      // Mostrar acciones si currentUsuario tiene rol admin o rol mod_usuarios
      if (this.$store.getters['usuario/rolesUsuario'](['MOD_USUARIOS'])) {
        this.headers.push({
          text: 'Acciones',
          value: 'ACCIONES',
          align: 'center',
          width: '140px',
          sortable: false,
        })
      }
    },

    async activated() {
      this.loadUsuarios()
    },
  }
</script>

<style lang="scss" scoped>
  /* Estilos para que la tabla tenga un max-height y pueda crecer */
  .table {
    width: 100vw;
    max-height: calc(100vh - 280px);
  }
  .tableMobile {
    width: 100vw;
    max-height: calc(100vh - 360px);
  }
  .v-data-table {
    overflow: auto;
  }
  .v-data-table >>> .v-data-table__wrapper {
    /* Header fijo */
    overflow: unset;
  }
  /* .v-data-table >>> .v-data-footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: white;
  } */

  /* Hover transparente para todas las filas */
  ::v-deep tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
  /* Hover en color gris para las filas impares */
  ::v-deep tbody {
    tr:hover:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
  /* Color gris fila data table impar */
  ::v-deep tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  /* Sticky column data-table - Usuario */
  ::v-deep thead tr th:nth-of-type(1) {
    position: sticky;
    left: 0;
  }

  ::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(1) {
    background: whitesmoke;
    position: sticky;
    left: 0;
  }

  ::v-deep tbody tr td:nth-of-type(1) {
    background: white;
    position: sticky;
    left: 0;
  }

  /* Sticky column data-table - Acciones */
  ::v-deep thead tr th:nth-of-type(8) {
    position: sticky;
    right: 0;
  }

  ::v-deep tbody tr:nth-of-type(odd) td:nth-of-type(8) {
    background: whitesmoke;
    position: sticky;
    right: 0;
  }

  ::v-deep tbody tr td:nth-of-type(8) {
    background: white;
    position: sticky;
    right: 0;
  }

  /* Tamnaño inputs y select */
  .inputSelectMobile {
    // margin-top: -15px;
    margin-bottom: 0px;
  }

  /* Checkbox usuarios borrados/deshabilitados */
  .checkboxMobile {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  /* Tamaño select mostrar por página */
  .select {
    width: 85px;
  }
  .selectMobile {
    width: 85px;
  }

  /* Mostrar */
  .textMostrar {
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    justify-content: flex-end;
  }

  /* Resultados */
  .textResultados {
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -15px;
    margin-right: 0px;
    display: flex;
    justify-content: flex-start;
  }

  /* Paginacion */
  .paginacion {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    justify-content: flex-end;
  }

  /* Speed dial list */
  #create .v-speed-dial {
    position: absolute;
    top: 18px;
  }

  #create .v-btn--floating {
    position: relative;
  }

  ::v-deep .v-speed-dial--right {
    right: 16px;
    // top: 18px;
  }

  ::v-deep .v-speed-dial--direction-left .v-speed-dial__list,
  .v-speed-dial--direction-right .v-speed-dial__list {
    height: 100%;
    top: 0;
    padding: 0 16px;
    margin-top: 22px;
  }

  /* Alinear iconos speed dial list */
  .iconsSpeedDial {
    margin-top: 8px;
  }
</style>
